
<template>
    <div class="loginWrapper">

        <div class="topNav">

            <img class="imgLogo" :src="require(`@/assets/images/logo_trans2.png`)" />

        </div>

        <div class="formWrapper"> 

            <div v-if="authStore.errorMessage" class="alert alert-danger" role="alert">
                {{ authStore.errorMessage }}
            </div>

            <h5 style="margin-bottom: 30px; font-size: 18px;">Please enter the 6 digit number that was sent to your email</h5>

            <form @submit.prevent="authStore.verifyUserAuthCode">
     

                <div class="mb-3">
                    <label for="exampleInputPassword1" class="form-label" style="float: left; font-size: 14px;">Auth Code</label>
                    <input v-model="authStore.authCode" type="number" class="form-control" placeholder="6 digit code here" required>
                </div>

    

                <div>
                    <button type="submit" class="btn btn-primary loginBTN">Verfiy</button>
                </div>

            </form>

            <div style="margin-top: 25px;">

                <div id="emailHelp" class="form-text">Having issues? contact our support@convertlyio.app</div>

            </div>





        </div>

    </div>
</template>

<script>

import { useAuthStore } from '../../stores/AuthStore'

export default {
    name: 'VerifyAuthCode',


    setup() {

        const authStore = useAuthStore()


        return {
            authStore
        }

    }


};
</script>

<style scoped>
.imgLogo {
    float: left;
    width: 170px;
}

.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: aliceblue; */
}

.topNav {
    /* background-color: rgb(255, 255, 255); */
    width: 100%;
    position: fixed;
    top: 0;
}

.formWrapper {
    /* Styling for form wrapper */
}

.loginBTN {
    background-color: black;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
}

.loginBTN:hover {
    background-color: rgb(36, 141, 48);
}

.form-label {
    font-weight: bold;
}

.form-control {
    background-color: rgb(243, 246, 246);
}
</style>