import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/AuthStore'

// import MainEditor from '../views/QuizEditor/MainEditor'
// import MyEditor from '../views/QuizEditor/MyEditor'
import MyEditorV2 from '../views/QuizEditor/MyEditorV2'
import LiveQuiz from '../views/QuizEditor/LiveQuiz'
// import TestEditor from '../views/QuizEditor/TestEditor'

import LoginView from '../views/Auth/Login'
import RegisterView from '../views/Auth/Register'
import RegisterManager from '../views/Auth/RegisterManager'
import VerifyAuthCode from '../views/Auth/VerifyAuthCode'
import ForgotPassword from '../views/Auth/ForgotPassword'
import ResetPassword from '../views/Auth/ResetPassword'

import ContactSupport from '../views/Support/Contact'

import CreateQuiz from '../views/Wizard/CreateQuiz'

import MyDashboard from '../views/Dashboard'
import ViewAWorkspace from '../views/Workspace/ViewAWorkspace'

import MainPortal from '../views/Project/MainPortal'
import ViewQuizResponse from '../views/Responses/ViewQuizResponse'

//FOR IFRAME
import TestPage from '../views/Project/TestPage' //same domain
import LiveQuizIframe from '../views/QuizEditor/LiveQuizIframe' //user domain
import LivePopupQuizIframe from '../views/QuizEditor/LivePopupQuizIframe'

//Billing
import MainBillingView from '../views/Billing/MainBillingView'
import PaidPlans from '../views/Billing/Plans'
import AccountUsage from '../views/Billing/AccountUsage'

const routes = [

  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
  },


  {

    path: '/q/:random_string',
    name: 'LiveQuiz',
    component: LiveQuiz,
    props: true,
  

  },

  {

    path: '/embed/:random_string',
    name: 'LiveQuizIframe',
    component: LiveQuizIframe,
    props: true,
  

  },

  {

    path: '/embed/p/:random_string',
    name: 'LivePopupQuizIframe',
    component: LivePopupQuizIframe,
    props: true,
  

  },

  

  {

    path: '/auth/login',
    name: 'LoginView',
    component: LoginView,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/dashboard') : next()
    },

  },

  {

    path: '/slnk52wbd',  //hide this from public
    name: 'RegisterView',
    component: RegisterView,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/dashboard') : next()
    },

  },

  {

    path: '/auth/register',
    name: 'RegisterManager',
    component: RegisterManager,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/dashboard') : next()
    },

  },

  {

    path: '/auth/verify',
    name: 'VerifyAuthCode',
    component: VerifyAuthCode,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/dashboard') : next()
    },

  },

  {

    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/dashboard') : next()
    },

  },

  {

    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      const authStore = useAuthStore()
      authStore.token ? next('/dashboard') : next()
    },

  },

  {

    path: '/dashboard',
    name: 'MyDashboard',
    component: MyDashboard,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')

    },

  },


  // {
  //   path: '/quiz/editor/:quiz_random_string',
  //   name: 'MyEditor',
  //   component: MyEditor,
  //   props: true,
  //   beforeEnter: (to, from, next) => {

  //     const authStore = useAuthStore()
  //     authStore.token ? next() : next('/auth/login')

  //   }
  // },

  {
    path: '/quiz/editor/:quiz_random_string',
    name: 'MyEditorV2',
    component: MyEditorV2,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')

    }
  },


  {
    path: '/workspace/:workspaceUUID',
    name: 'ViewAWorkspace',
    component: ViewAWorkspace,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')

    }
  },

  {
    path: '/quiz/responses/:quiz_random_string',
    name: 'ViewQuizResponse',
    component: ViewQuizResponse,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login') 

    }
  },


  {
    path: '/project/:random_string/:workspaceUUID',
    name: 'MainPortal',
    component: MainPortal,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login') 

    }
  },


  {
    path: '/billing',
    name: 'MainBillingView',
    component: MainBillingView,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login') 

    }
  },

  {
    path: '/billing/plans',
    name: 'PaidPlans',
    component: PaidPlans,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login') 

    }
  },

  {
    path: '/account/usage',
    name: 'AccountUsage', 
    component: AccountUsage,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login') 

    }
  },

  {
    path: '/support',
    name: 'ContactSupport',
    component: ContactSupport,
    props: true,
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')

    }
  },


  {
    path: '/workspace/:workspaceUUID/create/quiz', 
    name: 'CreateQuiz',
    component: CreateQuiz, 
    props: true, 
    beforeEnter: (to, from, next) => {

      const authStore = useAuthStore()
      authStore.token ? next() : next('/auth/login')

    }
  },
  

  {
    path: '/testpage',
    name: 'TestPage',
    component: TestPage,
  
  },






]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,



})



export default router