<template>
    <div>

        <div class="dashboardBodyWrapper">


            <MainSideBarVue />



            <div class="MainContentArea">


                <div class="contentHeaderWrapper">

                    <div class="leftDivWrapper">

                        <div class="headerText">

                            Plans

                        </div>



                    </div>

                    <div class="rightDivWrapper">


                        <!-- USAGE STATES HERE -->

                    </div>



                </div>


                <center>
                    <hr class="dividerLine">
                </center>



                <div class="contentBodyWrapper">


                    <div class="outerTabWrapper">

                        <div class="tabsBox">


                            <div class="tabsWrapper">


                                <div class="tabBtn" v-for="(planTabOption, index) in authStore.plansTabOptions" :key="index"
                                    :class="{ tabBtnClicked: planTabOption.type === authStore.currentPlanTabType }"
                                    @click="authStore.currentPlanTabType = planTabOption.type">
                                    {{ planTabOption.name }}
                                </div>


                            </div>



                        </div>

                    </div>


                    <div class="plansBoxWrapper">



                        <div v-for="(paddleProduct, index) in authStore.paddlePlanProducts" :key="index" class="planItem">

                            <div class="planName">
                                {{ paddleProduct.name }}
                            </div>

                            <div class="planDesc">
                                {{ paddleProduct.desc }}
                            </div>

                            <div class="priceArea">
                                ${{ paddleProduct.monthly_price }} / month
                            </div>

                            <div class="priceMoreInfoArea">
                                Billed Monthly
                            </div>

                            <div class="ctaArea">


                                <div v-if="authStore.currentPlan.type !== 'free'">

                                    <div v-if="authStore.currentPlan.id === paddleProduct.id">

                                        <button @click="authStore.cancelPlan()" class="btn btn-danger">
                                            Cancel
                                        </button>

                                    </div>

                                    <div v-else>

                                        <button @click="authStore.swapCurrentPlan(paddleProduct.uuid)" class="button-3">
                                            Switch to this plan
                                        </button>

                                    </div>

                                </div>






                                <div v-if="authStore.userCanceledAndIsOnGracePeriod === true">

                                    <span class="badge badge-pill badge-danger">
                                        Your subscription ends on {{ authStore.endsAt ?? '' }}
                                    </span>

                                </div>


                                <div v-if="authStore.currentPlan.type === 'free'">

                                    <button @click="openPaddleModal(paddleProduct.payLink)" class="button-3">Get {{
                                        paddleProduct.name }}</button>

                                        <!-- <button class="button-3">Get {{
                                        paddleProduct.name }}</button> -->

                                </div>


                                <!-- <div v-if="authStore.userCanceledAndIsOnGracePeriod === true">

                                    <span class="badge badge-pill badge-danger">
                                        Your subscription ends on [date here]
                                    </span>

                                </div>



                                <button v-if="authStore.currentPlan.id === paddleProduct.id" @click="authStore.cancelPlan()"
                                    class="btn btn-danger">
                                    Cancel
                                </button>

                                <button v-else @click="openPaddleModal(paddleProduct.payLink)" class="button-3">Get {{
                                    paddleProduct.name }}</button> -->

                            </div>


               

                            <div v-if="paddleProduct.type === 'starter'">

                                   <div v-for="(feature, index) in authStore.starterPlanFeatures" :key="index"> 

                                        <div class="featureItem">

                                            <div class="imgWrapper">
                                                <img :src="require(`@/assets/images/tick.png`)" width="12" />
                                            </div>

                                            <div class="featureDescWrapper">
                                                {{feature.desc}}
                                            </div>

                                        </div>

                                    </div>

                            </div>

                            <div v-if="paddleProduct.type === 'pro'">

                                 <div v-for="(feature, index) in authStore.proPlanFeatures" :key="index"> 

                                        <div class="featureItem">

                                            <div class="imgWrapper">
                                                <img :src="require(`@/assets/images/tick.png`)" width="12" />
                                            </div>

                                            <div class="featureDescWrapper">
                                                {{feature.desc}}
                                            </div>

                                        </div>

                                    </div>

                            </div>

                            <div v-if="paddleProduct.type === 'business'">

                                 <div v-for="(feature, index) in authStore.businessPlanFeatures" :key="index"> 

                                        <div class="featureItem">

                                            <div class="imgWrapper">
                                                <img :src="require(`@/assets/images/tick.png`)" width="12" />
                                            </div>

                                            <div class="featureDescWrapper">
                                                {{feature.desc}}
                                            </div>

                                        </div>

                                    </div>

                            </div>

                         

                        </div>


                    </div>




                </div>



            </div>


        </div>



    </div>
</template>

<script>
import MainSideBarVue from '@/components/MainSideBar.vue';
import { useAuthStore } from "@/stores/AuthStore";
import { onMounted, ref } from 'vue';


export default {
    name: 'PaidPlans',

    components: {
        MainSideBarVue
    },



    setup() {

        const authStore = useAuthStore();


        var selectedProductId = ref(0)

        /* eslint-disable */
        var setSelectedProductId = (paddle_id) => {

            selectedProductId.value = paddle_id

            var checkoutConfig = {
                product: selectedProductId.value,
                email: authStore.userData.email,
                passthrough: {
                    billable_id: authStore.userData.id,
                    billable_type: 'App\\Models\\User',

                },

                successCallback: function (data) {

                    // updateIsSubscribed()

                    alert('Purchase was successful');
                    console.log('Purchase was successful!');

                },
            };

            // Paddle.Environment.set('sandbox');
            Paddle.Checkout.open(checkoutConfig);

            console.log('checkoutConfig', checkoutConfig)


        }


        const openPaddleModal = (payLink) => {

            // Paddle.Environment.set('sandbox');
            Paddle.Checkout.open({

                override: payLink,
                successCallback: function (data) {

                    

                    authStore.activateUserPaidPlanAfterPaddleSuccessCallback(data.product.id);

                    authStore.getUserData() //the nrefresh user data

                    console.log('Payment was successful!', data);
                    console.log('PADDLE ID', data.product.id);

                    alert('Purchase was successful');


                },
                errorCallback: function (error) {

                    console.log('There was an error with the payment', error);
                    alert('Purchase did not went through. Please contact support');
                    // Handle the error
                    // You can update your application's state here or make an API call to your backend
                }
            });

        }

        onMounted(() => {

            // eslint-disable-next-line
            const script = document.createElement('script');
            script.src = "https://cdn.paddle.com/paddle/paddle.js";
            document.body.appendChild(script);

            //fetch user data
            authStore.getUserData()

            //fetch plans
            authStore.getPlans()

            // eslint-disable-next-line
            Paddle.Setup({ vendor: 129746 }); // Replace with your own vendor ID
        });


        return {
            authStore, setSelectedProductId, openPaddleModal
        }

    }


};
</script>

<style scoped>
.dashboardBodyWrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}


.MainContentArea {
    width: 83%;
    overflow-y: auto;
    padding-bottom: 30px;
}



.contentHeaderWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    /* background-color: antiquewhite; */
    display: flex;
}

.contentBodyWrapper {
    /* background-color: aquamarine; */
    padding-left: 25px;
    padding-right: 25px;

}

.leftDivWrapper {
    /* background-color: rgb(167, 167, 138); */
    width: 50%;
}

.rightDivWrapper {
    /* background-color: rgb(206, 197, 206); */
    width: 50%;
}

.headerText {
    font-weight: bold;
    font-size: 25px;
    float: left;
}

.dividerLine {
    width: 95%;
    color: #afb2c6;
}



.billingWrapper {
    width: 100%;
    /* background-color: #afb2c6; */
    display: flex;

}

.currentPlanWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
}

.headerPlan {
    text-align: left;
    font-weight: bold;
}

.subHeaderPlan {
    text-align: left;
}

.viewPlansWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;

}


.featuresAndInvoicesBox {
    display: flex;
}

.currentPlanFeatures {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.featureHeader {
    /* background-color: #afb2c6; */
    text-align: left;
    font-weight: bold;
}


.invoiceHeaderText {
    /* background-color: #afb2c6; */
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.invoicesContainer {
    /* background-color: #94d3a2; */
    height: 640px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 5px;
    padding: 15px;
}

.featureItem {
    /* background-color: #94d3a2; */
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
    align-items: flex-start;
    font-size: 14px;
  
}




.featureDescWrapper {
    text-align: left;
}

.invoicesWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 10px;
    height: 700px;
    

}

.imgIcon {
    margin-right: 6px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}


/* CSS */
.button-3 {
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
}

.button-3:hover {
    background-color: #2c974b;
}

.button-3:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
}

.button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
}

.button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


.outerTabWrapper {
    /* background-color: #94d3a2; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.tabsBox {
    background-color: aliceblue;
    width: 400px;
}




.tabBtn {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #f1efef;
    background: rgb(255, 255, 255);
    font-size: 13px;
    color: rgb(8, 5, 5);
    cursor: pointer;
}

.tabBtnClicked {
    background: #efefef;
    color: rgb(33, 31, 31);
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
}

.tabBtn:hover {
    background: #f9f9f9;
    color: rgb(33, 31, 31);
}

.tabsWrapper {
    display: flex;
    /* background: yellow; */
}

.plansBoxWrapper {
    display: flex;
    /* background-color: #afb2c6; */
    margin-top: 20px;
}

.planItem {
    /* background-color: aqua; */
    width: 100%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
}

.planName {
    /* background-color: #f4f4f6; */
    width: 100%;
    padding-top: 15px;
    padding-bottom: 7px;
    font-size: 20px;
    font-weight: bold;
}

.planDesc {
    /* background-color: #d5d5d5; */
    width: 100%;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
}

.priceArea {

    /* background-color: #988888; */
    width: 100%;
    padding-top: 15px;
    font-size: 21px;
    font-weight: bold;
}

.priceMoreInfoArea {
    width: 100%;
    font-size: 13px;
}

.ctaArea {
    /* background-color: #e7f8c1; */
    width: 100%;
    padding: 15px;

}

.featureItem {
    /* background-color: #94d3a2; */
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 5px;
}

.imgWrapper {
    margin-right: 10px;
}
</style>