import { defineStore } from 'pinia'
import QuizService from '@/services/QuizService.js'
import AuthService from '@/services/AuthService.js'
import { debounce } from 'lodash'
import { toast } from 'vue3-toastify'

/* eslint-disable */

export const useQuizEditorStoreV2 = defineStore('quizEditorStoreV2', {
  state: () => ({
    defaultPagesArray: [],
    outcomePagesArray: [],

    currentDefaultPageIndex: 0,
    currentDefaultPage: {},

    currentOutcomePageIndex: null,
    currentOutcomePage: {},

    currentPageInView: {}, //whether default or outcome
    currentPageInViewIndex: {}, //whether default or outcome

    toolbarWidgets: [
      {
        id: 1,
        label: 'Text',
        icon_name: 'text',
        type: 'text'
      },

      {
        id: 2,
        label: 'Header',
        icon_name: 'headertext',
        type: 'header_text'
      },

      {
        id: 3,
        label: 'Long Text',
        icon_name: 'textbox',
        type: 'textbox'
      },

      {
        id: 4,
        label: 'Text Field',
        icon_name: 'input',
        type: 'text_input'
      },

      // {
      //     id: 5,
      //     label: "Slider",
      //     icon_name: "number",
      //     type: 'number_input',

      // },

      {
        id: 6,
        label: 'Time',
        icon_name: 'time',
        type: 'time_input'
      },

      {
        id: 7,
        label: 'Date',
        icon_name: 'date',
        type: 'date_input'
      },

      {
        id: 8,
        label: 'Email',
        icon_name: 'email',
        type: 'email_input'
      },

      {
        id: 9,
        label: 'Button',
        icon_name: 'button',
        type: 'button'
      },

      {
        id: 10,
        label: 'Image',
        icon_name: 'imgupload',
        type: 'image'
      },

      {
        id: 11,
        label: 'Question',
        icon_name: 'question',
        type: 'question'
      },

      {
        id: 12,
        label: 'Embed',
        icon_name: 'embed',
        type: 'embed'
      },

      {
        id: 13,
        label: 'Video',
        icon_name: 'video',
        type: 'video'
      },

      {
        id: 14,
        label: 'Sharing',
        icon_name: 'sharing',
        type: 'sharing'
      },

      {
        id: 15,
        label: 'Opt-in',
        icon_name: 'checkbox',
        type: 'checkbox'
      },

      // {
      //     id: 16,
      //     label: "Rating",
      //     icon_name: "rating",
      //     type: 'rating',
      // },

      {
        id: 17,
        label: 'Score',
        icon_name: 'score',
        type: 'score'
      },

      {
        id: 18,
        label: 'Appointment',
        icon_name: 'calendar',
        type: 'calendar_embed'
      }
    ],

    widgetElements: {
      page: {
        id: '',
        type: 'page',
        design_layout: [],
        position: null,
        page_title: 'Page'
      },

      header_text: {
        id: '',
        type: 'header_text',
        main_headline: 'Headline Text Here',
        position: '',
        tab: 'textTab',
        font_color: '#000000',
        font_size: 30,
        font_family: 'Arial, sans-serif',
        font_weight: 'normal',
        alignment: 'center',
        margin: 0
      },

      text: {
        id: '',
        type: 'text',
        text_value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        position: '',
        tab: 'textTab',
        font_color: '#000000',
        font_size: 17,
        font_family: 'Arial, sans-serif',
        font_weight: 'normal',
        alignment: 'center',
        margin: 0
      },

      // text_input: {
      //     id: '',
      //     type: 'text_input',
      // },

      // text_input: {
      //     id: '',
      //     type: 'text_input',
      // },

      // number_input: {
      //     id: '',
      //     type: 'number_input'
      // },

      // input_field: {
      //     id: '',
      //     type: 'input_field',
      //     label: 'Label here',
      //     tab: 'inputTab',
      //     value: '',
      //     is_required: true,
      //     placeholder: 'Placeholder text',
      //     background_color: 'white',
      //     width: 300,
      //     border_radius: 5,
      //     padding: 10,
      //     border_color: '#c0c0c0',
      //     border_width: 1,
      //     border_style: 'solid',
      //     box_shadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
      //     input_field_float: 'left',
      //     input_font_color: '#898989',
      //     margin: 12,
      //     label_font_color: '#000000',
      //     label_font_size: 16,
      //     label_float: 'left',
      //     justify_content: 'left'

      // },

      button: {
        id: '',
        tab: 'buttonTab',
        type: 'button',
        text: 'Button Text Here',
        position: '',
        padding: 13,
        border_radius: 10,
        border_color: '#34a34d',
        border_width: 3,
        border_style: 'solid',
        background_color: '#34a34d',
        font_color: '#FFFFFF',
        font_size: 18,
        font_family: 'Arial, sans-serif',
        font_weight: 'bold',
        alignment: 'center',
        width: 180,
        box_shadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        margin: 0
      },

      image: {
        id: '',
        tab: 'imageTab',
        type: 'image',
        src_url: '',
        uploaded_img_url: '',
        position: '',
        width: 30,
        margin: 0,
        border_radius: 20,
        alignment: 'center'
      },

      embed: {
        id: '',
        tab: 'embedTab',
        type: 'embed',
        embed_string: '',
        position: ''
      },

      video: {
        id: '',
        tab: 'videoTab',
        type: 'video',
        video_url: '',
        video_embed_code: '',
        position: '',
        video_type: 'youtube',
        border_radius: 20,
        box_shadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        width: 500,
        margin: 0
      },

      sharing: {
        id: '',
        type: 'sharing',
        platforms: [
          {
            id: '',
            name: 'Facebook',
            img_name: 'facebook',
            share_text: 'Share',
            is_included: true
          },
          {
            id: '',
            name: 'Twitter',
            img_name: 'twitter',
            share_text: 'Share',
            is_included: true
          },
          {
            id: '',
            name: 'LinkedIn',
            img_name: 'linkedin',
            share_text: 'Share',
            is_included: true
          },
          {
            id: '',
            name: 'Pinterest',
            img_name: 'pinterest',
            share_text: 'Share',
            is_included: false
          }
        ],
        position: '',
        main_share_text: 'Share',
        font_color: '#000000',
        font_size: 18,
        font_family: 'Arial, sans-serif',
        font_weight: 'bold',
        alignment: 'center',
        img_width: 25,
        margin: 0,
        tab: 'sharingTab'
      },

      question: {
        id: '',
        type: 'question',
        question_text: 'Whats your question?',
        answers: [
          // {
          //     id: '',
          //     position: '',
          //     answer_text: 'First answer',
          //     is_correct: false,
          //     next_page_id: ''
          // },
          // {
          //     id: '',
          //     position: '',
          //     answer_text: 'Second answer here',
          //     is_correct: false,
          //     next_page_id: ''
          // },
          // {
          //     id: '',
          //     position: '',
          //     answer_text: 'Third answer here',
          //     is_correct: false,
          //     next_page_id: ''
          // },
          // {
          //     id: '',
          //     position: '',
          //     answer_text: 'Fourth answer',
          //     is_correct: true,
          //     next_page_id: ''
          // },
        ],
        position: '',
        tab: 'questionTab',
        question_design: {
          id: '',
          font_color: '#000000',
          font_size: 25,
          font_family: 'Arial, sans-serif',
          font_weight: 'bold',
          alignment_box: 'center',
          margin: 5,
          text_align: 'center'
        },
        answer_design: {
          id: '',
          font_color: '#000000',
          font_size: 15,
          font_family: 'Arial, sans-serif',
          font_weight: 'normal',
          margin: 12,
          padding: 15,
          background_color: '#FFFFFF',
          border_radius: 6,
          border_color: '#ebebeb',
          border_width: 1,
          border_style: 'solid',
          text_align: 'left'
        },
        answer_type: 'text_only',
        is_multi_answer_selection: 'no'
      },

      textbox: {
        id: '',
        tab: 'textboxTab',
        type: 'textbox',
        width: 300
      },

      checkbox: {
        id: '',
        tab: 'checkboxTab',
        type: 'checkbox'
      },

      rating: {
        id: '',
        tab: 'ratingTab',
        type: 'rating'
      },

      score: {
        type: 'score'
      }
    },

    answerTypesArray: [
      { id: 1, name: 'Text only', type: 'text_only' },
      { id: 2, name: 'Text with image', type: 'text_with_image' }
    ],

    fontStylesArray: [
      'Arial, sans-serif',
      'Helvetica, sans-serif',
      'Verdana, sans-serif',
      'Tahoma, sans-serif',
      'Trebuchet MS, sans-serif',
      'Times New Roman, serif',
      'Georgia, serif',
      'Courier New, monospace',
      'Lucida Console, monospace',
      'Impact, Charcoal, sans-serif',
      'Arial Black, Gadget, sans-serif',
      'Comic Sans MS, cursive',
      'Palatino Linotype, Book Antiqua, Palatino, serif',
      'Lucida Sans Unicode, Lucida Grande, sans-serif',
      'MS Sans Serif, Geneva, sans-serif',
      'MS Serif, New York, serif'
    ],

    fontAlignmentArray: [
      { id: 1, label: 'Left', align: 'left' },
      { id: 2, label: 'Center', align: 'center' },
      { id: 3, label: 'Right', align: 'right' }
    ],

    videoPlatforms: [
      { id: 1, name: 'Youtube', video_type: 'youtube' },
      { id: 2, name: 'Vimeo', video_type: 'vimeo' },
      { id: 3, name: 'Wistia', video_type: 'wistia' },
      { id: 4, name: 'External Video', video_type: 'external' }
    ],

    fontWeightArray: [
      { id: 1, label: 'Normal', weight: 'normal' },
      { id: 2, label: 'Bold', weight: 'bold' }
    ],

    currentPage: {},
    currentPageIndex: 0,

    currentDefaultPageIndex: 0,
    currentOutcomePageIndex: 0,

    previewPageIndex: 0,

    designAreaArray: [],

    currentElementIndex: null,
    showDeleteIcon: false,

    currentQuiz: {},

    backgroundTypes: [
      { id: 1, name: 'Gradient', type: 'gradient_bg' },
      { id: 2, name: 'Image', type: 'img_bg' },
      { id: 3, name: 'Color', type: 'normal_bg' }
    ],

    gradientBGs: [
      {
        id: 1,
        name: 'Shady Lane',
        background_value: 'linear-gradient(90deg, #3F2B96 0%, #A8C0FF 100%)'
      },
      {
        id: 2,
        name: 'Calm',
        background_value: 'linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%)'
      },
      {
        id: 3,
        name: 'Disco Club',
        background_value: 'linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%)'
      },
      {
        id: 4,
        name: 'Sky Blue',
        background_value:
          'linear-gradient(62deg, #8EC5FC 0%, #ffffff 99%, #E0C3FC 100%)'
      },
      {
        id: 5,
        name: 'Flow',
        background_value: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)'
      },
      {
        id: 6,
        name: 'Pasture',
        background_value: 'linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%)'
      },
      {
        id: 7,
        name: 'Goldie',
        background_value: 'linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)'
      },
      {
        id: 8,
        name: 'Bubble',
        background_value: 'linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%)'
      }
      // {id: 9, name: 'Pasture', background_value: 'linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%)'}
    ],

    selectedProgressBarColor: '',
    is_progress_bar_enabled: '',
    selectedOuterBGtype: '',
    selectedOuterBGgradient: '',
    selectedNormalBGcolor: '',
    selectedBGimageUrl: '',

    defaultOuterBGgradient: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
    defaultOuterBGnormalColor: '#D3D3D3',
    defaultOuterBGgimageURL:
      'https://img.freepik.com/free-photo/white-textured-concrete_125540-1826.jpg',

    selectedCardBGtype: '',
    selectedCardBGgradient: '',
    selectedCardNormalBGcolor: '',
    selectedCardBGimageUrl: '',

    defaultCardBGgradient: 'linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%)',
    defaultCardBGnormalColor: '#FFFFFF',
    defaultCardBGgimageURL:
      'https://img.freepik.com/free-photo/distressed-yellow-wall-texture-background_1017-18217.jpg',

    progressPercentage: 0,

    show_label: '',

    quizBGDesigns: {
      outer_bg_color: '',
      card_bg_color: ''
    },

    clickActionTypes: [
      {
        id: 1,
        name: 'Single answer',
        type: 'single_answer',
        desc: 'User selects one answer goes to next page'
      },
      {
        id: 2,
        name: 'Multiple answers',
        type: 'multiple_answers',
        desc: 'User can select more than one answers then click next button'
      }
      // { id: 3, name: 'Skip Logic', type: 'branch_logic', desc: 'Answers route to specific pages' },
    ],

    questionTabOptions: [
      { id: 1, name: 'Question Design' },
      { id: 2, name: 'Answers Design' },
      { id: 3, name: 'Click Action' }
    ],

    currentQuestionTabId: 1,

    currentElementSetInStore: '',

    linkToTypes: [
      { id: 1, name: 'None', type: 'none' },
      { id: 2, name: 'Redirect to', type: 'link_to' }
      // { id: 3, name: 'Popup', type: 'popup' },
    ],

    buttonActionTypes: [
      { id: 1, name: 'Next Page', type: 'next_page' },
      { id: 2, name: 'Open URL in New Tab', type: 'open_url_in_new_tab' },
      // { id: 3, name: 'Open URL in Same Tab', type: 'open_url_in_same_tab' },
      { id: 4, name: 'Restart', type: 'restart_quiz' }
    ],

    answers_selected_count: 0,

    showPagePopOver: false,

    currentAnswerToMap: {},

    answerNavigationOptions: [
      { id: 1, name: 'Next Page', type: 'next_page' },
      { id: 2, name: 'Skip Logic', type: 'skip_logic' }
    ],

    outcomeTracker: [],
    defaultSubTypePagesOnly: [],
    theFinalOutcomeIndex: null,
    isTheOutcomeIndexReady: false,
    theLastDefaultPageIndex: null,

    userAnswers: [],
    pagesWithQuestionOnlyForScoreBased: [],
    correctAnswersCount: null,
    questionPagesCount: null,
    quesitonsWithFinalScore: [],

    showAnswerOutcomeModal: false,
    currentAnswer: {},
    currentAnswerOutcomeArray: [],

    deviceType: 'Desktop', // Default device type,

    currentDevicePreview: 'Desktop',

    socialPlatforms: [
      {
        id: 1,
        platform_name: 'Instagram',
        type: 'instagram',
        img_name: 'instagram'
      },
      { id: 2, platform_name: 'TikTok', type: 'tiktok', img_name: 'tiktok' },
      { id: 3, platform_name: 'Youtube', type: 'youtube', img_name: 'youtube' },
      {
        id: 4,
        platform_name: 'Facebook',
        type: 'facebook',
        img_name: 'facebook'
      },
      { id: 5, platform_name: 'Twitter', type: 'twitter', img_name: 'twitter' },
      {
        id: 6,
        platform_name: 'Pinterest',
        type: 'pinterest',
        img_name: 'pinterest'
      },
      {
        id: 7,
        platform_name: 'LinkedIn',
        type: 'linkedin',
        img_name: 'linkedin'
      },
      { id: 8, platform_name: 'Website', type: 'website', img_name: 'website' }
    ],

    calendarApps: [
      { id: 1, calender_app_name: 'Calendly', type: 'Calendly' },
      { id: 2, calender_app_name: 'Cal.com', type: 'Cal.com' },
      { id: 3, calender_app_name: 'Youcanbook.me', type: 'Youcanbook.me' }
    ],

    imageIsUploading: false,

    accountUsageData: {},

    showDeletOutcomePageModal: false,

    showAnswerPipingModal: false,


    questionsAnsweredArray: [],



  }),

  getters: {
    getAllPages(state) {
      // var pages = state.motherArray

      return []

      // state.motherArray.forEach((page) => {

      //     if (page.sub_type === 'outcome') {
      //         outcomePages.push(page);
      //     }

      // });

      // return outcomePages;
    },

    getAllOutcomePages(state) {
      return answer => {
        var outcomePages = []

        var answerOutcomePagesArray = this.currentAnswerOutcomeArray

        this.outcomePagesArray.forEach(page => {
          outcomePages.push(page)
        })

        outcomePages.forEach(page => {
          page.hasMatch = answerOutcomePagesArray.some(
            item => item.page_id === page.id
          )
        })

        return outcomePages
      }
    },

    filteredBackgroundTypes() {
      return this.backgroundTypes.filter(option => option.type !== 'img_bg')
    },

    defaultPagesOnly() {
      // return this.motherArray.filter(page => page.sub_type === 'default')
    },

    ////only outcome page must have options for url open in new and same tab
    buttonActionTypesForDefaultPages() {
      return this.buttonActionTypes.filter(
        option =>
          option.type !== 'open_url_in_new_tab' &&
          option.type !== 'open_url_in_same_tab'
      )
    },

    buttonActionTypesForOutcomePages() {
      return this.buttonActionTypes.filter(
        option => option.type !== 'next_page'
      )
    },

    //get all questions up to the current index for answer piping
    getQuestionsForAnswerPiping() {
      //first need to know if its default or outcome page in current view
      //if its default, use the currentPageInViewIndex to get all question elements from previous pages to that index

      let questions = [];

      if (this.currentPageInView.sub_type === 'default') {

        let pages = this.defaultPagesArray.slice(0, this.currentPageInViewIndex)

        pages.forEach(page => {
          page.design_layout.forEach(element => {
            if (element.type === 'question') {
              questions.push(element)
            }
          })
        })

      }

      if (this.currentPageInView.sub_type === 'outcome') {

        this.defaultPagesArray.forEach(page => {

          page.design_layout.forEach(element => {
            if (element.type === 'question') {
              questions.push(element)
            }
          })
        })

      }

      return questions
    },

    getInputFieldsForAnswerPiping() {

      let inputFields = []

      if (this.currentPageInView.sub_type === 'default') {

        let pages = this.defaultPagesArray.slice(0, this.currentPageInViewIndex)

    
        pages.forEach(page => {
          page.design_layout.forEach(element => {
            if (
              element.type === 'text_input' ||
              element.type === 'number_input' ||
              element.type === 'date_input' ||
              element.type === 'email_input' ||
              element.type === 'textbox' ||
              element.type === 'time_input'
            ) {
              inputFields.push(element)
            }
          })
        })


      }

      if (this.currentPageInView.sub_type === 'outcome') {

        this.defaultPagesArray.forEach(page => {

          page.design_layout.forEach(element => {
            if (
              element.type === 'text_input' ||
              element.type === 'number_input' ||
              element.type === 'date_input' ||
              element.type === 'email_input' ||
              element.type === 'textbox' ||
              element.type === 'time_input'
            ) {
              inputFields.push(element)
            }
          })
        })

      }

      

      return inputFields
    }
  },

  actions: {
    fetchQuiz(quiz_random_string) {
      //refresh variable
      this.quiz = {}

      this.defaultPagesArray = []
      this.outcomePagesArray = []

      QuizService.fetchQuizDataForEditorV2(quiz_random_string)
        .then(response => {
          this.currentQuiz = response.data.quiz

          var defaultPages = response.data.defaultPagesArray
          var outcomePages = response.data.outcomePagesArray

          defaultPages.forEach(page => {
            this.defaultPagesArray.push(page)
          })

          outcomePages.forEach(page => {
            this.outcomePagesArray.push(page)
          })

          this.currentDefaultPage = this.defaultPagesArray[0] //set first page in index as current page
          this.currentPageInView = this.defaultPagesArray[0] //used for both default & outcome pages when adding elements
          this.currentPageInViewIndex = 0

          console.log('defaultPagesArray', this.defaultPagesArray)
          console.log('outcomePagesArray', this.outcomePagesArray)

          //populate quiz BG styling
          this.selectedOuterBGtype = this.currentQuiz.outer_bg_type
          this.selectedCardBGtype = this.currentQuiz.card_bg_type
          this.selectedProgressBarColor = this.currentQuiz.progress_bar_bg_color
          this.is_progress_bar_enabled =
            this.currentQuiz.is_progress_bar_enabled

          if (this.currentQuiz.outer_bg_type == 'gradient_bg') {
            this.selectedOuterBGgradient = this.currentQuiz.outer_bg_gradient
          }
          if (this.currentQuiz.outer_bg_type == 'normal_bg') {
            this.selectedNormalBGcolor = this.currentQuiz.outer_bg_color
          }
          if (this.currentQuiz.outer_bg_type == 'img_bg') {
            this.selectedBGimageUrl = this.currentQuiz.outer_bg_img_url
          }

          //populate quiz card BG styling
          if (this.currentQuiz.card_bg_type == 'gradient_bg') {
            this.selectedCardBGgradient = this.currentQuiz.card_bg_gradient
          }

          if (this.currentQuiz.card_bg_type == 'normal_bg') {
            this.selectedCardNormalBGcolor = this.currentQuiz.card_bg_color
          }

          if (this.currentQuiz.card_bg_type == 'img_bg') {
            this.selectedCardBGimageUrl = this.currentQuiz.card_bg_img_url
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    refreshTheDesign() {
      console.log('refactor this code')

      // QuizService.fetchQuizData(this.currentQuiz.random_string)
      //   .then(response => {
      //     var pages = response.data.pages

      //     this.motherArray = []

      //     pages.forEach(page => {
      //       this.motherArray.push(page)
      //     })

      //     // console.log('REFRESHED RESPONSE: ', response.data)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },

    addPageToDefaultPagesArray() {
      QuizService.saveANewPage(this.currentQuiz.id)
        .then(response => {
          var newPage = response.data.newPage

          this.defaultPagesArray.push(newPage)

          this.currentPageInView = newPage

          //find the index of the newly added page
          const newPageIndex = this.defaultPagesArray.findIndex(
            page => page.id === newPage.id
          )

          this.currentDefaultPageIndex = newPageIndex
        })
        .catch(err => {
          console.log(err)
        })
    },

    addPageToOutcomePagesArray() {
      //save page to database and return it
      QuizService.saveNewOutcomePage(this.currentQuiz.id)
        .then(response => {
          var newOutcomePage = response.data.newOutcomePage

          this.outcomePagesArray.push(newOutcomePage)

          this.currentPageInView = newOutcomePage

          //find the index of the newly added page
          const newPageIndex = this.outcomePagesArray.findIndex(
            page => page.id === newOutcomePage.id
          )

          this.currentOutcomePageIndex = newPageIndex
        })
        .catch(err => {
          console.log(err)
        })
    },

    addElement(type) {
      const formData = {
        element_type: type
      }

      QuizService.storePageElement(formData, this.currentPageInView.id)
        .then(response => {
          //add returned element in its page array
          var element = response.data.element

          this.currentPageInView.design_layout.push(element)

          //for question set to 1 currentQuestionTabId
          this.currentQuestionTabId = 1

          console.log(this.currentPageInView.design_layout)
        })
        .catch(err => {
          console.log(err)
        })
    },

    checkForEmptyMotherArray() {
      // if (this.motherArray.length == 0) {
      //     //start with a blank page
      //     const blankPage = this.widgetElements.page
      //     //give a id. note to be generate from db
      //     blankPage.id = Math.floor((Math.random() * 100) + 1);
      //     //add to motherarray
      //     this.motherArray.push({ ...blankPage })
      //     //set blankPage as current page
      //     this.currentPage = blankPage
      //     console.log('yes')
      // } else {
      //     console.log('load data from DB')
      // }
    },

    addAnswerToQuestion(currentElement) {
      const formData = {
        question: currentElement
      }

      QuizService.addNewAnswer(formData)
        .then(response => {
          let newAnswer = response.data.newAnswer

          currentElement.answers.push(newAnswer)

          console.log(newAnswer)
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteAnswerFromAQuestion(answer, currentElement) {
      const formData = {
        id: answer.id
      }

      QuizService.deleteThisAnswer(formData)
        .then(response => {
          // Find the index of the answer in the answers array
          const answerIndexToDelete = currentElement.answers.findIndex(
            ans => ans.id === answer.id
          )

          // Delete the answer from the question
          if (answerIndexToDelete !== -1) {
            currentElement.answers.splice(answerIndexToDelete, 1)
          }

          // //get new array lenght for answers
          // currentElement.answers.lenght

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    limitText(body) {
      if (body.length > 35) {
        return body.substring(0, 35) + '...'
      }

      return body
    },

    limitAnswerText(body) {
      if (body.length > 10) {
        return body.substring(0, 10) + '...'
      }

      return body
    },

    limitPageNameText(body) {
      if (body.length > 10) {
        return body.substring(0, 10) + '...'
      }

      return body
    },

    deleteADefaultPage() {
      QuizService.deleteAPage(this.currentPageInView.id)
        .then(response => {
          //current page is already set so delete
          if (this.currentDefaultPageIndex !== -1) {
            this.defaultPagesArray.splice(this.currentDefaultPageIndex, 1)
          }

          this.currentDefaultPageIndex = this.defaultPagesArray.length - 1

          this.currentDefaultPage =
            this.defaultPagesArray[this.currentDefaultPageIndex]
          this.currentPageInView =
            this.defaultPagesArray[this.currentDefaultPageIndex]
          this.currentPageInViewIndex = this.currentDefaultPageIndex
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteAOutcomePage() {
      QuizService.deleteAPage(this.currentPageInView.id)
        .then(response => {
          this.showDeletOutcomePageModal = false

          //current page is already set so delete
          if (this.currentOutcomePageIndex !== -1) {
            this.outcomePagesArray.splice(this.currentOutcomePageIndex, 1)
          }

          this.currentOutcomePageIndex = this.outcomePagesArray.length - 1

          this.currentPageInViewIndex = this.currentOutcomePageIndex

          this.currentOutcomePage =
            this.outcomePagesArray[this.currentOutcomePageIndex]
          this.currentPageInView =
            this.outcomePagesArray[this.currentOutcomePageIndex]
          this.currentPageInViewIndex = this.currentOutcomePageIndex
        })
        .catch(err => {
          console.log(err)
        })
    },

    deleteElement(element) {
      const formData = {
        pageElement: element
      }

      QuizService.deletePageElement(formData)
        .then(response => {
          //find the element and remve from DOM
          const elementIndexToDelete =
            this.currentPageInView.design_layout.findIndex(
              ele => ele.id === element.id
            )

          if (elementIndexToDelete !== -1) {
            this.currentPageInView.design_layout.splice(elementIndexToDelete, 1)
          }

          console.log('LOOK AT THIS: ', response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    // This code will be executed after a 5000ms debounce delay
    // Add your logic here, such as making an API call or updating the data
    debouncedSaveDesign: debounce(function () {
      this.saveDesign()
      console.log('TIME PASSED')
    }, 4000),

    clickActionTypeWarning(currentElement) {
      if (this.currentQuiz.type === 'score_based_outcomes') {
        alert(
          "IMPORTANT: Switching the click action type resets the answers that marked 'correct'. Make sure to re-select the correct answer."
        )

        //change the currentelement answers is_correct to 0
        currentElement.answers.forEach(answer => {
          answer.is_correct = 0
        })

        this.saveDesign()
        console.log('change the currentelement answers is_correct to 0')
      }
    },

    setOuterBGgradient(gradient) {
      this.selectedOuterBGgradient = gradient.background_value
    },

    setCardBGgradient(gradient) {
      this.selectedCardBGgradient = gradient.background_value
    },

    updateOuterBGtype() {
      if (this.selectedOuterBGtype === 'normal_bg') {
        this.selectedNormalBGcolor = this.defaultOuterBGnormalColor
      }

      if (this.selectedOuterBGtype === 'gradient_bg') {
        this.selectedOuterBGgradient = this.defaultOuterBGgradient
      }

      if (this.selectedOuterBGtype === 'img_bg') {
        this.selectedBGimageUrl = this.defaultOuterBGgimageURL
      }
    },

    updateCardBGtype() {
      if (this.selectedCardBGtype === 'normal_bg') {
        this.selectedCardNormalBGcolor = this.defaultCardBGnormalColor
      }

      if (this.selectedCardBGtype === 'gradient_bg') {
        this.selectedCardBGgradient = this.defaultCardBGgradient
      }

      if (this.selectedCardBGtype === 'img_bg') {
        this.selectedCardBGimageUrl = this.defaultCardBGgimageURL
      }
    },

    updateVideoElement(currentElement) {
      //use the page_element_id to find the video
      var videoElement = this.currentPage.design_layout.find(
        element => element.page_element_id === currentElement.page_element_id
      )

      //update it
      videoElement.video_url = currentElement.video_url
      videoElement.video_embed_code = currentElement.video_embed_code

      // console.log('CURRENT PAGE: ', this.currentPage)
      console.log(
        'CURRENT ELEMENT: ',
        currentElement.video_url,
        currentElement.video_embed_code
      )
      // console.log('FOUND ELEMENT: ', videoElement)
      // console.log('NEWLY UPDATED VIDEO ELEMENT: ', videoElement)
    },

    nextQuestion(answer) {
      ///check if this answer is skip_logic or just next_page
      if (answer.answer_navigation_type === 'skip_logic') {
        const nexPageIndexToSkipTo = this.defaultPagesArray.findIndex(
          page => page.id === answer.next_page_id
        )

        //jump to that index
        this.previewPageIndex = nexPageIndexToSkipTo

        this.calcProgressPercentage()
      }

      if (answer.answer_navigation_type === 'next_page') {
        this.previewPageIndex++

        this.calcProgressPercentage()
      }
    },

    validateAndNext(page) {
      const elements = page.design_layout

      //loop over each input element and check for empty fields

      var errorCount = 0

      let isRestartQuizButton = false

      elements.forEach(element => {
        element.show_error = 0 //reset

        if (
          element.type === 'text_input' &&
          element.is_required === 1 &&
          (element.value === '' || element.value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element.label)
        }

        if (
          element.type === 'number_input' &&
          element.is_required === 1 &&
          (element.number_value === '' || element.number_value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element)
        }

        if (
          element.type === 'time_input' &&
          element.is_required === 1 &&
          (element.value === '' || element.value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element)
        }

        if (
          element.type === 'date_input' &&
          element.is_required === 1 &&
          (element.value === '' || element.value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element)
        }

        if (
          element.type === 'email_input' &&
          element.is_required === 1 &&
          (element.value === '' ||
            element.value === null ||
            !element.value.includes('@'))
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element)
        }

        if (
          element.type === 'textbox' &&
          element.is_required === 1 &&
          (element.value === '' || element.value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element.label)
        }

        if (
          element.type === 'checkbox' &&
          element.is_required === 1 &&
          element.value === 0
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element.name_in_report)
        }

        if (element.type === 'question') {
          var answersSelectedCount = 0

          //check if min_amt of answers was selected
          element.answers.forEach(answer => {
            if (answer.is_selected === 1) {
              answersSelectedCount++
            }
          })

          //then compare
          if (element.min_answers_amt > answersSelectedCount) {
            errorCount++

            alert(
              'Please select a minium of ' + element.min_answers_amt + ' anwers'
            )
          }
        }

        if (
          element.type === 'button' &&
          element.action_type === 'restart_quiz'
        ) {
          isRestartQuizButton = true

          console.log('yes restart')
        }
      })

      console.log('ERROR COUNT: ', errorCount)

      if (errorCount === 0) {
        //only if isRestartQuizButton is false go next
        if (isRestartQuizButton === false) {

          this.previewPageIndex++
          this.calcProgressPercentage()
          // this.checkNextPageForPipableIds()

        } else {
          this.resetPreview()
        }
      }

      if (
        this.currentQuiz.type === 'answer_based_outcomes' &&
        this.previewPageIndex > this.defaultPagesArray.length - 1
      ) {
        const winnngOutcomePage =
          this.outcomePagesArray[this.theFinalOutcomeIndex]

        this.defaultPagesArray.push(winnngOutcomePage)

        // this.previewPageIndex = this.theFinalOutcomeIndex
        // this.progressPercentage = 100

        console.log('REDIRECT TO FINAL OUTCOMEEEEEE')
      }

      // //since we know the theLastDefaultPageIndex, anything shown after that should be the final outcome page with highest weight
      // //so if previewPageIndex is greater than theLastDefaultPageIndex, show the final outcome
      // if (
      //   this.currentQuiz.type === 'answer_based_outcomes' &&
      //   this.previewPageIndex > this.theLastDefaultPageIndex
      // ) {
      //   console.log('REDIRECT TO FINAL OUTCOMEEEEEE')

      //   this.previewPageIndex = this.theFinalOutcomeIndex
      //   this.progressPercentage = 100
      // }
    },

    toggleSelectedAnswer(question, answer) {


      this.addQuestionAnsweredToArray(answer, question)
      this.checkNextPageForPipableIds()


      if (answer.is_selected === 1) {
        // Unselect the answer
        answer.is_selected = 0
        question.answers_selected_count--

        //check quiz types and do respective operation
        if (this.currentQuiz.type === 'answer_based_outcomes') {
          //update outcome mapping
          let changeWeightValue = -1
          this.multipleAnswersSelect(answer, changeWeightValue)
        }

        if (this.currentQuiz.type === 'score_based_outcomes') {
          //baciscally remove the answer the user selected from userAnswer.answers
          var userAnswer = this.userAnswers.find(
            obj => obj.question_id === question.id
          )
          var newAnswersArray = userAnswer.answers.filter(
            ansId => ansId !== answer.id
          )
          userAnswer.answers = newAnswersArray

          this.calcMultipleAnswersScore()
        }
      } else if (question.answers_selected_count < question.max_answers_amt) {
        // Select the answer if the maximum limit is not reached
        answer.is_selected = 1
        question.answers_selected_count++

        if (this.currentQuiz.type === 'answer_based_outcomes') {
          //update outcome mapping
          let changeWeightValue = 1
          this.multipleAnswersSelect(answer, changeWeightValue)
        }

        if (this.currentQuiz.type === 'score_based_outcomes') {
          //put the correct answers in a seperate arryay to compare later
          var correctAnswersOnly = []

          question.answers.forEach(ans => {
            if (ans.is_correct === 1) {
              correctAnswersOnly.push(ans.id)
            }
          })

          var optionSelected = {
            question_id: question.id,
            answers: [],
            correct_answers_only: correctAnswersOnly
          }

          if (this.userAnswers.length === 0) {
            optionSelected.answers.push(answer.id)
            this.userAnswers.push(optionSelected)

            console.log('userAnswers', this.userAnswers)
          } else {
            // Check if the question already exists in userAnswers
            var questionExists = false

            this.userAnswers.forEach(userAnswer => {
              if (userAnswer.question_id === optionSelected.question_id) {
                // Update the existing entry

                questionExists = true

                //add answer

                userAnswer.answers.push(answer.id)
                // console.log('MATCHED - Updated', userAnswer);
              }
            })

            // If the question doesn't exist, add it to the array
            if (!questionExists) {
              optionSelected.answers.push(answer.id)
              this.userAnswers.push(optionSelected)

              console.log('Added to array')
            }

            // console.log('userAnswers', this.userAnswers);
          }

          this.calcMultipleAnswersScore()
        }
      } else {
        // Show an alert if the maximum limit is reached
        alert(
          `You can only select a maximum of ${question.max_answers_amt} answers.`
        )
      }
    },

    //for score based multple answers
    calcMultipleAnswersScore() {
      this.userAnswers.forEach(userAnswer => {
        const sortedAnswers = [...userAnswer.answers].sort()
        const sortedCorrectAnswers = [...userAnswer.correct_answers_only].sort()

        if (
          JSON.stringify(sortedAnswers) === JSON.stringify(sortedCorrectAnswers)
        ) {
          userAnswer.got_correct = 'yes'
        } else {
          userAnswer.got_correct = 'no'
        }
      })

      this.correctAnswersCount = this.userAnswers.filter(
        userAnswer => userAnswer.got_correct === 'yes'
      ).length

      //i need to know how many default pages have question element
      let countPagesWithAQuestion = 0

      this.defaultPagesArray.forEach(page => {
        page.design_layout.forEach(element => {
          if (element.type === 'question') {
            countPagesWithAQuestion++
          }
        })
      })

      this.questionPagesCount = countPagesWithAQuestion

      //put the correctAnswersCount and questionPagesCount values in the score elemeent
      this.defaultPagesArray.forEach(page => {
        page.design_layout.forEach(element => {
          if (element.type === 'score') {
            element.correct_amt = this.correctAnswersCount
            element.total_questions_amt = this.questionPagesCount
          }
        })
      })
      //for outcome pages array too
      this.outcomePagesArray.forEach(page => {
        page.design_layout.forEach(element => {
          if (element.type === 'score') {
            element.correct_amt = this.correctAnswersCount
            element.total_questions_amt = this.questionPagesCount
          }
        })
      })

      console.log('calcMultipleAnswersScore', this.userAnswers)
    },

    oneAnswerSelectOnly(answer, question) {


      this.addQuestionAnsweredToArray(answer, question)
      this.checkNextPageForPipableIds()

      //single answer questions must only have one answer is_selected highlighted
      if (question.click_action_type === 'single_answer') {
        //so reset them
        question.answers.forEach(ans => {
          ans.is_selected = 0
        })
      }

      if (answer.is_selected === 1) {
        answer.is_selected = 0

        if (this.currentQuiz.type === 'single_outcome') {
          this.nextQuestion(answer)
        }

        if (this.currentQuiz.type === 'answer_based_outcomes') {
          this.calculatePageOutcomeToShow(answer)
        }

        if (this.currentQuiz.type === 'score_based_outcomes') {
          this.calculateScore(answer, question)
        }
      } else {
        //we know the user selected this answer
        answer.is_selected = 1

        if (this.currentQuiz.type === 'single_outcome') {
          this.nextQuestion(answer)
        }

        if (this.currentQuiz.type === 'answer_based_outcomes') {
          this.calculatePageOutcomeToShow(answer)
        }

        if (this.currentQuiz.type === 'score_based_outcomes') {
          this.calculateScore(answer, question)
        }
      }
    },

    // redirectToURL(){

    //   //check if the next page in default page array has a redirect url
    //   let nextPageIndex = this.defaultPagesArray[this.previewPageIndex + 1]

    // },

    //for score based single answer
    calculateScore(answer, question) {
      if (question.click_action_type === 'single_answer') {
        var optionSelected = {
          question_id: question.id,
          answers: [],
          correct_answers_only: [],
          got_correct: answer.is_correct === 1 ? 'yes' : 'no'
        }

        optionSelected.answers.push(answer.id)

        if (answer.is_correct === 1) {
          optionSelected.correct_answers_only.push(answer.id)
        }

        //check question id if it already exists in array
        var questionExists = false

        this.userAnswers.forEach(userAnswer => {
          if (userAnswer.question_id === optionSelected.question_id) {
            questionExists = true
            userAnswer.answers = optionSelected.answers
          }
        })

        // If the question doesn't exist, add it to the array
        if (!questionExists) {
          this.userAnswers.push(optionSelected)
        }

        this.calcMultipleAnswersScore()

        this.nextQuestion(answer)
      }
    },

    multipleAnswersSelect(answer, changeWeightValue) {
      if (changeWeightValue === 1) {
        //i need to know which outcome page this answer maps to
        let answerMapping = answer.outcome_pages

        ////foreach the outcomeTracker, and inside of that aslo foreach the answerMapping
        //// and check if the page_id from outcomeTracker matches the one in answerMapping
        ////then add the weight value to the weight property in outcomeTracker
        this.outcomeTracker.forEach(outcomePage => {
          answerMapping.forEach(answerMapping => {
            if (outcomePage.page_id === answerMapping.page_id) {
              outcomePage.total_weight =
                outcomePage.total_weight + answerMapping.weight
            }
          })
        })

        ////run the outcome caluction to see which outcome has the highest total weight
        let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
          return prev.total_weight > current.total_weight ? prev : current
        })

        const outcomePageIndexToShow = this.outcomePagesArray.findIndex(
          page => page.id === maxWeightPage.page_id
        )

        this.theFinalOutcomeIndex = outcomePageIndexToShow

        console.log('theFinalOutcomeIndex', this.theFinalOutcomeIndex)
        console.log(
          'the outcome page to show',
          this.outcomePagesArray[this.theFinalOutcomeIndex]
        )
      }

      if (changeWeightValue === -1) {
        //i need to know which outcome page this answer maps to
        let answerMapping = answer.outcome_pages

        ////foreach the outcomeTracker, and inside of that aslo foreach the answerMapping
        //// and check if the page_id from outcomeTracker matches the one in answerMapping
        ////then add the weight value to the weight property in outcomeTracker
        this.outcomeTracker.forEach(outcomePage => {
          answerMapping.forEach(answerMapping => {
            if (outcomePage.page_id === answerMapping.page_id) {
              outcomePage.total_weight =
                outcomePage.total_weight - answerMapping.weight
            }
          })
        })

        ////run the outcome caluction to see which outcome has the highest total weight
        let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
          return prev.total_weight > current.total_weight ? prev : current
        })

        const outcomePageIndexToShow = this.outcomePagesArray.findIndex(
          page => page.id === maxWeightPage.page_id
        )

        this.theFinalOutcomeIndex = outcomePageIndexToShow

        console.log('theFinalOutcomeIndex', this.theFinalOutcomeIndex)
        console.log(
          'the outcome page to show',
          this.outcomePagesArray[this.theFinalOutcomeIndex]
        )
      }
    },

    //FOR SINGLE ANSWER outcome based quiz
    calculatePageOutcomeToShow(answer) {
      //i need to know which outcome page this answer maps to
      let answerMapping = answer.outcome_pages

      ////foreach the outcomeTracker, and inside of that aslo foreach the answerMapping
      //// and check if the page_id from outcomeTracker matches the one in answerMapping
      ////then add the weight value to the weight property in outcomeTracker
      this.outcomeTracker.forEach(outcomePage => {
        answerMapping.forEach(answerMapping => {
          if (outcomePage.page_id === answerMapping.page_id) {
            outcomePage.total_weight =
              outcomePage.total_weight + answerMapping.weight
          }
        })
      })

      ////run the outcome caluction to see which outcome has the highest total weight
      let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
        return prev.total_weight > current.total_weight ? prev : current
      })

      const outcomePageIndexToShow = this.outcomePagesArray.findIndex(
        page => page.id === maxWeightPage.page_id
      )

      this.theFinalOutcomeIndex = outcomePageIndexToShow

      console.log('theFinalOutcomeIndex', this.theFinalOutcomeIndex)
      console.log(
        'the outcome page to show',
        this.outcomePagesArray[this.theFinalOutcomeIndex]
      )

      //Handle skip logic if its used for this answer
      if (answer.answer_navigation_type === 'skip_logic') {
        const nexPageIndexToSkipTo = this.defaultPagesArray.findIndex(
          page => page.id === answer.next_page_id
        )

        ////jump to that index
        this.previewPageIndex = nexPageIndexToSkipTo

        this.calcProgressPercentage()

        return //stop here
      }

      // ////Check if this default sub type is the last one in the mother array
      // let lastDefaultPageIndex = -1

      // this.motherArray.forEach((page, index) => {
      //   if (page.sub_type === 'default') {
      //     lastDefaultPageIndex = index
      //   }
      // })

      // if (lastDefaultPageIndex !== -1) {
      //   const outcomePageIndexToShow = this.motherArray.findIndex(
      //     page => page.id === maxWeightPage.page_id
      //   )
      //   this.theFinalOutcomeIndex = outcomePageIndexToShow

      //   this.theLastDefaultPageIndex = lastDefaultPageIndex

      //   console.log(
      //     `The last 'default' type object is at index ${lastDefaultPageIndex}`
      //   )
      // }

      // if (this.previewPageIndex > this.theLastDefaultPageIndex) {
      //   console.log('REDIRECT TO FINAL OUTCOME')

      //   this.previewPageIndex = this.theFinalOutcomeIndex
      //   this.progressPercentage = 100
      // }

      // if (answer.answer_navigation_type === 'skip_logic') {
      //   const nexPageIndexToSkipTo = this.motherArray.findIndex(
      //     page => page.id === answer.next_page_id
      //   )

      //   ////jump to that index
      //   this.previewPageIndex = nexPageIndexToSkipTo

      //   this.calcProgressPercentage()
      // }

      this.validateAndNext(this.defaultPagesArray[this.previewPageIndex])
    },

    toggleIsCorrect(answer, currentElement) {
      let question = currentElement
      let answers = currentElement.answers

      const selectedAnswersCount = answers.filter(
        answer => answer.is_correct === 1
      ).length

      console.log('answers', answers)
      console.log('selectedAnswersCount', selectedAnswersCount)

      // if question.click_action_type === 'single_answer' and if the answer.is_correct === 1 and selectedAnswersCount === 1, user want to switch answer.is_correct = 0
      if (
        question.click_action_type === 'single_answer' &&
        answer.is_correct === 1 &&
        selectedAnswersCount === 1
      ) {
        //switch to 0
        answer.is_correct = 0
      }

      // if question.click_action_type === 'single_answer' and if the answer.is_correct === 0 and selectedAnswersCount === 0, user want to switch answer.is_correct = 1
      if (
        question.click_action_type === 'single_answer' &&
        answer.is_correct === 0 &&
        selectedAnswersCount === 0
      ) {
        //switch to 1
        answer.is_correct = 1
      }

      if (
        question.click_action_type === 'single_answer' &&
        answer.is_correct === 0 &&
        selectedAnswersCount === 1
      ) {
        alert('This question can only have one answer')
      }

      // question.click_action_type === 'single_answer' and the answer.is_correct === 0 and selectedAnswersCount === 1, then
      //user cannot select any more is_correct
      // if(question.click_action_type === 'single_answer' && answer.is_correct === 0 && selectedAnswersCount === 1){

      //     alert('no more')

      // }

      // if question.click_action_type === 'multiple_answers' no need to check for selectedAnswersCount, user can select more than one answers
      if (question.click_action_type === 'multiple_answers') {
        if (answer.is_correct === 1) {
          //switch to 0
          answer.is_correct = 0
        } else {
          //switch to 1
          answer.is_correct = 1
        }
      }
    },

    setResultsTracker() {
      //get the pages of sub_type default into its own array for better navigation
      this.defaultPagesArray.forEach(page => {
        this.defaultSubTypePagesOnly.push({
          page_id: page.id
        })
      })

      //get the outcome pages out into an array to tracker weight increment results
      this.outcomePagesArray.forEach(page => {
        this.outcomeTracker.push({
          page_id: page.id,
          title: page.page_title,
          total_weight: 0
        })
      })

      console.log('outcomeTracker: ', this.outcomeTracker)
      console.log('defaultSubTypePagesOnly: ', this.defaultSubTypePagesOnly)
    },

    // setScoreBasedQuestionPagesOnly() {

    //   console.log('refactor this code')

    //   // this.motherArray.forEach(page => {
    //   //   if (page.sub_type === 'default') {
    //   //     page.design_layout.forEach(element => {
    //   //       if (element.type === 'question') {
    //   //         //add page to array only if it has a question
    //   //         this.pagesWithQuestionOnlyForScoreBased.push(page)
    //   //       }
    //   //     })
    //   //   }
    //   // })

    //   // console.log(
    //   //   'pagesWithQuestionOnlyForScoreBased',
    //   //   this.pagesWithQuestionOnlyForScoreBased
    //   // )
    // },

    resetPreview() {
      this.previewPageIndex = 0
      this.progressPercentage = 0

      //answer based variables
      this.outcomeTracker = []
      this.defaultSubTypePagesOnly = []
      this.theFinalOutcomeIndex = null

      //score based variables
      this.userAnswers = []
      this.correctAnswersCount = null
      this.questionPagesCount = null

      this.questionsAnsweredArray = []

      //reset answers selected too
      this.defaultPagesArray.forEach(page => {
        page.design_layout.forEach(element => {
          if (element.type === 'question') {
            element.answers_selected_count = 0

            element.answers.forEach(answer => {
              answer.is_selected = 0
            })
          }
        })
      })
    },

    prevQuestion() {
      if (this.previewPageIndex > 0) {
        this.previewPageIndex--

        this.calcProgressPercentage()
      }
    },

    calcProgressPercentage() {
      if (this.previewPageIndex == this.defaultPagesArray.length - 1) {
        //rember if this quiz is a answer based the outcome page will be added last
        //so set to 90%
        if (this.currentQuiz.type === 'answer_based_outcomes') {
          this.progressPercentage = 90
        } else {
          //for any other quiz type the outcome pages was already added to the array so
          //set to 100%
          this.progressPercentage = 100
        }
      } else {
        this.progressPercentage =
          (this.previewPageIndex / this.defaultPagesArray.length) * 100
      }
    },

    toggleEnableProgressBar() {
      this.is_progress_bar_enabled = this.is_progress_bar_enabled === 0 ? 1 : 0
    },

    toggleShowAndHideInputLabel() {
      this.show_label = this.show_label === 0 ? 1 : 0
    },

    addOutcomeSelector(answer) {
      //if the answer.outcome_pages_lenght is equal to the total count of outcome pages show alert
      var outcomePages = []
      // var answerOutcomePagesArray = answer.outcome_pages;
      var answerOutcomePagesArray = this.currentAnswerOutcomeArray

      this.outcomePagesArray.forEach(page => {
        outcomePages.push(page)
      })

      if (outcomePages.length === answerOutcomePagesArray.length) {
        alert('Cant add any more outcomes!')
      } else {
        const formData = {
          id: '',
          quiz_id: this.currentQuiz.id,
          answer_id: this.currentAnswer.id,
          page_id: '', //the outcome page id,
          weight: 1
        }

        QuizService.saveQuizAnswerMapping(formData)
          .then(response => {
            var answerToOutcome = response.data.answerToOutcomeMap

            // answer.outcome_pages.push(answerToOutcome)
            this.currentAnswerOutcomeArray.push(answerToOutcome)

            //update the current element
            this.currentAnswer.outcome_pages = this.currentAnswerOutcomeArray

            // console.log('look here', response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    deleteThisAnswerToOutcome(outcome) {
      alert('Are you sure you want to remove this outcome?')

      const formData = {
        id: outcome.id
      }

      QuizService.deleteAnswerToOutcomeMapping(formData)
        .then(response => {
          let message = response.data.message

          if (message === 'deleted_success') {
            // Find the index of the outcome in the answers.outcome_pages array
            const outcomeIndexToDelete =
              this.currentAnswer.outcome_pages.findIndex(
                out => out.id === outcome.id
              )

            // Delete it
            if (outcomeIndexToDelete !== -1) {
              this.currentAnswer.outcome_pages.splice(outcomeIndexToDelete, 1)
            }
          }

          //then remove it from array on frontend
        })
        .catch(err => {
          console.log(err)
        })
    },

    setSelectedOutcome(outcome) {
      //record not created yet
      if (outcome.id === '') {
        const formData = {
          id: '',
          quiz_id: this.currentQuiz.id,
          answer_id: this.currentAnswerToMap.id,
          page_id: outcome.page_id, //the outcome page is,
          weight: outcome.weight
        }

        QuizService.saveQuizAnswerMapping(formData)
          .then(response => {
            console.log('look here', response.data)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        const formData = {
          id: outcome.id,
          quiz_id: this.currentQuiz.id,
          answer_id: this.currentAnswer.id,
          page_id: outcome.page_id, //the outcome page is,
          weight: outcome.weight
        }

        QuizService.saveQuizAnswerMapping(formData)
          .then(response => {
            let message = response.data.message

            //update with fresh data
            var allOutcomes = response.data.allOutcomes
            this.currentAnswer.outcome_pages = allOutcomes

            if (message === 'page_alreay_mapped_to_answer') {
              alert('You can only map an answer to an outcome once')
            }

            // this.refreshTheDesign()

            console.log(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    updateAnswerNavigationOption(answer) {
      if (answer.answer_navigation_type === 'next_page') {
        //just update it back to next_page and remove the page id from column
        const formData = {
          answer: answer,
          updatingNextPage: null
        }

        QuizService.updateAnswerNavigationType(formData)
          .then(response => {
            console.log(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }

      if (answer.answer_navigation_type === 'skip_logic') {
        //as a backup, use the current page index, add 1 to it to get the next page id in sequence
        //in case the user forgets to select a page
        let nextPageIndex = this.currentDefaultPageIndex + 1
        let nextPage = this.defaultPagesArray[nextPageIndex]

        //set backup page
        answer.next_page_id = nextPage.id

        const formData = {
          answer: answer,
          updatingNextPage: null
        }

        QuizService.updateAnswerNavigationType(formData)
          .then(response => {
            console.log(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    updateThePageSelectedForSkipLogic(answer) {
      const formData = {
        answer: answer
      }

      QuizService.updateAnswerNavigationType(formData)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    setThisAnswerAsCurrent(answer) {
      this.currentAnswer = answer
      this.currentAnswerOutcomeArray = answer.outcome_pages

      console.log('currentAnswer', this.currentAnswer)
      console.log('currentAnswerOutcomeArray', this.currentAnswerOutcomeArray)

      this.showAnswerOutcomeModal = true
    },

    closeAnswerOutcomeModal() {
      this.showAnswerOutcomeModal = false
    },

    setDeviceType(type) {
      this.deviceType = type
    },

    getDeviceType() {
      if (window.innerWidth <= 768) {
        this.setDeviceType('Mobile')
      } else if (window.innerWidth <= 1024) {
        this.setDeviceType('Tablet')
      } else {
        this.setDeviceType('Desktop')
      }
    },

    initDeviceType() {
      // Initialize the device type
      this.getDeviceType()

      // Listen for window resize events
      window.addEventListener('resize', () => {
        this.getDeviceType()
      })
    },

    setPreviewDevice(devicePreview) {
      this.currentDevicePreview = devicePreview
    },

    saveDesign() {
      //add quiz BG styles
      this.currentQuiz.progress_bar_bg_color = this.selectedProgressBarColor
      this.currentQuiz.is_progress_bar_enabled = this.is_progress_bar_enabled

      if (this.selectedOuterBGtype === 'normal_bg') {
        this.currentQuiz.outer_bg_type = this.selectedOuterBGtype
        this.currentQuiz.outer_bg_color = this.selectedNormalBGcolor
      }

      if (this.selectedOuterBGtype === 'gradient_bg') {
        this.currentQuiz.outer_bg_type = this.selectedOuterBGtype
        this.currentQuiz.outer_bg_gradient = this.selectedOuterBGgradient
      }

      if (this.selectedOuterBGtype === 'img_bg') {
        this.currentQuiz.outer_bg_type = this.selectedOuterBGtype
        this.currentQuiz.outer_bg_img_url = this.selectedBGimageUrl
      }

      if (this.selectedCardBGtype === 'normal_bg') {
        this.currentQuiz.card_bg_type = this.selectedCardBGtype
        this.currentQuiz.card_bg_color = this.selectedCardNormalBGcolor
      }

      if (this.selectedCardBGtype === 'gradient_bg') {
        this.currentQuiz.card_bg_type = this.selectedCardBGtype
        this.currentQuiz.card_bg_gradient = this.selectedCardBGgradient
      }

      if (this.selectedCardBGtype === 'img_bg') {
        this.currentQuiz.card_bg_type = this.selectedCardBGtype
        this.currentQuiz.card_bg_img_url = this.selectedCardBGimageUrl
      }

      const formData = {
        defaultPagesArray: this.defaultPagesArray,
        outcomePagesArray: this.outcomePagesArray,
        quiz: this.currentQuiz
      }

      console.log(' SENT IN FORM DATA', formData)

      QuizService.saveTheWholeQuizProject(formData)
        .then(response => {
          toast('Your project changes is saved!', {
            position: 'bottom-right',
            autoClose: 1000
          })

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })

      // //use copy of motherArray instead to avoid changes reflect in editor
      // let copiedMotherArray = this.motherArray.slice();

      // //make sure the positioning is correct for pages based on quiz type
      // if (this.currentQuiz.type === 'single_outcome') {

      //   //outcome page is always last

      //   // Find the object with type 'outcome'
      //   const outcomePage = copiedMotherArray.find(
      //     page => page.sub_type === 'outcome'
      //   )

      //   if (outcomePage) {
      //     // Remove the outcomePage from the array
      //     const index = copiedMotherArray.indexOf(outcomePage)

      //     copiedMotherArray.splice(index, 1)

      //     // Push it to the last position
      //     copiedMotherArray.push(outcomePage)
      //   }
      // }

      // if (this.currentQuiz.type === 'score_based_outcomes') {
      //   //score outcome page is always last as it just display the score user got

      //   // Find the object with type 'outcome'
      //   const outcomePage = copiedMotherArray.find(
      //     page => page.sub_type === 'outcome'
      //   )

      //   if (outcomePage) {
      //     // Remove the outcomePage from the array
      //     const index = copiedMotherArray.indexOf(outcomePage)

      //     copiedMotherArray.splice(index, 1)

      //     // Push it to the last position
      //     copiedMotherArray.push(outcomePage)
      //   }
      // }

      // const formData = {
      //   pages: copiedMotherArray,
      //   quiz: this.currentQuiz
      // }

      // console.log('MOTHER FORM DATA SENT: ', formData)

      // QuizService.saveTheWholeQuizProject(formData)
      //   .then(response => {
      //     console.log(response.data)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },

    saveChangesBeforeExit() {
      if (
        confirm(
          'IMPORTANT: Did you save your changes? If not click the "Cancel" button and save your changes!'
        )
      ) {
        this.saveDesign()

        this.resetThisStore()

        this.router.push({
          name: 'MainPortal',
          params: {
            random_string: this.currentQuiz.random_string,
            workspaceUUID: this.currentQuiz.workspace.uuid
          }
        })

        console.log('exit editor')
      }
    },

    defaultPageMoved(page) {
      //set current defaultpage index to newIndex
      this.currentDefaultPageIndex = page.moved.newIndex
      this.currentDefaultPage =
        this.defaultPagesArray[this.currentDefaultPageIndex]

      //set current page in view
      this.currentPageInViewIndex = page.moved.newIndex
      this.currentPageInView =
        this.defaultPagesArray[this.currentDefaultPageIndex]

      // console.log('MOVED', page)
      // console.log('MOVED', page.moved.newIndex)
    },

    publishThisProjectLive(is_live) {
      //check usage agaignt limit
      if (
        this.accountUsageData.plan.monthly_submissions_limit ===
        this.accountUsageData.usageData.current_submission_count
      ) {
        alert(
          'You account have reached its montly submission limit. Go to "Plans & Billing" to upgrade your account.'
        )

        // this.router.push({ name: 'PaidPlans'});
      } else {
        this.currentQuiz.is_live = is_live

        const formData = {
          quiz: this.currentQuiz
        }

        QuizService.publishProjectLive(formData)
          .then(response => {
            console.log(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    setSelectedPlaftorm(platformFromDB, platforms) {
      if (platformFromDB.type === 'instagram') {
        platformFromDB.platform_name = 'Instagram'
        platformFromDB.img_name = 'instagram'

        console.log('selected instagram', platformFromDB)
      }

      if (platformFromDB.type === 'facebook') {
        platformFromDB.platform_name = 'Facebook'
        platformFromDB.img_name = 'facebook'

        console.log('selected facebook')
      }
      if (platformFromDB.type === 'twitter') {
        platformFromDB.platform_name = 'Twitter'
        platformFromDB.img_name = 'twitter'

        console.log('selected twitter')
      }
      if (platformFromDB.type === 'pinterest') {
        platformFromDB.platform_name = 'Pinterest'
        platformFromDB.img_name = 'pinterest'

        console.log('selected pinterest')
      }
      if (platformFromDB.type === 'youtube') {
        platformFromDB.platform_name = 'Youtube'
        platformFromDB.img_name = 'youtube'

        console.log('selected youtube')
      }
      if (platformFromDB.type === 'tiktok') {
        platformFromDB.platform_name = 'Tiktok'
        platformFromDB.img_name = 'tiktok'

        console.log('selected tiktok')
      }
      if (platformFromDB.type === 'linkedin') {
        platformFromDB.platform_name = 'Linkedin'
        platformFromDB.img_name = 'linkedin'

        console.log('selected linkedin')
      }
      if (platformFromDB.type === 'website') {
        platformFromDB.platform_name = 'Website'
        platformFromDB.img_name = 'website'

        console.log('selected website')
      }

      //   const platformToUpdateIndex = platforms.findIndex(
      //     plat => plat.id === platformFromDB.id
      //   )
      //   let platformToUpdate = platforms[platformToUpdateIndex]

      //   platformToUpdate.platform_name = platformFromDB.platform_name
      //   platformToUpdate.type = platformFromDB.type

      //   console.log('platformFromDB', platformFromDB)
      //   console.log('platformToUpdate', platformToUpdate)
    },

    socialLinkInputSave(platformFromDB) {
      console.log(platformFromDB)
    },

    deleteASocialPlatform(platformFromDB, plaforms) {
      const plafformIndexToDelete = plaforms.findIndex(
        plat => plat.id === platformFromDB.id
      )

      // Delete the answer from the question
      if (plafformIndexToDelete !== -1) {
        plaforms.splice(plafformIndexToDelete, 1)
      }

      //delete from db
      const formData = {
        id: platformFromDB.id
      }

      QuizService.deleteThisSocialPlaform(formData)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })

      console.log('deleted')
    },

    addSocialPlaform(sharingElement) {
      const formData = {
        sharingElement: sharingElement
      }

      QuizService.addNewSocialPlaform(formData)
        .then(response => {
          let newplatform = response.data.newplatform

          //to array
          sharingElement.platforms.push(newplatform)

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    uploadThisImageForImageElement(event, imageElement) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        // 1MB in bytes
        alert('File size should not exceed 1MB')
        return
      }

      const formData = new FormData()

      formData.append('imageFile', file)
      formData.append('quiz_id', this.currentQuiz.id)
      formData.append('image_element_id', imageElement.id)

      this.imageIsUploading = true

      QuizService.uploadImageForImageElement(formData)
        .then(response => {
          //load image
          imageElement.uploaded_img_url = response.data.url

          this.imageIsUploading = false

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    uploadAnswerImage(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        // 1MB in bytes
        alert('File size should not exceed 1MB')
        return
      }

      const formData = new FormData()

      formData.append('imageFile', file)
      formData.append('answer_id', this.currentAnswer.id)

      this.imageIsUploading = true

      QuizService.uploadAnswerImage(formData)
        .then(response => {
          this.currentAnswer.img_url = response.data.url

          this.imageIsUploading = false
        })
        .catch(err => {
          console.log(err)
        })
    },

    uploadQuizBackgroundImage(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        // 1MB in bytes
        alert('File size should not exceed 1MB')
        return
      }

      const formData = new FormData()

      formData.append('imageFile', file)
      formData.append('quiz_id', this.currentQuiz.id)

      this.imageIsUploading = true

      QuizService.uploadQuizBGImage(formData)
        .then(response => {
          this.currentQuiz.outer_bg_img_url = response.data.url
          this.selectedBGimageUrl = response.data.url
          console.log(response.data)

          this.imageIsUploading = false
        })
        .catch(err => {
          console.log(err)
        })
    },

    fetchManagerAccountUsageData() {
      AuthService.getAccountUsageData()
        .then(response => {
          this.accountUsageData = response.data.accountUsageData

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    openURLNewTab(url) {
      window.open(url, '_blank')
    },

    openURLInSameTab(url) {
      window.location.href = url
    },

    prepareDefaultPagesArrayForSingleOutcomeQuiz() {
      //add the outcome last in the index
      this.defaultPagesArray.push(this.outcomePagesArray[0])

      console.log('defaultPagesArray SHOWING', this.defaultPagesArray)
    },

    prepareDefaultPagesArrayForScoreOutcomeQuiz() {
      //add the outcome last in the index
      this.defaultPagesArray.push(this.outcomePagesArray[0])

      console.log('defaultPagesArray SHOWING', this.defaultPagesArray)
    },

    removeOutcomePageFromDefaultPagesArray() {
      this.defaultPagesArray = this.defaultPagesArray.filter(
        page => page.sub_type !== 'outcome'
      )

      console.log('defaultPagesArray HIDE', this.defaultPagesArray)
    },

    openDeleteOutcomeModal() {
      this.showDeletOutcomePageModal = true
    },

    closeDeleteOutcomeModal() {
      this.showDeletOutcomePageModal = false
    },

    checkRedirectURL(pastedUrl) {
      if (!pastedUrl.startsWith('https:')) {
        alert('A valid URL with https must be entered')

        this.outcomePagesArray[this.currentOutcomePageIndex].redirect_url = null
      } else {
        // console.log('all good')
      }
    },

    resetThisStore() {
      this.currentPageInViewIndex = 0
      this.currentDefaultPageIndex = 0
      this.currentDefaultPage = {}
      this.currentOutcomePage = {}
      this.currentOutcomePageIndex = 0
    },

    openAnswerPipingModal() {
      this.showAnswerPipingModal = true
    },

    closeAnswerPipingModal() {
      this.showAnswerPipingModal = false
    },


    limitPipingText(body) { 

      if (body.length > 8) {
        return body.substring(0, 8) + '...'
      }

      return body
    },


    addQuestionAnsweredToArray(answer, question){ 
      

      let questionData = {

        question_id: null,
        question_pipeable_id: null,
        answers: []

      }


      //check if question already exists 
      if (this.questionsAnsweredArray.some(obj => obj.question_id === question.id)) {

        let theIndexOfExistingQuestionId = this.questionsAnsweredArray.findIndex(
          obj => obj.question_id === question.id
        )

        //update the answers for this question
        //check if answer already exists, if yes, remove it. user will select another one
        let questiontToUpdate = this.questionsAnsweredArray[theIndexOfExistingQuestionId];
        
        if (questiontToUpdate.answers.some(obj => obj.answer_id === answer.id)) {

          //alredy does so delete
          let theIndexOfExistingAnswerId = questiontToUpdate.answers.findIndex(
            obj => obj.answer_id === answer.id
          )

          if (theIndexOfExistingAnswerId !== -1) {

            questiontToUpdate.answers.splice(theIndexOfExistingAnswerId, 1);
            // console.log('answer ID removed from the array');
          } 


        }else{

          //no then just add it
          let answerToAdd = {
            answer_id: answer.id,
            answer_text: answer.answer_text,
          }

          questiontToUpdate.answers.push(answerToAdd)

        }



        // console.log('Question ID exists in the array');

       } else {

        questionData.question_id = question.id
        questionData.question_pipeable_id = question.pipeable_id

        //add answer
        let answerToAdd = {
          answer_id: answer.id,
          answer_text: answer.answer_text,
        }

        questionData.answers.push(answerToAdd)

        //add to array
        this.questionsAnsweredArray.push(questionData);

        // console.log('NEW Question ID ADDED TO array');


      }

     
    
      


    },


    // checkNextPageForPipableIds(){
    //   let nextPage = this.defaultPagesArray[this.previewPageIndex + 1];
     
    //   //find matching string in question text and replace
    //   nextPage.design_layout.forEach((element) => {
    //     if(element.type === 'question'){
    //       let fullBracketContent = this.extractBracketContent(element.question_text);
     
    //       if(fullBracketContent){
    //         let thePipeableId = this.extractPipeableId(fullBracketContent);
    //         let answerString = ''; // Move this line outside of the forEach loop
     
    //         //find match
    //         this.questionsAnsweredArray.forEach((questionData) => {
    //           if(questionData.question_pipeable_id === thePipeableId){
    //             questionData.answers.forEach((ans) => {
    //               answerString += ` ${ans.answer_text}`;
    //             });
    //           }
    //         });
     
    //         element.question_text = answerString;
    //         console.log('answerString', answerString);
    //       }
    //     }
    //   });
    //  },
     

    checkNextPageForPipableIds(){


      let nextPage = this.defaultPagesArray[this.previewPageIndex + 1];

      //find matching string in question text and replace
      nextPage.design_layout.forEach((element) => {

        if(element.type === 'question'){

          let fullBracketContent = this.extractBracketContent(element.question_text);

          if(fullBracketContent){

            let thePipeableId = this.extractPipeableId(fullBracketContent);

            let answerString = ''


            //find match
            this.questionsAnsweredArray.forEach((questionData) => { 

              if(questionData.question_pipeable_id === thePipeableId){

                let answers = questionData.answers.map(ans => ans.answer_text);

                answerString = answers.join(', ');

                if(answers.length > 1){
                  let lastCommaIndex = answerString.lastIndexOf(', ');

                  answerString = answerString.substring(0, lastCommaIndex) + ' & ' + answerString.substring(lastCommaIndex + 2);
                }
              }
             }
             );
             
            // this.questionsAnsweredArray.forEach((questionData) => {

            //   if(questionData.question_pipeable_id === thePipeableId){

                
            //     questionData.answers.forEach((ans) => {

            //       answerString += ` ${ans.answer_text}`

            //     })

        
             
            //   }

            // })



            console.log('answerString',answerString)

          }

        
          


          // console.log('fullBracketContent: ', fullBracketContent)
          // console.log('thePipeableId: ', thePipeableId)


        }

      })



    },

    extractBracketContent(str) {

      var matches = str.match(/\[(.*?)\]/);
   
      if (matches) {
          return matches[1];
      } else {
          return null;
      }

    },

    extractPipeableId(str) {

      var matches = str.match(/\{(.*?)\}/);
   
      if (matches) {
          return matches[1];
      } else {
          return null;
      }

    },

    replaceBracketContent(str, questionData) {

      if(questionData.answers){

        //combine all the answers into one string
        let answerString = questionData.answers.map(ans => ans.answer_text).join(' '); 

        console.log(answerString);
        console.log(str);

        var replacedStr = str.replace(/\[.*?\]/g, answerString);

        // console.log('replacedStr', replacedStr);

        return replacedStr;

        



      }


      
      // console.log('answers', answers)

      // var replacedStr = str.replace(/\[.*?\]/g, questionData.answers[0].answer_text);

      // return replacedStr;
    }


  }
})
