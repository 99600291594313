<template>
    <div class="mainWrapper">


        <div v-if="liveQuizDataStore.message === 'no_published_yet'">

            <h3 style="margin-top: 200px;">This project is hasn't been published yet.</h3>

        </div>

        <div v-if="liveQuizDataStore.message === 'account_is_within_limit'">

            <LiveQuizProgressBar v-if="liveQuizDataStore.is_progress_bar_enabled === 1" />

            <div class="p_QuizOuterBG" :style="{
                'background': liveQuizDataStore.selectedOuterBGtype === 'gradient_bg'
                    ? liveQuizDataStore.selectedOuterBGgradient
                    : liveQuizDataStore.selectedOuterBGtype === 'normal_bg'
                        ? liveQuizDataStore.selectedNormalBGcolor
                        : liveQuizDataStore.selectedOuterBGtype === 'img_bg'
                            ? `url(${liveQuizDataStore.selectedBGimageUrl}) no-repeat center/cover`
                            : 'none',

            }">


                



                <div class="contentWrapper"> 

                    <div class="centerWrapper">


                        <!-- inputFieldsDataCollected: {{  liveQuizDataStore.inputFieldsDataCollected }} -->

                        

                        <!-- inputFieldsAnsweredArray: {{ liveQuizDataStore.inputFieldsAnsweredArray }} -->

                        <!-- questionsAnsweredArray: {{ liveQuizDataStore.questionsAnsweredArray }} -->


                        <!-- <p> current page index : {{ liveQuizDataStore.currentPageIndex  }} </p> |  <p> defaultPagesArray lenght - 1 : {{ liveQuizDataStore.defaultPagesArray.length - 1  }} </p>  -->

                        <!-- <h6>correctAnswersCount: {{ liveQuizDataStore.correctAnswersCount }}</h6>
                    <h6>questionPagesCount: {{ liveQuizDataStore.questionPagesCount }}</h6>  -->

                        <!-- <h6>outcomeTrackerr: {{ liveQuizDataStore.outcomeTracker }}</h6> -->

                        <!-- <li v-for="(userAnswer, index) in liveQuizDataStore.userAnswers" :key="index">
                        {{ userAnswer }}
                    </li> 


                      

                    <h6>Default pages only array: {{ liveQuizDataStore.defaultSubTypePagesOnly }}</h6>

                    <p> current page index : {{ liveQuizDataStore.currentPageIndex  }} </p>
                    <p> theFinalOutcomeIndex : {{ liveQuizDataStore.theFinalOutcomeIndex }} </p>


                    <p>theLastDefaultPageIndex: {{liveQuizDataStore.theLastDefaultPageIndex}}</p> -->

                        <!-- <p>Timer: {{ liveQuizDataStore.seconds }} seconds</p> -->

                        <!-- <p>deviceType: {{ deviceType }}</p> -->

                        <!-- <p>currentTime: {{ liveQuizDataStore.currentTime }}</p> -->
                        <!-- <p>currentDate: {{ liveQuizDataStore.currentDate }}</p> -->

                        <!-- <button @click="liveQuizDataStore.startTimer">Start Timer</button> -->
                        <!-- <button @click="liveQuizDataStore.stopTimer">Stop Timer</button> -->


                        <div v-for="(page, index) in liveQuizDataStore.defaultPagesArray" :key="index">


                            <div v-if="index === liveQuizDataStore.currentPageIndex">

                                


                                <div class="p_QuizCard" :style="{
                                    // 'width': deviceType === 'Desktop' ? '800px' : '100%',
                                    'border-radius': deviceType === 'Mobile' ? '0px' : '15px',
                                    'background': liveQuizDataStore.selectedCardBGtype === 'gradient_bg'
                                        ? liveQuizDataStore.selectedCardBGgradient
                                        : liveQuizDataStore.selectedCardBGtype === 'normal_bg'
                                            ? liveQuizDataStore.selectedCardNormalBGcolor
                                            : liveQuizDataStore.selectedCardBGtype === 'img_bg'
                                                ? `url(${liveQuizDataStore.selectedCardBGimageUrl}) no-repeat center/cover`
                                                : 'none',

                                }">


                                    <!-- <h2>{{ liveQuizDataStore.currentAnswerIndex }}</h2> -->

                                    <div v-if="page.redirect_url != null"> 

                                        <p>
                                            <b>
                                                Redirecting...
                                            </b>
                                        </p>

                                    </div>



                                    <div v-for="(element, index) in page.design_layout" :key="index">

                                        <div v-if="element.type == 'header_text'">


                                            <div :style="{
                                                'color': element.font_color,
                                                // 'font-size': element.font_size + 'px',
                                                'font-family': element.font_family,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }" v-html="element.code_block"></div>


                                        </div>

                                        <div v-if="element.type == 'text'">


                                            <div :style="{
                                                'color': element.font_color,
                                                'font-size': element.font_size + 'px',
                                                'font-family': element.font_family,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }" v-html="element.code_block"></div>


                                        </div>

                                        <div v-if="element.type == 'button'" :style="{
                                            'display': 'flex',
                                            'justify-content': element.alignment,
                                        }">

                                            <div v-if="element.action_type === 'open_url_in_new_tab'">

                                                <button :style="{
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                    'font-weight': element.font_weight,
                                                    'background-color': element.background_color,
                                                    'padding': element.padding + 'px',
                                                    'width': element.width + 'px',
                                                    'border-radius': element.border_radius + 'px',
                                                    'border-color': element.border_color,
                                                    'border-style': element.border_style,
                                                    'border-width': element.border_width + 'px',
                                                    'box-shadow': element.box_shadow,
                                                    'outline': 'none',
                                                    'border-style': 'solid',
                                                }" type="button" @click="liveQuizDataStore.openURLNewTab(element.url)">
                                                    <span>
                                                        {{ element.text }}
                                                    </span>

                                                </button>

                                            </div>

                                            <div v-if="element.action_type === 'open_url_in_same_tab'">

                                                <button :style="{
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                    'font-weight': element.font_weight,
                                                    'background-color': element.background_color,
                                                    'padding': element.padding + 'px',
                                                    'width': element.width + 'px',
                                                    'border-radius': element.border_radius + 'px',
                                                    'border-color': element.border_color,
                                                    'border-style': element.border_style,
                                                    'border-width': element.border_width + 'px',
                                                    'box-shadow': element.box_shadow,
                                                    'outline': 'none',
                                                    'border-style': 'solid',
                                                }" type="button"
                                                    @click="liveQuizDataStore.openURLInSameTab(element.url)">
                                                    <span>
                                                        {{ element.text }}
                                                    </span>

                                                </button>

                                            </div>

                                            <div
                                                v-if="element.action_type === 'next_page' || element.action_type === 'restart_quiz'">

                                                <button :style="{
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                    'font-weight': element.font_weight,
                                                    'background-color': element.background_color,
                                                    'padding': element.padding + 'px',
                                                    'width': element.width + 'px',
                                                    'border-radius': element.border_radius + 'px',
                                                    'border-color': element.border_color,
                                                    'border-style': element.border_style,
                                                    'border-width': element.border_width + 'px',
                                                    'box-shadow': element.box_shadow,
                                                    'outline': 'none',
                                                    'border-style': 'solid',
                                                }" type="button" @click="liveQuizDataStore.validateAndNext(page)">
                                                    <span>
                                                        {{ element.text }}
                                                    </span>

                                                </button>

                                            </div>

                                        </div>

                                        <div v-if="element.type == 'image'">

                                            <div class="imageWidgetWrapper" :style="{
                                                'display': 'flex',
                                                'justify-content': element.alignment,
                                            }">

                                                <img v-if="element.uploaded_img_url === null" class="img-fluid" :style="{
                                                    'max-width': element.width + '%',
                                                    'border-radius': element.border_radius + 'px',
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                }" :src="require(`@/assets/toolbar/placeholder.png`)" />

                                                <img v-else :src="element.uploaded_img_url" class="img-fluid" :style="{
                                                    'max-width': element.width + '%',
                                                    'border-radius': element.border_radius + 'px',
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                }" />
                                                <!-- <img class="img-fluid" :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                                'border-radius': element.border_radius + 'px',
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                            }" :src="require(`@/assets/toolbar/placeholder.png`)" /> -->
                                            </div>

                                        </div>

                                        <!-- text input -->
                                        <div class="parent-container" :style="{
                                            'justify-content': element.justify_content,
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                        }" v-show="element.type == 'text_input'">

                                            <div class="inputFieldWrapper" :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                            }">


                                                <!-- {{ page.design_layout[index].value }} -->


                                                <div class="lableWrapper">

                                                    <label v-if="element.show_label === 1" class="form-label" :style="{
                                                        'color': element.label_font_color,
                                                        'font-size': element.label_font_size + 'px',
                                                        'float': element.label_float,
                                                        'font-weight': 'bold',
                                                        'padding-bottom': '2px',
                                                        'word-wrap': 'break-word',
                                                        'text-align': 'left',
                                                    }">{{ element.label }}</label>


                                                </div>

                                                <div class="theInputWrapper">

                                                    <input type="text" class="form-control"
                                                        v-model="page.design_layout[index].value"
                                                        :placeholder="element.placeholder"
                                                        @keyup="liveQuizDataStore.saveInputFieldValue(page.design_layout[index].value, element)"
                                                        :style="{


                                                            'background-color': element.background_color,
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'padding': element.padding + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'float': element.input_field_float,
                                                        }">

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>


                                                </div>




                                            </div>

                                        </div>

                                        <!-- number input -->
                                        <div class="parent-container" :style="{
                                            'justify-content': element.justify_content,
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                        }" v-show="element.type == 'number_input'">

                                            <div class="inputFieldWrapper" :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                            }">




                                                <div class="lableWrapper">

                                                    <label v-if="element.show_label === 1" class="form-label" :style="{
                                                        'color': element.label_font_color,
                                                        'font-size': element.label_font_size + 'px',
                                                        'float': element.label_float,
                                                        'font-weight': 'bold',
                                                        'padding-bottom': '2px',
                                                        'word-wrap': 'break-word',
                                                        'text-align': 'left',
                                                    }">{{ element.label }}</label>


                                                </div>

                                                <div class="theInputWrapper">

                                                    <input type="text" class="form-control"
                                                        v-model="page.design_layout[index].number_value"
                                                        :placeholder="element.placeholder"
                                                        @keyup="liveQuizDataStore.saveInputFieldValue(page.design_layout[index].value, element)"
                                                        :style="{


                                                            'background-color': element.background_color,
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'padding': element.padding + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'float': element.input_field_float,
                                                        }">

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>


                                                </div>




                                            </div>

                                        </div>

                                        <!-- time input -->
                                        <div class="parent-container" :style="{
                                            'justify-content': element.justify_content,
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                        }" v-show="element.type == 'time_input'">

                                            <div class="inputFieldWrapper" :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                            }">



                                                <div class="lableWrapper">

                                                    <label v-if="element.show_label === 1" class="form-label" :style="{
                                                        'color': element.label_font_color,
                                                        'font-size': element.label_font_size + 'px',
                                                        'float': element.label_float,
                                                        'font-weight': 'bold',
                                                        'padding-bottom': '2px',
                                                        'word-wrap': 'break-word',
                                                        'text-align': 'left',
                                                    }">{{ element.label }}</label>


                                                </div>

                                                <div class="theInputWrapper">


                                                    <VueDatePicker v-model="page.design_layout[index].value" time-picker
                                                        placeholder="Select Time" :is-24="false" :format="'hh:mm'"
                                                        class="form-control" :style="{
                                                            'background-color': element.background_color,
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'padding': element.padding + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'float': element.input_field_float,
                                                            'padding': 0 + 'px'
                                                        }"
                                                        @update:model-value="liveQuizDataStore.formatTime(page.design_layout[index].value, element)">
                                                    </VueDatePicker>

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>


                                                </div>




                                            </div>

                                        </div>

                                        <!-- date input -->
                                        <div class="parent-container" :style="{
                                            'justify-content': element.justify_content,
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                        }" v-show="element.type == 'date_input'">

                                            <div class="inputFieldWrapper" :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                            }">




                                                <div class="lableWrapper">

                                                    <label v-if="element.show_label === 1" class="form-label" :style="{
                                                        'color': element.label_font_color,
                                                        'font-size': element.label_font_size + 'px',
                                                        'float': element.label_float,
                                                        'font-weight': 'bold',
                                                        'padding-bottom': '2px',
                                                        'word-wrap': 'break-word',
                                                        'text-align': 'left',
                                                    }">{{ element.label }}</label>


                                                </div>

                                                <div class="theInputWrapper">


                                                    <VueDatePicker v-model="page.design_layout[index].value" :style="{
                                                        'background-color': element.background_color,
                                                        'border-radius': element.border_radius + 'px',
                                                        'border-color': element.border_color,
                                                        'border-style': element.border_style,
                                                        'border-width': element.border_width + 'px',
                                                        'padding': element.padding + 'px',
                                                        'box-shadow': element.box_shadow,
                                                        'float': element.input_field_float,
                                                        'padding': 0 + 'px'
                                                    }" placeholder="Select Date" :enable-time-picker="false"
                                                        @update:model-value="liveQuizDataStore.formatDate(page.design_layout[index].value, element)">
                                                    </VueDatePicker>

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>


                                                </div>




                                            </div>

                                        </div>

                                        <!-- email_input -->
                                        <div class="parent-container" :style="{
                                            'justify-content': element.justify_content,
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                        }" v-show="element.type == 'email_input'">

                                            <div class="inputFieldWrapper" :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                            }">




                                                <div class="lableWrapper">

                                                    <label v-if="element.show_label === 1" class="form-label" :style="{
                                                        'color': element.label_font_color,
                                                        'font-size': element.label_font_size + 'px',
                                                        'float': element.label_float,
                                                        'font-weight': 'bold',
                                                        'padding-bottom': '2px',
                                                        'word-wrap': 'break-word',
                                                        'text-align': 'left',
                                                    }">{{ element.label }}</label>


                                                </div>

                                                <div class="theInputWrapper">

                                                    <input class="form-control theInput" type="email"
                                                        :placeholder="element.placeholder"
                                                        v-model="page.design_layout[index].value"
                                                        @keyup="liveQuizDataStore.saveInputFieldValue(page.design_layout[index].value, element)"
                                                        :style="{
                                                            'background-color': element.background_color,
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'padding': element.padding + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'float': element.input_field_float,
                                                        }" />

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>


                                                </div>




                                            </div>

                                        </div>

                                        <!-- textbox input -->
                                        <div class="parent-container" :style="{
                                            'justify-content': element.justify_content,
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                        }" v-show="element.type == 'textbox'">

                                            <div class="inputFieldWrapper" :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                            }">

{{ page.design_layout[index].value }}


                                                <div class="lableWrapper" v-if="element.show_label === 1">


                                                    <div :style="{
                                                        'color': element.label_font_color,
                                                        'font-size': element.label_font_size + 'px',
                                                        'float': element.label_float,
                                                        'font-weight': 'bold',
                                                        'padding-bottom': '5px',
                                                    }">
                                                        {{ element.label }}
                                                    </div>




                                                </div>

                                                <div class="theInputWrapper">

                                                    <textarea v-model="page.design_layout[index].value"
                                                        @keyup="liveQuizDataStore.saveInputFieldValue(page.design_layout[index].value, element)"
                                                        class="form-control" :style="{
                                                            'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                                            'background-color': element.background_color,
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'padding': element.padding + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            '::placeholder': 'color: red'
                                                        }" rows="3" :placeholder="element.placeholder"></textarea>



                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>


                                                </div>




                                            </div>

                                        </div>

                                        <!-- checkbox input -->
                                        <div class="parent-container" :style="{
                                            'justify-content': element.justify_content,
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                        }" v-show="element.type == 'checkbox'">

                                            <div class="inputFieldWrapper" :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                            }">

                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"   
                                                    v-model="page.design_layout[index].value"
                                                    @click="page.design_layout[index].value = !page.design_layout[index].value,
                                                        liveQuizDataStore.saveCheckboxValue(page.design_layout[index].value, element)"
                                                        id="flexCheckDefault">
                                                    <label class="form-check-label" for="flexCheckDefault"
                                                        style="text-align: left;">
                                                        {{ element.text_body }}
                                                    </label>
                                                </div>


                                                <div v-if="element.show_error === 1">

                                                    <span style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                        This checkbox is required
                                                    </span>

                                                </div>



                                            </div>

                                        </div>




                                        <div class="center-video" v-if="element.type == 'video'" style="padding: 0px">


                                            <div :style="{
                                                'width': deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                                'margin': element.margin + 'px',
                                            }">
                                                <div :style="{
                                                    'overflow': 'hidden',
                                                    'border-radius': element.border_radius + 'px',
                                                    'box-shadow': element.box_shadow,
                                                }" v-html="element.video_embed_code"></div>
                                            </div>



                                            <!-- <div v-if="element.video_embed_code == NULL">
                                            <img class="v_placeholder" width="500"
                                                :src="require(`@/assets/toolbar/v_placeholder.jpg`)" />
                                        </div> -->

                                            <!-- <div v-else :style="{
                                            'max-width': element.width + 'px',
                                            'margin': element.margin + 'px',
                                        }">
                                            <div :style="{
                                                'overflow': 'hidden',
                                                'border-radius': element.border_radius + 'px',
                                                'box-shadow': element.box_shadow,
                                            }" v-html="element.video_embed_code"></div>
                                        </div> -->



                                        </div>

                                        <div v-if="element.type == 'sharing'" :style="{
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            'display': 'flex',
                                            'flex-direction': 'column',
                                            'justify-content': 'center',
                                            'align-items': 'center'
                                        }">

                                            <div class="shareTextWrapper" style="font-weight: bold; margin-bottom: 0px"
                                                :style="{
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                }">
                                                {{ element.main_share_text }}
                                            </div>

                                            <!-- <center> -->
                                            <div class="sharingWrapper">

                                                <div class="platformItem" v-for="(platform, index) in element.platforms"
                                                    :key="index">

                                                    <a :href="platform.url" target="_blank">

                                                        <img :style="{
                                                            'width': element.img_width + 'px',
                                                            'margin-bottom': '10px',
                                                        }"
                                                            :src="require(`@/assets/toolbar/${platform.img_name}.png`)" />

                                                    </a>


                                                </div>
                                            </div>
                                            <!-- </center> -->


                                        </div>

                                        <div v-if="element.type == 'question'">

                                            <div v-if="element.click_action_type === 'multiple_answers'">

                                                <div v-if="element.answer_type == 'text_only'">

                                                    <div class="questionTextOnlyWrapper" :style="{
                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                    }">

                                                        <div :style="{
                                                            'color': element.question_design.font_color,
                                                            'font-size': element.question_design.font_size + 'px',
                                                            'font-family': element.question_design.font_family,
                                                            'font-weight': element.question_design.font_weight,
                                                            'text-align': element.question_design.text_align,
                                                            'font-weight': element.question_design.font_weight,
                                                            'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                        }">
                                                            {{ element.question_text }}
                                                        </div>

                                                        <div class="d-flex align-items-center" :style="{
                                                            'justify-content': element.question_design.alignment_box,
                                                        }">
                                                            <div class="questionContainer">

                                                                <div class="myBox">

                                                                    <!-- min: {{ element.min_answers_amt }}
                                                                | max allowed: {{ element.max_answers_amt }} -->

                                                                    <div v-for="(answer, answerIndex) in element.answers"
                                                                        @click="liveQuizDataStore.toggleSelectedAnswer(element, answer)"
                                                                        @mouseover="liveQuizDataStore.currentAnswerIndex = answerIndex"
                                                                        @mouseleave="liveQuizDataStore.currentAnswerIndex = null"
                                                                        :key="answerIndex" class="previewAnswerWrapper"
                                                                        :style="{
                                                                            'color': element.answer_design.font_color,
                                                                            'font-size':
                                                                                element.answer_design.font_size + 'px',
                                                                            'font-family':
                                                                                element.answer_design.font_family,
                                                                            'font-weight':
                                                                                element.answer_design.font_weight,
                                                                            'padding':
                                                                                element.answer_design.padding + 'px',
                                                                            'background-color': answer.is_selected === 1 ?
                                                                                element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                            'border-radius':
                                                                                element.answer_design.border_radius +
                                                                                'px',
                                                                            'border-color':
                                                                                element.answer_design.border_color,
                                                                            'border-style':
                                                                                element.answer_design.border_style,
                                                                            'border-width':
                                                                                element.answer_design.border_width +
                                                                                'px',
                                                                            'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                        }">


                                                                        <div class="row">
                                                                            <div class="col" :style="{
                                                                                'text-align':
                                                                                    element.answer_design.text_align,
                                                                            }">
                                                                                {{ answer.answer_text }}
                                                                                <!-- {{answer.is_selected}} -->
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="element.answer_type == 'text_with_image'">



                                                    <div class="questionTextImageWrapper" :style="{
                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                    }">
                                                        <div :style="{
                                                            'color': element.question_design.font_color,
                                                            'font-size':
                                                                element.question_design.font_size + 'px',
                                                            'font-family': element.question_design.font_family,
                                                            'font-weight': element.question_design.font_weight,
                                                            'text-align': element.question_design.text_align,
                                                            'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                        }">
                                                            {{ element.question_text }}
                                                        </div>

                                                        <div class="d-flex align-items-center" :style="{
                                                            'justify-content': element.question_design.alignment_box,
                                                        }">

                                                            <div class="questionTextImageContainer">

                                                                <div class="container1">


                                                                    <div v-for="(answer, answerIndex) in element.answers"
                                                                        :key="answerIndex">



                                                                        <div class="box1"
                                                                            @click="liveQuizDataStore.toggleSelectedAnswer(element, answer)"
                                                                            @mouseover="liveQuizDataStore.currentAnswerIndex = answerIndex"
                                                                            @mouseleave="liveQuizDataStore.currentAnswerIndex = null"
                                                                            :style="{
                                                                                'color': element.answer_design.font_color,
                                                                                'font-size':
                                                                                    element.answer_design.font_size + 'px',
                                                                                'font-family':
                                                                                    element.answer_design.font_family,
                                                                                'font-weight':
                                                                                    element.answer_design.font_weight,
                                                                                // 'background-color': answer.is_selected === 1 ?
                                                                                //     element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                                'border-radius':
                                                                                    element.answer_design.border_radius +
                                                                                    'px',
                                                                                'border-color':
                                                                                    element.answer_design.border_color,
                                                                                'border-style':
                                                                                    element.answer_design.border_style,
                                                                                'border-width':
                                                                                    element.answer_design.border_width +
                                                                                    'px',
                                                                                'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                            }">


                                                                            <div class="imageTop">
                                                                                <img v-if="answer.img_url === null"
                                                                                    :src="require(`@/assets/toolbar/placeholder.png`)" />
                                                                                <img v-else :src="answer.img_url" />
                                                                            </div>

                                                                            <div class="answerBody" :style="{
                                                                                'background-color': answer.is_selected === 1 ?
                                                                                    element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                                'font-weight':
                                                                                    element.answer_design.font_weight,
                                                                                'font-size':
                                                                                    element.answer_design.font_size +
                                                                                    'px',
                                                                            }">
                                                                                {{ answer.answer_text }}
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                            <div v-if="element.click_action_type === 'single_answer'">

                                                <div v-if="element.answer_type == 'text_only'">

                                                    <div class="questionTextOnlyWrapper" :style="{
                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                    }">

                                                        <div :style="{
                                                            'color': element.question_design.font_color,
                                                            'font-size': element.question_design.font_size + 'px',
                                                            'font-family': element.question_design.font_family,
                                                            'font-weight': element.question_design.font_weight,
                                                            'text-align': element.question_design.text_align,
                                                            'font-weight': element.question_design.font_weight,
                                                            'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                        }">
                                                            {{ element.question_text }}
                                                        </div>

                                                        <div class="d-flex align-items-center" :style="{
                                                            'justify-content': element.question_design.alignment_box,
                                                        }">
                                                            <div class="questionContainer">

                                                                <div class="myBox">



                                                                    <div v-for="(answer, answerIndex) in element.answers"
                                                                        @click="liveQuizDataStore.oneAnswerSelectOnly(element, answer)"
                                                                        @mouseover="liveQuizDataStore.currentAnswerIndex = answerIndex"
                                                                        @mouseleave="liveQuizDataStore.currentAnswerIndex = null"
                                                                        :key="answerIndex" class="previewAnswerWrapper"
                                                                        :style="{
                                                                            'color': element.answer_design.font_color,
                                                                            'font-size':
                                                                                element.answer_design.font_size + 'px',
                                                                            'font-family':
                                                                                element.answer_design.font_family,
                                                                            'font-weight':
                                                                                element.answer_design.font_weight,
                                                                            'padding':
                                                                                element.answer_design.padding + 'px',
                                                                            'background-color': answer.is_selected === 1 ?
                                                                                element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                            'border-radius':
                                                                                element.answer_design.border_radius +
                                                                                'px',
                                                                            'border-color':
                                                                                element.answer_design.border_color,
                                                                            'border-style':
                                                                                element.answer_design.border_style,
                                                                            'border-width':
                                                                                element.answer_design.border_width +
                                                                                'px',
                                                                            'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                        }">


                                                                        <div class="row">
                                                                            <div class="col" :style="{
                                                                                'text-align':
                                                                                    element.answer_design.text_align,
                                                                            }">
                                                                                {{ answer.answer_text }}

                                                                                <!-- is_selected: {{answer.is_selected }} -->
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="element.answer_type == 'text_with_image'">

                                                    <div class="questionTextImageWrapper" :style="{
                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                    }">
                                                        <div :style="{
                                                            'color': element.question_design.font_color,
                                                            'font-size':
                                                                element.question_design.font_size + 'px',
                                                            'font-family': element.question_design.font_family,
                                                            'font-weight': element.question_design.font_weight,
                                                            'text-align': element.question_design.text_align,
                                                            'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                        }">
                                                            {{ element.question_text }}
                                                        </div>

                                                        <div class="d-flex align-items-center" :style="{
                                                            'justify-content': element.question_design.alignment_box,
                                                        }">

                                                            <div class="questionTextImageContainer">

                                                                <div class="container1">


                                                                    <div v-for="(answer, answerIndex) in element.answers"
                                                                        @click="liveQuizDataStore.oneAnswerSelectOnly(element, answer)"
                                                                        :key="answerIndex">
                                                                        <div class="box1" :style="{
                                                                            'color': element.answer_design.font_color,
                                                                            'font-size':
                                                                                element.answer_design.font_size + 'px',
                                                                            'font-family':
                                                                                element.answer_design.font_family,
                                                                            'font-weight':
                                                                                element.answer_design.font_weight,
                                                                            'background-color':
                                                                                element.answer_design.background_color,
                                                                            'border-radius':
                                                                                element.answer_design.border_radius +
                                                                                'px',
                                                                            'border-color':
                                                                                element.answer_design.border_color,
                                                                            'border-style':
                                                                                element.answer_design.border_style,
                                                                            'border-width':
                                                                                element.answer_design.border_width +
                                                                                'px',
                                                                            'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                        }">


                                                                            <div class="imageTop">
                                                                                <img v-if="answer.img_url === null"
                                                                                    :src="require(`@/assets/toolbar/placeholder.png`)" />
                                                                                <img v-else :src="answer.img_url" />
                                                                            </div>

                                                                            <div class="answerBody" :style="{
                                                                                'background-color': answer.is_selected === 1 ?
                                                                                    element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                                'font-weight':
                                                                                    element.answer_design.font_weight,
                                                                                'font-size':
                                                                                    element.answer_design.font_size +
                                                                                    'px',
                                                                            }">
                                                                                {{ answer.answer_text }}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>


                                            </div>



                                        </div>

                                        <div v-if="element.type == 'score'">



                                            <div class="scoreOuterWrapper" :style="{
                                                'justify-content': element.alignment,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">


                                                <div class="scoreContainer" :style="{
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                }">


                                                    {{ element.text_label }}: {{ element.correct_amt }} / {{
                                                        element.total_questions_amt }}

                                                </div>

                                            </div>

                                        </div>

                                        <div v-if="element.type == 'embed'">


                                            <div v-if="element.has_code === 'no'">

                                                <img width="50" :src="require(`@/assets/images/code.png`)" />

                                            </div>


                                            <div v-if="element.has_code === 'yes'" class="embedCodeWrapper" :style="{
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <div v-html="element.code_block" :style="{
                                                    'width': element.width + '%',
                                                    'height': element.height + 'px',
                                                }"></div>

                                            </div>



                                        </div>

                                        <div v-if="element.type == 'calendar_embed'">

                                            <div v-if="element.has_code === 'no'">

                                                <img width="40" :src="require(`@/assets/images/calendar_black.png`)" />

                                            </div>


                                            <div v-if="element.has_code === 'yes'" class="" :style="{
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <iframe :width="`${element.width}%`" :height="`${element.height}px`"
                                                    :src="element.calender_url" frameborder="0" :style="{
                                                        'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                        'border-radius': '5px',
                                                    }"></iframe>


                                            </div>



                                        </div>




                                    </div>



                                </div>






                            </div>



                        </div>




                       

                    </div>

                    <div v-if="liveQuizDataStore.currentPlan.type === 'free'" class="powerContainer"> 
                        
                        <a href="https://convertlyio.com" target="_blank" style="color: inherit; text-decoration: none;">
                            <div class="poweredByBrand">
                                <div>
                                    <img width="20" style="margin-right: 10px;" :src="require(`@/assets/images/logo_icon.png`)" />
                                </div>
                                <div style="font-size: 14px;">
                                    Powered by <b>Convertlyio</b>
                                </div>
                            </div>
                        </a>
                        

                    </div> 

               


                </div>








            </div>

        </div>

        <div v-if="liveQuizDataStore.message === 'limit_reached'">

            <h3 style="margin-top: 200px;">This project is unavailable.</h3>

        </div>





    </div>
</template>

<script>
/* eslint-disable */
import { useLiveQuizDataStore } from "@/stores/LiveQuizDataStore";
import LiveQuizProgressBar from '@/components/LiveQuizProgressBar.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, onMounted } from 'vue';


export default {
    name: 'LiveQuiz',

    components: {
        LiveQuizProgressBar, VueDatePicker
    },

    props: ['random_string'],


    setup(props) {


        const liveQuizDataStore = useLiveQuizDataStore();

        // Initial device type
        var deviceType = ref();

        onMounted(async () => {

            await liveQuizDataStore.fetchQuiz(props.random_string);

            if (liveQuizDataStore.message === 'account_is_within_limit') {

                if (liveQuizDataStore.quiz.type === 'answer_based_outcomes') {

                    liveQuizDataStore.setResultsTracker()

                }




                //start timer
                liveQuizDataStore.startTimer()

                deviceType.value = getDeviceType()

                //increment visit count
                liveQuizDataStore.incrementQuizVisitCount()

            }




            // console.log('Hello, World!', liveQuizDataStore.quiz);

        });




        const showCheckboxOverlay = ref(false);

        const showAndHideCheckboxOverlay = () => {
            showCheckboxOverlay.value = !showCheckboxOverlay.value;
        }

        const currentPopupBoxContent = ref(null);
        const setCurrentPopupBoxContent = (content) => {
            currentPopupBoxContent.value = content
        }



        // Function to determine the device type
        const getDeviceType = () => {
            if (window.innerWidth <= 715) {

                liveQuizDataStore.saveDeviceType('Mobile')

                return 'Mobile';

            } else if (window.innerWidth <= 924) {

                liveQuizDataStore.saveDeviceType('Tablet')

                return 'Tablet';

            } else {

                liveQuizDataStore.saveDeviceType('Desktop')

                return 'Desktop';
            }
        };



        // Update the device type when the window is resized
        window.addEventListener('resize', () => {
            deviceType.value = getDeviceType(); 
        });


        // //USED ONLY FOR SINGLE AND SCORED not answerbased quiz. watch if the current preview page has a redirect url is yes redirect to it
        // watch(
        //     [
        //         () => liveQuizDataStore.defaultPagesArray[liveQuizDataStore.currentPageIndex],

        //     ],
        //     () => {

        //         let page = liveQuizDataStore.defaultPagesArray[liveQuizDataStore.currentPageIndex];


        //         if(liveQuizDataStore.quiz.type === 'single_outcome' || liveQuizDataStore.quiz.type === 'score_based_outcomes'){

        //             if (page.redirect_url != null) {

        //                 window.open(page.redirect_url, '_self'); //open in new tab becuase dont want navigate user from editor

        //             } 

        //         }

                


        //     }
        // );


        return {
            liveQuizDataStore, showCheckboxOverlay, showAndHideCheckboxOverlay,
            setCurrentPopupBoxContent, deviceType
        }


    }


};
</script>

<style scoped>
.p_QuizOuterBG {
    display: grid;
    place-items: center;
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: thin;
    padding-top: 80px;
    padding-bottom: 80px;
    /* height: 100vh;
    overflow-y: auto;
    scrollbar-width: thin;
    padding-top: 80px;
    padding-bottom: 80px; */

}

/* .contentWrapper {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    justify-content: center;
    
}

.centerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
} */

.mainWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.p_QuizCard {

    max-width: 700px; 
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    background-color: white;
    padding: 30px;
}

.v_placeholder {
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sharingWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 300px;
}

.platformItem {
    flex-basis: calc(25% - 20px);
    /* Display 4 items per row on desktop */
    padding: 10px;
    box-sizing: border-box;
}

.outerWrapper {
    /* display: flex;
    flex-direction: row; */
}

.mycontainer {
    display: flex;
    flex-direction: column;


}

.box {
    /* margin-bottom: 10px; */

    height: 100%;
    /* background-color: #ccc; */
}


.container1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
}

@media (max-width: 767px) {
    .container1 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 768px) {
    .container1 {
        grid-template-columns: repeat(2, 1fr);
    }
}

.box1 {
    max-width: 150px;
    background-color: rgb(232, 232, 233);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: relative;
}

.imageTop {
    height: 100px;
}

.imageTop img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.answerBody {
    flex-grow: 1;
    padding: 10px;
}

.previewAnswerWrapper {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    position: relative;
    max-width: 500px;
    transition: transform 0.4s ease-in-out;
}

.previewAnswerWrapper:hover {
    transform: scale(1.1);
}


.textareaWrapper {
    width: 100%;
}


.textboxOuterWrapper {
    display: flex;
    flex-direction: row;
    /* width: 100%; */

}


.textboxContainer {
    display: flex;
    flex-direction: column;
}

.theBox {
    height: 100%;
}

.checkboxWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* padding: 10px; */

}


.theInput {
    width: 100%;
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
}



.center-video {
    display: grid;
    place-items: center;
}


.embedCodeWrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}


.parent-container {
    display: flex;

}

.inputFieldWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align children (label and input) to the left */
    width: 100%;
    /* background-color: antiquewhite; */
}



.lableWrapper {
    /* Add any specific styles for your label wrapper here */
}

.theInputWrapper {
    /* Add any specific styles for your input wrapper here */
    width: 100%;
    /* Full width to fill the container */
}

.inputFieldWrapper.align-left {
    align-items: flex-start;
    /* Aligns children to the left */
}

.inputFieldWrapper.align-center {
    align-items: center;
    /* Aligns children to the center */
}

.inputFieldWrapper.align-right {
    align-items: flex-end;
    /* Aligns children to the right */
}

/* Use a media query for desktop screens */
@media (min-width: 768px) {

    /* Adjust the min-width as needed for your desktop breakpoint */
    .inputFieldWrapper {
        max-width: 500px;
        /* Set the max-width for desktop */
    }
}



.poweredByBrand { 
    height: 40px;
    cursor: pointer;
    background-color: white;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Add this line */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.poweredByBrand:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.powerContainer{ 
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}







</style>