<template>
    <div>

        <div class="dashboardBodyWrapper">


            <MainSideBarVue />



            <div class="MainContentArea">


                <div class="contentHeaderWrapper">

                    <div class="leftDivWrapper">

                        <div class="headerText">

                            Plans & Billing

                        </div>



                    </div>

                    <div class="rightDivWrapper">


                        <!-- USAGE STATES HERE -->

                    </div>



                </div>


                <center>
                    <hr class="dividerLine">
                </center>



                <div class="contentBodyWrapper">


                    <div class="billingWrapper">

                        <div class="currentPlanWrapper">

                            <div class="headerPlan">
                                Current Plan
                            </div>

                            <div class="subHeaderPlan" v-if="authStore.currentPlan.type === 'free'">
                                Free
                            </div>

                            <div class="subHeaderPlan" v-if="authStore.currentPlan.type === 'starter'">
                                Starter
                            </div>

                            <div class="subHeaderPlan" v-if="authStore.currentPlan.type === 'pro'">
                                Professional
                            </div>

                            <div class="subHeaderPlan" v-if="authStore.currentPlan.type === 'business'">
                                Business
                            </div>


                        </div>

                        <div class="viewPlansWrapper">


                            <!-- {{ authStore.currentPlan }} -->
             
                        
                                <div class="headerPlan">
                                    See Plans
                                </div>

                                <div class="subHeaderPlan" style="margin-top: 12px;">

                                    <router-link class="button-3"
                                    :to="{ name: 'PaidPlans'}">
                                        View
                                    </router-link>

                                </div>


                        

                        </div>

                    </div>

                    <div class="featuresAndInvoicesBox">


<!-- 
                        <div class="currentPlanFeatures">

                            <div class="featureHeader">
                                Features on the Free plan:
                            </div>

                            <div v-for="index in 10" :key="index">
                                
                                <div class="featureItem">
                                    
                                    <div class="imgWrapper">
                                        <img :src="require(`@/assets/images/tick.png`)"  width="12" />
                                    </div>

                                    <div class="featureDescWrapper">
                                        desc here
                                    </div>

                                </div>


                            </div>

                            <div v-for="index in 10" :key="index">
                                
                                <div class="featureItem">
                                    
                                    <div class="imgWrapper">
                                        <img :src="require(`@/assets/images/red_x.png`)"  width="12" />
                                    </div>

                                    <div class="featureDescWrapper">
                                        desc here
                                    </div>

                                </div>


                            </div>


                        </div> -->

                        <!-- <div class="invoicesWrapper">

                            <div class="invoiceHeaderText">
                                Invoices
                            </div>

                            <div class="invoicesContainer">

                                <div v-for="index in 205" :key="index">
                                    invoice
                                </div>

                            </div>

                            

                        </div> -->

                    </div>






                </div>



            </div>


        </div>



    </div>
</template>

<script>
import MainSideBarVue from '@/components/MainSideBar.vue'; 
import { useAuthStore } from "@/stores/AuthStore";
import { onMounted } from 'vue';



export default {
    name: 'MainBillingView',

    components: {
        MainSideBarVue
    },



    setup() {


        const authStore = useAuthStore();

        onMounted(() => {
 
            //fetch user data
            authStore.getUserData()  

            //fetch plans
            authStore.getPlans()

        });

        return {

            authStore

        }

    }


};
</script>

<style scoped>
.dashboardBodyWrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}


.MainContentArea {
    width: 83%;
    overflow-y: auto;
    padding-bottom: 30px;
}



.contentHeaderWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    /* background-color: antiquewhite; */
    display: flex;
}

.contentBodyWrapper {
    /* background-color: aquamarine; */
    padding-left: 25px;
    padding-right: 25px;

}

.leftDivWrapper {
    /* background-color: rgb(167, 167, 138); */
    width: 50%;
}

.rightDivWrapper {
    /* background-color: rgb(206, 197, 206); */
    width: 50%;
}

.headerText {
    font-weight: bold;
    font-size: 25px;
    float: left;
}

.dividerLine {
    width: 95%;
    color: #afb2c6;
}



.billingWrapper {
    width: 100%;
    /* background-color: #afb2c6; */
    display: flex;

}

.currentPlanWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
}

.headerPlan{
    text-align: left;
    font-weight: bold;
}

.subHeaderPlan{
    text-align: left;
}

.viewPlansWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;

}


.featuresAndInvoicesBox {
    display: flex;
}

.currentPlanFeatures {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.featureHeader{
    /* background-color: #afb2c6; */
    text-align: left;
    font-weight: bold;
}


.invoiceHeaderText{
    /* background-color: #afb2c6; */
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.invoicesContainer{
    /* background-color: #94d3a2; */
    height: 640px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 5px;
    padding: 15px;
}

.featureItem{
    /* background-color: #94d3a2; */
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    margin-top: 5px;
}


.imgWrapper{
    margin-right: 10px;
}
.featureDescWrapper{

}

.invoicesWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 10px;
    height: 700px;
  
}

.imgIcon {
    margin-right: 6px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}


/* CSS */
.button-3 {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2c974b;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}
</style>