<template>
    <div>

        <div class="dashboardBodyWrapper">


            <MainSideBarVue />



            <div class="MainContentArea">


                <div class="contentHeaderWrapper">

                    <div class="leftDivWrapper">

                        <div class="headerText">

                            Workspaces

                            <!-- <img class="teamPlus" width="20" style="margin-left: 10px;"
                                :src="require(`@/assets/images/team_plus.png`)" /> -->
                        </div>



                    </div>

                    <div class="rightDivWrapper">

                        <button @click="showNewSpaceModal" class="button-4" role="button">New Workspace +</button>


                    </div>



                </div>


                <center>
                    <hr class="dividerLine">
                </center>



                <div class="contentBodyWrapper">


                    <div class="MainWorkspaceWrapper">


                        <div v-for="(workspace, index) in workspaceStore.workspaces" :key="index">

                            <!-- <router-link class="linkStyle"
                                :to="{ name: 'ViewAWorkspace', params: { workspaceUUID: workspace.uuid } }"> -->

                            <div class="workSpaceItem" @click="navigateToWorkspace(workspace)">



                                <div class="workspaceitemHeader">

                                    <div class="spaceHeaderText">

                                        {{ workspaceStore.limitText(workspace.name) }}

                                    </div>






                                </div>

                                <div class="workspaceItemBottom">

                                    <div class="optionsWrapper">

                                        <div class="boxy">
                                            <div @click.stop="showUpdateWorkspaceModal(workspace);" class=""> <img
                                                    width="14" :src="require(`@/assets/images/rename.png`)" /> Rename</div>
                                        </div>

                                        <!-- <div class="boxy">
                                            <div v-if="workspace.type == 'normal'" @click.stop="showDeleteModal(workspace)"
                                                class=""> <img width="16"
                                                    :src="require(`@/assets/images/team_members.png`)" />
                                                Members
                                            </div>
                                        </div> -->

                                        <div class="boxy">
                                            <div v-if="workspace.type == 'normal'" @click.stop="showDeleteModal(workspace)">

                                                <img width="13" style="margin-top: -4px;"
                                                    :src="require(`@/assets/images/t_trash.png`)">

                                            </div>
                                        </div>

                                    </div>

                                    <!-- <div @click.stop="showUpdateWorkspaceModal(workspace);" class=""> <img width="15"
                                            :src="require(`@/assets/images/rename.png`)" /> Rename</div>


                                    <div v-if="workspace.type == 'normal'" @click.stop="showDeleteModal(workspace)"
                                        class=""> <img width="16" :src="require(`@/assets/images/team_members.png`)" />
                                        Members
                                    </div>


                                    <div v-if="workspace.type == 'normal'" @click.stop="showDeleteModal(workspace)">

                                        <img width="13" style="margin-top: -4px;"
                                            :src="require(`@/assets/images/t_trash.png`)">

                                    </div> -->




                                </div>




                            </div>

                            <!-- </router-link> -->

                        </div>











                    </div>



                </div>



            </div>


        </div>



        <div class="modal fade" id="newWorkspaceModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Create A New Workspace
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form @submit.prevent="saveNewWorkspace">

                        <div class="modal-body">


                            <div class="mb-3">

                                <input v-model="workspaceStore.workspaceFormData.name" type="text" class="form-control"
                                    placeholder="Workspace name" required>
                            </div>


                        </div>

                        <div class="modal-footer">

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Cancel
                            </button>

                            <button type="submit" class="btn btn-success">
                                Create
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>

        <div class="modal fade" id="updateWorkspaceModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Rename Workspace
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form @submit.prevent="updateAWorkspace">

                        <div class="modal-body">


                            <div class="mb-3">

                                <input v-model="workspaceStore.currentWorkspace.name" type="text" class="form-control"
                                    required>
                            </div>


                        </div>

                        <div class="modal-footer">

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Cancel
                            </button>

                            <button type="submit" class="btn btn-success">
                                Save
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>


        <div class="modal fade" id="deleteWorkspaceModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Are you sure you want to delete {{ workspaceStore.currentWorkspace.name }}
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form>

                        <div class="modal-body" style="text-align: left;">


                            <b style="color: red;">IMPORTANT:</b> Deleting <b>{{ workspaceStore.currentWorkspace.name }}</b>
                            will delete all projects in this workspace and the data cannot be recovered.


                        </div>

                        <div class="modal-footer">

                            <button type="submit" class="btn btn-danger" @click.prevent="deleteAWorkspace">
                                YES, Delete This Workspace
                            </button>

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Cancel
                            </button>


                        </div>

                    </form>


                </div>
            </div>
        </div>



    </div>
</template>

<script>


import { useWorkspaceStore } from '@/stores/WorkspaceStore'
import { useGeneralStore } from '@/stores/GeneralStore'
import { useAuthStore } from '@/stores/AuthStore'
import MainSideBarVue from '@/components/MainSideBar.vue';
import { Modal } from "bootstrap";
import { useRouter } from 'vue-router';

export default {

    name: 'MyDashboard',

    components: {
        MainSideBarVue
    },


    setup() {

        const workspaceStore = useWorkspaceStore()
        const generalStore = useGeneralStore()
        const authStore = useAuthStore()
        const router = useRouter();

        //fetch workspaces
        workspaceStore.fetchWorkspaces()


        const saveNewWorkspace = () => {

            //save it
            workspaceStore.storeNewWorkspace()

            //hide modal
            hideNewWorkspaceModal()

        }


        const showUpdateWorkspaceModal = (workspace) => {

            setCurrentWorkspace(workspace)

            showModal('updateWorkspaceModal')


        }

        const setCurrentWorkspace = (workspace) => {
            workspaceStore.currentWorkspace = workspace
        }

        const updateAWorkspace = () => {

            workspaceStore.updateThisWorkspace()

            hideModal('updateWorkspaceModal')

        }

        const deleteAWorkspace = () => {

            workspaceStore.deleteThisWorkspace()

            hideModal('deleteWorkspaceModal')

        }



        var showNewSpaceModal = () => {

            var modal = new Modal(
                document.getElementById("newWorkspaceModal"),
                {
                    keyboard: false,
                }
            );

            modal.show();
        };

        var hideNewWorkspaceModal = () => {

            //hide modal
            var modal = document.getElementById("newWorkspaceModal");
            var thisModal = Modal.getOrCreateInstance(modal);

            thisModal.hide();
        };

        const showModal = (modalName) => {

            var modal = new Modal(
                document.getElementById(modalName),
                {
                    keyboard: false,
                }
            );

            modal.show();
        }

        const hideModal = (modalName) => {

            //hide modal
            var modal = document.getElementById(modalName);
            var thisModal = Modal.getOrCreateInstance(modal);

            thisModal.hide();
        };

        const showDeleteModal = (workspace) => {

            setCurrentWorkspace(workspace)

            showModal('deleteWorkspaceModal')

        }

        const navigateToWorkspace = (workspace) => {
            router.push({ name: 'ViewAWorkspace', params: { workspaceUUID: workspace.uuid } });
        };



        return {
            workspaceStore, showNewSpaceModal, saveNewWorkspace, showUpdateWorkspaceModal,
            showModal, updateAWorkspace, showDeleteModal, deleteAWorkspace, navigateToWorkspace,
            generalStore, authStore
        }

    }


};
</script>

<style scoped>
.dashboardBodyWrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.MainContentArea {
    width: 83%;
    overflow-y: auto;

}

.sideBar {
    background-color: #f9f9f9;
    width: 17%;
    overflow-y: auto;
    padding: 10px;
}


.logoWrapper {
    background-color: #a6baba;
    margin-bottom: 65px;
    /* padding: 15px; */
}

.imgLogo {
    float: left;
    width: 130px;
}

.menuItemRow {
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
}

.iconArea {
    /* background-color: #b8ffd3; */
    width: 10%;
    display: flex;
    align-items: center;
}

.labelArea {

    /* background-color: #36e1e1; */
    width: 80%;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

}

.menuIcon {
    /* width: 15px; */
}


.MainMenuWrapper {
    /* background-color: aqua; */
    margin-bottom: 30px;
}


.resourcesWrapper {
    /* background-color: bisque; */
}


.resourceHeader {
    font-size: 14px;
    font-weight: bold;
    float: left;
    padding-left: 13px;
    margin-bottom: 10px;
    color: #3b4848;
}


.contentHeaderWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    /* background-color: antiquewhite; */
    display: flex;
}

.contentBodyWrapper {
    /* background-color: aquamarine; */
    padding-left: 25px;
    padding-right: 25px;

}

.leftDivWrapper {
    /* background-color: rgb(167, 167, 138); */
    width: 50%;
}

.rightDivWrapper {
    /* background-color: rgb(206, 197, 206); */
    width: 50%;
}

.headerText {
    font-weight: bold;
    font-size: 25px;
    float: left;
}

.dividerLine {
    width: 95%;
    color: #afb2c6;
}



.button-4 {
    float: right;
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.button-4:hover {
    background-color: #F3F4F6;
    text-decoration: none;
    transition-duration: 0.1s;
}

.button-4:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
}

.button-4:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
}

.button-4:focus {
    outline: 1px transparent;
}

.button-4:before {
    display: none;
}

.button-4:-webkit-details-marker {
    display: none;
}

.downArrow {
    cursor: pointer;
    float: right;
}

.downArrow:hover {

    /* scale: 1.2; */
    /* transition: transform 0.5s ease; */
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 3px;

}

.teamPlus {
    cursor: pointer;
}

.listItem {
    background-color: #f9f9f9;
    display: flex;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 21px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

}


.listLeftSide {
    /* background-color: rgb(137, 123, 123); */
    width: 50%;
}

.listRightSide {
    /* background-color: rgb(163, 179, 213); */
    width: 50%;
}


.headTitle {
    font-size: 18px;
    font-weight: bold;
    float: left;
}

.actionsDiv {
    display: flex;
    /* background-color: #d1d3d3; */
    float: right;
    width: 30%;
}

.actionItem {
    width: 33%;
    cursor: pointer;
}

.actionItem:hover {
    background-color: rgb(235, 236, 235);
    border-radius: 50px;
}


.draftPill {
    float: left;
    margin-left: 10px;
    font-size: 9px !important;
    background-color: #b2b2b2 !important;
}


.MainWorkspaceWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
}


.workSpaceItem {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    /* background-color: #959DA5; */
    border-radius: 15px;
    /* margin: 5px; */
    height: 90px;
    cursor: pointer;


}


.workspaceitemHeader {
    /* background-color: hsl(66, 18%, 59%); */
    height: 50%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px;

}


.workspaceItemBottom {
    width: 100%;
    /* background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%); */
    /* background-color: #a6baba; */
    height: 50%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-left: 15px;
    margin-top: 20px;
    /* display: flex;
    flex-direction: row; */

}

.spaceHeaderText {
    font-weight: bold;
    float: left;
}



.aDropdownItem {
    font-size: 15px;
    padding: 10px;
}

.aDropdownItem:hover {
    background-color: #f9f9f9;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}


.dropdown-menu {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border: none;
}

.optionsWrapper {
    width: 100%;
    /* background-color: #959DA5; */
    display: flex;
    flex-direction: row;

}


.boxy {
    /* background-color: #2fd4d4; */
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    margin-right: 18px;

}

.closeX {

    width: 15px;
    float: right;
    margin-bottom: 18px;
    cursor: pointer;

}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(66, 66, 66, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-box {
    background-color: white;
    width: 650px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.modal-content {
    text-align: center;
}
</style>