import { defineStore } from 'pinia' 
import AuthService from '@/services/AuthService';
// import { useAuthStore } from '../stores/AuthStore'

export const useGeneralStore = defineStore('generalStore', {


    state: () => ({


        topMenuArray: [
            {id: 1, label: 'Home', icon_name: 'dashbaord', width: '15', route_name: 'MyDashboard'},
            // {id: 2, label: 'Templates', icon_name: 'templates', width: '13', route_name: ''},
            // {id: 3, label: 'Custom Domains', icon_name: 'domain', width: '13', route_name: ''},
            // {id: 4, label: 'Team', icon_name: 'team_members', width: '16', route_name: ''},
            {id: 5, label: 'Account Usage', icon_name: 'usage', width: '14', route_name: 'AccountUsage'},
            {id: 6, label: 'Plans & Billing', icon_name: 'invoice', width: '14', route_name: 'MainBillingView'},
            // {id: 7, label: 'Settings', icon_name: 'settings2', width: '14', route_name: ''},
            {id: 8, label: 'Logout', icon_name: 'logout', is_logout: true, width: '13', route_name: ''},
        ],

        resourcesArray: [
            // {id: 1, label: 'Get Started', icon_name: 'started', width: '15'},
            {id: 2, label: 'How-to guides', icon_name: 'guides', width: '15', link_url: 'https://convertlyio.com/tutorials/'},
            {id: 3, label: 'Give Feedback', icon_name: 'support', width: '15', route_name: 'ContactSupport', link_url: null},
            // {id: 4, label: 'Blog', icon_name: 'blog', width: '15'},

        ],


        showContactModal: false,

        supportContactForm: {
            category: 'Please select a category',
            message: '',
        },

        supportMessageSuccess: false,
        isLoading: false,

    }),


    actions: {




        
        openContactModal(){ 
            this.showContactModal = true
        },

        closeContactModal(){ 
            this.showContactModal = false
            this.supportMessageSuccess = false
        },

        submitSupportContactDetails(){


            this.isLoading = true //


            AuthService.sendSupportMessage(this.supportContactForm) 
                .then((response) => {

                    console.log(response.data)

                    this.isLoading = true
                    this.supportMessageSuccess = true
                    

                    
                }).catch((err) => {

                    console.log(err)
                    
                });


        }
    

    }


})