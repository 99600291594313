
<template>
    <div class="loginWrapper">

        <div class="topNav">

            <img class="imgLogo" :src="require(`@/assets/images/logo_trans2.png`)" />

        </div>

        <div class="formWrapper">

            <div v-if="authStore.errorMessage" class="alert alert-danger" role="alert" style="max-width: 400px;">
                {{ authStore.errorMessage }}
            </div>

             

            <form @submit.prevent="authStore.registerManager">



                <div class="mb-3">
                    <label class="form-label" style="float: left;">Name</label>
                    <input v-model="authStore.registerFormData.name" type="text" class="form-control" required>
                </div>


                <div class="mb-3">
                    <label class="form-label" style="float: left;">Email address</label>
                    <input v-model="authStore.registerFormData.email" type="email" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Password</label>
                    <input v-model="authStore.registerFormData.password" type="password" class="form-control" required>
                </div>

                <div class="mb-3">
                    <label class="form-label" style="float: left;">Confirm Password</label>
                    <input v-model="authStore.registerFormData.password_confirmation" type="password" class="form-control"
                        required>
                </div>


                <div v-if="authStore.isLoading">

                    <button type="button" class="btn btn-primary loginBTN" disabled>Loading...</button>

                </div>

                <div v-else>
                    <button type="submit" class="btn btn-primary loginBTN">SIGN UP</button>
                </div>

            </form>

            <div style="margin-top: 25px;">

                <div id="emailHelp" class="form-text">Already have an account? <router-link :to="{ name: 'LoginView' }">
                        <span>Login</span>
                    </router-link>
                </div>


            </div>





        </div>

    </div>
</template>

<script>

import { useAuthStore } from '../../stores/AuthStore'


export default {

    name: 'RegisterManager',


    setup() {

        const authStore = useAuthStore()


        return {
            authStore
        }

    }



};
</script>

<style scoped>
.imgLogo {
    float: left;
    width: 170px;
}

.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: aliceblue; */
}

.topNav {
    /* background-color: rgb(255, 255, 255); */
    width: 100%;
    position: fixed;
    top: 0;
}

.formWrapper {
    /* Styling for form wrapper */
}

.loginBTN {
    background-color: black;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
}

.loginBTN:hover {
    background-color: rgb(36, 141, 48);
}

.form-label {
    font-weight: bold;
}


.form-control {
    background-color: rgb(243, 246, 246);
}
</style>