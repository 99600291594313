<template>
    <div>



        <div class="floatingToolbar">


            <div class="row">
                <div class="col" @click="widgetTabSelected">
                    <img :src="require(`@/assets/toolbar/plus.png`)" class="widgetImgIcon" />
                    <br />
                    <span class="widgetLabelText">Widget</span>
                </div>


                <div class="col" @click="quizEditorStore.saveDesign">
                    <img :src="require(`@/assets/toolbar/save.png`)" class="widgetImgIcon" />
                    <br />
                    <span class="widgetLabelText">Save</span>
                </div>


                <div class="col" @click="showAndHidePreviewQuizOverlay">
                    <img :src="require(`@/assets/toolbar/eye.png`)" class="widgetImgIcon" />
                    <br />
                    <span class="widgetLabelText">Preview</span>
                </div>

                <div class="col">

                    <div v-if="quizEditorStore.currentQuiz.is_live === 'no'"
                        @click="quizEditorStore.publishThisProjectLive('yes')">
                        <img :src="require(`@/assets/toolbar/live.png`)" class="widgetImgIcon" />
                        <br />
                        <span class="widgetLabelText">Publish</span>
                    </div>

                    <div v-if="quizEditorStore.currentQuiz.is_live === 'yes'"
                        @click="quizEditorStore.publishThisProjectLive('no')">
                        <img :src="require(`@/assets/toolbar/hide.png`)" class="widgetImgIcon" />
                        <br />
                        <span class="widgetLabelText">Unpublish</span>
                    </div>



                </div>

                <div class="col" @click="quizEditorStore.saveChangesBeforeExit()">
                    <img :src="require(`@/assets/images/x_white.png`)" class="widgetImgIcon" />
                    <br />
                    <span class="widgetLabelText">Exit Editor</span>
                </div>

            </div>
        </div>



        <div class="outerBG">


            <div class="theSideBarOnLeft">


                <div class="mainSidebar">

                    <div class="topQuizNameArea" style="text-align: left;">



                        <div style="color: black;">
                            <b>{{ quizEditorStore.currentQuiz.name }}</b>
                        </div>

                        <div v-if="quizEditorStore.currentQuiz.type === 'answer_based_outcomes'"
                            style="color: black; font-size: 12px;">
                            Type: Answer-based Outcomes
                        </div>

                        <div v-if="quizEditorStore.currentQuiz.type === 'single_outcome'"
                            style="color: black; font-size: 12px;">
                            Type: Single Outcome
                        </div>

                        <div v-if="quizEditorStore.currentQuiz.type === 'score_based_outcomes'"
                            style="color: black; font-size: 12px;">
                            Type: Score Assessment
                        </div>

                    </div>

                    <div v-if="currentlySelected == 'widgetTab'">
                        <div class="sidebarHeader">Widgets</div>

                        <div class="sidebarBody">

                            <div class="iconRowWrapper">

                                <div v-for="(widget, index) in quizEditorStore.toolbarWidgets" :key="index"
                                    @click="quizEditorStore.addElement(widget.type)" class="toolIcon">
                                    <img :src="require(`@/assets/toolbar/${widget.icon_name}.png`)" class="toolIconImage" />
                                    <br />
                                    <span class="tooliconText">{{ widget.label }}</span>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div v-if="currentlySelected == 'optionTab'">
                        <div class="sidebarHeader">Options</div>

                        <div class="sidebarBody">
                            <div class="row optionsWrapper">
                                <div class="col-2" style="background-color: aquamarine">
                                    ICON + TEXT
                                </div>
                                <div class="col-10" style="background-color: purple">
                                    V-IF TAB CONTENT HERE
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="currentlySelected == 'showQuizOuterBGOption'">



                        <div class="sidebarBody" style="padding: 20px">



                            <div class="optionWrapper row">


                                <div class="col-6">
                                    <label style="font-size: 14px; float: left">Background Type</label>
                                </div>

                                <div class="col-6">
                                    <select style="width: 140px" v-model="quizEditorStore.selectedOuterBGtype"
                                        @change="quizEditorStore.updateOuterBGtype">
                                        <option v-for="option in quizEditorStore.backgroundTypes" :value="option.type"
                                            :key="option">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>



                            </div>



                            <div class="optionWrapper row" v-if="quizEditorStore.selectedOuterBGtype == 'gradient_bg'">

                                <div class="col">
                                    <label class="labelText">Gradient</label>
                                </div>

                                <div class="col">

                                    <div class="dropdown">


                                        <div v-if="quizEditorStore.selectedOuterBGgradient !== ''" class="row"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown">


                                            <div class="col" :style="{
                                                'background': quizEditorStore.selectedOuterBGgradient,
                                                'width': '35px',
                                                'height': '35px',
                                                'border-radius': '6px'
                                            }">

                                            </div>

                                        </div>

                                        <div v-else class="row" id="dropdownMenuButton1" data-bs-toggle="dropdown">

                                            <div class="col">

                                                <span style="font-size: 12px;'">Select</span>

                                            </div>

                                            <div class="col" :style="{
                                                'background': 'linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%)',
                                                'width': '35px',
                                                'height': '35px',
                                                'border-radius': '6px'
                                            }">

                                            </div>

                                        </div>



                                        <ul class="dropdown-menu">


                                            <li v-for="(gradient, index) in quizEditorStore.gradientBGs" :key="index"
                                                @click="quizEditorStore.setOuterBGgradient(gradient)">

                                                <div class="dropdown-item">

                                                    <div class="row">

                                                        <div class="col-8" style="font-size: 13px;">
                                                            {{ gradient.name }}
                                                        </div>

                                                        <div class="col-4" :style="{
                                                            'background': gradient.background_value,
                                                            'width': '35px',
                                                            'height': '35px',
                                                            'border-radius': '6px'
                                                        }">

                                                        </div>

                                                    </div>



                                                </div>

                                            </li>


                                        </ul>

                                    </div>

                                </div>



                            </div>

                            <div class="optionWrapper row" v-if="quizEditorStore.selectedOuterBGtype == 'normal_bg'">

                                <div class="col-auto">
                                    <label class="labelText">Background Color</label>
                                </div>
                                <div class="col">
                                    <div class="color-input-container">
                                        <input class="color-input" type="color"
                                            v-model="quizEditorStore.selectedNormalBGcolor" />
                                    </div>
                                </div>
                                <div class="col">
                                    <input class="colorInputBox" type="text"
                                        v-model="quizEditorStore.selectedNormalBGcolor" />
                                </div>
                            </div>




                            <div class="optionWrapper row">

                                <div class="col-auto">
                                    <label class="labelText">Progress Bar Color</label>
                                </div>
                                <div class="col">
                                    <div class="color-input-container">
                                        <input class="color-input" type="color"
                                            v-model="quizEditorStore.selectedProgressBarColor" />
                                    </div>
                                </div>
                                <div class="col">
                                    <input class="colorInputBox" type="text"
                                        v-model="quizEditorStore.selectedProgressBarColor" />
                                </div>

                            </div>

                            <div class="optionWrapper row">

                                <div class="col">
                                    <label class="labelText">Show Progress Bar</label>
                                </div>


                                <div class="col-3">


                                    <div class="switchOn" @click="quizEditorStore.toggleEnableProgressBar"
                                        v-if="quizEditorStore.is_progress_bar_enabled === 1">

                                        YES

                                    </div>

                                    <div v-else class="switchOff" @click="quizEditorStore.toggleEnableProgressBar">
                                        NO
                                    </div>

                                </div>

                            </div>

                            <div class="imgUploadWrapper" v-if="quizEditorStore.selectedOuterBGtype == 'img_bg'">

                                <div class="uploadImgheader">
                                    Upload
                                </div>


                                <div class="uploadImgBody" @click="triggerFileInputForQuizBG">


                                    <img v-if="quizEditorStore.currentQuiz.outer_bg_img_url === null"
                                        class="imgInsideUploadBody" :src="require(`@/assets/toolbar/placeholder.png`)" />

                                    <img v-else class="imgInsideUploadBody"
                                        :src="quizEditorStore.currentQuiz.outer_bg_img_url" />



                                    <div class="uploadIBtn">

                                        <div v-if="quizEditorStore.imageIsUploading" style="font-weight: bold;">
                                            UPLOADING....
                                        </div>
                                        <div v-else>
                                            Click here to upload
                                        </div>

                                        <input style="display: none;" ref="fileInputForQuizBG" type="file" accept="image/*"
                                            @change="quizEditorStore.uploadQuizBackgroundImage($event)" />
                                    </div>

                                </div>



                                <div class="imgNotes">Maximum file size is 1 MB</div>


                            </div>


                        </div>


                    </div>

                    <div v-if="currentlySelected == 'showQuizCARDBGoption'">


                        <div class="sidebarBody" style="padding: 20px">


                            <div class="optionWrapper row">


                                <div class="col-6">
                                    <label style="font-size: 14px; float: left">Background Type</label>
                                </div>

                                <div class="col-6">
                                    <select style="width: 140px" v-model="quizEditorStore.selectedCardBGtype"
                                        @change="quizEditorStore.updateCardBGtype">
                                        <option v-for="option in quizEditorStore.filteredBackgroundTypes"
                                            :value="option.type" :key="option">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>



                            </div>



                            <div class="optionWrapper row" v-if="quizEditorStore.selectedCardBGtype == 'gradient_bg'">

                                <div class="col">
                                    <label class="labelText">Gradient</label>
                                </div>

                                <div class="col">

                                    <div class="dropdown">


                                        <div v-if="quizEditorStore.selectedCardBGgradient !== ''" class="row"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown">


                                            <div class="col" :style="{
                                                'background': quizEditorStore.selectedCardBGgradient,
                                                'width': '35px',
                                                'height': '35px',
                                                'border-radius': '6px'
                                            }">

                                            </div>

                                        </div>

                                        <div v-else class="row" id="dropdownMenuButton1" data-bs-toggle="dropdown">

                                            <div class="col">

                                                <span style="font-size: 12px;'">Select</span>

                                            </div>

                                            <div class="col" :style="{
                                                'background': 'linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%)',
                                                'width': '35px',
                                                'height': '35px',
                                                'border-radius': '6px'
                                            }">

                                            </div>

                                        </div>



                                        <ul class="dropdown-menu">


                                            <li v-for="(gradient, index) in quizEditorStore.gradientBGs" :key="index"
                                                @click="quizEditorStore.setCardBGgradient(gradient)">

                                                <div class="dropdown-item">

                                                    <div class="row">

                                                        <div class="col-8" style="font-size: 13px;">
                                                            {{ gradient.name }}
                                                        </div>

                                                        <div class="col-4" :style="{
                                                            'background': gradient.background_value,
                                                            'width': '35px',
                                                            'height': '35px',
                                                            'border-radius': '6px'
                                                        }">

                                                        </div>

                                                    </div>



                                                </div>

                                            </li>


                                        </ul>

                                    </div>

                                </div>



                            </div>

                            <div class="optionWrapper row" v-if="quizEditorStore.selectedCardBGtype === 'normal_bg'">
                                <div class="col-auto">
                                    <label class="labelText">Background Color</label>
                                </div>
                                <div class="col">
                                    <div class="color-input-container">
                                        <input class="color-input" type="color"
                                            v-model="quizEditorStore.selectedCardNormalBGcolor" />
                                    </div>
                                </div>
                                <div class="col">
                                    <input class="colorInputBox" type="text"
                                        v-model="quizEditorStore.selectedCardNormalBGcolor" />
                                </div>
                            </div>


                            <div class="imageWrapperDiv" v-if="quizEditorStore.selectedCardBGtype === 'img_bg'">
                                <div class="imgUploadTxt">Background Image</div>

                                <br />

                                <div class="row">
                                    <div class="uploadedImg col">
                                        <img :src="require(`@/assets/toolbar/placeholder.png`)" class="placeholderImg" />
                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>

                    <div v-if="currentlySelected == 'questionTab'">


                        <div class="tabsWrapper">


                            <div class="tabBtn" v-for="(questionTabOption, index) in quizEditorStore.questionTabOptions"
                                :key="index"
                                :class="{ tabBtnClicked: questionTabOption.id === quizEditorStore.currentQuestionTabId }"
                                @click="quizEditorStore.currentQuestionTabId = questionTabOption.id">
                                {{ questionTabOption.name }}
                            </div>


                        </div>


                        <div class="sidebarBody">


                            <div class="optionWrapper row" v-show="quizEditorStore.currentQuestionTabId === 1">


                                <div class="questionEditorWrapper">


                                    <button class="button-11" @click="quizEditorStore.openAnswerPipingModal">Pipe Answer
                                        +</button>

                                    <textarea style="word-wrap: break-word; width: 100%;" rows="4" cols="50"
                                        v-model="currentElement.question_text"></textarea>

                                    <!-- <editor id="questionEditor" api-key="bvj08wudza185lm5frysfdtyh7px2unli6gwux420yeebie4" 
                                    v-model="currentElement.text_code_block"
                                    :init="{
                                        branding: false,
                                        height: 250,
                                        toolbar: false,
                                        menubar: false, 
                                        statusbar: false,
                                   
                                    }"
                                    initial-value="Once upon a time..."
                                    @change="handleEditorChange()"
                                    /> -->

                                </div>



                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 14px; float: left">Font Color</label>
                                    </div>

                                    <div class="col">
                                        <div class="color-input-container" style="max-width: 50px; float: right">
                                            <input class="color-input" type="color"
                                                v-model="currentElement.question_design.font_color" />
                                        </div>
                                    </div>

                                    <div class="col">
                                        <input class="colorInputBox" type="text"
                                            v-model="currentElement.question_design.font_color" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 14px; float: left">Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="17" v-model="currentElement.question_design.font_size"
                                            style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: left"
                                            v-model="currentElement.question_design.font_size" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-1">
                                        <label style="font-size: 14px; float: left">Margin</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" v-model="currentElement.question_design.margin"
                                            style="margin-top: 6px" />
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.question_design.margin" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Text Aligment</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.question_design.text_align" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontAlignmentArray"
                                                :value="option.align" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Font Family</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.question_design.font_family" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontStylesArray" :value="option"
                                                :key="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Font Weight</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.question_design.font_weight" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontWeightArray" :value="option.weight"
                                                :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Box Aligment</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.question_design.alignment_box" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontAlignmentArray"
                                                :value="option.align" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div v-if="quizEditorStore.currentQuestionTabId === 2">

                                <div class="optionWrapper row">







                                    <div class="row">
                                        <div class="col-6">
                                            <label style="font-size: 14px; float: left">Answer Type</label>
                                        </div>

                                        <div class="col-6">
                                            <select v-model="currentElement.answer_type" style="width: 140px">
                                                <option v-for="option in quizEditorStore.answerTypesArray"
                                                    :value="option.type" :key="option.id">
                                                    {{ option.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col">
                                            <label style="font-size: 14px; float: left">Font Color</label>
                                        </div>

                                        <div class="col">
                                            <div class="color-input-container" style="max-width: 50px; float: right">
                                                <input class="color-input" type="color"
                                                    v-model="currentElement.answer_design.font_color" />
                                            </div>
                                        </div>

                                        <div class="col">
                                            <input class="colorInputBox" type="text"
                                                v-model="currentElement.answer_design.font_color" />
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col">
                                            <label style="font-size: 14px; float: left">Size</label>
                                        </div>

                                        <div class="col">
                                            <input type="range" v-model="currentElement.answer_design.font_size"
                                                style="margin-top: 6px" />
                                        </div>

                                        <div class="col">
                                            <input type="number" style="width: 50px; float: left"
                                                v-model="currentElement.answer_design.font_size" />
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col-1">
                                            <label style="font-size: 14px; float: left">Margin</label>
                                        </div>

                                        <div class="col">
                                            <input type="range" v-model="currentElement.answer_design.margin"
                                                style="margin-top: 6px" />
                                            <input type="number" style="width: 50px; float: right"
                                                v-model="currentElement.answer_design.margin" />
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col-6">
                                            <label style="font-size: 14px; float: left">Text Aligment</label>
                                        </div>

                                        <div class="col-6">
                                            <select v-model="currentElement.answer_design.text_align" style="width: 140px">
                                                <option v-for="option in quizEditorStore.fontAlignmentArray"
                                                    :value="option.align" :key="option.id">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col-6">
                                            <label style="font-size: 14px; float: left">Font Family</label>
                                        </div>

                                        <div class="col-6">
                                            <select v-model="currentElement.answer_design.font_family" style="width: 140px">
                                                <option v-for="option in quizEditorStore.fontStylesArray" :value="option"
                                                    :key="option">
                                                    {{ option }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col-6">
                                            <label style="font-size: 14px; float: left">Font Weight</label>
                                        </div>

                                        <div class="col-6">
                                            <select v-model="currentElement.answer_design.font_weight" style="width: 140px">
                                                <option v-for="option in quizEditorStore.fontWeightArray"
                                                    :value="option.weight" :key="option.id">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col">
                                            <label style="font-size: 14px; float: left">Background</label>
                                        </div>

                                        <div class="col">
                                            <div class="color-input-container" style="max-width: 50px; float: right">
                                                <input class="color-input" type="color"
                                                    v-model="currentElement.answer_design.background_color" />
                                            </div>
                                        </div>

                                        <div class="col">
                                            <input class="colorInputBox" type="text"
                                                v-model="currentElement.answer_design.background_color" />
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col">
                                            <label style="font-size: 14px; float: left">Hover</label>
                                        </div>

                                        <div class="col">
                                            <div class="color-input-container" style="max-width: 50px; float: right">
                                                <input class="color-input" type="color"
                                                    v-model="currentElement.answer_design.hover_bg_color" />
                                            </div>
                                        </div>

                                        <div class="col">
                                            <input class="colorInputBox" type="text"
                                                v-model="currentElement.answer_design.hover_bg_color" />
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col-5">
                                            <label style="font-size: 14px; float: left">Border Radius</label>
                                        </div>

                                        <div class="col">
                                            <input type="range" max="20"
                                                v-model="currentElement.answer_design.border_radius"
                                                style="margin-top: 6px; width: 80px; float: left" />

                                            <input type="number" style="width: 50px; float: right"
                                                v-model="currentElement.answer_design.border_radius" />
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col-5">
                                            <label style="font-size: 14px; float: left">Border Color</label>
                                        </div>

                                        <div class="col">
                                            <div class="color-input-container" style="max-width: 50px; float: right">
                                                <input class="color-input" type="color"
                                                    v-model="currentElement.answer_design.border_color" />
                                            </div>
                                        </div>

                                        <div class="col">
                                            <input class="colorInputBox" type="text"
                                                v-model="currentElement.answer_design.border_color" />
                                        </div>
                                    </div>

                                    <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                    <div class="row">
                                        <div class="col-5">
                                            <label style="font-size: 14px; float: left">Border Width</label>
                                        </div>

                                        <div class="col">
                                            <input type="range" v-model="currentElement.answer_design.border_width"
                                                style="margin-top: 6px; max-width: 50px" max="5" />
                                        </div>

                                        <div class="col">
                                            <input type="number" style="width: 50px; float: right"
                                                v-model="currentElement.answer_design.border_width" />
                                        </div>
                                    </div>

                                </div>


                                <div v-if="currentElement.answer_type === 'text_with_image'" class="imgUploadWrapper">

                                    <div class="imgNotes">Maximum file size is 1 MB</div>


                                    <div v-for="(answer, index) in currentElement.answers" :key=index>



                                        <div class="answerImagerWrapper">

                                            <div style="margin-bottom: 10px;">
                                                <input v-model="answer.answer_text"
                                                    style="background-color: #e0e4e8; height: 25px; border-radius: 3px;"
                                                    type="text" class="form-control" />
                                            </div>

                                            <div class="uploadImgBody" style="margin-bottom: 20px;"
                                                @click="triggerAnswerImageFileInput(answer, index)">

                                                <img v-if="answer.img_url === null" class="imgInsideUploadBody"
                                                    :src="require(`@/assets/toolbar/placeholder.png`)" />

                                                <img v-else class="imgInsideUploadBody" :src="answer.img_url" />

                                                <div class="uploadIBtn">


                                                    <div v-if="quizEditorStore.imageIsUploading &&
                                                        quizEditorStore.currentAnswer.id === answer.id
                                                        " style="font-weight: bold;">
                                                        UPLOADING....
                                                    </div>
                                                    <div v-else>
                                                        Click here to upload
                                                    </div>

                                                    <input style="display: none;" :id="`answerImageFileInput-${index}`"
                                                        type="file" accept="image/*"
                                                        @change="quizEditorStore.uploadAnswerImage($event)" />

                                                </div>



                                            </div>

                                            <hr style="color: rgb(189, 155, 18); margin-top: 10px; margin-bottom: 20px;">

                                        </div>




                                    </div>








                                </div>

                            </div>



                            <div v-if="quizEditorStore.currentQuestionTabId === 3">

                                <div class="optionWrapper row">

                                    <div class="row">

                                        <div class="col-3">
                                            <label style="font-size: 14px; float: left">Selection</label>
                                        </div>

                                        <div class="col-9">


                                            <select v-model="currentElement.click_action_type" style="width: 220px; "
                                                @change="quizEditorStore.clickActionTypeWarning(currentElement)">

                                                <option v-for="option in quizEditorStore.clickActionTypes"
                                                    :value="option.type" :key="option.id">
                                                    {{ option.name }} - {{ option.desc }}

                                                </option>

                                            </select>

                                        </div>



                                    </div>

                                </div>


                                <div class="optionWrapper row">



                                    <div v-if="currentElement.click_action_type === 'single_answer'">



                                        <div v-for="(answer, answerIndex) in currentElement.answers" :key="answerIndex">


                                            <div class="row" style="margin-bottom: 15px;">

                                                <div class="col">
                                                    <input class="form-control" v-model="answer.answer_text" type="text"
                                                        style="height: 30px; width: 100%;">
                                                </div>


                                            </div>

                                            <div v-if="quizEditorStore.currentQuiz.type === 'answer_based_outcomes'">



                                                <div v-if="answer.outcome_pages.length > 0">


                                                    <table class="table table-light">

                                                        <tbody>

                                                            <tr class="table-light"
                                                                v-for="(outcome, outcomeIndex) in answer.outcome_pages"
                                                                :key="outcomeIndex">

                                                                <td>
                                                                    <div v-if="outcome.page_details"
                                                                        style="font-size: 14px;">
                                                                        {{ outcome.page_details.page_title }}
                                                                    </div>

                                                                    <div v-else style="font-size: 14px;">
                                                                        Please select an outcome
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <div style="font-size: 14px;">
                                                                        Weight: {{ outcome.weight }}
                                                                    </div>
                                                                </td>

                                                            </tr>

                                                        </tbody>

                                                    </table>





                                                </div>


                                                <div class="row">

                                                    <div class="col" style="font-size:12px; float: left;"
                                                        v-if="answer.outcome_pages.length === 0">
                                                        No outcomes mapped
                                                    </div>

                                                    <div class="col">

                                                        <button v-if="answer.outcome_pages.length === 0"
                                                            class="btn btn-light btn-sm"
                                                            style="font-size:12px; float: right;"
                                                            @click="quizEditorStore.setThisAnswerAsCurrent(answer)">
                                                            Add Outcome +
                                                        </button>

                                                        <button v-else class="btn btn-light btn-sm"
                                                            style="font-size:12px; float: right;"
                                                            @click="quizEditorStore.setThisAnswerAsCurrent(answer)">
                                                            Edit
                                                        </button>



                                                    </div>


                                                </div>




                                            </div>

                                            <div v-if="quizEditorStore.currentQuiz.type === 'score_based_outcomes'"
                                                class="row">

                                                <div class="col-6">


                                                    <div v-if="answer.is_correct === 1" class="isCorrect"
                                                        @click="quizEditorStore.toggleIsCorrect(answer, currentElement)">

                                                        Correct

                                                    </div>

                                                    <div v-else class="notCorrect"
                                                        @click="quizEditorStore.toggleIsCorrect(answer, currentElement)">

                                                        Not correct

                                                    </div>


                                                </div>

                                                <div class="col-6">


                                                </div>


                                            </div>



                                            <div v-if="answer.answer_navigation_type === 'next_page'" class="row"
                                                style=" margin-top: 20px;">

                                                <div class="col text-start">

                                                    <div class="leftDiv" style="font-size:12px; margin-bottom: 7px;">
                                                        Navigates to:
                                                    </div>

                                                    <select class="form-select" style="height: 30px; font-size: 13px;"
                                                        v-model="answer.answer_navigation_type"
                                                        @change="quizEditorStore.updateAnswerNavigationOption(answer)">


                                                        <option
                                                            v-for="(answerNavigationOption, thisIndex) in quizEditorStore.answerNavigationOptions"
                                                            :key="thisIndex" :value="answerNavigationOption.type">

                                                            {{ answerNavigationOption.name }}

                                                        </option>


                                                    </select>

                                                </div>

                                                <div class="col text-end">
                                                    <div class="rightDiv">




                                                    </div>
                                                </div>

                                            </div>

                                            <div v-if="answer.answer_navigation_type === 'skip_logic'" class="row"
                                                style=" margin-top: 20px;">

                                                <div class="col text-start">

                                                    <div class="leftDiv" style="font-size:12px; margin-bottom: 7px;">
                                                        Skip to:
                                                    </div>

                                                    <select class="form-select" style="height: 30px; font-size: 13px;"
                                                        v-model="answer.answer_navigation_type"
                                                        @change="quizEditorStore.updateAnswerNavigationOption(answer)">


                                                        <option
                                                            v-for="(answerNavigationOption, thisIndex) in quizEditorStore.answerNavigationOptions"
                                                            :key="thisIndex" :value="answerNavigationOption.type">

                                                            {{ answerNavigationOption.name }}

                                                        </option>


                                                    </select>

                                                </div>

                                                <div class="col text-end">


                                                    <div style="font-size:12px; margin-bottom: 7px; float: left;">
                                                        This page:
                                                    </div>

                                                    <div class="rightDiv">

                                                        <select class="form-select" style="height: 30px; font-size: 13px;"
                                                            v-model="answer.next_page_id"
                                                            @change="quizEditorStore.updateThePageSelectedForSkipLogic(answer)">



                                                            <option
                                                                v-for="(page, pageIndex) in quizEditorStore.defaultPagesArray"
                                                                :key="pageIndex" :value="page.id"
                                                                :disabled="page.id === quizEditorStore.currentPageInView.id">

                                                                {{ page.page_title }}

                                                            </option>


                                                        </select>



                                                    </div>
                                                </div>

                                            </div>

                                            <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />




                                        </div>






                                    </div>

                                    <div v-if="currentElement.click_action_type === 'multiple_answers'">



                                        <div class="row">

                                            <div class="col">
                                                <label style="font-size: 14px; float: left; margin-bottom: 10px;"><b>Amount
                                                        of
                                                        Selectable Answers</b></label>
                                            </div>

                                            <div class="row">

                                                <div class="col">

                                                    <div class="row align-items-center">

                                                        <div class="col-auto">
                                                            <label class="col-form-label"
                                                                style="font-size: 12px;">MIN</label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <input v-model="currentElement.min_answers_amt" type="number"
                                                                min="1" :max="currentElement.answers.length"
                                                                class="form-control">
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="col">


                                                    <div class="row align-items-center">

                                                        <div class="col-auto">
                                                            <label class="col-form-label"
                                                                style="font-size: 12px;">MAX</label>
                                                        </div>
                                                        <div class="col-auto">
                                                            <input type="number" v-model="currentElement.max_answers_amt"
                                                                :max="currentElement.answers.length" :min="1"
                                                                class="form-control">
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>

                                            <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />



                                            <div v-for="(answer, answerIndex) in currentElement.answers" :key="answerIndex">

                                                <div class="row" style="margin-bottom: 15px;">

                                                    <div class="col">
                                                        <input class="form-control" v-model="answer.answer_text" type="text"
                                                            style="height: 30px; width: 100%;">
                                                    </div>


                                                </div>



                                                <div v-if="quizEditorStore.currentQuiz.type === 'answer_based_outcomes'">




                                                    <div v-if="answer.outcome_pages.length > 0">


                                                        <table class="table table-light">

                                                            <tbody>

                                                                <tr class="table-light"
                                                                    v-for="(outcome, outcomeIndex) in answer.outcome_pages"
                                                                    :key="outcomeIndex">

                                                                    <td>
                                                                        <div v-if="outcome.page_details"
                                                                            style="font-size: 14px;">
                                                                            {{ outcome.page_details.page_title }}
                                                                        </div>

                                                                        <div v-else style="font-size: 14px;">
                                                                            Please select an outcome
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div style="font-size: 14px;">
                                                                            Weight: {{ outcome.weight }}
                                                                        </div>
                                                                    </td>

                                                                </tr>

                                                            </tbody>

                                                        </table>





                                                    </div>


                                                    <div class="row">

                                                        <div class="col" style="font-size:12px; float: left;"
                                                            v-if="answer.outcome_pages.length === 0">
                                                            No outcomes mapped
                                                        </div>

                                                        <div class="col">

                                                            <button v-if="answer.outcome_pages.length === 0"
                                                                class="btn btn-light btn-sm"
                                                                style="font-size:12px; float: right;"
                                                                @click="quizEditorStore.setThisAnswerAsCurrent(answer)">
                                                                Add Outcome +
                                                            </button>

                                                            <button v-else class="btn btn-light btn-sm"
                                                                style="font-size:12px; float: right;"
                                                                @click="quizEditorStore.setThisAnswerAsCurrent(answer)">
                                                                Edit
                                                            </button>



                                                        </div>


                                                    </div>







                                                </div>

                                                <div v-if="quizEditorStore.currentQuiz.type === 'score_based_outcomes'"
                                                    class="row">

                                                    <div class="col-6">


                                                        <div class="isCorrect" v-if="answer.is_correct === 1"
                                                            @click="answer.is_correct = 0">

                                                            Correct

                                                        </div>

                                                        <div class="notCorrect" @click="answer.is_correct = 1" v-else>

                                                            Not correct

                                                        </div>


                                                    </div>

                                                    <div class="col-6">




                                                    </div>




                                                </div>

                                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                            </div>






                                        </div>

                                    </div>



                                </div>







                            </div>

                        </div>

                    </div>

                    <div v-if="currentlySelected == 'textTab'">

                        <div class="sidebarBody" style="padding: 20px"> 


                            <button class="button-11" @click="quizEditorStore.openAnswerPipingModal">Pipe Answer
                                +</button>

                            <keep-alive>

                                <editor :key="editorKey" ref="textTabEditor" id="textTabEditor"
                                    api-key="bvj08wudza185lm5frysfdtyh7px2unli6gwux420yeebie4"
                                    v-model="currentElement.code_block" :init="editorConfig" 
                                    @change="handleEditorChange()"
                                    @onInit="handleEditorInit" />




                            </keep-alive>




                            <div class="optionWrapper row">
                                <div class="col">
                                    <label class="labelText">Color</label>
                                </div>

                                <div class="col">
                                    <div class="color-input-container" style="max-width: 50px; float: right">
                                        <input class="color-input" type="color" v-model="currentElement.font_color" />
                                    </div>
                                </div>

                                <div class="col">
                                    <input class="colorInputBox" type="text" v-model="currentElement.font_color" />
                                </div>
                            </div>



                            <div class="optionWrapper row">
                                <div class="col-5">
                                    <label style="float: left">Font Family</label>
                                </div>

                                <div class="col-7">
                                    <select v-model="currentElement.font_family" style="width: 140px; float: right">
                                        <option v-for="option in quizEditorStore.fontStylesArray" :value="option"
                                            :key="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="optionWrapper row">
                                <div class="col-1">
                                    <label style="float: left">Margin</label>
                                </div>

                                <div class="col">
                                    <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />
                                    <input type="number" style="width: 50px; float: right"
                                        v-model="currentElement.margin" />
                                </div>
                            </div>


                        </div>
                    </div>

                    <div v-if="currentlySelected == 'inputTab'">
                        <div class="sidebarBody" style="padding: 20px">


                            <div class="textBoxWrapper">


                                <input v-model="currentElement.label" style="width: 100%;  border-radius: 5px;" />


                            </div>


                            <div class="optionWrapper row">
                                <div class="col">
                                    <label style="font-size: 14px">Label Color</label>
                                </div>

                                <div class="col">
                                    <div class="color-input-container" style="max-width: 50px; float: right">
                                        <input class="color-input" type="color" v-model="currentElement.label_font_color" />
                                    </div>
                                </div>

                                <div class="col">
                                    <input class="colorInputBox" type="text" v-model="currentElement.label_font_color" />
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 14px; float: left">Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="12" v-model="currentElement.label_font_size"
                                            style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: left"
                                            v-model="currentElement.label_font_size" />
                                    </div>
                                </div>
                            </div>

                            <div class="optionWrapper row">

                                <div class="col-1">
                                    <label style="font-size: 14px; float: left">Width</label>
                                </div>

                                <div class="col">
                                    <input type="range" v-model="currentElement.width" style="margin-top: 6px;" min="200"
                                        max="500" />

                                    <input type="number" style="width: 50px; float: right" v-model="currentElement.width" />
                                </div>



                            </div>

                            <div class="optionWrapper row">
                                <div class="col-5">
                                    <label style="float: left">Aligment</label>
                                </div>

                                <div class="col-7">
                                    <select v-model="currentElement.justify_content" style="width: 140px; float: right">
                                        <option v-for="option in quizEditorStore.fontAlignmentArray" :value="option.align"
                                            :key="option.id">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="optionWrapper row">
                                <div class="col-1">
                                    <label style="float: left">Margin</label>
                                </div>

                                <div class="col">
                                    <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />
                                    <input type="number" style="width: 50px; float: right"
                                        v-model="currentElement.margin" />
                                </div>
                            </div>

                            <div class="optionWrapper row">

                                <div class="col-6">
                                    <label style="float: left">Show Label</label>
                                </div>

                                <div class="col-6">


                                    <div class="switchOn" v-if="currentElement.show_label === 1"
                                        @click="currentElement.show_label = 0" style="float: right">

                                        YES

                                    </div>


                                    <div class="switchOff" v-else @click="currentElement.show_label = 1"
                                        style="float: right">
                                        NO
                                    </div>


                                </div>
                            </div>

                            <div class="optionWrapper row">

                                <div class="col-6">
                                    <label style="float: left">Required Field</label>
                                </div>

                                <div class="col-6">


                                    <div class="switchOn" v-if="currentElement.is_required === 1"
                                        @click="currentElement.is_required = 0" style="float: right">

                                        YES

                                    </div>


                                    <div class="switchOff" v-else @click="currentElement.is_required = 1"
                                        style="float: right">
                                        NO
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>

                    <div v-if="currentlySelected == 'buttonTab'">

                        <div class="sidebarBody">


                            <div class="optionWrapper">

                                <div class="row" style="padding-left: 15px; padding-right: 15px;">

                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Action</label>
                                    </div>

                                    <div class="col-7">


                                        <div v-if="quizEditorStore.currentPageInView.sub_type === 'default'">

                                            <select v-model="currentElement.action_type" style="width: 140px; float: right">

                                                <option v-for="option in quizEditorStore.buttonActionTypesForDefaultPages"
                                                    :value="option.type" :key="option.id"
                                                    :disabled="option.type === 'branch_logic'">

                                                    {{ option.name }}

                                                </option>

                                            </select>

                                        </div>

                                        <div v-if="quizEditorStore.currentPageInView.sub_type === 'outcome'">

                                            <select v-model="currentElement.action_type" style="width: 190px; float: right">

                                                <option v-for="option in quizEditorStore.buttonActionTypesForOutcomePages"
                                                    :value="option.type" :key="option.id"
                                                    :disabled="option.type === 'branch_logic'">

                                                    {{ option.name }}

                                                </option>

                                            </select>


                                        </div>



                                    </div>

                                </div>

                                <div
                                    v-if="currentElement.action_type === 'open_url_in_new_tab' || currentElement.action_type === 'open_url_in_same_tab'">


                                    <div class="urlContainer">

                                        <div class="urlHeader">

                                            URL

                                        </div>

                                        <div class="urlInputWrapper">

                                            <input v-model="currentElement.url" type="text"
                                                class="form-control urlInuputbox" placeholder="Paste url here..." />

                                        </div>



                                    </div>


                                </div>


                            </div>

                            <div class="optionWrapper row">



                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Font Color</label>
                                    </div>

                                    <div class="col">
                                        <div class="color-input-container" style="max-width: 50px; float: right">
                                            <input class="color-input" type="color" v-model="currentElement.font_color" />
                                        </div>
                                    </div>

                                    <div class="col">
                                        <input class="colorInputBox" type="text" v-model="currentElement.font_color" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Background</label>
                                    </div>

                                    <div class="col">
                                        <div class="color-input-container" style="max-width: 50px; float: right">
                                            <input class="color-input" type="color"
                                                v-model="currentElement.background_color" />
                                        </div>
                                    </div>

                                    <div class="col">
                                        <input class="colorInputBox" type="text"
                                            v-model="currentElement.background_color" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 14px; float: left">Font Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" v-model="currentElement.font_size" style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: left"
                                            v-model="currentElement.font_size" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-1">
                                        <label style="font-size: 14px; float: left">Margin</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.margin" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Aligment</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.alignment" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontAlignmentArray"
                                                :value="option.align" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Font Family</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.font_family" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontStylesArray" :value="option"
                                                :key="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Font Weight</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.font_weight" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontWeightArray" :value="option.weight"
                                                :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Border Radius</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" max="20" v-model="currentElement.border_radius"
                                            style="margin-top: 6px; width: 80px; float: left" />

                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.border_radius" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Border Width</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" v-model="currentElement.border_width"
                                            style="margin-top: 6px; max-width: 50px" max="5" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.border_width" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Border Color</label>
                                    </div>

                                    <div class="col">
                                        <div class="color-input-container" style="max-width: 50px; float: right">
                                            <input class="color-input" type="color" v-model="currentElement.border_color" />
                                        </div>
                                    </div>

                                    <div class="col">
                                        <input class="colorInputBox" type="text" v-model="currentElement.border_color" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-3">
                                        <label style="font-size: 14px; float: left">Width</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" v-model="currentElement.width"
                                            style="margin-top: 6px; max-width: 80px" min="180" max="500" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.width" />
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>

                    <div v-if="currentlySelected == 'imageTab'">

                        <div class="sidebarBody">

                            <div class="optionWrapper row">
                                <h6 style="color: white; margin-bottom: 20px; margin-top: 10px">
                                    Image
                                </h6>

                                <hr style="margin-top: 0px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 14px; float: left">Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="20" max="100" v-model="currentElement.width"
                                            style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: left"
                                            v-model="currentElement.width" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-1">
                                        <label style="font-size: 14px; float: left">Margin</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.margin" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Aligment</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.alignment" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontAlignmentArray"
                                                :value="option.align" :key="option.id">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Border Radius</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" max="100" v-model="currentElement.border_radius"
                                            style="margin-top: 6px; width: 80px; float: left" />

                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.border_radius" />
                                    </div>
                                </div>
                            </div>

                            <div class="imgUploadWrapper">

                                <div class="uploadImgheader">
                                    Upload
                                </div>


                                <div class="uploadImgBody" @click="triggerFileInput">




                                    <img v-if="currentElement.uploaded_img_url === null" class="imgInsideUploadBody"
                                        :src="require(`@/assets/toolbar/placeholder.png`)" />

                                    <img v-else class="imgInsideUploadBody" :src="currentElement.uploaded_img_url" />

                                    <div class="uploadIBtn">

                                        <div v-if="quizEditorStore.imageIsUploading" style="font-weight: bold;">
                                            UPLOADING....
                                        </div>
                                        <div v-else>
                                            Click here to upload
                                        </div>

                                        <input style="display: none;" ref="fileInput" type="file" accept="image/*"
                                            @change="quizEditorStore.uploadThisImageForImageElement($event, currentElement)" />
                                    </div>

                                </div>



                                <div class="imgNotes">Maximum file size is 1 MB</div>


                            </div>

                        </div>
                    </div>

                    <div v-if="currentlySelected == 'embedTab'">

                        <div class="sidebarBody">


                            <div class="columnWrapper">

                                <h6 style="color: white; margin-top: 10px; text-align: left; font-size: 14px;">
                                    Embed Iframe
                                </h6>

                                <div class="codeWrapper">

                                    <div class="codeTextboxContainer">

                                        <textarea style="width: 100%; min-height: 200px; border-radius: 5px;"
                                            v-model="currentElement.code_block" placeholder="Paste code here"
                                            @input="resetIframeEmbedWidthAndHeight(currentElement)">
                            </textarea>

                                    </div>



                                </div>


                            </div>

                            <div class="optionWrapper row">


                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 12px; float: left">Width (%)</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="20" max="100" v-model="currentElement.width"
                                            style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: right;"
                                            v-model="currentElement.width" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-4">
                                        <label style="font-size: 12px; float: left">Height (px)</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="20" max="900" v-model="currentElement.height"
                                            style="margin-top: 6px; width: 110px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.height" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-1">
                                        <label style="font-size: 12px; float: left">Margin</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="5" v-model="currentElement.margin"
                                            style="margin-top: 6px" />
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.margin" />
                                    </div>
                                </div>


                            </div>







                        </div>

                    </div>

                    <div v-if="currentlySelected == 'videoTab'">
                        <div class="sidebarBody">
                            <div class="optionWrapper row">
                                <h6 style="color: white; margin-bottom: 20px; margin-top: 10px">
                                    Video
                                </h6>

                                <hr style="margin-top: 0px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Platform</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.video_type" style="width: 140px"
                                            @change="resetVideoInput">
                                            <option v-for="option in quizEditorStore.videoPlatforms"
                                                :value="option.video_type" :key="option.id">
                                                {{ option.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div v-if="currentElement.video_type == 'wistia'" class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Embed Code</label>
                                    </div>

                                    <div class="col-6">
                                        <input type="text" @keyup="pasteVideoURL" v-model="currentElement.video_embed_code"
                                            style="width: 140px" />
                                    </div>
                                </div>

                                <div v-else class="row">




                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Video URL</label>
                                    </div>

                                    <div class="col-6">
                                        <input type="text" @input="pasteVideoURL" v-model="currentElement.video_url"
                                            style="width: 140px" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Border Radius</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" max="30" v-model="currentElement.border_radius"
                                            style="margin-top: 6px; width: 80px; float: left" />

                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.border_radius" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Width</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="300" max="700" v-model="currentElement.width"
                                            style="margin-top: 6px; width: 80px; float: left" />

                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.width" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-1">
                                        <label style="font-size: 14px; float: left">Margin</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.margin" />
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                    <div v-if="currentlySelected == 'sharingTab'">

                        <div class="sidebarBody">


                            <div class="textBoxWrapper">


                                <input v-model="currentElement.main_share_text" style="width: 100%;  border-radius: 5px;" />


                            </div>

                            <div class="optionWrapper row">
                                <h6 style="color: white; margin-bottom: 20px; margin-top: 10px">
                                    Sharing
                                </h6>

                                <hr style="margin-top: 0px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Icon Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="20" max="50" v-model="currentElement.img_width"
                                            style="margin-top: 6px; width: 80px; float: left" />

                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.img_width" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Font Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="16" max="50" v-model="currentElement.font_size"
                                            style="margin-top: 6px; width: 80px; float: left" />

                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.font_size" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Font Color</label>
                                    </div>

                                    <div class="col">
                                        <div class="color-input-container" style="max-width: 50px; float: right">
                                            <input class="color-input" type="color" v-model="currentElement.font_color" />
                                        </div>
                                    </div>

                                    <div class="col">
                                        <input class="colorInputBox" type="text" v-model="currentElement.font_color" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-6">
                                        <label style="font-size: 14px; float: left">Font Family</label>
                                    </div>

                                    <div class="col-6">
                                        <select v-model="currentElement.font_family" style="width: 140px">
                                            <option v-for="option in quizEditorStore.fontStylesArray" :value="option"
                                                :key="option">
                                                {{ option }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-1">
                                        <label style="float: left">Margin</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.margin" />
                                    </div>
                                </div>
                            </div>



                            <div class="sharingList">

                                <div class="sharingItem" v-for="(platformFromDB, index) in currentElement.platforms"
                                    :key=index>

                                    <div class="sharingItemTop">

                                        <div class="plat_icon_wrapper">

                                            <img width="15" :src="require(`@/assets/socials/${platformFromDB.type}.png`)" />

                                        </div>

                                        <div class="input_field_wrapper">


                                            <select class="socialLinkInput" v-model="platformFromDB.type"
                                                @change="quizEditorStore.setSelectedPlaftorm(platformFromDB, currentElement.platforms)">

                                                <option v-for="option in quizEditorStore.socialPlatforms"
                                                    :value="option.type" :key="option.id">

                                                    {{ option.platform_name }}

                                                </option>

                                            </select>

                                        </div>

                                        <div class="trash_icon_wrapper">
                                            <img width="13" :src="require(`@/assets/images/t_trash.png`)"
                                                @click="quizEditorStore.deleteASocialPlatform(platformFromDB, currentElement.platforms)" />
                                        </div>

                                    </div>

                                    <div class="sharingItemBottom">

                                        <div class="urlLabel">
                                            URL
                                        </div>

                                        <div class="urlWrapper">
                                            <input v-model="platformFromDB.url" type="text" class="linkInput"
                                                placeholder="Paste link">
                                        </div>



                                    </div>




                                </div>

                                <button class="button-2"
                                    @click="quizEditorStore.addSocialPlaform(currentElement)">Add</button>

                            </div>




                        </div>
                    </div>

                    <div v-if="currentlySelected == 'textboxTab'">



                        <div class="sidebarBody">


                            <div class="textBoxWrapper">



                                <input v-model="currentElement.label" style="width: 100%;  border-radius: 5px;" />


                            </div>

                            <div class="optionWrapper row">
                                <div class="col">
                                    <label style="font-size: 14px">Label Color</label>
                                </div>

                                <div class="col">
                                    <div class="color-input-container" style="max-width: 50px; float: right">
                                        <input class="color-input" type="color" v-model="currentElement.label_font_color" />
                                    </div>
                                </div>

                                <div class="col">
                                    <input class="colorInputBox" type="text" v-model="currentElement.label_font_color" />
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 14px; float: left">Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="12" v-model="currentElement.label_font_size"
                                            style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: left"
                                            v-model="currentElement.label_font_size" />
                                    </div>
                                </div>
                            </div>

                            <div class="optionWrapper row">
                                <div class="col-5">
                                    <label style="float: left">Aligment</label>
                                </div>

                                <div class="col-7">
                                    <select v-model="currentElement.justify_content" style="width: 140px; float: right">
                                        <option v-for="option in quizEditorStore.fontAlignmentArray" :value="option.align"
                                            :key="option.id">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="optionWrapper row">

                                <div class="col-1">
                                    <label style="font-size: 14px; float: left">Width</label>
                                </div>

                                <div class="col">
                                    <input type="range" v-model="currentElement.width" style="margin-top: 6px;" min="200"
                                        max="600" />

                                    <input type="number" style="width: 50px; float: right" v-model="currentElement.width" />
                                </div>



                            </div>

                            <div class="optionWrapper row">

                                <div class="col-1">
                                    <label style="float: left">Margin</label>
                                </div>

                                <div class="col">
                                    <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />

                                    <input type="number" style="width: 50px; float: right"
                                        v-model="currentElement.margin" />
                                </div>


                            </div>

                            <div class="optionWrapper row">
                                <div class="col-6">
                                    <label style="float: left">Show Label</label>
                                </div>

                                <div class="col-6">



                                    <div class="switchOn" v-if="currentElement.show_label === 1"
                                        @click="currentElement.show_label = 0" style="float: right">

                                        YES

                                    </div>


                                    <div class="switchOff" v-else @click="currentElement.show_label = 1"
                                        style="float: right">
                                        NO
                                    </div>

                                </div>
                            </div>

                            <div class="optionWrapper row">

                                <div class="col-6">
                                    <label style="float: left">Required Field</label>
                                </div>

                                <div class="col-6">




                                    <div class="switchOn" v-if="currentElement.is_required === 1"
                                        @click="currentElement.is_required = 0" style="float: right">

                                        YES

                                    </div>


                                    <div class="switchOff" v-else @click="currentElement.is_required = 1"
                                        style="float: right">
                                        NO
                                    </div>


                                </div>
                            </div>


                        </div>

                    </div>

                    <div v-if="currentlySelected == 'checkboxTab'">


                        <div class="sidebarBody">


                            <div class="optionWrapper row">

                                <div class="col-6">
                                    <label style="float: left">Checkbox name</label>
                                </div>

                                <div class="col-6">

                                    <input type="text" style="width: 150px; float: right"
                                        v-model="currentElement.name_in_report" />
                                </div>



                            </div>

                            <div class="optionWrapper row">

                                <div class="col">

                                    <label style="float: left; margin-bottom: 15px; ">Checkbox content</label>

                                    <textarea style="width: 100%;" v-model="currentElement.text_body"></textarea>

                                </div>

                            </div>

                            <div class="optionWrapper">

                                <div class="row" style="padding-left: 15px; padding-right: 15px;">

                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">Action</label>
                                    </div>

                                    <div class="col-7">


                                        <select v-model="currentElement.link_to_type" style="width: 140px; float: right">

                                            <option v-for="option in quizEditorStore.linkToTypes" :value="option.type"
                                                :key="option.id">
                                                {{ option.name }}

                                            </option>

                                        </select>

                                    </div>

                                </div>

                                <div v-if="currentElement.link_to_type === 'link_to'" class="row"
                                    style="padding-left: 15px; padding-right: 15px; margin-top: 10px;">

                                    <div class="col-5">
                                        <label style="font-size: 14px; float: left">URL</label>
                                    </div>

                                    <div class="col-7">

                                        <input type="text" placeholder="Paste URL here" style="width: 140px; float: right"
                                            v-model="currentElement.url">

                                    </div>

                                </div>

                                <div v-if="currentElement.link_to_type === 'popup'" class="row"
                                    style="padding-left: 15px; padding-right: 15px; margin-top: 20px;">


                                    <div class="col">


                                        <div class="popupContentWrapper">

                                            <div style="align-self: flex-start; margin-bottom: 15px; font-size: 14px; ">
                                                Popup Content</div>



                                            <editor api-key="no-api-key" v-model="currentElement.pop_modal_content" :init="{
                                                menubar: false,
                                                plugins: 'lists link',
                                                toolbar: 'styleselect | bold italic alignleft aligncenter alignright bullist removeformat | fontsizeselect',
                                                fontsize_formats: '12px 18px 24px'
                                            }"></editor>

                                        </div>





                                    </div>

                                </div>


                                <!-- 


                    <div class="row" style="margin-top: 15px;"
                    v-if="currentElement.link_to_type === 'link_to'">
                         
                       <div class="col">
                            <input type="text" style="width: 100%; float: right"/>
                       </div>
                        

                    </div>

                    <div v-if="currentElement.link_to_type === 'popup'">
                        POPUP 
                    </div>
-->




                            </div>


                            <div class="optionWrapper row">

                                <div class="col">
                                    <label style="font-size: 14px">Label Color</label>
                                </div>

                                <div class="col">
                                    <div class="color-input-container" style="max-width: 50px; float: right">
                                        <input class="color-input" type="color" v-model="currentElement.font_color" />
                                    </div>
                                </div>

                                <div class="col">
                                    <input class="colorInputBox" type="text" v-model="currentElement.font_color" />
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 14px; float: left">Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="12" v-model="currentElement.font_size"
                                            style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: left"
                                            v-model="currentElement.font_size" />
                                    </div>
                                </div>
                            </div>

                            <div class="optionWrapper row">
                                <div class="col-5">
                                    <label style="float: left">Aligment</label>
                                </div>

                                <div class="col-7">
                                    <select v-model="currentElement.justify_content" style="width: 140px; float: right">
                                        <option v-for="option in quizEditorStore.fontAlignmentArray" :value="option.align"
                                            :key="option.id">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>



                            <div class="optionWrapper row">

                                <div class="col-1">
                                    <label style="float: left">Margin</label>
                                </div>

                                <div class="col">
                                    <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />

                                    <input type="number" style="width: 50px; float: right"
                                        v-model="currentElement.margin" />
                                </div>


                            </div>

                            <div class="optionWrapper row">

                                <div class="col-6">
                                    <label style="float: left">Required Field</label>
                                </div>

                                <div class="col-6">

                                    <!-- {{ currentElement.is_required }} -->

                                    <div class="switchOn" v-if="currentElement.is_required === 1"
                                        @click="currentElement.is_required = 0" style="float: right">

                                        YES

                                    </div>


                                    <div class="switchOff" v-else @click="currentElement.is_required = 1"
                                        style="float: right">
                                        NO
                                    </div>


                                </div>
                            </div>


                        </div>

                    </div>

                    <div v-if="currentlySelected == 'scoreTab'">


                        <div class="sidebarBody">


                            <div class="optionWrapper row">
                                <div class="col-5">
                                    <label style="float: left">Score Label</label>
                                </div>

                                <div class="col-7">

                                    <input class="form-control" type="text" v-model="currentElement.text_label"
                                        style="width: 140px; float: right" />

                                </div>
                            </div>

                            <div class="optionWrapper row">

                                <div class="col">
                                    <label style="font-size: 14px; float: left;">Text Color</label>
                                </div>

                                <div class="col">
                                    <div class="color-input-container" style="max-width: 50px; float: right">
                                        <input class="color-input" type="color" v-model="currentElement.font_color" />
                                    </div>
                                </div>

                                <div class="col">
                                    <input class="colorInputBox" type="text" v-model="currentElement.font_color" />
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 14px; float: left">Size</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="12" v-model="currentElement.font_size"
                                            style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: left"
                                            v-model="currentElement.font_size" />
                                    </div>
                                </div>

                            </div>

                            <div class="optionWrapper row">
                                <div class="col-5">
                                    <label style="float: left">Font Family</label>
                                </div>

                                <div class="col-7">
                                    <select v-model="currentElement.font_family" style="width: 140px; float: right">
                                        <option v-for="option in quizEditorStore.fontStylesArray" :value="option"
                                            :key="option">
                                            {{ option }}
                                        </option>
                                    </select>
                                </div>
                            </div>


                            <div class="optionWrapper row">
                                <div class="col-5">
                                    <label style="float: left">Font Weight</label>
                                </div>

                                <div class="col-7">
                                    <select v-model="currentElement.font_weight" style="width: 140px; float: right">
                                        <option v-for="option in quizEditorStore.fontWeightArray" :value="option.weight"
                                            :key="option.id">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>





                            <div class="optionWrapper row">
                                <div class="col-1">
                                    <label style="float: left">Margin</label>
                                </div>

                                <div class="col">
                                    <input type="range" v-model="currentElement.margin" style="margin-top: 6px" />
                                    <input type="number" style="width: 50px; float: right"
                                        v-model="currentElement.margin" />
                                </div>
                            </div>

                            <div class="optionWrapper row">
                                <div class="col-5">
                                    <label style="float: left">Aligment</label>
                                </div>

                                <div class="col-7">
                                    <select v-model="currentElement.alignment" style="width: 140px; float: right">
                                        <option v-for="option in quizEditorStore.fontAlignmentArray" :value="option.align"
                                            :key="option.id">
                                            {{ option.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>


                        </div>

                    </div>

                    <div v-if="currentlySelected == 'calendarEmbedTab'">

                        <div class="sidebarBody">


                            <div class="columnWrapper">

                                <h6 style="color: white; margin-top: 10px; text-align: left; font-size: 14px;">
                                    Schedule Appointment
                                </h6>

                                <div class="calendarWrapper">


                                    <div class="calendarOptions">



                                        <select class="form-control" style="width: 100%"
                                            v-model="currentElement.calender_app_name"
                                            @change="resetCalendarSelect(currentElement)">

                                            <option v-for="option in quizEditorStore.calendarApps" :value="option.type"
                                                :key="option">
                                                {{ option.calender_app_name }}
                                            </option>
                                        </select>



                                    </div>

                                    <div class="calendarUrlWrapper">

                                        <input v-model="currentElement.calender_url"
                                            @input="buildIframeCode(currentElement)" type="text" class="form-control"
                                            placeholder="Paste URL here" />

                                    </div>




                                </div>


                            </div>

                            <div class="optionWrapper row">


                                <div class="row">
                                    <div class="col">
                                        <label style="font-size: 12px; float: left">Width (%)</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="20" max="100" v-model="currentElement.width"
                                            style="margin-top: 6px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: right;"
                                            v-model="currentElement.width" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-4">
                                        <label style="font-size: 12px; float: left">Height (px)</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="20" max="900" v-model="currentElement.height"
                                            style="margin-top: 6px; width: 110px" />
                                    </div>

                                    <div class="col">
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.height" />
                                    </div>
                                </div>

                                <hr style="margin-top: 15px; margin-bottom: 15px; color: #e5e5ea" />

                                <div class="row">
                                    <div class="col-1">
                                        <label style="font-size: 12px; float: left">Margin</label>
                                    </div>

                                    <div class="col">
                                        <input type="range" min="10" v-model="currentElement.margin"
                                            style="margin-top: 6px" />
                                        <input type="number" style="width: 50px; float: right"
                                            v-model="currentElement.margin" />
                                    </div>
                                </div>


                            </div>







                        </div>

                    </div>



                </div>

            </div>

            <div class="outerBGOnRight">


                <div class="bigWrapper" :style="{

                    'background': quizEditorStore.selectedOuterBGtype === 'gradient_bg'
                        ? quizEditorStore.selectedOuterBGgradient
                        : quizEditorStore.selectedOuterBGtype === 'normal_bg'
                            ? quizEditorStore.selectedNormalBGcolor
                            : quizEditorStore.selectedOuterBGtype === 'img_bg'
                                ? `url(${quizEditorStore.selectedBGimageUrl}) no-repeat center/cover`
                                : 'none',

                }" @click="showQuizOuterBGOptions">

                    <!-- THE QUESTION EDITOR -->
                    <!-- <editor id="questionEditor" api-key="bvj08wudza185lm5frysfdtyh7px2unli6gwux420yeebie4" 
                v-model="currentElement.text_code_block"
                :init="{
                    branding: false,
                    height: 250,
                    toolbar: false,
                    menubar: false, 
                    statusbar: false
                }"
                initial-value="Once upon a time..."
                @change="handleEditorChange()"
                /> -->

                    <!-- <div v-for="(answer, index) in currentElement.answers" :key=index>
                    <editor
                    v-model="currentElement.answers[index].text_code_block"
                    :id="'answer-editor-' + index"
                    api-key="bvj08wudza185lm5frysfdtyh7px2unli6gwux420yeebie4" 
                    :init="{height: 200}" 
                    @change="handleAnswerEditorChange(index)" />


                </div> -->









                    <!-- currentPageInView: {{ quizEditorStore.currentPageInView }}

                     ____________________________________________________

                     currentPageInViewIndex: {{quizEditorStore.currentPageInViewIndex}} -->

                    <!-- currentPageInView: {{ quizEditorStore.currentPageInView }}

                    <br> -->


                    <!-- 
                    currentDefaultPage: {{ quizEditorStore.currentDefaultPage }} | currentDefaultPageIndex: {{
                        quizEditorStore.currentDefaultPageIndex }} -->

                    <!-- ____________________________________________________ -->

                    <!-- currentOutcomePage: {{ quizEditorStore.currentOutcomePage }} | currentOutcomePageIndex: {{
                        quizEditorStore.currentOutcomePageIndex }} -->



                    <!-- QUESTION text_code_block: {{currentElement.text_code_block}} | ANSWERS: {{currentElement.answers}} -->


                    <div v-if="quizEditorStore.currentPageInView.sub_type === 'default'">

                        <div v-for="(page, index) in quizEditorStore.defaultPagesArray" :key="index">

                            <div v-if="index === quizEditorStore.currentDefaultPageIndex">

                                <div class="quizCard" @click.stop="showQuizBGOptions" :style="{
                                    'background': quizEditorStore.selectedCardBGtype === 'gradient_bg'
                                        ? quizEditorStore.selectedCardBGgradient
                                        : quizEditorStore.selectedCardBGtype === 'normal_bg'
                                            ? quizEditorStore.selectedCardNormalBGcolor
                                            : quizEditorStore.selectedCardBGtype === 'img_bg'
                                                ? `url(${quizEditorStore.selectedCardBGimageUrl}) no-repeat center/cover`
                                                : 'none',

                                }">


                                    <div v-if="page.design_layout.length == 0">

                                        Add a widget this page

                                    </div>

                                    <div v-else>

                                        <draggable class="innerQuizContainer" v-model="page.design_layout" item-key="id"
                                            :animation="600" handle=".myhandle" @end="quizEditorStore.saveDesign">



                                            <template #item="{ element: element, index }">



                                                <div class="elementBox" :class="{ highlighted: highlightedIndex === index }"
                                                    @click.stop="toggleHighlight(element, index)"
                                                    :id="'elementBox' + element.page_element_id">





                                                    <div v-if="highlightedIndex === index" class="deleteIcon">

                                                        <div class="row">

                                                            <div class="col" v-if="currentElement.type === 'question'"
                                                                @click="
                                                                    quizEditorStore.addAnswerToQuestion(currentElement)
                                                                    ">
                                                                <img :src="require(`@/assets/toolbar/a-plus.png`)"
                                                                    width="14" />
                                                            </div>

                                                            <div class="col">
                                                                <img @click="showDeleteElementModal"
                                                                    :src="require(`@/assets/toolbar/delete.png`)"
                                                                    width="14" />
                                                            </div>

                                                            <div class="col">
                                                                <img class="myhandle"
                                                                    :src="require(`@/assets/images/m_move.png`)"
                                                                    width="14" />
                                                            </div>

                                                        </div>

                                                    </div>



                                                    <div v-if="element.type == 'header_text'">


                                                        <div :style="{
                                                            'word-wrap': 'break-word',
                                                            'width': '100%',
                                                            'color': element.font_color,
                                                            'font-family': element.font_family,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }" v-html="element.code_block">
                                                        </div>



                                                    </div>


                                                    <div v-if="element.type == 'text'">



                                                        <div :style="{
                                                            'word-wrap': 'break-word',
                                                            'width': '100%',
                                                            'color': element.font_color,
                                                            'font-family': element.font_family,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }" v-html="element.code_block">
                                                        </div>



                                                    </div>



                                                    <div class="inputFieldWrapper" v-if="element.type == 'text_input'">



                                                        <div class="outerWrapper" :style="{
                                                            'justify-content': element.justify_content,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">


                                                            <div class="" style="display:flex; flex-direction: column;">


                                                                <label v-if="element.show_label === 1" class="form-label"
                                                                    :style="{
                                                                        'color': element.label_font_color,
                                                                        'font-size': element.label_font_size + 'px',
                                                                        'float': element.label_float,
                                                                        'font-weight': 'bold',
                                                                        'padding-bottom': '2px',
                                                                        'word-wrap': 'break-word',
                                                                        'text-align': 'left',
                                                                        // 'width': '300px',
                                                                    }">{{ element.label }}</label>



                                                                <input type="text" class="form-control"
                                                                    :placeholder="element.placeholder"
                                                                    v-model="element.placeholder" :style="{
                                                                        'width': element.width + 'px',
                                                                        'background-color': element.background_color,
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'border-color': element.border_color,
                                                                        'border-style': element.border_style,
                                                                        'border-width': element.border_width + 'px',
                                                                        'padding': element.padding + 'px',
                                                                        'box-shadow': element.box_shadow,
                                                                        'float': element.input_field_float,
                                                                    }">





                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="inputFieldWrapper" v-if="element.type == 'number_input'">



                                                        <div class="outerWrapper" :style="{
                                                            'justify-content': element.justify_content,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">


                                                            <div class="mycontainer">

                                                                <div v-if="element.show_label === 1" class="box">
                                                                    <div :style="{
                                                                        'color': element.label_font_color,
                                                                        'font-size': element.label_font_size + 'px',
                                                                        'float': element.label_float,
                                                                        'font-weight': 'bold',
                                                                        'padding-bottom': '5px',
                                                                    }" contenteditable
                                                                        @input="updateLabelText(element, $event)">
                                                                        {{ element.label }}
                                                                    </div>
                                                                </div>

                                                                <div class="box">
                                                                    <input class="form-control theInput" type="number"
                                                                        :placeholder="element.number_value"
                                                                        v-model="element.placeholder" :style="{
                                                                            'width': element.width + 'px',
                                                                            'background-color': element.background_color,
                                                                            'border-radius': element.border_radius + 'px',
                                                                            'border-color': element.border_color,
                                                                            'border-style': element.border_style,
                                                                            'border-width': element.border_width + 'px',
                                                                            'padding': element.padding + 'px',
                                                                            'box-shadow': element.box_shadow,
                                                                            'float': element.input_field_float,
                                                                        }" />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="inputFieldWrapper" v-if="element.type == 'time_input'">


                                                        <div class="outerWrapper" :style="{
                                                            'justify-content': element.justify_content,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">


                                                            <div class="mycontainer">





                                                                <div v-if="element.show_label === 1" class="box">
                                                                    <div :style="{
                                                                        'color': element.label_font_color,
                                                                        'font-size': element.label_font_size + 'px',
                                                                        'float': element.label_float,
                                                                        'font-weight': 'bold',
                                                                        'padding-bottom': '5px',
                                                                    }">
                                                                        {{ element.label }}
                                                                    </div>
                                                                </div>

                                                                <div class="box">

                                                                    <VueDatePicker time-picker placeholder="Select Time"
                                                                        :is-24="false" :format="'hh:mm'"
                                                                        class="form-control theInput" :style="{
                                                                            'width': element.width + 'px',
                                                                            'background-color': element.background_color,
                                                                            'border-radius': element.border_radius + 'px',
                                                                            'border-color': element.border_color,
                                                                            'border-style': element.border_style,
                                                                            'border-width': element.border_width + 'px',
                                                                            'padding': element.padding + 'px',
                                                                            'box-shadow': element.box_shadow,
                                                                            'float': element.input_field_float,
                                                                            'padding': 0 + 'px'
                                                                        }"></VueDatePicker>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="inputFieldWrapper" v-if="element.type == 'date_input'">



                                                        <div class="outerWrapper" :style="{
                                                            'justify-content': element.justify_content,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">


                                                            <div class="mycontainer">

                                                                <div v-if="element.show_label === 1" class="box">
                                                                    <div :style="{
                                                                        'color': element.label_font_color,
                                                                        'font-size': element.label_font_size + 'px',
                                                                        'float': element.label_float,
                                                                        'font-weight': 'bold',
                                                                        'padding-bottom': '5px',
                                                                    }">
                                                                        {{ element.label }}
                                                                    </div>
                                                                </div>

                                                                <div class="box">


                                                                    <VueDatePicker :style="{
                                                                        'width': element.width + 'px',
                                                                        'background-color': element.background_color,
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'border-color': element.border_color,
                                                                        'border-style': element.border_style,
                                                                        'border-width': element.border_width + 'px',
                                                                        'padding': element.padding + 'px',
                                                                        'box-shadow': element.box_shadow,
                                                                        'float': element.input_field_float,
                                                                        'padding': 0 + 'px'
                                                                    }" :format="'MM/dd/yyyy'" placeholder="Select Date"
                                                                        :enable-time-picker="false">
                                                                    </VueDatePicker>




                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="inputFieldWrapper" v-if="element.type == 'email_input'">


                                                        <div class="outerWrapper" :style="{
                                                            'justify-content': element.justify_content,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">


                                                            <div class="" style="display:flex; flex-direction: column;">


                                                                <label v-if="element.show_label === 1" class="form-label"
                                                                    :style="{
                                                                        'color': element.label_font_color,
                                                                        'font-size': element.label_font_size + 'px',
                                                                        'float': element.label_float,
                                                                        'font-weight': 'bold',
                                                                        'padding-bottom': '2px',
                                                                        'word-wrap': 'break-word',
                                                                        'text-align': 'left',

                                                                    }">{{ element.label }}</label>



                                                                <input type="email" class="form-control"
                                                                    :placeholder="element.placeholder"
                                                                    v-model="element.placeholder" :style="{
                                                                        'width': element.width + 'px',
                                                                        'background-color': element.background_color,
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'border-color': element.border_color,
                                                                        'border-style': element.border_style,
                                                                        'border-width': element.border_width + 'px',
                                                                        'padding': element.padding + 'px',
                                                                        'box-shadow': element.box_shadow,
                                                                        'float': element.input_field_float,
                                                                    }">

                                                                <div class="box" v-if="element.show_error === 1">
                                                                    <span
                                                                        style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                        Please fill out this required field
                                                                    </span>
                                                                </div>




                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div v-if="element.type == 'question'">



                                                        <div v-if="element.answer_type == 'text_only'"
                                                            @mouseleave="clickedAnswerIndex = null">

                                                            <div class="questionTextOnlyWrapper" :style="{
                                                                'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                            }">


                                                                <div @click.stop="toggleHighlight(element, index)" :style="{
                                                                    'color': element.question_design.font_color,
                                                                    'font-size': element.question_design.font_size + 'px',
                                                                    'font-family': element.question_design.font_family,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'text-align': element.question_design.text_align,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                    'width': '100%',
                                                                    'word-wrap': 'break-word'

                                                                }">
                                                                    {{ element.question_text }}
                                                                </div>


                                                                <div class="d-flex align-items-center" :style="{
                                                                    'justify-content':
                                                                        element.question_design.alignment_box,
                                                                }">
                                                                    <div class="questionContainer">
                                                                        <div class="myBox">
                                                                            <draggable v-model="element.answers"
                                                                                item-key="id" handle=".handle"
                                                                                :animation="600" @end="dragEnded">
                                                                                <template
                                                                                    #item="{ element: answer, index }">

                                                                                    <div class="answerWrapper" :style="{
                                                                                        'width': '100%',
                                                                                        'word-wrap': 'break-word',
                                                                                        'color': element.answer_design.font_color,
                                                                                        'font-size':
                                                                                            element.answer_design.font_size + 'px',
                                                                                        'font-family':
                                                                                            element.answer_design.font_family,
                                                                                        'font-weight':
                                                                                            element.answer_design.font_weight,
                                                                                        'padding':
                                                                                            element.answer_design.padding + 'px',
                                                                                        'background-color':
                                                                                            element.answer_design.background_color,
                                                                                        'border-radius':
                                                                                            element.answer_design.border_radius +
                                                                                            'px',
                                                                                        'border-color':
                                                                                            element.answer_design.border_color,
                                                                                        'border-style':
                                                                                            element.answer_design.border_style,
                                                                                        'border-width':
                                                                                            element.answer_design.border_width +
                                                                                            'px',
                                                                                        'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                                    }">
                                                                                        <div class="answerOptionsBox"
                                                                                            v-if="clickedAnswerIndex === index"
                                                                                            @click="
                                                                                                quizEditorStore.deleteAnswerFromAQuestion(
                                                                                                    answer,
                                                                                                    currentElement
                                                                                                )
                                                                                                ">
                                                                                            <div class="row">
                                                                                                <div class="col handle">
                                                                                                    <img :src="require(`@/assets/toolbar/move.png`)
                                                                                                        " width="15" />
                                                                                                </div>

                                                                                                <div class="col">
                                                                                                    <img :src="require(`@/assets/toolbar/b-delete.png`)
                                                                                                        " width="15" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="row">
                                                                                            <div class="col" :style="{
                                                                                                'text-align':
                                                                                                    element.answer_design.text_align,
                                                                                            }" @click="
    toggleHighlightAnswer(answer, index)
    ">
                                                                                                {{ answer.answer_text }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </template>
                                                                            </draggable>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="element.answer_type == 'text_with_image'"
                                                            @mouseleave="clickedAnswerIndex = null">
                                                            <div class="questionTextImageWrapper" :style="{
                                                                margin: `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                            }">

                                                                <div @click.stop="toggleHighlight(element, index)" :style="{
                                                                    'color': element.question_design.font_color,
                                                                    'font-size': element.question_design.font_size + 'px',
                                                                    'font-family': element.question_design.font_family,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'text-align': element.question_design.text_align,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                    'width': '100%',
                                                                    'word-wrap': 'break-word'

                                                                }" v-html="element.text_code_block"></div>

                                                                <div class="d-flex align-items-center" :style="{
                                                                    'justify-content':
                                                                        element.question_design.alignment_box,
                                                                }">
                                                                    <div class="questionTextImageContainer">

                                                                        <draggable v-model="element.answers"
                                                                            :animation="600" @end="dragEnded"
                                                                            class="container1">
                                                                            <template #item="{ element: answer, index }">
                                                                                <div class="">
                                                                                    <div class="box1" :style="{
                                                                                        'width': '100%',
                                                                                        'word-wrap': 'break-word',
                                                                                        'color': element.answer_design.font_color,
                                                                                        'font-size':
                                                                                            element.answer_design.font_size + 'px',
                                                                                        'font-family':
                                                                                            element.answer_design.font_family,
                                                                                        'font-weight':
                                                                                            element.answer_design.font_weight,
                                                                                        'background-color':
                                                                                            element.answer_design.background_color,
                                                                                        'border-radius':
                                                                                            element.answer_design.border_radius +
                                                                                            'px',
                                                                                        'border-color':
                                                                                            element.answer_design.border_color,
                                                                                        'border-style':
                                                                                            element.answer_design.border_style,
                                                                                        'border-width':
                                                                                            element.answer_design.border_width +
                                                                                            'px',
                                                                                        margin: `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                                    }">

                                                                                        <div class="answerBoxOptions2"
                                                                                            v-if="clickedAnswerIndex === index"
                                                                                            @click="
                                                                                                quizEditorStore.deleteAnswerFromAQuestion(
                                                                                                    answer,
                                                                                                    currentElement
                                                                                                )
                                                                                                ">
                                                                                            <div class="row">
                                                                                                <div class="col">
                                                                                                    <img :src="require(`@/assets/toolbar/move.png`)
                                                                                                        " width="15" />
                                                                                                </div>

                                                                                                <div class="col">
                                                                                                    <img :src="require(`@/assets/toolbar/b-delete.png`)
                                                                                                        " width="15" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="imageTop">

                                                                                            <img v-if="answer.img_url === null"
                                                                                                :src="require(`@/assets/toolbar/placeholder.png`)" />
                                                                                            <img v-else
                                                                                                :src="answer.img_url" />


                                                                                        </div>

                                                                                        <div class="answerBody" :style="{
                                                                                            'background-color':
                                                                                                element.answer_design
                                                                                                    .background_color,
                                                                                            'font-weight':
                                                                                                element.answer_design.font_weight,
                                                                                            'font-size':
                                                                                                element.answer_design.font_size +
                                                                                                'px',
                                                                                        }" @click="
    toggleHighlightAnswer(answer, index)
    ">
                                                                                            {{ answer.answer_text }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </template>
                                                                        </draggable>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div v-if="element.type == 'button'" :style="{
                                                        'display': 'flex',
                                                        'justify-content': element.alignment,
                                                    }">
                                                        <button :style="{
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            'font-size': element.font_size + 'px',
                                                            'font-family': element.font_family,
                                                            'font-weight': element.font_weight,
                                                            'background-color': element.background_color,
                                                            'padding': element.padding + 'px',
                                                            'width': element.width + 'px',
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'outline': 'none',
                                                            'border-style': 'solid',
                                                            'position': 'relative'
                                                        }" type="button">

                                                            <input :style="{
                                                                'background': 'transparent',
                                                                'border': 'none',
                                                                'outline': 'none',
                                                                'width': '100%',
                                                                'text-align': 'center',
                                                                'color': element.font_color,
                                                                'font-family': element.font_family,
                                                                'font-weight': element.font_weight,
                                                            }" @input="updateButtonText(element, $event)" type="text"
                                                                :value="element.text" />

                                                        </button>
                                                    </div>


                                                    <!-- image_in_editor -->
                                                    <div v-if="element.type == 'image'">

                                                        <div class="imageWidgetWrapper" :style="{
                                                            'display': 'flex',
                                                            'justify-content': element.alignment,
                                                            'width': '100%'
                                                        }">


                                                            <img v-if="element.uploaded_img_url === null" class="img-fluid"
                                                                :style="{
                                                                    'max-width': element.width + '%',
                                                                    'border-radius': element.border_radius + 'px',
                                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                    'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                }"
                                                                :src="require(`@/assets/toolbar/placeholder.png`)" />

                                                            <img v-else :src="element.uploaded_img_url" class="img-fluid"
                                                                :style="{
                                                                    'max-width': element.width + '%',
                                                                    'border-radius': element.border_radius + 'px',
                                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                    'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                }" />


                                                        </div>
                                                    </div>

                                                    <div v-if="element.type == 'embed'">



                                                        <div v-if="element.has_code === 'no'">

                                                            <img width="50" :src="require(`@/assets/images/code.png`)" />

                                                        </div>


                                                        <div v-if="element.has_code === 'yes'" class="embedCodeWrapper"
                                                            :style="{
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">

                                                            <div v-html="element.code_block" :style="{
                                                                'width': element.width + '%',
                                                                'height': element.height + 'px',
                                                            }"></div>

                                                        </div>



                                                    </div>

                                                    <div class="centered-content" v-if="element.type == 'video'"
                                                        style="padding: 0px">



                                                        <div :style="{
                                                            'width': element.width + 'px',
                                                            'max-width': element.width + 'px',
                                                            'margin': element.margin + 'px',

                                                        }">
                                                            <div :style="{
                                                                'overflow': 'hidden',
                                                                'border-radius': element.border_radius + 'px',
                                                                'box-shadow': element.box_shadow,
                                                            }" v-html="element.video_embed_code"></div>
                                                        </div>



                                                    </div>


                                                    <div v-if="element.type == 'sharing'" :style="{
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        'display': 'flex',
                                                        'flex-direction': 'column',
                                                        'justify-content': 'center',
                                                        'align-items': 'center'
                                                    }">
                                                        <div class="shareTextWrapper" :style="{
                                                            'width': '250px',
                                                            'color': element.font_color,
                                                            'font-size': element.font_size + 'px',
                                                            'font-family': element.font_family,
                                                            'font-weight': element.font_weight,
                                                            'background': 'transparent',
                                                            'border': 'none',
                                                            'outline': 'none',
                                                            'text-align': 'center',
                                                            'word-wrap': 'break-word',

                                                        }">
                                                            {{ element.main_share_text }}

                                                        </div>

                                                        <div class="sharingWrapper">
                                                            <div class="platformItem"
                                                                v-for="(platform, index) in element.platforms" :key="index">
                                                                <img :style="{
                                                                    'width': element.img_width + 'px',
                                                                    'margin-bottom': '10px',
                                                                }"
                                                                    :src="require(`@/assets/toolbar/${platform.img_name}.png`)" />
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div class="textareaWrapper" v-if="element.type == 'textbox'">


                                                        <div class="textboxOuterWrapper" :style="{
                                                            'justify-content': element.justify_content,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">

                                                            <div class="textboxContainer">


                                                                <div v-if="element.show_label === 1" class="theBox">



                                                                    <div :style="{
                                                                        'color': element.label_font_color,
                                                                        'font-size': element.label_font_size + 'px',
                                                                        'float': element.label_float,
                                                                        'font-weight': 'bold',
                                                                        'padding-bottom': '5px',
                                                                        'width': '100%',
                                                                        'word-wrap': 'break-word',
                                                                        'text-align': 'left',
                                                                    }">
                                                                        {{ element.label }}
                                                                    </div>

                                                                </div>

                                                                <div class="theBox">

                                                                    <textarea class="form-control" :style="{
                                                                        'width': element.width + 'px',
                                                                        'background-color': element.background_color,
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'border-color': element.border_color,
                                                                        'border-style': element.border_style,
                                                                        'border-width': element.border_width + 'px',
                                                                        'padding': element.padding + 'px',
                                                                        'box-shadow': element.box_shadow,
                                                                    }" rows="3" v-model="element.placeholder">
                                                                </textarea>


                                                                </div>

                                                            </div>


                                                        </div>


                                                    </div>


                                                    <!-- editor checkbox default page -->
                                                    <div class="parent-container" :style="{
                                                        'justify-content': element.justify_content,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }" v-if="element.type == 'checkbox'">

                                                        <div class="checkboxinputFieldWrapper" :style="{
                                                            'max-width': '500px'
                                                        }">

                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value=""
                                                                    id="flexCheckDefault">
                                                                <label class="form-check-label" for="flexCheckDefault"
                                                                    style="text-align: left;">
                                                                    {{ element.text_body }}
                                                                </label>
                                                            </div>




                                                        </div>

                                                    </div>


                                                    <div v-if="element.type == 'score'">



                                                        <div class="scoreOuterWrapper" :style="{
                                                            'justify-content': element.alignment,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">


                                                            <div class="scoreContainer" :style="{
                                                                'color': element.font_color,
                                                                'font-size': element.font_size + 'px',
                                                                'font-family': element.font_family,
                                                                'font-weight': element.font_weight,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">


                                                                {{ element.text_label }}: X / Y

                                                            </div>

                                                        </div>

                                                    </div>


                                                    <div v-if="element.type == 'calendar_embed'">

                                                        <div v-if="element.has_code === 'no'">

                                                            <img width="40"
                                                                :src="require(`@/assets/images/calendar_black.png`)" />

                                                        </div>


                                                        <div v-if="element.has_code === 'yes'" class="" :style="{
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">

                                                            <iframe :width="`${element.width}%`"
                                                                :height="`${element.height}px`" :src="element.calender_url"
                                                                frameborder="0" :style="{
                                                                    'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                    'border-radius': '5px',
                                                                }"></iframe>


                                                        </div>



                                                    </div>



                                                </div>

                                            </template>


                                        </draggable>

                                    </div>






                                </div>



                            </div>

                        </div>

                    </div>

                    <div v-if="quizEditorStore.currentPageInView.sub_type === 'outcome'">

                        <div v-for="(page, index) in quizEditorStore.outcomePagesArray" :key="index">

                            <div v-if="index === quizEditorStore.currentOutcomePageIndex">

                                <div class="quizCard" @click.stop="showQuizBGOptions" :style="{
                                    'background': quizEditorStore.selectedCardBGtype === 'gradient_bg'
                                        ? quizEditorStore.selectedCardBGgradient
                                        : quizEditorStore.selectedCardBGtype === 'normal_bg'
                                            ? quizEditorStore.selectedCardNormalBGcolor
                                            : quizEditorStore.selectedCardBGtype === 'img_bg'
                                                ? `url(${quizEditorStore.selectedCardBGimageUrl}) no-repeat center/cover`
                                                : 'none',

                                }">


                                    <div v-if="page.redirect_url != null">

                                        <p>
                                            <b>
                                                This outcome page will redirect to url: {{ page.redirect_url }}
                                            </b>
                                        </p>

                                    </div>

                                    <div v-else>

                                        <div v-if="page.design_layout.length == 0">



                                            <h5>Outcome Page</h5>

                                            <br />
                                            <p>Users will be shown one of these outcome pages based the answers they select.
                                                Add
                                                a widget to add to this page.</p>




                                        </div>

                                        <div v-else>

                                            <draggable class="innerQuizContainer" v-model="page.design_layout" item-key="id"
                                                :animation="600" handle=".myhandle" @end="quizEditorStore.saveDesign">



                                                <template #item="{ element: element, index }">



                                                    <div class="elementBox"
                                                        :class="{ highlighted: highlightedIndex === index }"
                                                        @click.stop="toggleHighlight(element, index)"
                                                        :id="'elementBox' + element.page_element_id">





                                                        <div v-if="highlightedIndex === index" class="deleteIcon">

                                                            <div class="row">

                                                                <div class="col" v-if="currentElement.type === 'question'"
                                                                    @click="
                                                                        quizEditorStore.addAnswerToQuestion(currentElement)
                                                                        ">
                                                                    <img :src="require(`@/assets/toolbar/a-plus.png`)"
                                                                        width="14" />
                                                                </div>

                                                                <div class="col">
                                                                    <img @click="showDeleteElementModal"
                                                                        :src="require(`@/assets/toolbar/delete.png`)"
                                                                        width="14" />
                                                                </div>

                                                                <div class="col">
                                                                    <img class="myhandle"
                                                                        :src="require(`@/assets/images/m_move.png`)"
                                                                        width="14" />
                                                                </div>

                                                            </div>

                                                        </div>



                                                        <div v-if="element.type == 'header_text'">


                                                            <div :style="{
                                                                'word-wrap': 'break-word',
                                                                'width': '100%',
                                                                'color': element.font_color,
                                                                'font-family': element.font_family,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }" v-html="element.code_block">
                                                            </div>



                                                        </div>


                                                        <div v-if="element.type == 'text'">



                                                            <div :style="{
                                                                'word-wrap': 'break-word',
                                                                'width': '100%',
                                                                'color': element.font_color,
                                                                'font-family': element.font_family,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }" v-html="element.code_block">
                                                            </div>



                                                        </div>



                                                        <div class="inputFieldWrapper" v-if="element.type == 'text_input'">




                                                            <div class="outerWrapper" :style="{
                                                                'justify-content': element.justify_content,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">


                                                                <div class="" style="display:flex; flex-direction: column;">


                                                                    <label v-if="element.show_label === 1"
                                                                        class="form-label" :style="{
                                                                            'color': element.label_font_color,
                                                                            'font-size': element.label_font_size + 'px',
                                                                            'float': element.label_float,
                                                                            'font-weight': 'bold',
                                                                            'padding-bottom': '2px',
                                                                            'word-wrap': 'break-word',
                                                                            'text-align': 'left',
                                                                            'width': '300px',

                                                                        }">{{ element.label }}</label>



                                                                    <input type="text" class="form-control"
                                                                        :placeholder="element.placeholder"
                                                                        v-model="element.placeholder" :style="{
                                                                            'width': element.width + 'px',
                                                                            'background-color': element.background_color,
                                                                            'border-radius': element.border_radius + 'px',
                                                                            'border-color': element.border_color,
                                                                            'border-style': element.border_style,
                                                                            'border-width': element.border_width + 'px',
                                                                            'padding': element.padding + 'px',
                                                                            'box-shadow': element.box_shadow,
                                                                            'float': element.input_field_float,
                                                                        }">

                                                                    <div class="box" v-if="element.show_error === 1">
                                                                        <span
                                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                            Please fill out this required field
                                                                        </span>
                                                                    </div>




                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div class="inputFieldWrapper"
                                                            v-if="element.type == 'number_input'">



                                                            <div class="outerWrapper" :style="{
                                                                'justify-content': element.justify_content,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">


                                                                <div class="mycontainer">

                                                                    <div v-if="element.show_label === 1" class="box">
                                                                        <div :style="{
                                                                            'color': element.label_font_color,
                                                                            'font-size': element.label_font_size + 'px',
                                                                            'float': element.label_float,
                                                                            'font-weight': 'bold',
                                                                            'padding-bottom': '5px',
                                                                        }" contenteditable
                                                                            @input="updateLabelText(element, $event)">
                                                                            {{ element.label }}
                                                                        </div>
                                                                    </div>

                                                                    <div class="box">
                                                                        <input class="form-control theInput" type="number"
                                                                            :placeholder="element.number_value"
                                                                            v-model="element.placeholder" :style="{
                                                                                'width': element.width + 'px',
                                                                                'background-color': element.background_color,
                                                                                'border-radius': element.border_radius + 'px',
                                                                                'border-color': element.border_color,
                                                                                'border-style': element.border_style,
                                                                                'border-width': element.border_width + 'px',
                                                                                'padding': element.padding + 'px',
                                                                                'box-shadow': element.box_shadow,
                                                                                'float': element.input_field_float,
                                                                            }" />
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="inputFieldWrapper" v-if="element.type == 'time_input'">


                                                            <div class="outerWrapper" :style="{
                                                                'justify-content': element.justify_content,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">


                                                                <div class="mycontainer">





                                                                    <div v-if="element.show_label === 1" class="box">
                                                                        <div :style="{
                                                                            'color': element.label_font_color,
                                                                            'font-size': element.label_font_size + 'px',
                                                                            'float': element.label_float,
                                                                            'font-weight': 'bold',
                                                                            'padding-bottom': '5px',
                                                                        }" contenteditable
                                                                            @input="updateLabelText(element, $event)">
                                                                            {{ element.label }}
                                                                        </div>
                                                                    </div>

                                                                    <div class="box">

                                                                        <VueDatePicker time-picker placeholder="Select Time"
                                                                            :is-24="false" :format="'hh:mm'"
                                                                            class="form-control theInput" :style="{
                                                                                'width': element.width + 'px',
                                                                                'background-color': element.background_color,
                                                                                'border-radius': element.border_radius + 'px',
                                                                                'border-color': element.border_color,
                                                                                'border-style': element.border_style,
                                                                                'border-width': element.border_width + 'px',
                                                                                'padding': element.padding + 'px',
                                                                                'box-shadow': element.box_shadow,
                                                                                'float': element.input_field_float,
                                                                                'padding': 0 + 'px'
                                                                            }"></VueDatePicker>


                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="inputFieldWrapper" v-if="element.type == 'date_input'">



                                                            <div class="outerWrapper" :style="{
                                                                'justify-content': element.justify_content,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">


                                                                <div class="mycontainer">

                                                                    <div v-if="element.show_label === 1" class="box">
                                                                        <div :style="{
                                                                            'color': element.label_font_color,
                                                                            'font-size': element.label_font_size + 'px',
                                                                            'float': element.label_float,
                                                                            'font-weight': 'bold',
                                                                            'padding-bottom': '5px',
                                                                        }" contenteditable
                                                                            @input="updateLabelText(element, $event)">
                                                                            {{ element.label }}
                                                                        </div>
                                                                    </div>

                                                                    <div class="box">


                                                                        <VueDatePicker :style="{
                                                                            'width': element.width + 'px',
                                                                            'background-color': element.background_color,
                                                                            'border-radius': element.border_radius + 'px',
                                                                            'border-color': element.border_color,
                                                                            'border-style': element.border_style,
                                                                            'border-width': element.border_width + 'px',
                                                                            'padding': element.padding + 'px',
                                                                            'box-shadow': element.box_shadow,
                                                                            'float': element.input_field_float,
                                                                            'padding': 0 + 'px'
                                                                        }" :format="'MM/dd/yyyy'"
                                                                            placeholder="Select Date"
                                                                            :enable-time-picker="false">
                                                                        </VueDatePicker>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="inputFieldWrapper" v-if="element.type == 'email_input'">


                                                            <div class="outerWrapper" :style="{
                                                                'justify-content': element.justify_content,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">


                                                                <div class="" style="display:flex; flex-direction: column;">


                                                                    <label v-if="element.show_label === 1"
                                                                        class="form-label" :style="{
                                                                            'color': element.label_font_color,
                                                                            'font-size': element.label_font_size + 'px',
                                                                            'float': element.label_float,
                                                                            'font-weight': 'bold',
                                                                            'padding-bottom': '2px',
                                                                            'word-wrap': 'break-word',
                                                                            'text-align': 'left',
                                                                        }">Email address</label>



                                                                    <input type="text" class="form-control"
                                                                        :placeholder="element.placeholder"
                                                                        v-model="element.placeholder" :style="{
                                                                            'width': element.width + 'px',
                                                                            'background-color': element.background_color,
                                                                            'border-radius': element.border_radius + 'px',
                                                                            'border-color': element.border_color,
                                                                            'border-style': element.border_style,
                                                                            'border-width': element.border_width + 'px',
                                                                            'padding': element.padding + 'px',
                                                                            'box-shadow': element.box_shadow,
                                                                            'float': element.input_field_float,
                                                                        }">



                                                                </div>

                                                            </div>


                                                        </div>

                                                        <div v-if="element.type == 'question'">



                                                            <div v-if="element.answer_type == 'text_only'"
                                                                @mouseleave="clickedAnswerIndex = null">

                                                                <div class="questionTextOnlyWrapper" :style="{
                                                                    'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                }">
                                                                    <div :style="{
                                                                        'color': element.question_design.font_color,
                                                                        'font-size': element.question_design.font_size + 'px',
                                                                        'font-family': element.question_design.font_family,
                                                                        'font-weight': element.question_design.font_weight,
                                                                        'text-align': element.question_design.text_align,
                                                                        'font-weight': element.question_design.font_weight,
                                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                    }">
                                                                        {{ element.question_text }}
                                                                    </div>

                                                                    <div class="d-flex align-items-center" :style="{
                                                                        'justify-content':
                                                                            element.question_design.alignment_box,
                                                                    }">
                                                                        <div class="questionContainer">
                                                                            <div class="myBox">
                                                                                <draggable v-model="element.answers"
                                                                                    item-key="id" handle=".handle"
                                                                                    :animation="600" @end="dragEnded">
                                                                                    <template
                                                                                        #item="{ element: answer, index }">

                                                                                        <div class="answerWrapper" :style="{
                                                                                            'color': element.answer_design.font_color,
                                                                                            'font-size':
                                                                                                element.answer_design.font_size + 'px',
                                                                                            'font-family':
                                                                                                element.answer_design.font_family,
                                                                                            'font-weight':
                                                                                                element.answer_design.font_weight,
                                                                                            'padding':
                                                                                                element.answer_design.padding + 'px',
                                                                                            'background-color':
                                                                                                element.answer_design.background_color,
                                                                                            'border-radius':
                                                                                                element.answer_design.border_radius +
                                                                                                'px',
                                                                                            'border-color':
                                                                                                element.answer_design.border_color,
                                                                                            'border-style':
                                                                                                element.answer_design.border_style,
                                                                                            'border-width':
                                                                                                element.answer_design.border_width +
                                                                                                'px',
                                                                                            'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                                        }">
                                                                                            <div class="answerOptionsBox"
                                                                                                v-if="clickedAnswerIndex === index"
                                                                                                @click="
                                                                                                    quizEditorStore.deleteAnswerFromAQuestion(
                                                                                                        answer,
                                                                                                        currentElement
                                                                                                    )
                                                                                                    ">
                                                                                                <div class="row">
                                                                                                    <div class="col handle">
                                                                                                        <img :src="require(`@/assets/toolbar/move.png`)
                                                                                                            "
                                                                                                            width="15" />
                                                                                                    </div>

                                                                                                    <div class="col">
                                                                                                        <img :src="require(`@/assets/toolbar/b-delete.png`)
                                                                                                            "
                                                                                                            width="15" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="row">
                                                                                                <div class="col" :style="{
                                                                                                    'text-align':
                                                                                                        element.answer_design.text_align,
                                                                                                }" contenteditable
                                                                                                    @input="updateAnswerText(answer, $event)"
                                                                                                    @click="
                                                                                                        toggleHighlightAnswer(answer, index)
                                                                                                        ">
                                                                                                    {{ answer.answer_text }}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </template>
                                                                                </draggable>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div v-if="element.answer_type == 'text_with_image'"
                                                                @mouseleave="clickedAnswerIndex = null">
                                                                <div class="questionTextImageWrapper" :style="{
                                                                    margin: `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                }">
                                                                    <div :style="{
                                                                        'color': element.question_design.font_color,
                                                                        'font-size': element.question_design.font_size + 'px',
                                                                        'font-family': element.question_design.font_family,
                                                                        'font-weight': element.question_design.font_weight,
                                                                        'text-align': element.question_design.text_align,
                                                                        'font-weight': element.question_design.font_weight,
                                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                    }">
                                                                        {{ element.question_text }}
                                                                    </div>

                                                                    <div class="d-flex align-items-center" :style="{
                                                                        'justify-content':
                                                                            element.question_design.alignment_box,
                                                                    }">
                                                                        <div class="questionTextImageContainer">

                                                                            <draggable v-model="element.answers"
                                                                                :animation="600" @end="dragEnded"
                                                                                class="container1">
                                                                                <template
                                                                                    #item="{ element: answer, index }">
                                                                                    <div class="">
                                                                                        <div class="box1" :style="{
                                                                                            'color': element.answer_design.font_color,
                                                                                            'font-size':
                                                                                                element.answer_design.font_size + 'px',
                                                                                            'font-family':
                                                                                                element.answer_design.font_family,
                                                                                            'font-weight':
                                                                                                element.answer_design.font_weight,
                                                                                            'background-color':
                                                                                                element.answer_design.background_color,
                                                                                            'border-radius':
                                                                                                element.answer_design.border_radius +
                                                                                                'px',
                                                                                            'border-color':
                                                                                                element.answer_design.border_color,
                                                                                            'border-style':
                                                                                                element.answer_design.border_style,
                                                                                            'border-width':
                                                                                                element.answer_design.border_width +
                                                                                                'px',
                                                                                            'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                                        }">

                                                                                            <div class="answerBoxOptions2"
                                                                                                v-if="clickedAnswerIndex === index"
                                                                                                @click="
                                                                                                    quizEditorStore.deleteAnswerFromAQuestion(
                                                                                                        answer,
                                                                                                        currentElement
                                                                                                    )
                                                                                                    ">
                                                                                                <div class="row">
                                                                                                    <div class="col">
                                                                                                        <img :src="require(`@/assets/toolbar/move.png`)
                                                                                                            "
                                                                                                            width="15" />
                                                                                                    </div>

                                                                                                    <div class="col">
                                                                                                        <img :src="require(`@/assets/toolbar/b-delete.png`)
                                                                                                            "
                                                                                                            width="15" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="imageTop">

                                                                                                <img v-if="answer.img_url === null"
                                                                                                    :src="require(`@/assets/toolbar/placeholder.png`)" />
                                                                                                <img v-else
                                                                                                    :src="answer.img_url" />


                                                                                            </div>

                                                                                            <div class="answerBody" :style="{
                                                                                                'background-color':
                                                                                                    element.answer_design
                                                                                                        .background_color,
                                                                                                'font-weight':
                                                                                                    element.answer_design.font_weight,
                                                                                                'font-size':
                                                                                                    element.answer_design.font_size +
                                                                                                    'px',
                                                                                            }" @click="
    toggleHighlightAnswer(answer, index)
    ">
                                                                                                {{ answer.answer_text }}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </template>
                                                                            </draggable>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div v-if="element.type == 'button'" :style="{
                                                            'display': 'flex',
                                                            'justify-content': element.alignment,
                                                        }">
                                                            <button :style="{
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                'font-size': element.font_size + 'px',
                                                                'font-family': element.font_family,
                                                                'font-weight': element.font_weight,
                                                                'background-color': element.background_color,
                                                                'padding': element.padding + 'px',
                                                                'width': element.width + 'px',
                                                                'border-radius': element.border_radius + 'px',
                                                                'border-color': element.border_color,
                                                                'border-style': element.border_style,
                                                                'border-width': element.border_width + 'px',
                                                                'box-shadow': element.box_shadow,
                                                                'outline': 'none',
                                                                'border-style': 'solid',
                                                                'position': 'relative'
                                                            }" type="button">

                                                                <input :style="{
                                                                    'background': 'transparent',
                                                                    'border': 'none',
                                                                    'outline': 'none',
                                                                    'width': '100%',
                                                                    'text-align': 'center',
                                                                    'color': element.font_color,
                                                                    'font-family': element.font_family,
                                                                    'font-weight': element.font_weight,
                                                                }" @input="updateButtonText(element, $event)"
                                                                    type="text" :value="element.text" />

                                                            </button>
                                                        </div>


                                                        <div v-if="element.type == 'image'">

                                                            <div class="imageWidgetWrapper" :style="{
                                                                'display': 'flex',
                                                                'justify-content': element.alignment,
                                                            }">


                                                                <img v-if="element.uploaded_img_url === null"
                                                                    class="img-fluid" :style="{
                                                                        'max-width': element.width + '%',
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                        'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                    }"
                                                                    :src="require(`@/assets/toolbar/placeholder.png`)" />

                                                                <img v-else :src="element.uploaded_img_url"
                                                                    class="img-fluid" :style="{
                                                                        'max-width': element.width + '%',
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                        'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                    }" />


                                                            </div>
                                                        </div>

                                                        <div v-if="element.type == 'embed'">



                                                            <div v-if="element.has_code === 'no'">

                                                                <img width="50"
                                                                    :src="require(`@/assets/images/code.png`)" />

                                                            </div>


                                                            <div v-if="element.has_code === 'yes'" class="embedCodeWrapper"
                                                                :style="{
                                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                }">

                                                                <div v-html="element.code_block" :style="{
                                                                    'width': element.width + '%',
                                                                    'height': element.height + 'px',
                                                                }"></div>

                                                            </div>



                                                        </div>

                                                        <div class="centered-content" v-if="element.type == 'video'"
                                                            style="padding: 0px">



                                                            <div :style="{
                                                                'width': element.width + 'px',
                                                                'max-width': element.width + 'px',
                                                                'margin': element.margin + 'px',

                                                            }">
                                                                <div :style="{
                                                                    'overflow': 'hidden',
                                                                    'border-radius': element.border_radius + 'px',
                                                                    'box-shadow': element.box_shadow,
                                                                }" v-html="element.video_embed_code"></div>
                                                            </div>



                                                        </div>


                                                        <div v-if="element.type == 'sharing'" :style="{
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            'display': 'flex',
                                                            'flex-direction': 'column',
                                                            'justify-content': 'center',
                                                            'align-items': 'center'
                                                        }">
                                                            <input class="shareTextWrapper"
                                                                style="font-weight: bold; margin-bottom: 0px; width: 100%;"
                                                                :style="{
                                                                    'color': element.font_color,
                                                                    'font-size': element.font_size + 'px',
                                                                    'font-family': element.font_family,
                                                                    'font-weight': element.font_weight,
                                                                    'background': 'transparent',
                                                                    'border': 'none',
                                                                    'outline': 'none',
                                                                    'text-align': 'center'
                                                                }" @input="updateShareText(element, $event)"
                                                                type="text" :value="element.main_share_text" />

                                                            <div class="sharingWrapper">
                                                                <div class="platformItem"
                                                                    v-for="(platform, index) in element.platforms"
                                                                    :key="index">
                                                                    <img :style="{
                                                                        'width': element.img_width + 'px',
                                                                        'margin-bottom': '10px',
                                                                    }"
                                                                        :src="require(`@/assets/toolbar/${platform.img_name}.png`)" />
                                                                </div>
                                                            </div>
                                                        </div>




                                                        <div class="textareaWrapper" v-if="element.type == 'textbox'">



                                                            <div class="textboxOuterWrapper" :style="{
                                                                'justify-content': element.justify_content,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">

                                                                <div class="textboxContainer">


                                                                    <div :style="{
                                                                        'color': element.label_font_color,
                                                                        'font-size': element.label_font_size + 'px',
                                                                        'float': element.label_float,
                                                                        'font-weight': 'bold',
                                                                        'padding-bottom': '5px',
                                                                        'width': '100%',
                                                                        'word-wrap': 'break-word',
                                                                        'text-align': 'left',
                                                                    }">
                                                                        {{ element.label }}
                                                                    </div>

                                                                    <div class="theBox">

                                                                        <textarea class="form-control" :style="{
                                                                            'width': element.width + 'px',
                                                                            'background-color': element.background_color,
                                                                            'border-radius': element.border_radius + 'px',
                                                                            'border-color': element.border_color,
                                                                            'border-style': element.border_style,
                                                                            'border-width': element.border_width + 'px',
                                                                            'padding': element.padding + 'px',
                                                                            'box-shadow': element.box_shadow,
                                                                        }" rows="3" v-model="element.placeholder">
                        </textarea>


                                                                    </div>

                                                                </div>


                                                            </div>


                                                        </div>



                                                        <!-- editor checkbox outcome page -->
                                                        <div class="parent-container" :style="{
                                                            'justify-content': element.justify_content,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }" v-if="element.type == 'checkbox'">


                                                            <div class="checkboxinputFieldWrapper" :style="{
                                                                'max-width': '500px'
                                                            }">

                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="checkbox" value=""
                                                                        id="flexCheckDefault">
                                                                    <label class="form-check-label" for="flexCheckDefault"
                                                                        style="text-align: left;">
                                                                        {{ element.text_body }}
                                                                    </label>
                                                                </div>




                                                            </div>

                                                        </div>


                                                        <div v-if="element.type == 'score'">



                                                            <div class="scoreOuterWrapper" :style="{
                                                                'justify-content': element.alignment,
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">


                                                                <div class="scoreContainer" :style="{
                                                                    'color': element.font_color,
                                                                    'font-size': element.font_size + 'px',
                                                                    'font-family': element.font_family,
                                                                    'font-weight': element.font_weight,
                                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                }">


                                                                    {{ element.text_label }}: X / Y

                                                                </div>

                                                            </div>

                                                        </div>


                                                        <div v-if="element.type == 'calendar_embed'">

                                                            <div v-if="element.has_code === 'no'">

                                                                <img width="40"
                                                                    :src="require(`@/assets/images/calendar_black.png`)" />

                                                            </div>


                                                            <div v-if="element.has_code === 'yes'" class="" :style="{
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            }">

                                                                <iframe :width="`${element.width}%`"
                                                                    :height="`${element.height}px`"
                                                                    :src="element.calender_url" frameborder="0" :style="{
                                                                        'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                        'border-radius': '5px',
                                                                    }"></iframe>


                                                            </div>



                                                        </div>



                                                    </div>

                                                </template>


                                            </draggable>

                                        </div>

                                    </div>








                                </div>



                            </div>

                        </div>

                    </div>





                    <div class="pageFloatingBottomBar">
                        <div class="row">
                            <div class="col-10">
                                <div class="container">
                                    <div class="row" style="min-width: 100px">
                                        <div class="col">



                                            <draggable class="row flex-nowrap overflow-auto custom-scrollbar"
                                                v-model="quizEditorStore.defaultPagesArray" item-key="id" :animation="600"
                                                @end="quizEditorStore.saveDesign"
                                                @change="quizEditorStore.defaultPageMoved">

                                                <template #item="{ element: page, index }">


                                                    <div v-show="page.sub_type === 'default'" class="col-1 boxy"
                                                        @click="setCurrentPageInBottomBar(page, index)" :class="{
                                                            clickedTab: quizEditorStore.currentDefaultPageIndex === index,
                                                        }">
                                                        <div class="pageBtnDelete"
                                                            v-show="quizEditorStore.currentDefaultPageIndex === index && quizEditorStore.currentDefaultPageIndex != 0">
                                                            <div>
                                                                <img @click="showDeletePageModal"
                                                                    :src="require(`@/assets/images/t_trash.png`)"
                                                                    class="trashPageIcon" />
                                                            </div>
                                                        </div>

                                                        <div class="pageBtnEdit"
                                                            v-show="quizEditorStore.currentDefaultPageIndex === index">

                                                            <img :src="require(`@/assets/images/p_edit.png`)"
                                                                style="width: 12px;" data-bs-toggle="modal"
                                                                data-bs-target="#pageNameModal" />

                                                        </div>


                                                        <span style="font-size: 9px;">

                                                            {{ quizEditorStore.limitPageNameText(page.page_title) }}


                                                        </span>




                                                    </div>


                                                </template>

                                            </draggable>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-2" @click="quizEditorStore.addPageToDefaultPagesArray()">
                                <img class="plusIcon" :src="require(`@/assets/toolbar/b-plus3.png`)" />
                            </div>
                        </div>
                    </div>


                    <div v-if="quizEditorStore.currentQuiz.type === 'single_outcome'"
                        class="singleOutcomePageFloatingBottomBar">

                        <div class="row">

                            <div class="col-4 d-flex align-items-center justify-content-center">

                                <img :src="require(`@/assets/images/one_outcome.png`)" width="30" />

                            </div>

                            <div class="col-8">

                                <div v-for="(page, index) in quizEditorStore.outcomePagesArray" :key="index" class="boxy"
                                    @click="setCurrentPageInBottomBar(page, index)" :class="{
                                        clickedTab: quizEditorStore.currentOutcomePageIndex === index,
                                    }">

                                    <div class="pageBtnEdit">

                                        <img :src="require(`@/assets/images/p_edit.png`)" style="width: 12px;"
                                            data-bs-toggle="modal" data-bs-target="#outcomePageNameModal" />

                                    </div>



                                    <span style="font-size: 9px;">
                                        {{ quizEditorStore.limitPageNameText(page.page_title) }}
                                    </span>



                                </div>


                            </div>



                        </div>

                    </div>


                    <div v-if="quizEditorStore.currentQuiz.type === 'answer_based_outcomes'"
                        class="answerBasedOutcomePageFloatingBottomBar">



                        <div class="row">

                            <div class="col-10">

                                <div class="container">

                                    <div class="row" style="min-width: 100px">

                                        <div class="col">

                                            <div class="row flex-nowrap overflow-auto custom-scrollbar">

                                                <div v-for="(page, index) in quizEditorStore.outcomePagesArray" :key="index"
                                                    class="col-1 boxy" @click="setCurrentPageInBottomBar(page, index)"
                                                    :class="{
                                                        clickedTab: quizEditorStore.currentOutcomePageIndex === index,
                                                    }">

                                                    <!-- this one -->
                                                    <div class="pageBtnDelete"
                                                        v-show="quizEditorStore.currentOutcomePageIndex === index && quizEditorStore.currentOutcomePageIndex != 0">
                                                        <div>
                                                            <img :src="require(`@/assets/images/t_trash.png`)"
                                                                class="trashPageIcon"
                                                                @click="quizEditorStore.openDeleteOutcomeModal" />
                                                        </div>
                                                    </div>

                                                    <div class="pageBtnEdit"
                                                        v-show="quizEditorStore.currentOutcomePageIndex === index">

                                                        <img :src="require(`@/assets/images/p_edit.png`)"
                                                            style="width: 12px;" data-bs-toggle="modal"
                                                            data-bs-target="#outcomePageNameModal" />

                                                    </div>


                                                    <span style="font-size: 9px;">
                                                        {{ quizEditorStore.limitPageNameText(page.page_title) }}
                                                    </span>




                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-2">
                                <img class="plusIcon" :src="require(`@/assets/toolbar/b-plus3.png`)"
                                    @click="quizEditorStore.addPageToOutcomePagesArray" />
                            </div>
                        </div>


                    </div>



                    <div v-if="quizEditorStore.currentQuiz.type === 'score_based_outcomes'"
                        class="singleOutcomePageFloatingBottomBar">

                        <div class="row">

                            <div class="col-4 d-flex align-items-center justify-content-center">

                                <img :src="require(`@/assets/images/score_trophy.png`)" width="30" />

                            </div>

                            <div class="col-8">

                                <div v-for="(page, index) in quizEditorStore.outcomePagesArray" :key="index"
                                    v-show="page.sub_type === 'outcome'" class="boxy"
                                    @click="setCurrentPageInBottomBar(page, index)" :class="{
                                        clickedTab: quizEditorStore.currentOutcomePageIndex === index,
                                    }">

                                    <div class="pageBtnEdit">

                                        <img :src="require(`@/assets/images/p_edit.png`)" style="width: 12px;"
                                            data-bs-toggle="modal" data-bs-target="#outcomePageNameModal" />

                                    </div>

                                    <span style="font-size: 9px;">
                                        {{ quizEditorStore.limitPageNameText(page.page_title) }}
                                    </span>



                                </div>


                            </div>



                        </div>

                    </div>


                </div>


            </div>



        </div>


        <div class="modal fade" id="pageNameModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered">

                <div class="modal-content">



                    <div class="modal-body">

                        <div v-if="quizEditorStore.currentPageInView.sub_type === 'default'">

                            <div class="mb-3"
                                v-if="quizEditorStore.defaultPagesArray[quizEditorStore.currentDefaultPageIndex]">

                                <label class="form-label" style="float: left;">Page Name</label>
                                <input type="text" class="form-control"
                                    v-model="quizEditorStore.defaultPagesArray[quizEditorStore.currentDefaultPageIndex].page_title">
                                <div class="form-text" style="float: left;">For internal use only</div>

                            </div>

                        </div>

                        <div v-if="quizEditorStore.currentPageInView.sub_type === 'outcome'">

                            <div class="mb-3"
                                v-if="quizEditorStore.outcomePagesArray[quizEditorStore.currentOutcomePageIndex]">

                                <label class="form-label" style="float: left;">Page Name</label>
                                <input type="text" class="form-control"
                                    v-model="quizEditorStore.outcomePagesArray[quizEditorStore.currentOutcomePageIndex].page_title">
                                <div class="form-text" style="float: left;">For internal use only</div>

                            </div>

                        </div>




                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>

                        <button type="button" class="btn btn-success" @click="quizEditorStore.saveDesign()"
                            data-bs-dismiss="modal">Save changes</button>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade" id="outcomePageNameModal" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered">

                <div class="modal-content">



                    <div class="modal-body">

                        <div v-if="quizEditorStore.outcomePagesArray[quizEditorStore.currentOutcomePageIndex]">

                            <div class="mb-3">

                                <label class="form-label" style="float: left; font-size: 13px;"><b>Outcome Page
                                        Name</b></label>
                                <input type="text" class="form-control"
                                    v-model="quizEditorStore.outcomePagesArray[quizEditorStore.currentOutcomePageIndex].page_title">
                                <div class="form-text" style="float: left; font-size: 11px;">For internal use only</div>

                            </div>

                            <br />

                            <div class="mb-3">

                                <label class="form-label" style="float: left; font-size: 13px;"><b>Custom Redirect
                                        URL</b></label>

                                <input type="text" class="form-control"
                                    v-model="quizEditorStore.outcomePagesArray[quizEditorStore.currentOutcomePageIndex].redirect_url"
                                    placeholder="Paste your url example https://yourdomain.com"
                                    @input="quizEditorStore.checkRedirectURL(quizEditorStore.outcomePagesArray[quizEditorStore.currentOutcomePageIndex].redirect_url)">

                                <div class="form-text" style="float: left; font-size: 11px;">
                                    Users will be redirected to this url instead seeing this outcome page
                                </div>

                            </div>

                        </div>




                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>

                        <button type="button" class="btn btn-success" @click="quizEditorStore.saveDesign()"
                            data-bs-dismiss="modal">Save changes</button>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade" id="deleteAPageModal" tabindex="-1">

            <div class="modal-dialog">

                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Are you sure you want to delete this page?
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div style="text-align: left">

                            Deleting this page also deletes any widgets that was added to it. This action cannot be undone.

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button @click="deleteThisDefaultPage" type="button" class="btn btn-danger">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>



        <div class="modal fade" id="deleteElementModal" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Are you sure you want to delete this element?
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div style="float: left">
                            Be aware that this action can't be reversed
                            <!-- 
                            {{ currentElement }} -->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button @click="deleteThisElement" type="button" class="btn btn-danger">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="quizPreviewOverlay" v-show="showQuizPreviewOverlay">


            <QuizProgressBar
                v-if="quizEditorStore.is_progress_bar_enabled === 1 && quizEditorStore.currentDevicePreview === 'Desktop'" />





            <div class="preview_floatingToolbar">

                <div class="row">

                    <!-- <div class="col" @click="quizEditorStore.prevQuestion">
                        <img :src="require(`@/assets/images/left_arrow.png`)" class="widgetImgIcon" />

                    </div>

                    <div class="col" @click="quizEditorStore.nextQuestion">
                        <img :src="require(`@/assets/images/right_arrow.png`)" class="widgetImgIcon" />

                    </div> -->

                    <div class="col" @click="quizEditorStore.setPreviewDevice('Desktop')">

                        <img :src="require(`@/assets/images/desktop.png`)" class="widgetImgIcon" />

                    </div>

                    <div class="col" @click="quizEditorStore.setPreviewDevice('Mobile')">
                        <img :src="require(`@/assets/images/mobile1.png`)" class="widgetImgIcon" />

                    </div>

                    <div class="col" @click="hidePreviewQuizOverlay">
                        <img :src="require(`@/assets/images/x_close.png`)" class="widgetImgIcon" />
                    </div>


                </div>
            </div>




            <div v-show="quizEditorStore.currentDevicePreview === 'Desktop'" class="p_QuizOuterBG" :style="{
                'background': quizEditorStore.selectedOuterBGtype === 'gradient_bg'
                    ? quizEditorStore.selectedOuterBGgradient
                    : quizEditorStore.selectedOuterBGtype === 'normal_bg'
                        ? quizEditorStore.selectedNormalBGcolor
                        : quizEditorStore.selectedOuterBGtype === 'img_bg'
                            ? `url(${quizEditorStore.selectedBGimageUrl}) no-repeat center/cover`
                            : 'none',

            }">








                <!-- this.outcomeTracker: {{ quizEditorStore.outcomeTracker }} -->



                <!-- defaultPagesArray.length: {{ quizEditorStore.defaultPagesArray.length - 1 }} |  previewPageIndex: {{ quizEditorStore.previewPageIndex }}  -->

                <div class="pagesWrapper">



                    <div v-for="(page, index) in quizEditorStore.defaultPagesArray" :key="index">


                        <div v-if="index === quizEditorStore.previewPageIndex">



                            <div class="p_QuizCard" :style="{
                                'width': quizEditorStore.deviceType === 'Desktop' ? '800px' : '100%',
                                'border-radius': quizEditorStore.deviceType === 'Mobile' ? '0px' : '15px',
                                'background': quizEditorStore.selectedCardBGtype === 'gradient_bg'
                                    ? quizEditorStore.selectedCardBGgradient
                                    : quizEditorStore.selectedCardBGtype === 'normal_bg'
                                        ? quizEditorStore.selectedCardNormalBGcolor
                                        : quizEditorStore.selectedCardBGtype === 'img_bg'
                                            ? `url(${quizEditorStore.selectedCardBGimageUrl}) no-repeat center/cover`
                                            : 'none',

                            }">



                                <div v-if="page.redirect_url != null">

                                    <p>
                                        <b>
                                            This outcome page will redirect to url: {{ page.redirect_url }}
                                        </b>
                                    </p>

                                </div>

                                <div v-else>

                                    <div v-for="(element, index) in page.design_layout" :key="index">

                                        <div v-if="element.type == 'header_text'">


                                            <div :style="{
                                                // 'word-wrap': 'break-word',
                                                // 'width': '100%',
                                                'color': element.font_color,
                                                // 'font-size': element.font_size + 'px',
                                                'font-family': element.font_family,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }" v-html="element.code_block"></div>


                                        </div>

                                        <div v-if="element.type == 'text'">


                                            <div :style="{
                                                // 'word-wrap': 'break-word',
                                                // 'width': '100%',
                                                'color': element.font_color,
                                                // 'font-size': element.font_size + 'px',
                                                'font-family': element.font_family,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }" v-html="element.code_block"></div>


                                        </div>

                                        <div v-if="element.type == 'button'" :style="{
                                            'display': 'flex',
                                            'justify-content': element.alignment,
                                        }">

                                            <div v-if="element.action_type === 'open_url_in_new_tab'">

                                                <button :style="{
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                    'font-weight': element.font_weight,
                                                    'background-color': element.background_color,
                                                    'padding': element.padding + 'px',
                                                    'width': element.width + 'px',
                                                    'border-radius': element.border_radius + 'px',
                                                    'border-color': element.border_color,
                                                    'border-style': element.border_style,
                                                    'border-width': element.border_width + 'px',
                                                    'box-shadow': element.box_shadow,
                                                    'outline': 'none',
                                                    'border-style': 'solid',
                                                }" type="button" @click="quizEditorStore.openURLNewTab(element.url)">
                                                    <span>
                                                        {{ element.text }}
                                                    </span>

                                                </button>

                                            </div>

                                            <div v-if="element.action_type === 'open_url_in_same_tab'">

                                                <button :style="{
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                    'font-weight': element.font_weight,
                                                    'background-color': element.background_color,
                                                    'padding': element.padding + 'px',
                                                    'width': element.width + 'px',
                                                    'border-radius': element.border_radius + 'px',
                                                    'border-color': element.border_color,
                                                    'border-style': element.border_style,
                                                    'border-width': element.border_width + 'px',
                                                    'box-shadow': element.box_shadow,
                                                    'outline': 'none',
                                                    'border-style': 'solid',
                                                }" type="button"
                                                    @click="quizEditorStore.openURLInSameTab(element.url)">
                                                    <span>
                                                        {{ element.text }}
                                                    </span>

                                                </button>

                                            </div>

                                            <div
                                                v-if="element.action_type === 'next_page' || element.action_type === 'restart_quiz'">

                                                <button :style="{
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                    'font-weight': element.font_weight,
                                                    'background-color': element.background_color,
                                                    'padding': element.padding + 'px',
                                                    'width': element.width + 'px',
                                                    'border-radius': element.border_radius + 'px',
                                                    'border-color': element.border_color,
                                                    'border-style': element.border_style,
                                                    'border-width': element.border_width + 'px',
                                                    'box-shadow': element.box_shadow,
                                                    'outline': 'none',
                                                    'border-style': 'solid',
                                                }" type="button" @click="quizEditorStore.validateAndNext(page)">
                                                    <span>
                                                        {{ element.text }}
                                                    </span>

                                                </button>

                                            </div>




                                        </div>

                                        <!-- image_preview_desktop -->
                                        <div v-if="element.type == 'image'">

                                            <div class="imageWidgetWrapper" :style="{
                                                'display': 'flex',
                                                'justify-content': element.alignment,
                                            }">


                                                <img v-if="element.uploaded_img_url === null" class="img-fluid" :style="{
                                                    'max-width': element.width + '%',
                                                    'border-radius': element.border_radius + 'px',
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                }" :src="require(`@/assets/toolbar/placeholder.png`)" />

                                                <img v-else :src="element.uploaded_img_url" class="img-fluid" :style="{
                                                    'max-width': element.width + '%',
                                                    'border-radius': element.border_radius + 'px',
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                }" />


                                            </div>

                                        </div>

                                        <!-- preview_text_here -->
                                        <div class="inputFieldWrapper" v-if="element.type == 'text_input'">


                                            <div class="outerWrapper" :style="{
                                                'justify-content': element.justify_content,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">


                                                <div class="" style="display:flex; flex-direction: column;">


                                                    <label v-if="element.show_label === 1" class="form-label" :style="{
                                                        'color': element.label_font_color,
                                                        'font-size': element.label_font_size + 'px',
                                                        'float': element.label_float,
                                                        'font-weight': 'bold',
                                                        'padding-bottom': '2px',
                                                        'word-wrap': 'break-word',
                                                        'text-align': 'left',
                                                        // 'width': '300px',
                                                    }">{{ element.label }}</label>



                                                    <input type="text" class="form-control"
                                                        v-model="page.design_layout[index].value"
                                                        :placeholder="element.placeholder" :style="{
                                                            'width': element.width + 'px',
                                                            'background-color': element.background_color,
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'padding': element.padding + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'float': element.input_field_float,
                                                        }">

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>




                                                </div>

                                            </div>

                                        </div>

                                        <div class="inputFieldWrapper" v-if="element.type == 'number_input'">

                                            <div class="outerWrapper" :style="{
                                                'justify-content': element.justify_content,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <div class="mycontainer">

                                                    <!-- {{ element.show_error }} -->

                                                    <div class="box">
                                                        <div :style="{
                                                            'color': element.label_font_color,
                                                            'font-size': element.label_font_size + 'px',
                                                            'float': element.label_float,
                                                            'font-weight': 'bold',
                                                            'padding-bottom': '5px',
                                                        }">
                                                            {{ element.label }}
                                                        </div>
                                                    </div>

                                                    <div class="box">
                                                        <input class="form-control theInput" type="number"
                                                            v-model="page.design_layout[index].number_value"
                                                            :placeholder="element.number_value" :style="{
                                                                'width': quizEditorStore.deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                                                'background-color': element.background_color,
                                                                'border-radius': element.border_radius + 'px',
                                                                'border-color': element.border_color,
                                                                'border-style': element.border_style,
                                                                'border-width': element.border_width + 'px',
                                                                'padding': element.padding + 'px',
                                                                'box-shadow': element.box_shadow,
                                                                'float': element.input_field_float,
                                                            }" />


                                                    </div>

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <!-- preview_time_here -->
                                        <div class="inputFieldWrapper" v-if="element.type == 'time_input'">

                                            <div class="outerWrapper" :style="{
                                                'justify-content': element.justify_content,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <div class="mycontainer">


                                                    <div class="box">
                                                        <div :style="{
                                                            'color': element.label_font_color,
                                                            'font-size': element.label_font_size + 'px',
                                                            'float': element.label_float,
                                                            'font-weight': 'bold',
                                                            'padding-bottom': '5px',
                                                        }">
                                                            {{ element.label }}
                                                        </div>
                                                    </div>

                                                    <div class="box">

                                                        <VueDatePicker v-model="page.design_layout[index].value" time-picker
                                                            placeholder="Select Time" :is-24="false" :format="'hh:mm'"
                                                            class="form-control theInput" :style="{
                                                                'width': quizEditorStore.deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                                                'background-color': element.background_color,
                                                                'border-radius': element.border_radius + 'px',
                                                                'border-color': element.border_color,
                                                                'border-style': element.border_style,
                                                                'border-width': element.border_width + 'px',
                                                                'padding': element.padding + 'px',
                                                                'box-shadow': element.box_shadow,
                                                                'float': element.input_field_float,
                                                                'padding': 0 + 'px'
                                                            }"></VueDatePicker>



                                                    </div>

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="inputFieldWrapper" v-if="element.type == 'date_input'">

                                            <div class="outerWrapper" :style="{
                                                'justify-content': element.justify_content,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <div class="mycontainer">


                                                    <div class="box">
                                                        <div :style="{
                                                            'color': element.label_font_color,
                                                            'font-size': element.label_font_size + 'px',
                                                            'float': element.label_float,
                                                            'font-weight': 'bold',
                                                            'padding-bottom': '5px',
                                                        }">
                                                            {{ element.label }}
                                                        </div>
                                                    </div>

                                                    <div class="box">


                                                        <VueDatePicker v-model="page.design_layout[index].value" :style="{
                                                            'width': quizEditorStore.deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                                            'background-color': element.background_color,
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'padding': element.padding + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'float': element.input_field_float,
                                                            'padding': 0 + 'px'
                                                        }" :format="'MM/dd/yyyy'" placeholder="Select Date"
                                                            :enable-time-picker="false">
                                                        </VueDatePicker>

                                                        <!-- <input class="form-control theInput" type="date"
v-model="page.design_layout[index].value" :style="{
    'width': element.width + 'px',
    'background-color': element.background_color,
    'border-radius': element.border_radius + 'px',
    'border-color': element.border_color,
    'border-style': element.border_style,
    'border-width': element.border_width + 'px',
    'padding': element.padding + 'px',
    'box-shadow': element.box_shadow,
    'float': element.input_field_float,
}" /> -->


                                                    </div>

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="inputFieldWrapper" v-if="element.type == 'email_input'">

                                            <div class="outerWrapper" :style="{
                                                'justify-content': element.justify_content,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <div class="" style="display:flex; flex-direction: column;">


                                                    <label v-if="element.show_label === 1" class="form-label" :style="{
                                                        'color': element.label_font_color,
                                                        'font-size': element.label_font_size + 'px',
                                                        'float': element.label_float,
                                                        'font-weight': 'bold',
                                                        'padding-bottom': '2px',
                                                        'word-wrap': 'break-word',
                                                        'text-align': 'left',
                                                        // 'width': '250px',
                                                    }">{{ element.label }}</label>



                                                    <input type="email" class="form-control"
                                                        :placeholder="element.placeholder"
                                                        v-model="page.design_layout[index].value" :style="{
                                                            'width': element.width + 'px',
                                                            'background-color': element.background_color,
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'padding': element.padding + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'float': element.input_field_float,
                                                        }">

                                                    <div class="box" v-if="element.show_error === 1">
                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>
                                                    </div>




                                                </div>


                                            </div>
                                        </div>

                                        <div class="centered-content" v-if="element.type == 'video'" style="padding: 0px">


                                            <div :style="{
                                                'width': element.width + 'px',
                                                'max-width': element.width + 'px',
                                                'margin': element.margin + 'px',
                                            }">
                                                <div :style="{
                                                    'overflow': 'hidden',
                                                    'border-radius': element.border_radius + 'px',
                                                    'box-shadow': element.box_shadow,
                                                }" v-html="element.video_embed_code"></div>
                                            </div>




                                        </div>

                                        <div v-if="element.type == 'sharing'" :style="{
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            'display': 'flex',
                                            'flex-direction': 'column',
                                            'justify-content': 'center',
                                            'align-items': 'center'
                                        }">

                                            <div class="shareTextWrapper" style="font-weight: bold; margin-bottom: 0px"
                                                :style="{
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                }">
                                                {{ element.main_share_text }}
                                            </div>


                                            <div class="sharingWrapper">

                                                <div class="platformItem" v-for="(platform, index) in element.platforms"
                                                    :key="index">


                                                    <a :href="platform.url" target="_blank">

                                                        <img :style="{
                                                            'width': element.img_width + 'px',
                                                            'margin-bottom': '10px',
                                                        }"
                                                            :src="require(`@/assets/toolbar/${platform.img_name}.png`)" />

                                                    </a>




                                                </div>
                                            </div>



                                        </div>

                                        <div v-if="element.type == 'question'">

                                            <div v-if="element.click_action_type === 'single_answer'">

                                                <div v-if="element.answer_type == 'text_only'">

                                                    <div class="questionTextOnlyWrapper" :style="{
                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                    }">

                                                        <div :style="{
                                                            'color': element.question_design.font_color,
                                                            'font-size': element.question_design.font_size + 'px',
                                                            'font-family': element.question_design.font_family,
                                                            'font-weight': element.question_design.font_weight,
                                                            'text-align': element.question_design.text_align,
                                                            'font-weight': element.question_design.font_weight,
                                                            'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                        }">
                                                            {{ element.question_text }}
                                                        </div>

                                                        <div class="d-flex align-items-center" :style="{
                                                            'justify-content': element.question_design.alignment_box,
                                                        }">
                                                            <div class="questionContainer">

                                                                <div class="myBox">


                                                                    <!-- SINGLE TEXT -->
                                                                    <div v-for="(answer, answerIndex) in element.answers"
                                                                        @mouseover="currentAnswerIndex = answerIndex"
                                                                        @mouseleave="currentAnswerIndex = null"
                                                                        @click="quizEditorStore.oneAnswerSelectOnly(answer, element)"
                                                                        :key="answerIndex" class="previewAnswerWrapper"
                                                                        :style="{
                                                                            'color': element.answer_design.font_color,
                                                                            'font-size':
                                                                                element.answer_design.font_size + 'px',
                                                                            'font-family':
                                                                                element.answer_design.font_family,
                                                                            'font-weight':
                                                                                element.answer_design.font_weight,
                                                                            'padding':
                                                                                element.answer_design.padding + 'px',
                                                                            'background-color': answer.is_selected === 1 ?
                                                                                element.answer_design.hover_bg_color : element.answer_design.background_color,

                                                                            'border-radius':
                                                                                element.answer_design.border_radius +
                                                                                'px',
                                                                            'border-color':
                                                                                element.answer_design.border_color,
                                                                            'border-style':
                                                                                element.answer_design.border_style,
                                                                            'border-width':
                                                                                element.answer_design.border_width +
                                                                                'px',
                                                                            'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                        }">


                                                                        <div class="row">
                                                                            <div class="col" :style="{
                                                                                'text-align':
                                                                                    element.answer_design.text_align,
                                                                            }">
                                                                                {{ answer.answer_text }}
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="element.answer_type == 'text_with_image'">

                                                    <div class="questionTextImageWrapper" :style="{
                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                    }">
                                                        <div :style="{
                                                            'color': element.question_design.font_color,
                                                            'font-size':
                                                                element.question_design.font_size + 'px',
                                                            'font-family': element.question_design.font_family,
                                                            'font-weight': element.question_design.font_weight,
                                                            'text-align': element.question_design.text_align,
                                                            'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                        }">
                                                            {{ element.question_text }}
                                                        </div>

                                                        <div class="d-flex align-items-center" :style="{
                                                            'justify-content': element.question_design.alignment_box,
                                                        }">

                                                            <div class="questionTextImageContainer">

                                                                <div class="container1">

                                                                    <!-- SINGLE TEXT WITH IMAGE -->
                                                                    <div v-for="(answer, answerIndex) in element.answers"
                                                                        @mouseover="currentAnswerIndex = answerIndex"
                                                                        @mouseleave="currentAnswerIndex = null"
                                                                        @click="quizEditorStore.oneAnswerSelectOnly(answer, element)"
                                                                        :key="answerIndex">

                                                                        <div class="box1" :style="{
                                                                            'color': element.answer_design.font_color,
                                                                            'font-size':
                                                                                element.answer_design.font_size + 'px',
                                                                            'font-family':
                                                                                element.answer_design.font_family,
                                                                            'font-weight':
                                                                                element.answer_design.font_weight,
                                                                            'background-color':
                                                                                element.answer_design.background_color,
                                                                            'border-radius':
                                                                                element.answer_design.border_radius +
                                                                                'px',
                                                                            'border-color':
                                                                                element.answer_design.border_color,
                                                                            'border-style':
                                                                                element.answer_design.border_style,
                                                                            'border-width':
                                                                                element.answer_design.border_width +
                                                                                'px',
                                                                            'cursor': 'pointer',
                                                                            'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                        }">


                                                                            <div class="imageTop">

                                                                                <img v-if="answer.img_url === null"
                                                                                    :src="require(`@/assets/toolbar/placeholder.png`)" />
                                                                                <img v-else :src="answer.img_url" />
                                                                            </div>

                                                                            <div class="answerBody" :style="{
                                                                                'background-color': answer.is_selected === 1 ?
                                                                                    element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                                'font-weight':
                                                                                    element.answer_design.font_weight,
                                                                                'font-size':
                                                                                    element.answer_design.font_size +
                                                                                    'px',
                                                                            }">
                                                                                {{ answer.answer_text }}
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div v-if="element.click_action_type === 'multiple_answers'">

                                                <div v-if="element.answer_type == 'text_only'">

                                                    <div class="questionTextOnlyWrapper" :style="{
                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                    }">

                                                        <div :style="{
                                                            'color': element.question_design.font_color,
                                                            'font-size': element.question_design.font_size + 'px',
                                                            'font-family': element.question_design.font_family,
                                                            'font-weight': element.question_design.font_weight,
                                                            'text-align': element.question_design.text_align,
                                                            'font-weight': element.question_design.font_weight,
                                                            'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                        }">
                                                            {{ element.question_text }}
                                                        </div>

                                                        <div class="d-flex align-items-center" :style="{
                                                            'justify-content': element.question_design.alignment_box,
                                                        }">
                                                            <div class="questionContainer">

                                                                <div class="myBox">



                                                                    <div v-for="(answer, answerIndex) in element.answers"
                                                                        @click="quizEditorStore.toggleSelectedAnswer(element, answer)"
                                                                        @mouseover="currentAnswerIndex = answerIndex"
                                                                        @mouseleave="currentAnswerIndex = null"
                                                                        :key="answerIndex" class="previewAnswerWrapper"
                                                                        :style="{
                                                                            'color': element.answer_design.font_color,
                                                                            'font-size':
                                                                                element.answer_design.font_size + 'px',
                                                                            'font-family':
                                                                                element.answer_design.font_family,
                                                                            'font-weight':
                                                                                element.answer_design.font_weight,
                                                                            'padding':
                                                                                element.answer_design.padding + 'px',
                                                                            'background-color': answer.is_selected === 1 ?
                                                                                element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                            'border-radius':
                                                                                element.answer_design.border_radius +
                                                                                'px',
                                                                            'border-color':
                                                                                element.answer_design.border_color,
                                                                            'border-style':
                                                                                element.answer_design.border_style,
                                                                            'border-width':
                                                                                element.answer_design.border_width +
                                                                                'px',
                                                                            'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                        }">


                                                                        <div class="row">
                                                                            <div class="col" :style="{
                                                                                'text-align':
                                                                                    element.answer_design.text_align,
                                                                            }">
                                                                                {{ answer.answer_text }}

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div v-if="element.answer_type == 'text_with_image'">

                                                    <div class="questionTextImageWrapper" :style="{
                                                        'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                    }">
                                                        <div :style="{
                                                            'color': element.question_design.font_color,
                                                            'font-size':
                                                                element.question_design.font_size + 'px',
                                                            'font-family': element.question_design.font_family,
                                                            'font-weight': element.question_design.font_weight,
                                                            'text-align': element.question_design.text_align,
                                                            'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                        }">
                                                            {{ element.question_text }}
                                                        </div>

                                                        <div class="d-flex align-items-center" :style="{
                                                            'justify-content': element.question_design.alignment_box,
                                                        }">

                                                            <div class="questionTextImageContainer">

                                                                <div class="container1">


                                                                    <div v-for="(answer, answerIndex) in element.answers"
                                                                        :key="answerIndex">

                                                                        <div class="box1"
                                                                            @click="quizEditorStore.toggleSelectedAnswer(element, answer)"
                                                                            :style="{
                                                                                color: element.answer_design.font_color,
                                                                                'font-size':
                                                                                    element.answer_design.font_size + 'px',
                                                                                'font-family':
                                                                                    element.answer_design.font_family,
                                                                                'font-weight':
                                                                                    element.answer_design.font_weight,
                                                                                // 'background-color':
                                                                                //     element.answer_design.background_color,
                                                                                'border-radius':
                                                                                    element.answer_design.border_radius +
                                                                                    'px',
                                                                                'border-color':
                                                                                    element.answer_design.border_color,
                                                                                'border-style':
                                                                                    element.answer_design.border_style,
                                                                                'border-width':
                                                                                    element.answer_design.border_width +
                                                                                    'px',
                                                                                margin: `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                            }">


                                                                            <div class="imageTop">

                                                                                <img v-if="answer.img_url === null"
                                                                                    :src="require(`@/assets/toolbar/placeholder.png`)" />
                                                                                <img v-else :src="answer.img_url" />

                                                                            </div>

                                                                            <div class="answerBody" :style="{
                                                                                'background-color': answer.is_selected === 1 ?
                                                                                    element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                                'font-weight':
                                                                                    element.answer_design.font_weight,
                                                                                'font-size':
                                                                                    element.answer_design.font_size +
                                                                                    'px',
                                                                            }">
                                                                                {{ answer.answer_text }}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>




                                                        </div>
                                                    </div>
                                                </div>

                                            </div>





                                        </div>

                                        <div class="textareaWrapper" v-if="element.type == 'textbox'">

                                            <!-- {{ element.show_error }} -->


                                            <div class="textboxOuterWrapper" :style="{
                                                'justify-content': element.justify_content,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <div class="textboxContainer" :style="{
                                                    'width': quizEditorStore.deviceType === 'Desktop' ? '' : '100%',
                                                }">

                                                    <div v-if="element.show_label === 1" class="theBox">

                                                        <div :style="{
                                                            'color': element.label_font_color,
                                                            'font-size': element.label_font_size + 'px',
                                                            'float': element.label_float,
                                                            'font-weight': 'bold',
                                                            'padding-bottom': '5px',
                                                            'width': '100%',
                                                            'word-wrap': 'break-word',
                                                            'text-align': 'left',
                                                        }">
                                                            {{ element.label }}
                                                        </div>

                                                    </div>

                                                    <div class="theBox">

                                                        <!-- this is preview one -->
                                                        <textarea v-model="page.design_layout[index].value"
                                                            class="form-control" :style="{

                                                                'width': quizEditorStore.deviceType === 'Desktop' ? element.width + 'px' : '100%',
                                                                'background-color': element.background_color,
                                                                'border-radius': element.border_radius + 'px',
                                                                'border-color': element.border_color,
                                                                'border-style': element.border_style,
                                                                'border-width': element.border_width + 'px',
                                                                'padding': element.padding + 'px',
                                                                'box-shadow': element.box_shadow,
                                                                '::placeholder': 'color: red'
                                                            }" rows="3" :placeholder="element.placeholder"></textarea>


                                                    </div>

                                                    <div class="theBox" v-if="element.show_error === 1">

                                                        <span
                                                            style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                            Please fill out this required field
                                                        </span>

                                                    </div>

                                                </div>


                                            </div>




                                        </div>

                                        <!-- Preview checkbox desktop -->
                                        <div class="parent-container" :style="{
                                            'justify-content': element.justify_content,
                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                        }" v-if="element.type == 'checkbox'">


                                            <div class="checkboxinputFieldWrapper" :style="{
                                                'max-width': '500px'
                                            }">

                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        @click="page.design_layout[index].value = !page.design_layout[index].value"
                                                        v-model="page.design_layout[index].value" id="flexCheckDefault">
                                                    <label class="form-check-label" for="flexCheckDefault"
                                                        style="text-align: left;">
                                                        {{ element.text_body }}
                                                    </label>
                                                </div>

                                                <div v-if="element.show_error === 1">

                                                    <span
                                                        style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                        This checkbox is required
                                                    </span>

                                                </div>




                                            </div>

                                        </div>



                                        <div v-if="element.type == 'score'">



                                            <div class="scoreOuterWrapper" :style="{
                                                'justify-content': element.alignment,
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">


                                                <div class="scoreContainer" :style="{
                                                    'color': element.font_color,
                                                    'font-size': element.font_size + 'px',
                                                    'font-family': element.font_family,
                                                    'font-weight': element.font_weight,
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                }">


                                                    {{ element.text_label }}: {{ element.correct_amt }} / {{
                                                        element.total_questions_amt }}

                                                </div>

                                            </div>

                                        </div>

                                        <div v-if="element.type == 'embed'">



                                            <div v-if="element.has_code === 'no'">

                                                <img width="50" :src="require(`@/assets/images/code.png`)" />

                                            </div>


                                            <div v-if="element.has_code === 'yes'" class="embedCodeWrapper" :style="{
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <div v-html="element.code_block" :style="{
                                                    'width': element.width + '%',
                                                    'height': element.height + 'px',
                                                }"></div>

                                            </div>






                                        </div>

                                        <div v-if="element.type == 'calendar_embed'">

                                            <div v-if="element.has_code === 'no'">

                                                <img width="40" :src="require(`@/assets/images/calendar_black.png`)" />

                                            </div>


                                            <div v-if="element.has_code === 'yes'" class="" :style="{
                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                            }">

                                                <iframe :width="`${element.width}%`" :height="`${element.height}px`"
                                                    :src="element.calender_url" frameborder="0" :style="{
                                                        'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                        'border-radius': '5px',
                                                    }"></iframe>


                                            </div>



                                        </div>


                                    </div>

                                </div>





                            </div>




                        </div>



                    </div>





                </div>



                <a href="https://convertlyio.com" target="_blank" style="color: inherit; text-decoration: none;">
                    <div class="poweredByBrand">
                        <div>
                            <img width="20" style="margin-right: 10px;" :src="require(`@/assets/images/logo_icon.png`)" />
                        </div>
                        <div style="font-size: 14px;">
                            Powered by <b>Convertlyio</b>
                        </div>
                    </div>
                </a>




            </div>

            <div v-show="quizEditorStore.currentDevicePreview === 'Mobile'">





                <div class="progressBarStrip">
                    <MobileQuizProgressBar
                        v-if="quizEditorStore.is_progress_bar_enabled === 1 && quizEditorStore.currentDevicePreview === 'Mobile'" />
                </div>


                <div class="centerWrapper">

                    <img :src="require(`@/assets/images/phone_frame3.png`)" class="phoneFrame" />

                    <div class="phoneFrameContent" :style="{
                        'background': quizEditorStore.selectedOuterBGtype === 'gradient_bg'
                            ? quizEditorStore.selectedOuterBGgradient
                            : quizEditorStore.selectedOuterBGtype === 'normal_bg'
                                ? quizEditorStore.selectedNormalBGcolor
                                : quizEditorStore.selectedOuterBGtype === 'img_bg'
                                    ? `url(${quizEditorStore.selectedBGimageUrl}) no-repeat center/cover`
                                    : 'none',

                    }">



                        <div class="pagesWrapper">





                            <div v-for="(page, index) in quizEditorStore.defaultPagesArray" :key="index">

                                <div v-if="index === quizEditorStore.previewPageIndex">

                                    <div class="p_QuizCardMobile" :style="{

                                        'background': quizEditorStore.selectedCardBGtype === 'gradient_bg'
                                            ? quizEditorStore.selectedCardBGgradient
                                            : quizEditorStore.selectedCardBGtype === 'normal_bg'
                                                ? quizEditorStore.selectedCardNormalBGcolor
                                                : quizEditorStore.selectedCardBGtype === 'img_bg'
                                                    ? `url(${quizEditorStore.selectedCardBGimageUrl}) no-repeat center/cover`
                                                    : 'none',

                                    }">


                                        <div v-if="page.redirect_url != null" style="width: 200px; word-wrap: break-word;">


                                            <p>
                                                <b>
                                                    This outcome page will redirect to url: {{ page.redirect_url }}
                                                </b>
                                            </p>

                                        </div>


                                        <div v-else>

                                            <div v-for="(element, index) in page.design_layout" :key="index">

                                                <div v-if="element.type == 'header_text'">


                                                    <div :style="{

                                                        'color': element.font_color,
                                                        'font-size': element.font_size + 'px',
                                                        'font-family': element.font_family,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }" v-html="element.code_block"></div>


                                                </div>

                                                <div v-if="element.type == 'text'">


                                                    <div :style="{
                                                        'width': '100%',
                                                        'color': element.font_color,
                                                        'font-size': element.font_size + 'px',
                                                        'font-family': element.font_family,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }" v-html="element.code_block"></div>


                                                </div>

                                                <div v-if="element.type == 'button'" :style="{
                                                    'display': 'flex',
                                                    'justify-content': element.alignment,
                                                }">

                                                    <div v-if="element.action_type === 'open_url_in_new_tab'">

                                                        <button :style="{
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            'color': element.font_color,
                                                            'font-size': element.font_size + 'px',
                                                            'font-family': element.font_family,
                                                            'font-weight': element.font_weight,
                                                            'font-weight': element.font_weight,
                                                            'background-color': element.background_color,
                                                            'padding': element.padding + 'px',
                                                            'width': element.width + 'px',
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'outline': 'none',
                                                            'border-style': 'solid',
                                                        }" type="button"
                                                            @click="quizEditorStore.openURLNewTab(element.url)">
                                                            <span>
                                                                {{ element.text }}
                                                            </span>

                                                        </button>

                                                    </div>

                                                    <div v-if="element.action_type === 'open_url_in_same_tab'">

                                                        <button :style="{
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            'color': element.font_color,
                                                            'font-size': element.font_size + 'px',
                                                            'font-family': element.font_family,
                                                            'font-weight': element.font_weight,
                                                            'font-weight': element.font_weight,
                                                            'background-color': element.background_color,
                                                            'padding': element.padding + 'px',
                                                            'width': element.width + 'px',
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'outline': 'none',
                                                            'border-style': 'solid',
                                                        }" type="button"
                                                            @click="quizEditorStore.openURLInSameTab(element.url)">
                                                            <span>
                                                                {{ element.text }}
                                                            </span>

                                                        </button>

                                                    </div>

                                                    <div
                                                        v-if="element.action_type === 'next_page' || element.action_type === 'restart_quiz'">

                                                        <button :style="{
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                            'color': element.font_color,
                                                            'font-size': element.font_size + 'px',
                                                            'font-family': element.font_family,
                                                            'font-weight': element.font_weight,
                                                            'font-weight': element.font_weight,
                                                            'background-color': element.background_color,
                                                            'padding': element.padding + 'px',
                                                            'width': element.width + 'px',
                                                            'border-radius': element.border_radius + 'px',
                                                            'border-color': element.border_color,
                                                            'border-style': element.border_style,
                                                            'border-width': element.border_width + 'px',
                                                            'box-shadow': element.box_shadow,
                                                            'outline': 'none',
                                                            'border-style': 'solid',
                                                        }" type="button"
                                                            @click="quizEditorStore.validateAndNext(page)">
                                                            <span>
                                                                {{ element.text }}
                                                            </span>

                                                        </button>

                                                    </div>




                                                </div>


                                                <!-- image_preview_mobile -->
                                                <div v-if="element.type == 'image'">

                                                    <div class="imageWidgetWrapper" :style="{
                                                        'display': 'flex',
                                                        'justify-content': element.alignment,
                                                        'width': '100%',
                                                    }">


                                                        <img v-if="element.uploaded_img_url === null" class="img-fluid"
                                                            :style="{
                                                                'width': '100%',
                                                                'border-radius': element.border_radius + 'px',
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                            }" :src="require(`@/assets/toolbar/placeholder.png`)" />

                                                        <img v-else :src="element.uploaded_img_url" class="img-fluid"
                                                            :style="{
                                                                'width': '100%',
                                                                'border-radius': element.border_radius + 'px',
                                                                'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                                'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                            }" />





                                                    </div>

                                                </div>

                                                <!-- mobile_text_input -->
                                                <div class="inputFieldWrapper" v-if="element.type == 'text_input'">

                                                    <div class="outerWrapper" :style="{
                                                        'justify-content': element.justify_content,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">


                                                        <div class="" style="display:flex; flex-direction: column;">


                                                            <label v-if="element.show_label === 1" class="form-label"
                                                                :style="{
                                                                    'color': element.label_font_color,
                                                                    'font-size': element.label_font_size + 'px',
                                                                    'float': element.label_float,
                                                                    'font-weight': 'bold',
                                                                    'padding-bottom': '2px',
                                                                    'word-wrap': 'break-word',
                                                                    'text-align': 'left',
                                                                    'width': '250px',
                                                                }">{{ element.label }}</label>



                                                            <input type="text" class="form-control"
                                                                v-model="page.design_layout[index].value"
                                                                :placeholder="element.placeholder" :style="{
                                                                    'width': '100%',
                                                                    'background-color': element.background_color,
                                                                    'border-radius': element.border_radius + 'px',
                                                                    'border-color': element.border_color,
                                                                    'border-style': element.border_style,
                                                                    'border-width': element.border_width + 'px',
                                                                    'padding': element.padding + 'px',
                                                                    'box-shadow': element.box_shadow,
                                                                    'float': element.input_field_float,
                                                                }">

                                                            <div class="box" v-if="element.show_error === 1">
                                                                <span
                                                                    style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                    Please fill out this required field
                                                                </span>
                                                            </div>




                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="inputFieldWrapper" v-if="element.type == 'number_input'">

                                                    <div class="outerWrapper" :style="{
                                                        'justify-content': element.justify_content,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">

                                                        <div class="mycontainer">


                                                            <div class="box">
                                                                <div :style="{
                                                                    'color': element.label_font_color,
                                                                    'font-size': element.label_font_size + 'px',
                                                                    'float': element.label_float,
                                                                    'font-weight': 'bold',
                                                                    'padding-bottom': '5px',
                                                                }">
                                                                    {{ element.label }}
                                                                </div>
                                                            </div>

                                                            <div class="box">
                                                                <input class="form-control theInput" type="number"
                                                                    v-model="page.design_layout[index].number_value"
                                                                    :placeholder="element.number_value" :style="{
                                                                        'width': '100%',
                                                                        'background-color': element.background_color,
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'border-color': element.border_color,
                                                                        'border-style': element.border_style,
                                                                        'border-width': element.border_width + 'px',
                                                                        'padding': element.padding + 'px',
                                                                        'box-shadow': element.box_shadow,
                                                                        'float': element.input_field_float,
                                                                    }" />


                                                            </div>

                                                            <div class="box" v-if="element.show_error === 1">
                                                                <span
                                                                    style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                    Please fill out this required field
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="inputFieldWrapper" v-if="element.type == 'time_input'">

                                                    <div class="outerWrapper" :style="{
                                                        'justify-content': element.justify_content,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">

                                                        <div class="mycontainer">


                                                            <div class="box">
                                                                <div :style="{
                                                                    'color': element.label_font_color,
                                                                    'font-size': element.label_font_size + 'px',
                                                                    'float': element.label_float,
                                                                    'font-weight': 'bold',
                                                                    'padding-bottom': '5px',
                                                                }">
                                                                    {{ element.label }}
                                                                </div>
                                                            </div>

                                                            <div class="box">

                                                                <VueDatePicker v-model="page.design_layout[index].value"
                                                                    time-picker placeholder="Select Time" :is-24="false"
                                                                    :format="'hh:mm'" class="form-control theInput" :style="{
                                                                        'width': '100%',
                                                                        'background-color': element.background_color,
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'border-color': element.border_color,
                                                                        'border-style': element.border_style,
                                                                        'border-width': element.border_width + 'px',
                                                                        'padding': element.padding + 'px',
                                                                        'box-shadow': element.box_shadow,
                                                                        'float': element.input_field_float,
                                                                        'padding': 0 + 'px'
                                                                    }"></VueDatePicker>




                                                            </div>

                                                            <div class="box" v-if="element.show_error === 1">
                                                                <span
                                                                    style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                    Please fill out this required field
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="inputFieldWrapper" v-if="element.type == 'date_input'">

                                                    <div class="outerWrapper" :style="{
                                                        'justify-content': element.justify_content,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">

                                                        <div class="mycontainer">


                                                            <div class="box">
                                                                <div :style="{
                                                                    'color': element.label_font_color,
                                                                    'font-size': element.label_font_size + 'px',
                                                                    'float': element.label_float,
                                                                    'font-weight': 'bold',
                                                                    'padding-bottom': '5px',
                                                                }">
                                                                    {{ element.label }}
                                                                </div>
                                                            </div>

                                                            <div class="box">


                                                                <VueDatePicker v-model="page.design_layout[index].value"
                                                                    :style="{
                                                                        'width': '100%',
                                                                        'background-color': element.background_color,
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'border-color': element.border_color,
                                                                        'border-style': element.border_style,
                                                                        'border-width': element.border_width + 'px',
                                                                        'padding': element.padding + 'px',
                                                                        'box-shadow': element.box_shadow,
                                                                        'float': element.input_field_float,
                                                                        'padding': 0 + 'px'
                                                                    }" :format="'MM/dd/yyyy'" placeholder="Select Date"
                                                                    :enable-time-picker="false">
                                                                </VueDatePicker>




                                                            </div>

                                                            <div class="box" v-if="element.show_error === 1">
                                                                <span
                                                                    style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                    Please fill out this required field
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="inputFieldWrapper" v-if="element.type == 'email_input'">

                                                    <div class="outerWrapper" :style="{
                                                        'justify-content': element.justify_content,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">

                                                        <div class="" style="display:flex; flex-direction: column;">


                                                            <label v-if="element.show_label === 1" class="form-label"
                                                                :style="{
                                                                    'color': element.label_font_color,
                                                                    'font-size': element.label_font_size + 'px',
                                                                    'float': element.label_float,
                                                                    'font-weight': 'bold',
                                                                    'padding-bottom': '2px',
                                                                    'word-wrap': 'break-word',
                                                                    'text-align': 'left',
                                                                    'width': '250px',
                                                                }">{{ element.label }}</label>



                                                            <input type="email" class="form-control"
                                                                :placeholder="element.placeholder"
                                                                v-model="page.design_layout[index].value" :style="{
                                                                    'width': '100%',
                                                                    'background-color': element.background_color,
                                                                    'border-radius': element.border_radius + 'px',
                                                                    'border-color': element.border_color,
                                                                    'border-style': element.border_style,
                                                                    'border-width': element.border_width + 'px',
                                                                    'padding': element.padding + 'px',
                                                                    'box-shadow': element.box_shadow,
                                                                    'float': element.input_field_float,
                                                                }">

                                                            <div class="box" v-if="element.show_error === 1">
                                                                <span
                                                                    style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                    Please fill out this required field
                                                                </span>
                                                            </div>




                                                        </div>

                                                    </div>
                                                </div>


                                                <!-- MOBILE VIDEO ONE -->
                                                <div v-if="element.type == 'video'" style="background-color: ;">



                                                    <div :style="{
                                                        'width': '100%',
                                                        'overflow': 'hidden',
                                                        'border-radius': element.border_radius + 'px',
                                                        'box-shadow': element.box_shadow,
                                                    }" v-html="element.video_embed_code"></div>



                                                </div>

                                                <div v-if="element.type == 'sharing'" :style="{
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    'display': 'flex',
                                                    'flex-direction': 'column',
                                                    'justify-content': 'center',
                                                    'align-items': 'center'
                                                }">

                                                    <div class="shareTextWrapper"
                                                        style="font-weight: bold; margin-bottom: 0px" :style="{
                                                            'color': element.font_color,
                                                            'font-size': element.font_size + 'px',
                                                            'font-family': element.font_family,
                                                            'font-weight': element.font_weight,
                                                        }">
                                                        {{ element.main_share_text }}
                                                    </div>


                                                    <div class="sharingWrapper">

                                                        <div class="platformItem"
                                                            v-for="(platform, index) in element.platforms" :key="index">

                                                            <img :style="{
                                                                'width': element.img_width + 'px',
                                                                'margin-bottom': '10px',
                                                            }" :src="require(`@/assets/toolbar/${platform.img_name}.png`)
    " />
                                                        </div>
                                                    </div>



                                                </div>

                                                <div v-if="element.type == 'question'">

                                                    <div v-if="element.click_action_type === 'single_answer'">

                                                        <div v-if="element.answer_type == 'text_only'">

                                                            <div class="questionTextOnlyWrapper" :style="{
                                                                'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                            }">

                                                                <div :style="{
                                                                    'color': element.question_design.font_color,
                                                                    'font-size': element.question_design.font_size + 'px',
                                                                    'font-family': element.question_design.font_family,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'text-align': element.question_design.text_align,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                }">
                                                                    {{ element.question_text }}
                                                                </div>

                                                                <div class="d-flex align-items-center" :style="{
                                                                    'justify-content': element.question_design.alignment_box,
                                                                }">
                                                                    <div class="questionContainer">

                                                                        <div class="myBox">



                                                                            <div v-for="(answer, answerIndex) in element.answers"
                                                                                @mouseover="currentAnswerIndex = answerIndex"
                                                                                @mouseleave="currentAnswerIndex = null"
                                                                                @click="quizEditorStore.oneAnswerSelectOnly(answer, element)"
                                                                                :key="answerIndex"
                                                                                class="previewAnswerWrapper" :style="{
                                                                                    'color': element.answer_design.font_color,
                                                                                    'font-size':
                                                                                        element.answer_design.font_size + 'px',
                                                                                    'font-family':
                                                                                        element.answer_design.font_family,
                                                                                    'font-weight':
                                                                                        element.answer_design.font_weight,
                                                                                    'padding':
                                                                                        element.answer_design.padding + 'px',
                                                                                    'background-color': answer.is_selected === 1 ?
                                                                                        element.answer_design.hover_bg_color : element.answer_design.background_color,

                                                                                    'border-radius':
                                                                                        element.answer_design.border_radius +
                                                                                        'px',
                                                                                    'border-color':
                                                                                        element.answer_design.border_color,
                                                                                    'border-style':
                                                                                        element.answer_design.border_style,
                                                                                    'border-width':
                                                                                        element.answer_design.border_width +
                                                                                        'px',
                                                                                    'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                                }">


                                                                                <div class="row">
                                                                                    <div class="col" :style="{
                                                                                        'text-align':
                                                                                            element.answer_design.text_align,
                                                                                    }">
                                                                                        {{ answer.answer_text }}
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="element.answer_type == 'text_with_image'">

                                                            <div class="questionTextImageWrapper" :style="{
                                                                'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                            }">
                                                                <div :style="{
                                                                    'color': element.question_design.font_color,
                                                                    'font-size':
                                                                        element.question_design.font_size + 'px',
                                                                    'font-family': element.question_design.font_family,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'text-align': element.question_design.text_align,
                                                                    'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                }">
                                                                    {{ element.question_text }}
                                                                </div>

                                                                <div class="d-flex align-items-center" :style="{
                                                                    'justify-content': element.question_design.alignment_box,
                                                                }">

                                                                    <div class="questionTextImageContainer">

                                                                        <div class="container1">


                                                                            <div v-for="(answer, answerIndex) in element.answers"
                                                                                :key="answerIndex"
                                                                                @mouseover="currentAnswerIndex = answerIndex"
                                                                                @mouseleave="currentAnswerIndex = null"
                                                                                @click="quizEditorStore.oneAnswerSelectOnly(answer, element)">
                                                                                <div class="box1" :style="{
                                                                                    color: element.answer_design.font_color,
                                                                                    'font-size':
                                                                                        element.answer_design.font_size + 'px',
                                                                                    'font-family':
                                                                                        element.answer_design.font_family,
                                                                                    'font-weight':
                                                                                        element.answer_design.font_weight,
                                                                                    'background-color':
                                                                                        element.answer_design.background_color,
                                                                                    'border-radius':
                                                                                        element.answer_design.border_radius +
                                                                                        'px',
                                                                                    'border-color':
                                                                                        element.answer_design.border_color,
                                                                                    'border-style':
                                                                                        element.answer_design.border_style,
                                                                                    'border-width':
                                                                                        element.answer_design.border_width +
                                                                                        'px',
                                                                                    margin: `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                                }">


                                                                                    <div class="imageTop">
                                                                                        <img v-if="answer.img_url === null"
                                                                                            :src="require(`@/assets/toolbar/placeholder.png`)" />
                                                                                        <img v-else :src="answer.img_url" />
                                                                                    </div>

                                                                                    <div class="answerBody" :style="{
                                                                                        'background-color': answer.is_selected === 1 ?
                                                                                            element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                                        'font-weight':
                                                                                            element.answer_design.font_weight,
                                                                                        'font-size':
                                                                                            element.answer_design.font_size +
                                                                                            'px',
                                                                                    }">
                                                                                        {{ answer.answer_text }}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div v-if="element.click_action_type === 'multiple_answers'">

                                                        <div v-if="element.answer_type == 'text_only'">

                                                            <div class="questionTextOnlyWrapper" :style="{
                                                                'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                            }">

                                                                <div :style="{
                                                                    'color': element.question_design.font_color,
                                                                    'font-size': element.question_design.font_size + 'px',
                                                                    'font-family': element.question_design.font_family,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'text-align': element.question_design.text_align,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                }">
                                                                    {{ element.question_text }}
                                                                </div>

                                                                <div class="d-flex align-items-center" :style="{
                                                                    'justify-content': element.question_design.alignment_box,
                                                                }">
                                                                    <div class="questionContainer">

                                                                        <div class="myBox">

                                                                            <!-- min: {{ element.min_answers_amt }}
                | max allowed: {{ element.max_answers_amt }} -->

                                                                            <div v-for="(answer, answerIndex) in element.answers"
                                                                                @click="quizEditorStore.toggleSelectedAnswer(element, answer)"
                                                                                @mouseover="currentAnswerIndex = answerIndex"
                                                                                @mouseleave="currentAnswerIndex = null"
                                                                                :key="answerIndex"
                                                                                class="previewAnswerWrapper" :style="{
                                                                                    'color': element.answer_design.font_color,
                                                                                    'font-size':
                                                                                        element.answer_design.font_size + 'px',
                                                                                    'font-family':
                                                                                        element.answer_design.font_family,
                                                                                    'font-weight':
                                                                                        element.answer_design.font_weight,
                                                                                    'padding':
                                                                                        element.answer_design.padding + 'px',
                                                                                    'background-color': answer.is_selected === 1 ?
                                                                                        element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                                    'border-radius':
                                                                                        element.answer_design.border_radius +
                                                                                        'px',
                                                                                    'border-color':
                                                                                        element.answer_design.border_color,
                                                                                    'border-style':
                                                                                        element.answer_design.border_style,
                                                                                    'border-width':
                                                                                        element.answer_design.border_width +
                                                                                        'px',
                                                                                    'margin': `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                                }">


                                                                                <div class="row">
                                                                                    <div class="col" :style="{
                                                                                        'text-align':
                                                                                            element.answer_design.text_align,
                                                                                    }">
                                                                                        {{ answer.answer_text }}
                                                                                        <!-- - {{answer.is_selected }} -->
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="element.answer_type == 'text_with_image'">

                                                            <div class="questionTextImageWrapper" :style="{
                                                                'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                            }">
                                                                <div :style="{
                                                                    'color': element.question_design.font_color,
                                                                    'font-size':
                                                                        element.question_design.font_size + 'px',
                                                                    'font-family': element.question_design.font_family,
                                                                    'font-weight': element.question_design.font_weight,
                                                                    'text-align': element.question_design.text_align,
                                                                    'margin': `${element.question_design.margin}px 0px ${element.question_design.margin}px 0px`,
                                                                }">
                                                                    {{ element.question_text }}
                                                                </div>

                                                                <div class="d-flex align-items-center" :style="{
                                                                    'justify-content': element.question_design.alignment_box,
                                                                }">

                                                                    <div class="questionTextImageContainer">

                                                                        <div class="container1">


                                                                            <div v-for="(answer, answerIndex) in element.answers"
                                                                                :key="answerIndex">
                                                                                <div class="box1"
                                                                                    @click="quizEditorStore.toggleSelectedAnswer(element, answer)"
                                                                                    :style="{
                                                                                        color: element.answer_design.font_color,
                                                                                        'font-size':
                                                                                            element.answer_design.font_size + 'px',
                                                                                        'font-family':
                                                                                            element.answer_design.font_family,
                                                                                        'font-weight':
                                                                                            element.answer_design.font_weight,
                                                                                        // 'background-color':
                                                                                        //     element.answer_design.background_color,
                                                                                        'border-radius':
                                                                                            element.answer_design.border_radius +
                                                                                            'px',
                                                                                        'border-color':
                                                                                            element.answer_design.border_color,
                                                                                        'border-style':
                                                                                            element.answer_design.border_style,
                                                                                        'border-width':
                                                                                            element.answer_design.border_width +
                                                                                            'px',
                                                                                        margin: `${element.answer_design.margin}px 0px ${element.answer_design.margin}px 0px`,
                                                                                    }">


                                                                                    <div class="imageTop">
                                                                                        <img v-if="answer.img_url === null"
                                                                                            :src="require(`@/assets/toolbar/placeholder.png`)" />
                                                                                        <img v-else :src="answer.img_url" />
                                                                                    </div>

                                                                                    <div class="answerBody" :style="{
                                                                                        'background-color': answer.is_selected === 1 ?
                                                                                            element.answer_design.hover_bg_color : element.answer_design.background_color,
                                                                                        'font-weight':
                                                                                            element.answer_design.font_weight,
                                                                                        'font-size':
                                                                                            element.answer_design.font_size +
                                                                                            'px',
                                                                                    }">
                                                                                        {{ answer.answer_text }}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>





                                                </div>

                                                <div class="textareaWrapper" v-if="element.type == 'textbox'">



                                                    <div class="textboxOuterWrapper" :style="{
                                                        'justify-content': element.justify_content,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">

                                                        <div class="textboxContainer" :style="{
                                                            'width': '100%',
                                                        }">

                                                            <div :style="{
                                                                'color': element.label_font_color,
                                                                'font-size': element.label_font_size + 'px',
                                                                'float': element.label_float,
                                                                'font-weight': 'bold',
                                                                'padding-bottom': '5px',
                                                                'width': '100%',
                                                                'word-wrap': 'break-word',
                                                                'text-align': 'left',
                                                            }">
                                                                {{ element.label }}
                                                            </div>

                                                            <div class="theBox">

                                                                <textarea v-model="page.design_layout[index].value"
                                                                    class="form-control" :style="{

                                                                        'background-color': element.background_color,
                                                                        'border-radius': element.border_radius + 'px',
                                                                        'border-color': element.border_color,
                                                                        'border-style': element.border_style,
                                                                        'border-width': element.border_width + 'px',
                                                                        'padding': element.padding + 'px',
                                                                        'box-shadow': element.box_shadow,
                                                                        '::placeholder': 'color: red'
                                                                    }" rows="3"
                                                                    :placeholder="element.placeholder"></textarea>


                                                            </div>

                                                            <div class="theBox" v-if="element.show_error === 1">

                                                                <span
                                                                    style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                    Please fill out this required field
                                                                </span>

                                                            </div>

                                                        </div>


                                                    </div>




                                                </div>

                                                <!-- Preview checkbox mobile -->
                                                <div class="parent-container" :style="{
                                                    'justify-content': element.justify_content,
                                                    'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                }" v-if="element.type == 'checkbox'">


                                                    <div class="checkboxinputFieldWrapper" :style="{
                                                        'max-width': '500px'
                                                    }">

                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                @click="page.design_layout[index].value = !page.design_layout[index].value"
                                                                v-model="page.design_layout[index].value"
                                                                id="flexCheckDefault">
                                                            <label class="form-check-label" for="flexCheckDefault"
                                                                style="text-align: left;">
                                                                {{ element.text_body }}
                                                            </label>
                                                        </div>

                                                        <div v-if="element.show_error === 1">

                                                            <span
                                                                style="color: red; font-size: 13px; float: left; margin-top: 2.5px;">
                                                                This checkbox is required
                                                            </span>

                                                        </div>




                                                    </div>

                                                </div>


                                                <div v-if="element.type == 'score'">



                                                    <div class="scoreOuterWrapper" :style="{
                                                        'justify-content': element.alignment,
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">


                                                        <div class="scoreContainer" :style="{
                                                            'color': element.font_color,
                                                            'font-size': element.font_size + 'px',
                                                            'font-family': element.font_family,
                                                            'font-weight': element.font_weight,
                                                            'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                        }">


                                                            {{ element.text_label }}: {{ element.correct_amt }} / {{
                                                                element.total_questions_amt }}

                                                        </div>

                                                    </div>

                                                </div>

                                                <div v-if="element.type == 'embed'">



                                                    <div v-if="element.has_code === 'no'">

                                                        <img width="50" :src="require(`@/assets/images/code.png`)" />

                                                    </div>


                                                    <div v-if="element.has_code === 'yes'" class="embedCodeWrapper" :style="{
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">

                                                        <div v-html="element.code_block" :style="{
                                                            'width': '100%',
                                                            // 'width': element.width + '%',
                                                            'height': element.height + 'px',
                                                        }"></div>

                                                    </div>



                                                </div>

                                                <div v-if="element.type == 'calendar_embed'">

                                                    <div v-if="element.has_code === 'no'">

                                                        <img width="40"
                                                            :src="require(`@/assets/images/calendar_black.png`)" />

                                                    </div>


                                                    <div v-if="element.has_code === 'yes'" class="" :style="{
                                                        'margin': `${element.margin}px 0px ${element.margin}px 0px`,
                                                    }">

                                                        <iframe :width="`${element.width}%`" :height="`${element.height}px`"
                                                            :src="element.calender_url" frameborder="0" :style="{
                                                                'box-shadow': 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                                                'border-radius': '5px',
                                                            }"></iframe>


                                                    </div>



                                                </div>

                                            </div>

                                        </div>











                                    </div>


                                </div>


                            </div>

                        </div>



                        <a href="https://convertlyio.com" target="_blank" style="color: inherit; text-decoration: none;
                        margin-top: 40px;
                        ">
                            <div class="poweredByBrand">
                                <div>
                                    <img width="20" style="margin-right: 10px;"
                                        :src="require(`@/assets/images/logo_icon.png`)" />
                                </div>
                                <div style="font-size: 14px;">
                                    Powered by <b>Convertlyio</b>
                                </div>
                            </div>
                        </a>





                    </div>




                </div>



            </div>



        </div>

        <div v-if="quizEditorStore.showAnswerOutcomeModal" class="modal-overlay">

            <div class="modal-box">
                <!-- Modal content goes here -->

                <div class="outcome-modal-content">

                    <div class="row">
                        <div class="col-6">
                            <h6 style="float: left;">{{ quizEditorStore.currentAnswer.answer_text }}</h6>
                            <!-- <h6 style="float: left;">Answer to Outcome Mapping - {{ quizEditorStore.currentAnswer.answer_text }}</h6> -->
                        </div>
                        <div class="col-6">
                            <button class="btn btn-dark btn-sm" style="float: right;"
                                @click="quizEditorStore.closeAnswerOutcomeModal">Close</button>
                        </div>

                    </div>



                    <hr>




                    <div v-for="(outcome, outcomeIndex) in quizEditorStore.currentAnswerOutcomeArray" :key="outcomeIndex">



                        <div class="row" style="margin-right: 13px;">

                            <div class="col-6">

                                <div class="input-group mb-3">



                                    <select class="form-select" style="height: 30px; font-size: 13px;"
                                        v-model="quizEditorStore.currentAnswerOutcomeArray[outcomeIndex].page_id"
                                        @change="quizEditorStore.setSelectedOutcome(outcome)">

                                        <option disabled value="null">Select Outcome
                                        </option>

                                        <option v-for="(page) in quizEditorStore.getAllOutcomePages(answer)" :key="page.id"
                                            :value="page.id" :disabled="page.hasMatch === true">

                                            {{ page.page_title }}
                                        </option>


                                    </select>

                                </div>

                            </div>


                            <div class="col-5">



                                <div class="input-group input-group-sm">

                                    <span class="input-group-text" style="font-size: 11px; width: 50px;">
                                        Weight
                                    </span>

                                    <input v-model="outcome.weight" type="number" class="form-control" style="width: 30px;"
                                        @change="quizEditorStore.setSelectedOutcome(outcome, answer)">
                                </div>


                            </div>

                            <div class="col-1">

                                <img :src="require(`@/assets/images/x_close_black.png`)"
                                    @click="quizEditorStore.deleteThisAnswerToOutcome(outcome, answer)"
                                    style="cursor: pointer;" width="12" />

                            </div>

                        </div>


                    </div>



                    <div class="row">

                        <div class="col" style="font-size:12px; float: left;"
                            v-if="quizEditorStore.currentAnswerOutcomeArray.length === 0">
                            No outcomes mapped
                        </div>

                        <div class="col">
                            <button class="btn btn-light btn-sm" style="font-size:12px; float: right;"
                                @click="quizEditorStore.addOutcomeSelector(answer)">Add
                                Outcome +</button>
                        </div>


                    </div>





                </div>

            </div>

        </div>


        <div v-if="quizEditorStore.showDeletOutcomePageModal" class="modal-overlay1">

            <div class="modal-box1">

                <div class="modal-content1">
                    <h5 style="text-align:left;"><b>Are you sure you want to delete this outcome page?</b></h5>
                    <p style="text-align:left;">Deleting this outcome page also deletes any widgets that was added to it.
                        This action cannot be undone.</p>

                    <button style="float:right; margin-left: 9px;" @click="quizEditorStore.deleteAOutcomePage" type="button"
                        class="btn btn-danger">
                        Delete
                    </button>

                    <button style="float:right;" type="button" class="btn btn-secondary"
                        @click="quizEditorStore.closeDeleteOutcomeModal">
                        Close
                    </button>



                </div>
            </div>

        </div>



        <div v-if="quizEditorStore.showAnswerPipingModal" class="modal-overlay3">

            <div class="modal-box3">

                <div class="modal-content3">


                    <div class="pipingHeader">

                        <h6 style="text-align:left;"><b>Answer Piping</b></h6>



                        <img width="20" height="20" @click="quizEditorStore.closeAnswerPipingModal"
                            :src="require(`@/assets/images/x_black.png`)" class="" />

                    </div>




                    <div class="answerPipingBody">


                        <div class="pipingLeft">


                            <textarea 
                            v-if="currentElement.type === 'question'"
                            v-model="currentElement.question_text"
                            class="pipingTextarea" 
                            rows="10"
                            >
                            </textarea>

                            <div 
                            v-if="currentElement.type === 'header_text'">

                                    <editor :key="editorKey"
                                    api-key="bvj08wudza185lm5frysfdtyh7px2unli6gwux420yeebie4"
                                    v-model="currentElement.code_block" :init="editorConfig" @change="handleEditorChange()"
                                    @onInit="handleEditorInit" />
                            
                            </div>

                            <div 
                            v-if="currentElement.type === 'text'">

                                    <editor :key="editorKey"
                                    id="textEditorForPiping"
                                    api-key="bvj08wudza185lm5frysfdtyh7px2unli6gwux420yeebie4"
                                    v-model="currentElement.code_block" 
                                    :init="editorConfig" 
                                    @change="handleEditorChange()"
                                    @onInit="handleEditorInit" />
                            
                            </div>



                        </div>

                        <div class="pipingRight">


                            <div class="questionHeader">
                                Questions
                            </div>

                            


                            <hr style="margin: 0px;" />

                            <div v-if="quizEditorStore.getQuestionsForAnswerPiping.length > 0">

                                <div v-for="(question, index) in quizEditorStore.getQuestionsForAnswerPiping" :key="index">

                                    <div class="questHead">

                                        <div class="questLeft">
                                            
                                            <b>{{quizEditorStore.limitText(question.question_text)}}</b>
                                           
                                        </div>

                                        <div class="pageNumRight"></div>

                                    </div>

                                    <hr style="margin: 0px;" />

                                    <div @click="addPipeData(`{${question.pipeable_id}}`)" class="pipeAnswer">

                                        <div>ID:</div>

                                        <div>[{ {{question.pipeable_id}} }]</div>

                                    </div>

                                    <hr style="margin: 0px;" />

                                </div>

                            </div>

                            <div v-else style="padding: 10px; font-size: 12px;">

                                No data available

                            </div>







                            <div class="questionHeader">
                                Other Data
                            </div>

                            <hr style="margin: 0px;" />

                            <div v-if="quizEditorStore.getInputFieldsForAnswerPiping.length > 0">

                                <div v-for="(inputField, index) in quizEditorStore.getInputFieldsForAnswerPiping"
                                    :key="index">

                                    <div @click="addPipeData(`{${inputField.pipeable_id}}`)" class="pipeOtherData">

                                        <div>
                                            <b>{{quizEditorStore.limitPipingText(inputField.label)}}</b> ID
                                        </div>

                                        <div>{ {{inputField.pipeable_id}} }</div>

                                    </div>

                                    <hr style="margin: 0px;" />

                                </div>

                            </div>

                            <div v-else style="padding: 10px; font-size: 12px;">

                                No data available

                            </div>














                        </div>



                    </div>







                </div>
            </div>

        </div>

        <!-- <div class="modal fade" id="deleteAOutcomePageModal" tabindex="">

            <div class="modal-dialog">

                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Are you sure you want to delete this outcome page?
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div style="text-align: left">

                            Deleting this outcome page also deletes any widgets that was added to it. This action cannot be undone.

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                        <button @click="deleteThisOutcomePage" type="button" class="btn btn-danger">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div> -->




    </div>
</template>



<script>
// import { ref, reactive } from "@vue/reactivity";
import { ref, watch, onMounted, reactive } from 'vue'
import { useQuizEditorStoreV2 } from "@/stores/QuizEditorStoreV2";
import { useAuthStore } from '@/stores/AuthStore';
import { Modal } from "bootstrap";
import draggable from "vuedraggable";
// import "@vue-flow/core/dist/style.css";
// import "@vue-flow/core/dist/theme-default.css";
// import TipTap from '../../components/TipTap.vue'
import QuizProgressBar from '@/components/QuizProgressBar.vue'
import MobileQuizProgressBar from '@/components/MobileQuizProgressBar'
import VueDatePicker from '@vuepic/vue-datepicker';
import Editor from '@tinymce/tinymce-vue';




export default {
    name: "MyEditor",

    components: {
        draggable, VueDatePicker,
        QuizProgressBar,
        MobileQuizProgressBar,
        Editor,
        // TipTap

    },

    props: ['quiz_random_string'],

    setup(props) {

        const quizEditorStore = useQuizEditorStoreV2();
        const authStore = useAuthStore();




        //fetch quiz
        quizEditorStore.fetchQuiz(props.quiz_random_string)

        quizEditorStore.checkForEmptyMotherArray();

        var currentlySelected = ref("widgetTab");

        //user design selections
        const selectedDesignOptions = reactive({
            quizBGcolor: quizEditorStore.currentQuiz.outer_bg_color,
            quizCardBGcolor: "#ffffff",
        });

        var highlightedIndex = ref(null);

        const widgetTabSelected = () => {
            currentlySelected.value = "widgetTab";
        };

        const optionsTabSelected = () => {
            currentlySelected.value = "optionTab";
        };

        const showQuizOuterBGOptions = () => {
            currentlySelected.value = "showQuizOuterBGOption";

            //reset element highlight
            highlightedIndex.value = null;
        };

        const showQuizBGOptions = () => {
            currentlySelected.value = "showQuizCARDBGoption";

            //reset element highlight
            highlightedIndex.value = null;
        };

        var currentElement = ref({});

        let editorCurrentFontFamily = ref('Arial, sans-serif')



        //watch for changes on currentElement
        watch(currentElement, () => {




            if (currentElement.value.type === 'question') {

                if (currentElement.value.max_answers_amt > currentElement.value.answers.length) {

                    // alert('The value cannot be greater than the number of answers!');

                    currentElement.value.max_answers_amt = currentElement.value.answers.length

                }

            }

            if (currentElement.value.type === 'text') {


                editorCurrentFontFamily.value = currentElement.value.font_family

                editorConfig.value.content_style = `body { font-family: ${editorCurrentFontFamily.value}; }`


                // console.log("editorConfig", editorConfig.value.content_style)


            }


            // // This function will be called whenever there are changes to the object or its properties
            // quizEditorStore.debouncedSaveDesign()



        }, { deep: true });


        //watch changes for quiz BG styles
        watch(
            [
                () => quizEditorStore.selectedProgressBarColor,
                () => quizEditorStore.is_progress_bar_enabled,
                () => quizEditorStore.selectedOuterBGtype,
                () => quizEditorStore.selectedOuterBGgradient,
                () => quizEditorStore.selectedNormalBGcolor,
                () => quizEditorStore.selectedBGimageUrl,
                () => quizEditorStore.selectedCardBGtype,
                () => quizEditorStore.selectedCardBGgradient,
                () => quizEditorStore.selectedCardNormalBGcolor,
                () => quizEditorStore.selectedCardBGimageUrl,

            ],
            () => {

                // quizEditorStore.debouncedSaveDesign()

            }
        );

        //watch if the current preview page has a redirect url is yes redirect to it
        watch(
            [
                () => quizEditorStore.defaultPagesArray[quizEditorStore.previewPageIndex],

            ],
            () => {

                let page = quizEditorStore.defaultPagesArray[quizEditorStore.previewPageIndex];

                if (page.redirect_url != null) {

                    window.open(page.redirect_url, '_blank'); //open in new tab becuase dont want navigate user from editor

                    console.log("HAS REDIRECT URL", page.redirect_url)
                }


            }
        );




        const setCurrentPageInBottomBar = (page, index) => {

            if (page.sub_type === 'default') {

                //I did this(reset current elemt) only for the text and header_text element because
                //otherwise causing issues with navigation between pages
                if (currentElement.value.type === 'text' || currentElement.value.type === 'header_text') {
                    currentElement.value = {} //reset 
                }

                quizEditorStore.currentDefaultPage = page;
                quizEditorStore.currentDefaultPageIndex = index;

                quizEditorStore.currentPageInView = page; //used for both default or outcome pages
                quizEditorStore.currentPageInViewIndex = index //this too

                //make sure current outcome page is empty
                quizEditorStore.currentOutcomePage = {}
                quizEditorStore.currentOutcomePageIndex = null


            }

            if (page.sub_type === 'outcome') {

                if (currentElement.value.type === 'text' || currentElement.value.type === 'header_text') {
                    currentElement.value = {} //reset 
                }

                quizEditorStore.currentOutcomePage = page;
                quizEditorStore.currentOutcomePageIndex = index;

                quizEditorStore.currentPageInView = page; //used for both default or outcome pages
                quizEditorStore.currentPageInViewIndex = index //this too

                //make sure current default page is empty
                quizEditorStore.currentDefaultPage = {}
                quizEditorStore.currentDefaultPageIndex = null

            }








        };



        const toggleHighlight = (element, index) => {

            setCurrentElement(element)

            // currentElement.value = element; //set current element
            currentlySelected.value = element.tab; //set respective tab

            if (highlightedIndex.value === index) {
                highlightedIndex.value = null; // Remove highlight
            } else {
                highlightedIndex.value = index; // Highlight clicked element


            }


        };

        const setCurrentElement = (element) => {

            currentElement.value = element;


            // if(element.type === 'question'){

            //     setQuestionHTML(element.text_code_block)



            //     //then answers
            //     currentElement.value.answers.forEach((ans, index) => {

            //         setAnswerHTML(ans, index)

            //         console.log(editor)
            //     });




            // }



            // console.log("CURRENT ELEMENT: ", currentElement.value)


        }



        const updateMainHeadline = (element, event) => {
            element.main_headline = event.target.innerText;
        };


        const updateText = (element, event) => {

            element.text_value = event.target.innerText;

        };

        const updateLabelText = (element, event) => {
            element.label = event.target.innerText;
            // setTimeout(() => setCursorAtEnd(event.target), 0);
        };



        // const updateQuestionText = (element, event) => {
        //     element.question_text = event.target.innerText;
        // };

        // const updateAnswerText = (element, event) => {
        //     element.answer_text = event.target.innerText;
        // };

        // const updateQuestionText = (element, event) => {
        //     element.question_text = event.target.innerText;
        //     setTimeout(() => setCursorAtEnd(event.target), 0);
        // };

        // const updateAnswerText = (element, event) => {
        //     element.answer_text = event.target.innerText;
        //     setTimeout(() => setCursorAtEnd(event.target), 0);
        // };


        // const setCursorAtEnd = (element) => {
        //     const range = document.createRange();
        //     const selection = window.getSelection();
        //     range.selectNodeContents(element);
        //     range.collapse(false);
        //     selection.removeAllRanges();
        //     selection.addRange(range);
        //     element.focus();
        // };

        const updateQuestionText = (element, event) => {
            element.question_text = event.target.innerText;
            if (event.inputType === "insertText") {
                setTimeout(() => setCursorAtEnd(event.target), 0);
            }
        };

        const updateAnswerText = (element, event) => {
            element.answer_text = event.target.innerText;
            if (event.inputType === "insertText") {
                setTimeout(() => setCursorAtEnd(event.target), 0);
            }
        };

        const setCursorAtEnd = (element) => {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(element);
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
            element.focus();
        };





        // const updateButtonText = (element, event) => {

        //     element.text = event.target.innerText;

        //     console.log('somehting updated in button: ', element.text)
        // };












        const updateButtonText = (element, event) => {
            element.text = event.target.value;

            // console.log('something updated in button: ', element.text)

        };

        const updateShareText = (element, event) => {
            element.main_share_text = event.target.value;
        };


        // const updateShareText = (element, event) => {
        //     element.main_share_text = event.target.innerText;
        // };


        var editorContent = ref('<h1>TEST</h1>')

        var handleInput = (event) => {

            editorContent.value = event.target.getContent();

        }

        var showDeleteElementModal = () => {
            var deleteModal = new Modal(
                document.getElementById("deleteElementModal"),
                {
                    keyboard: false,
                }
            );

            deleteModal.show();
        };

        var deleteThisElement = () => {
            //delete
            quizEditorStore.deleteElement(currentElement.value);

            //hide modal
            var deleteModal = document.getElementById("deleteElementModal");
            var modal = Modal.getOrCreateInstance(deleteModal);

            modal.hide();
        };

        var clickedAnswerIndex = ref(null); //track which answer user clicked on

        const toggleHighlightAnswer = (answer, index) => {
            //set answer index
            clickedAnswerIndex.value = index;
        };

        const dragEnded = () => {
            //reset clickedAnswerIndex
            clickedAnswerIndex.value = null;
        };

        const pasteVideoURL = () => {

            if (currentElement.value.video_type == "youtube") {


                var youtubeVideoId = extractYoutubeVideoId(
                    currentElement.value.video_url
                );


                currentElement.value.video_embed_code = `<div style="position: relative; padding-bottom: 56.25%; height: 0;">
                <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://www.youtube.com/embed/${youtubeVideoId}" frameborder="0" allowfullscreen></iframe>
                </div>`;



            }

            if (currentElement.value.video_type == "vimeo") {

                const vimeoVideoId = extractVimeoVideoId(
                    currentElement.value.video_url
                );

                currentElement.value.video_embed_code = `<div style="position: relative; padding-bottom: 56.25%; height: 0;">
                        <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://player.vimeo.com/video/${vimeoVideoId}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </div>`;


                console.log("VIMEO FIRED", currentElement.value);
            }

            if (currentElement.value.video_type == "wistia") {

                const extractedWistiaDiv = extractDivBlock(currentElement.value.video_embed_code);
                currentElement.value.video_embed_code = extractedWistiaDiv
                console.log('wistia', extractedWistiaDiv)


            }

            if (currentElement.value.video_type == "external") {



                currentElement.value.video_embed_code = `<div style="position: relative; padding-bottom: 56.25%; height: 0;">
                        <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="${currentElement.value.video_url}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        </div>`;




            }
        };

        var extractYoutubeVideoId = (url) => {
            let videoId = "";

            // Extract video ID from YouTube URL
            if (url.includes("youtu.be")) {
                const match = /youtu\.be\/(.+)/i.exec(url);
                videoId = match ? match[1] : "";
            } else {
                try {
                    const { searchParams } = new URL(url);
                    videoId = searchParams.get("v");
                } catch (error) {
                    console.error("Error extracting YouTube video ID:", error);
                }
            }

            return videoId;
        };

        const extractVimeoVideoId = (url) => {
            let videoId = "";

            // Extract video ID from Vimeo URL
            if (url.includes("vimeo.com")) {
                const match = /vimeo.*\/(\d+)/i.exec(url);
                videoId = match ? match[1] : "";
            }

            return videoId;
        };

        const extractDivBlock = (code) => {

            const divRegex = /<div class="wistia_responsive_padding"[\s\S]*?<\/div>/;
            const divMatch = code.match(divRegex);

            if (divMatch) {
                const divBlock = divMatch[0];
                return divBlock.trim();
            }

        };



        //when dropdown selection changes
        const resetVideoInput = () => {
            // currentElement.value.video_embed_code = "";
            // currentElement.value.video_url = "";
        };

        const showOverlay = ref(false);
        const showQuizPreviewOverlay = ref(false);


        const showAndHideLogicBranchOverlay = () => {
            showOverlay.value = !showOverlay.value;
        };

        const showAndHidePreviewQuizOverlay = () => {



            quizEditorStore.initDeviceType();
            quizEditorStore.currentDevicePreview = 'Desktop'


            if (quizEditorStore.currentQuiz.type === 'single_outcome') {

                quizEditorStore.resetPreview()
                quizEditorStore.prepareDefaultPagesArrayForSingleOutcomeQuiz()

            }

            if (quizEditorStore.currentQuiz.type === 'score_based_outcomes') {

                quizEditorStore.resetPreview()
                quizEditorStore.prepareDefaultPagesArrayForScoreOutcomeQuiz()


            }

            if (quizEditorStore.currentQuiz.type === 'answer_based_outcomes') {

                //set the results tracker based on quiz type
                quizEditorStore.setResultsTracker()

            }



            showQuizPreviewOverlay.value = true


        }

        const hidePreviewQuizOverlay = () => {


            showQuizPreviewOverlay.value = false

            //reset the sequence
            quizEditorStore.resetPreview()
            quizEditorStore.removeOutcomePageFromDefaultPagesArray()

        }


        const showCheckboxOverlay = ref(false);
        const showAndHideCheckboxOverlay = () => {
            showCheckboxOverlay.value = !showCheckboxOverlay.value;
        }

        const currentPopupBoxContent = ref(null);

        const setCurrentPopupBoxContent = (content) => {
            currentPopupBoxContent.value = content
        }




        const showDeletePageModal = () => {


            showModal('deleteAPageModal')



        }

        const showDeleteOutcomePageModal = () => {

            showModal('deleteAOutcomePageModal')

        }



        const deleteThisDefaultPage = () => {

            quizEditorStore.deleteADefaultPage()

            hideModal('deleteAPageModal')
            hideModal('deleteAPageModal')

        }

        const deleteThisOutcomePage = () => {

            quizEditorStore.deleteAOutcomePage()

            hideModal('deleteAOutcomePageModal')


        }




        const hideModal = (modalName) => {

            //hide modal
            var modal = document.getElementById(modalName);
            var thisModal = Modal.getOrCreateInstance(modal);

            thisModal.hide();
        };

        const showModal = (modalName) => {

            var modal = new Modal(
                document.getElementById(modalName),
                {
                    keyboard: false,
                }
            );

            modal.show();

            console.log('SHOW MODAL CALLED')
        }


        var currentAnswerIndex = ref(null)


        // const updateAnswerToQuestion = (params) => {
        //     //get the anwer node(the source)
        //     const answerId = params.source;

        //     //get the page node with question (the target)
        //     const pageId = params.target;

        //     //add the edge line into the nodes array
        //     const newEdge = {
        //         id: `e${answerId}-${pageId}`,
        //         source: `${answerId}`,
        //         target: `${pageId}`,
        //         type: "default",
        //     };

        //     quizEditorStore.nodesArray.push(newEdge);

        //     // //TODO: update the answer next page id in the database
        //     // quizEditorStore.nodesArray.find((node) => node.id === answerId);

        //     // console.log(newEdge);



        //     // console.log(quizEditorStore.nodesArray.length, answer);
        // };


        const populateNodes = () => {

            // quizEditorStore.reset()

            // quizEditorStore.populateMindMapWithData()

            // addNodes(quizEditorStore.nodesArray);
        }

        // const rePopulateNodesArray = () => {

        //     quizEditorStore.populateMindMapWithData()

        //     //add data to nodes
        //     addNodes(quizEditorStore.nodesArray);

        //     console.log('repop NODE ARRAY', quizEditorStore.nodesArray)


        // }


        const adjustTextboxHeight = (element) => {

            const t_element = document.getElementById(`${element.page_element_id}textarea`);

            // t_element.style.height = '100%'; // Reset the height to measure actual content size

            const computedHeight = t_element.scrollHeight;

            element.textarea_height = computedHeight; // Update the reactive textarea_height property

        }

        // const resetTextboxHeight = (element) => {

        //     const t_element = document.getElementById(`${element.page_element_id}textarea`);

        //     // t_element.style.height = 'auto'; // Reset the height to measure actual content size

        //     const computedHeight = t_element.scrollHeight;

        //     element.textarea_height = computedHeight; // Update the reactive textarea_height property

        // }


        const handleContentChangeForHeader = (content) => {


            currentElement.value.code_block = content

            console.log('content change emiited: ', content)
            console.log('the current element: ', currentElement.value)

        };

        const handleContentChangeForText = (content) => {


            currentElement.value.code_block = content

            console.log('content change emiited: ', content)
            console.log('the current element: ', currentElement.value)

        };


        const answersMaxAmountAlert = (max_answers_amt) => {

            alert('You can only select a maximum of ' + max_answers_amt + ' answers')
        }



        const fileInput = ref(null);

        const triggerFileInput = () => {
            fileInput.value.click();
        };


        const triggerAnswerImageFileInput = (answer, index) => {

            //set current answer
            quizEditorStore.currentAnswer = answer

            //then do the click
            let answerinput = document.getElementById('answerImageFileInput-' + index)
            answerinput.click();


        };


        const fileInputForQuizBG = ref(null);

        const triggerFileInputForQuizBG = () => {
            fileInputForQuizBG.value.click();
        };



        const resetIframeEmbedWidthAndHeight = (element) => {


            if (element.code_block === '') {

                element.has_code = 'no'
                console.log('EMPTY')



            } else {

                element.has_code = 'yes'

                const code = element.code_block;

                // Define a regular expression pattern to match the iframe code
                const pattern = /<iframe[^>]*>[\s\S]*?<\/iframe>/i;

                // Check if the code contains an iframe
                if (pattern.test(code)) {

                    // Replace the width and height attributes with '100%'
                    const modifiedCode = code.replace(/width=["']\d+["']/i, 'width="100%"')
                        .replace(/height=["']\d+["']/i, 'height="100%"');

                    // Update the code_block with the modified code
                    element.code_block = modifiedCode;

                    // console.log('currentElement.value.code_block', currentElement.value.code_block)
                    console.log('modifiedCode', modifiedCode)
                }



            }




        }


        const buildIframeCode = (element) => {

            if (element.calender_url === '') {

                element.has_code = 'no'
                console.log('EMPTY')

                resetCalendarSelect(element)


            } else {

                element.has_code = 'yes'

                // //build iframe code
                // const iframeCode = `<iframe width="100%" src="${element.calender_url}" frameborder="0" ></iframe>`

                // element.code_block = iframeCode;

                // console.log('iframeCode', iframeCode)

            }


        }

        const resetCalendarSelect = (currentElement) => {

            currentElement.has_code = 'no'
            currentElement.calender_url = ''


        }


        onMounted(() => {

            //get usage data
            quizEditorStore.fetchManagerAccountUsageData()

          

        });


        const editor = ref(null);




        const handleEditorChange = () => {


            // console.log("Save HTML changes to database");
          


        };

        const handleAnswerEditorChange = (index) => {

            // const editor = window.tinymce.get('answer-editor-' + index);
            // const content = editor.getContent();
            // console.log("Save HTML changes to database");
            // console.log('content', content);

            // currentElement.value.answers[index].text_code_block = content;

            // console.log('content', currentElement.value.answers[index]);

            console.log(index);




        };

        

        const textTabEditor = ref(null);
        let editorInstance = null;


        let editorConfig = ref({
            toolbar: 'bold italic underline | bullist numlist | fontsizeselect | formatselect | removeformat | alignleft aligncenter alignright',
            fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 20pt 24pt 36pt',
            block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=p',
            branding: false,
            height: 250,
            menubar: false,
            statusbar: false,
            content_style: `body { 
                font-family: ${editorCurrentFontFamily.value}; 
                background-color: #ececec; 
                line-height: 1;
            }`
        })


        const handleEditorInit = (editor) => {
            editorInstance.value = editor;

        };

        const editorKey = ref(0);

        const refreshEditor = () => {
            editorKey.value++;
        };




        const addPipeData = (string) => {

            if(currentElement.value.type === 'question'){

                currentElement.value.question_text += `${string}`;

            }

            if(currentElement.value.type === 'header_text'){

                currentElement.value.code_block += `${string}`;

            }

            if(currentElement.value.type === 'text'){

                currentElement.value.code_block += `${string}`;

            }
            
            
            
        }



        return {
            addPipeData,
            handleEditorChange, editor, handleAnswerEditorChange, editorConfig, textTabEditor, refreshEditor,
            handleEditorInit, editorKey,
            authStore,
            currentlySelected,
            widgetTabSelected,
            optionsTabSelected,
            showQuizOuterBGOptions,
            showQuizBGOptions,
            selectedDesignOptions,
            highlightedIndex,
            updateQuestionText,
            toggleHighlight,
            updateMainHeadline,
            updateText,
            currentElement,
            quizEditorStore,
            showDeleteElementModal,
            showDeleteOutcomePageModal,
            deleteThisOutcomePage,
            deleteThisElement,
            updateLabelText,
            updateAnswerText,
            setCurrentPageInBottomBar,
            toggleHighlightAnswer,
            clickedAnswerIndex,
            dragEnded,
            updateButtonText,
            pasteVideoURL,
            resetVideoInput,
            updateShareText,
            showAndHideLogicBranchOverlay,
            showOverlay, populateNodes, showDeletePageModal,
            deleteThisDefaultPage, showQuizPreviewOverlay, showAndHidePreviewQuizOverlay, hidePreviewQuizOverlay,
            adjustTextboxHeight, handleContentChangeForHeader, handleContentChangeForText, currentAnswerIndex, editorContent,
            handleInput, showAndHideCheckboxOverlay, showCheckboxOverlay,
            currentPopupBoxContent, setCurrentPopupBoxContent, answersMaxAmountAlert, hideModal,
            fileInput, triggerFileInput, triggerAnswerImageFileInput,
            fileInputForQuizBG, triggerFileInputForQuizBG, resetIframeEmbedWidthAndHeight,
            buildIframeCode, resetCalendarSelect,

        };
    },
};
</script>
  
<style scoped>
.theSideBarOnLeft {
    width: 25%;
    float: left;
    /* Optional: Use float to make the two divs side by side */
    background-color: rgb(74, 202, 19);
    position: fixed;
}

.outerBGOnRight {
    width: 75%;
    float: right;
    /* background-color: yellow; */
}




body {
    margin: 0;
    padding: 0;
}

.outerBG {
    /* position: fixed; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* background-color: #d81212; */
    padding-right: 0px !important;
}

.floatingToolbar {
    position: fixed;
    z-index: 9999;
    border-radius: 5px;
    background-color: #2f2f37;
    padding-top: 6px;
    padding-bottom: 6px;
    width: 500px;
    right: 7px;
    margin-top: 7px;
    color: white;
}

.preview_floatingToolbar {

    position: fixed;
    z-index: 9999;
    border-radius: 5px;
    background-color: #2f2f37;
    padding: 15px;
    /* padding-top: 6px;
    padding-bottom: 6px; */
    width: 280px;
    right: 7px;
    margin-top: 7px;
    color: white;


}

.widgetLabelText {
    color: white;
    font-size: 11px;
}

.widgetMenu {
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    border-radius: 5px;
    background-color: #2f2f37;
    color: white;
    padding: 10px;
}

.mainSidebar {
    max-height: 100vh;
    background-color: #18191d;
    width: 100%;
    /* position: fixed; */



}

.sidebarHeader {
    background-color: #2f2f37;
    position: sticky;
    top: 0;
    padding: 10px;
    color: white;
}

.sidebarBody {
    overflow-y: auto;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 200px;
    height: 100vh;
}

.sidebarFooter {
    background-color: #2f2f37;
    padding: 10px;
    color: white;
}

.toolIcon {
    background-color: #000000;
    padding: 15px;
    margin: 5px;
    border-radius: 4px;
    width: calc(33.33% - 10px);

}

.toolIcon:hover {
    background-color: #289d72;
}

.toolIconImage {
    width: 30px;
}

.tooliconText {
    color: white;
    font-size: 11px;
}

.iconRowWrapper {
    /* margin: 0px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;


}

.toolIconImage {
    width: 20px;
    transition: transform 0.3s ease;
}

.toolIcon:hover .toolIconImage {
    transform: scale(1.2);
}

.widgetImgIcon {
    width: 16px;
    transition: transform 0.3s ease;
}

.widgetImgIcon:hover {
    transform: scale(1.2);
}

.optionsWrapper {
    /* background-color: yellow; */
    margin: 0px !important;
}

.color-input {
    width: 100%;
    height: 100%;
    padding: 0;
    border-color: none;
    background: none;
    outline-width: 2px;
    outline-color: gray;
}

.color-input-container {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    /* Adjust the border radius value as needed */
    overflow: hidden;
    float: left;
}

.optionWrapper {
    color: white;
    background-color: #26252a;
    border-radius: 5px;
    width: 100%;
    margin-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.labelText {
    font-size: 16px;
    float: left;
}

.colorInputBox {
    width: 80px;
    background-color: #ffffff;
    border-radius: 5px;
}

.imageWrapperDiv {
    margin-top: 20px;
    padding: 15px;
    background-color: #26252a;
    border-radius: 5px;
}

.imgUploadTxt {
    color: white;
    font-weight: bold;
    float: left;
}

.uploadedImg {
    /* background-color: #b0b0b5; */
    /* margin: 10px; */
}

.placeholderImg {
    margin-top: 15px;
    width: 100%;
    border-radius: 5px;
}

.bigWrapper {


    align-items: center;
    justify-content: center;
    /* background-color: rgb(1, 224, 38); */
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 150px;
    padding-top: 60px;

    /* Add padding to create some space between the content and scrollbar */
    height: 100vh;
    /* Subtract the height of the toolbarSubHeaderWrapper and toolbarSubHeaderBox */
    overflow-y: auto;
    /* Enable vertical scrolling within the container */
    scrollbar-width: thin;
}

.bigWrapper::-webkit-scrollbar {
    width: 8px;

    /* Set the width of the scrollbar */
}

.bigWrapper::-webkit-scrollbar-thumb {
    background-color: rgba(7, 12, 36, 0.3);
    /* Set the color of the scrollbar thumb */
    border-radius: 4px;
    /* Add border radius to the scrollbar thumb */
}

.quizCard {
    margin-top: 60px;
    padding: 30px;
    background-color: #cecece;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 15px;
    width: 100%;
    border-style: hidden;
    border-color: rgba(255, 255, 255, 0)
}

.quizCard:hover {
    border-width: 0.5px;
    border-color: rgb(218, 218, 218);
    border-style: solid;
}

.innerQuizContainer {
    padding: 30px;


    /* background-color: rgb(218, 218, 218); */
}

.elementBox {
    /* background-color: rgb(211, 207, 207); */
    position: relative;
    /* margin-bottom: 10px; */
    border-color: rgba(235, 235, 235, 0);
    border-style: solid;

}

.elementBox:hover {
    border-color: rgb(218, 218, 218);
    border-style: solid;
}

.elementBox.highlighted {
    border: 1px solid rgb(206, 206, 207);
}

.deleteIcon {
    position: absolute;
    right: 5px;
    cursor: pointer;
    color: red;
    padding: 5px;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    z-index: 9999;
}

.inputFieldWrapper {
    width: 100%;
    /* background-color: #b0b0b5; */
    /* float: right; */
    /* padding: 10px; */
    /* background-color: #b0b0b5;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-bottom: 10px; */
}

.theInput {

    width: 100%;

}

/* 
.theInput::placeholder {
  
  color: rgb(26, 44, 206) !important;
} */

.mycontainer {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* flex-direction: row; */
    /* justify-content: center; */
    /* max-width: 300px; */
}


.box {

    height: 100%;

}

.outerWrapper {
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    /* justify-content: right; */
}

.scoreOuterWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.scoreContainer {
    display: flex;
    flex-direction: column;
}

.pageFloatingBottomBar {
    position: fixed;
    z-index: 1;
    border-radius: 5px;
    padding: 4px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    max-width: 400px;
    min-width: 300px;
    left: 26%;
    margin-bottom: 10px;
    color: white;
    bottom: 0;
}

.answerBasedOutcomePageFloatingBottomBar {
    position: fixed;
    z-index: 1;
    border-radius: 5px;
    padding: 4px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    max-width: 400px;
    min-width: 300px;
    right: 15px;
    margin-bottom: 10px;
    color: white;
    bottom: 0;
}

.singleOutcomePageFloatingBottomBar {
    position: fixed;
    z-index: 1;
    border-radius: 5px;
    padding: 4px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    right: 15px;
    margin-bottom: 10px;
    color: white;
    bottom: 0;
    width: 190px;
}


.topQuizNameArea {

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);

}

.boxy {
    position: relative;
    background-color: #6f6f6f;
    border-radius: 5px;
    margin: 4px;
    padding: 10px;
    min-width: 80px;
    font-size: 12px;
    /* cursor: pointer; */
}

.boxy:hover {
    background-color: #3eb489;
}

.clickedTab {
    background-color: #3eb489;
}

.custom-scrollbar {
    background-color: rgb(255, 255, 255);
}

.plusIcon {
    margin-top: 15px;
    width: 20px;
    margin-left: -25px;
}

.questionTextOnlyWrapper {
    /* background-color: #289d72; */


}

.questionContainer {
    /* max-width: 500px; 
    background-color: yellow; */
    /* display: flex;
    flex-direction: column; */
}

.questionTextImageWrapper {
    /* background-color: yellow; */
}

.myBox {
    height: 100%;
}

.answerWrapper {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    position: relative;
    max-width: 500px;
}

.previewAnswerWrapper {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    position: relative;
    max-width: 500px;
    transition: transform 0.4s ease-in-out;
}

.previewAnswerWrapper:hover {
    transform: scale(1.1);
}

.answerWithImageTextItem {
    /* box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px; */
    /* background-color: #289d72; */
    /* width: 100px;
    height: 100px; */
}

.container1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
}

.box1 {
    max-width: 150px;
    background-color: rgb(232, 232, 233);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: relative;
}

@media (max-width: 767px) {
    .container1 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 768px) {
    .container1 {
        grid-template-columns: repeat(2, 1fr);
    }
}

.imageTop {
    height: 100px;
}

.imageTop img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.answerBody {
    flex-grow: 1;
    padding: 10px;
}

.answerOptionsBox {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    margin-right: -60px;
    margin-top: -10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 3px;
    cursor: pointer;
}

.answerBoxOptions2 {
    padding: 6px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.buttonContainer {
    background-clip: blue;
    width: 100%;
}

.imageWidgetWrapper {
    /* background-color: #289d72; */
}

.v_placeholder {
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sharingWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 300px;
}

.platformItem {
    flex-basis: calc(25% - 20px);
    /* Display 4 items per row on desktop */
    padding: 10px;
    box-sizing: border-box;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .platformItem {
        flex-basis: calc(50% - 20px);
        /* Display 2 items per row on mobile */
    }
}

.logicOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;
}

.logicDivNav {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    padding: 10px;
}

.logicBody {
    display: flex;
    height: calc(100% - 40px);
    /* Adjust the height calculation based on the padding and content within logicDivNav */
}

.mindMap {
    width: 80%;
    overflow-y: auto;
    overflow-x: auto;
    /* padding: 10px; */
    /* white-space: nowrap; */
}

.logicSiderBar {
    background-color: rgb(245, 245, 245);
    width: 20%;
    overflow-y: auto;
    padding: 10px;
}

.innerMindMap {
    /* background-color: #289d72; */
}

.questionBox {
    position: relative;
    display: inline-block;
    padding: 10px;
    margin-bottom: 50px;
    background-color: #e4eaf5;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.answerBlock {
    position: relative;
    background-color: #ffffff;
    margin: 10px;
    border-radius: 10px;
    width: 150px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 4px;
}

.mycircle {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    background-color: #76738b;
    border-radius: 50%;
    cursor: pointer;
}

.question_circle {
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    background-color: #76738b;
    /* Set the color of the circle */
    border-radius: 50%;
    cursor: pointer;
}

.answerContainer {
    display: flex;
}

.vue-flow {
    background: rgb(250, 250, 250);
    background-image: linear-gradient(to right, #b7b7b733 1px, transparent 1px),
        linear-gradient(to bottom, #b7b7b733 1px, transparent 1px);
    background-size: 3px 3px;
}

.trashPageIcon {
    width: 10px;
    float: right;
    /* z-index: 99999;
    position: absolute; */

}




.quizPreviewOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;
}


.previewOverlayDivNav {
    background-color: #18191d;
    /* background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%); */
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    padding: 10px;
}


.p_QuizOuterBG {

    display: grid;
    place-items: center;
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: thin;
    padding-top: 80px;
    padding-bottom: 80px;



}



.p_QuizCard {

    max-width: 900px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    padding: 30px;


}

.p_QuizCardMobile {

    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    padding: 30px;

}

.switchOn {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: #289d72;
    font-weight: bold;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.switchOff {

    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: #e6edea;
    font-weight: bold;
    font-size: 10px;
    color: black;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    cursor: pointer;

}

.tabsWrapper {
    display: flex;
    background: yellow;
}

.tabBtn {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #c7e6dd;
    font-size: 13px;
    color: rgb(8, 5, 5);
    cursor: pointer;
}

.tabBtn:hover {
    background: #18191d;
    color: white;
    border-color: #18191d;
}

.tabBtnClicked {
    background: #18191d;
    color: white;
    border-color: #18191d;
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
}


.isCorrect {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: #289d72;
    font-weight: bold;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.notCorrect {
    width: 70px;
    height: 20px;
    border-radius: 10px;
    background: #bac1be;
    font-weight: bold;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: black;
}


.textareaWrapper {
    width: 100%;
}


.textboxOuterWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: gray; */

}


.textboxContainer {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
}


.theBox {
    height: 100%;
    /* width: 100%; */
}



.checkboxWrapper {
    display: flex;
    flex-direction: row;
    background-color: red;

}


.popupContentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-check-input {
    border-color: rgb(32, 32, 31);
    border-style: solid;
    border-width: 1px;
}


.form-check-input:checked {
    background-color: #18191d !important;
    border-color: black !important;
    box-shadow: none;

}

.form-check-input:focus {
    border-color: black !important;
    box-shadow: none;
}

.form-check-input:active {
    background-color: rgb(72, 72, 70) !important;
    border-color: black !important;
    box-shadow: none;
}


.checkboxPopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Vertically center the content */
}

.modelContent {
    position: relative;
    padding: 25px;
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
    margin-left: 10px;
}

.bodyHeader {
    position: absolute;
    top: 10px;
    /* Adjust this value as needed for vertical positioning */
    right: 15px;
    /* Adjust this value as needed for horizontal positioning */
    cursor: pointer;
}

.pageBtnDelete {
    padding: 3px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.pageBtnEdit {

    padding-right: 3px;
    position: absolute;
    right: 0;
    top: -1px;
    cursor: pointer;
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.center-video {
    display: grid;
    place-items: center;
}

.center__relative {
    margin: 0 auto;

}



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 10000;
    /* Ensure the modal is on top */
}

.modal-box {
    background-color: white;
    width: 600px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.outcome-modal-content {
    /* Add your styles for modal content */
    text-align: center;
}



.phoneFrame {
    width: 350px;
    height: 660px;
    position: absolute;
    z-index: 0;

}



.phoneFrameContent {
    width: 330px;
    height: 500px;
    display: grid;
    place-items: center;
    overflow-y: auto;
    scrollbar-width: thin;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    /* Add this to create a stacking context */
    z-index: 2;
}

.progressBarStrip {
    /* width: 330px;
  position: absolute;
  top: 40;
  z-index: 9999;  */
    width: 330px;
    position: absolute;
    top: 50;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 9999;
}

/* .centerWrapper {
    display: grid;
    place-items: center;
    margin-top: 90px;
} */

.centerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
}



.sharingList {
    background-color: #26252a;
    width: 100%;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
    border-radius: 4px;
    height: auto;

}


.sharingItem {
    width: 100%;
    background-color: #feffff;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 10px;
    border-radius: 4px;
}

.sharingItemTop {
    width: 100%;
    background-color: #feffff;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 10px;
}

.sharingItemBottom {

    width: 100%;
    /* background-color: #15bebe; */
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    /* padding-left: 10px; */
    padding-right: 10px;
}



.plat_icon_wrapper {
    width: 20%;
    /* background-color: rgb(233, 239, 233); */
    display: flex;
    align-items: center;
    justify-content: center;
}


.input_field_wrapper {
    width: 60%;
    /* background-color: rgb(218, 245, 218); */
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}


.trash_icon_wrapper {
    width: 20%;
    /* background-color: rgb(233, 239, 233); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialLinkInput {
    height: 30px;
    width: 100%;
    border: none;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}



.urlLabel {
    width: 20%;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.urlWrapper {
    width: 80%;
}

.linkInput {
    height: 30px;
    width: 100%;
    border: none;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

}




.button-2 {
    background-color: white;
    border-radius: 5px;
    border-width: 0;
    color: #333333;
    cursor: pointer;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    float: right;
}


.imgUploadWrapper {
    width: 100%;
    background-color: #26252a;
    border-radius: 4px;
}


.uploadImgheader {
    /* font-weight: bold; */
    padding: 17px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #26252a;
    color: white;
}

.uploadImgBody {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #26252a;
    border: 2px dotted rgb(180, 197, 212);
    height: 150px;
    position: relative;
    cursor: pointer;
    /* added */
}


.imgInsideUploadBody {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    /* added */
    z-index: 0;
    /* added */
}

.uploadIBtn {

    padding: 10px;
    background-color: white;
    color: black;
    font-size: 13px;
    position: absolute;
    /* added */
    z-index: 1;
    /* added */
    /* width: 100%; */
    /* added */
    /* height: 100%; */
    /* added */
    display: flex;
    /* added */
    align-items: center;
    /* added */
    justify-content: center;
    /* added */
}

.imgNotes {
    font-size: 13px;
    text-align: right;
    padding: 10px;
    color: white;
}

.answerImagerWrapper {

    display: flex;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;

}

.embedCodeWrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.theEmbedCodeContainer {
    width: 100%;
    height: auto;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* background-color: gray; */
}


.codeWrapper {

    background-color: #26252a;
    width: 100%;
    height: auto;
    padding: 10px;
    margin-top: 15px;
    border-radius: 5px;

}

.codeTextboxContainer {
    background-color: #26252a;
    height: 100%;
}

.columnWrapper {
    display: flex;
    flex-direction: column
}


.calendarWrapper {

    background-color: #26252a;
    width: 100%;
    height: auto;
    padding: 10px;
    margin-top: 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

}


.calendarOptions {
    /* background-color: yellow; */
    padding: 5px;
}

.calendarUrlWrapper {
    /* background-color: rgb(108, 108, 22); */
    padding: 5px;
}


.urlContainer {

    display: flex;
    flex-direction: column;
    /* background-color: yellow; */
    width: 100%;
    /* margin-left: 15px;
    margin-right: 15px; */
    padding: 15px;

}

.urlHeader {
    text-align: left;
    margin-bottom: 5px;
    font-size: 14px;
}

.urlInuputbox {
    height: 30px;
    border-radius: 2px;
}

.questionEditorWrapper {
    /* background-color: red; */
    padding: 15px;
}

.answerEditorWrapper {
    padding: 10px;
    /* background-color: red; */
    /* margin-top: 10px;
    margin-bottom: 10px; */

}

.answerEditorItem {
    /* background-color: YELLOW; */
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.textBoxWrapper {
    /* background-color: red; */
    margin-top: 10px;
    margin-top: 10px;

}

/* Styles for the modal overlay */
.modal-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box1 {
    background-color: white;
    max-width: 600px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Styles for modal content (customize as needed) */
.modal-content1 {
    /* Add your styles for modal content */
    text-align: center;
}


.parent-container {
    display: flex;

}



.checkboxinputFieldWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align children (label and input) to the left */
    width: 100%;
    /* background-color: antiquewhite; */
}



.poweredByBrand {
    height: 40px;
    cursor: pointer;
    background-color: white;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Add this line */
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.poweredByBrand:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.button-11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px;
    font-size: 10px;
    font-weight: bold;
    color: #3D3D3D;
    background: #fff;
    border: none;
    margin-bottom: 13px;
    border-radius: 2.5px;

}



/* Styles for the modal overlay */
.modal-overlay3 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 9999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box3 {
    background-color: white;
    width: 950px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

}

/* Styles for modal content (customize as needed) */
.modal-content3 {
    /* Add your styles for modal content */
    text-align: center;

}


.pipingHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;


}

.answerPipingBody {
    display: flex;
    width: 100%;
    height: 400px;
    /* background-color: gray; */
    margin-top: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;


}

.pipingLeft {
    width: 60%;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pipingRight {
    width: 40%;
    background-color: white;
    overflow-y: auto;
    scrollbar-width: thin;
}


.pipingTextarea {
    width: 500px;
    height: 250px;
    resize: none;
    border-radius: 7px;
    border: none;
    padding: 15px;
}


.questionHeader {
    font-weight: bold;
    width: 100%;
    /* background-color: rgb(221, 221, 221); */
    padding: 10px;
    text-align: left;
}


.questHead {
    display: flex;
    text-align: left;
    font-size: 13px;
    padding: 10px;
    width: 100%;

}

.questLeft {
    /* background-color: yellow; */
    width: 70%;
    
}

.pageNumRight {
    /* background-color: red; */
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.pipeAnswer {
    display: flex;
    text-align: left;
    font-size: 13px;
    padding: 10px;
    justify-content: space-between;
}

.pipeAnswer:hover {
    cursor: pointer;
    background-color: rgb(238, 236, 236);
}

.pipeOtherData {

    display: flex;
    text-align: left;
    font-size: 13px;
    padding: 10px;
    justify-content: space-between;

}

.pipeOtherData:hover {
    cursor: pointer;
    background-color: rgb(238, 236, 236);
}
</style>
  