<template>
    <div>

        <div class="progressBarWrapper">

            <div class="wrapper">


                <div class="progressBar">

                    <div class="progressFill" :style="{ 
                        width: liveQuizDataStore.progressPercentage + '%',
                        background: liveQuizDataStore.selectedProgressBarColor,
                    }">

                    </div>

                </div>



            </div>


        </div>



    </div>
</template>

<script>
import { useLiveQuizDataStore } from "@/stores/LiveQuizDataStore";

export default {

    name: 'LiveQuizProgressBar',


    setup() {

        const liveQuizDataStore = useLiveQuizDataStore()




        return {
            liveQuizDataStore,
        }
    }


};
</script>

<style scoped>
.progressBarWrapper {
    position: relative;
    /* top: 0; */
    z-index: 300;
}

.progressBar {
    /* min-width: 5px; */
    width: 100%;
    height: 10px;
    /* background: rgb(225, 225, 57); */
    background-color: rgba(177, 192, 177, 0.5);
    overflow: hidden;
   
}

.wrapper {
    width: 100%;
    /* margin-top: 20px;
    margin-bottom: 20px; */

}

.progressFill {

    height: 100%;
    /* background: red; */
    background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
    transition: width 0.8s ease;
}
</style>