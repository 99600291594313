import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js' //must be added for dropdowns to work
import '@popperjs/core'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'



//make router aviable in all stores
const pinia = createPinia()

pinia
.use(({ store }) => {
    store.router = markRaw(router)
})
.use(piniaPluginPersistedstate)

createApp(App)
    .use(pinia)
    .use(bootstrap)
    .use(router)
    .use(Toast) // Add this line
    .mount('#app')

