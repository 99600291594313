<template>
    <div>

        <div class="dashboardBodyWrapper">


            <MainSideBarVue />



            <div class="MainContentArea">



                <div class="contentHeaderWrapper">

                    <div class="leftDivWrapper">

                        <div class="headerTextWrapper">


                            <router-link class="linkStyle"
                                :to="{ name: 'ViewAWorkspace', params: { workspaceUUID: workspaceUUID } }"
                                style="margin-right: 10px; margin-top: 5px;">

                                <img class="imgIcon" width="15" :src="require(`@/assets/images/left-arrow.png`)" />

                            </router-link>

                            <div class="headerText">
                                {{ quizStore.currentQuiz.name }}
                            </div>


                            <div class="dropdown" style="margin-left: 14px; margin-top: 5px;">

                                <svg id="dropdownMenuButton1" data-bs-toggle="dropdown" xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor" class="bi bi-three-dots mydots" viewBox="0 0 16 16">
                                    <path
                                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                </svg>

                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">



                                    <li @click="showUpdateQuizModal(quiz)">
                                        <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="16"
                                                :src="require(`@/assets/images/rename.png`)" />Rename</div>
                                    </li>


                                    <li @click="showDeleteQuizModal">
                                        <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="13"
                                                style="margin-top: -4px;"
                                                :src="require(`@/assets/images/t_trash.png`)" />Delete
                                        </div>
                                    </li>

                                </ul>

                            </div>


                        </div>





                    </div>

                    <div class="rightDivWrapper">


                        <!-- <router-link class="editBtn"
                            :to="{ name: 'MyEditor', params: { quiz_random_string: quizStore.currentQuiz.random_string } }">

                            <img class="imgIcon" width="15" :src="require(`@/assets/images/p_edit.png`)" /> <b>Edit</b>


                        </router-link> -->

                        <!-- {{ quizStore.currentQuiz.random_string }} -->

                        <!-- <router-link class="linkStyle"
                            :to="{ name: 'MyEditor', params: { quiz_random_string: quizStore.currentQuiz.random_string } }"> 

                            <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="15"
                                    :src="require(`@/assets/images/p_edit.png`)" />Edit</div>


                        </router-link> -->




                        <button class="editBtn" @click="navigateToEditor">
                            <img class="imgIcon" width="15" :src="require(`@/assets/images/p_edit.png`)" /> <b>Edit</b>
                        </button>


                    </div>



                </div>




                <div class="contentBodyWrapper">


                    <div class="tabsWrapper">


                        <div class="tabBtn" v-for="(projectTabOption, index) in quizStore.projectTabOptions" :key="index"
                            :class="{ tabBtnClicked: projectTabOption.type === quizStore.currentProjectTabType }"
                            @click="quizStore.currentProjectTabType = projectTabOption.type">
                            {{ projectTabOption.name }}
                        </div>


                    </div>






                </div>


                <div class="tabContentWrapper">


                    <div v-if="quizStore.currentProjectTabType === 'summary'">

                        <div class="summaryWrapper">

                            <div class="lastDataTimeText" >
                                Last 30 days data
                            </div>

                            <div class="summaryGrid">

                                <div class="summaryItem">

                                    <div class="summHeader">
                                        {{ quizStore.summaryData.visits }}
                                    </div>

                                    <div style="height: 100%;">

                                    </div>

                                    <div class="summBody">
                                        Visitors
                                    </div>


                                </div>

                                <div class="summaryItem">

                                    <div class="summHeader">
                                        {{ quizStore.summaryData.completedSubmissions }}
                                    </div>

                                    <div style="height: 100%;">

                                    </div>

                                    <div class="summBody">
                                        Completed Submissions
                                    </div>


                                </div>

                                <div class="summaryItem">

                                    <div class="summHeader">
                                        {{ quizStore.summaryData.partialSubmissions }}
                                    </div>

                                    <div style="height: 100%;">

                                    </div>

                                    <div class="summBody">
                                        Impressions
                                    </div>


                                </div>

                                <div class="summaryItem">

                                    <div class="summHeader">
                                        {{ quizStore.summaryData.completionRate }}%
                                    </div>

                                    <div style="height: 100%;">

                                    </div>

                                    <div class="summBody">
                                        Completion Rate
                                    </div>


                                </div>

                                <div class="summaryItem">

                                    <div class="summHeader">

                                        <!-- {{quizStore.summaryData.averageCompletionTime}} -->
                                        
                                        {{ quizStore.formatTime(quizStore.summaryData.averageCompletionTime) }}
                                    </div>

                                    <div style="height: 100%;">

                                    </div>

                                    <div class="summBody">
                                        Average Completion Time
                                    </div>


                                </div>

                                <div class="summaryItem">

                                    <div class="summHeader">
                                        {{ quizStore.summaryData.mostFrequentDeviceType }}
                                    </div>

                                    <div style="height: 100%;">

                                    </div>

                                    <div class="summBody">
                                        Top Device
                                    </div>


                                </div>

                            </div>


                        </div>








                    </div>


                    <div v-if="quizStore.currentProjectTabType === 'responses'">


                        <div v-if="quizStore.publicUsers.length > 0">

                            <div class="row tableHeader">

                                <div class="col">

                                    <div style="float: left;">


                                    </div>

                                </div>

                                <div class="col">


                                    <div style="float: right;">

                                        <div class="exportBtnWrapper">

                                            <div class="export1" @click="quizStore.exportDataAsExcel()">
                                                Export XLS
                                            </div>

                                            <div class="export2" @click="quizStore.exportAllSubmissionsAsCSV()">
                                                Export CSV
                                            </div>


                                        </div>


                                        <!-- <button @click="quizStore.exportAllSubmissionsAsCSV()"  >export as csv</button>

                                        <br>

                                        <img @click="quizStore.exportDataAsExcel()" class="imgIcon2" width="14" :src="require(`@/assets/images/download.png`)" />


                                        <img class="imgIcon2" width="14" :src="require(`@/assets/images/download.png`)" /> <b style="font-size: 13px;">Export</b>
 -->


                                    </div>

                                </div>



                            </div>


                            <table class="table table-bordered table-hover">

                                <thead>
                                    <tr>
                                        <th scope="col">Submitted at</th>
                                        <th scope="col">Form Completion</th>
                                        <th scope="col">Duration</th>
                                        <th scope="col">Country</th>
                                        <th scope="col">Device</th>

                                    </tr>
                                </thead>

                                <tbody>

                                    <tr v-for="(publicUser, index) in quizStore.publicUsers" :key="publicUser.id"
                                        class="rowBG" @mouseover="quizStore.currentResponseIndex = index"
                                        @mouseleave="quizStore.currentResponseIndex = null"
                                        @click="quizStore.fetchDataAndOpenResponseModal(publicUser.uuid)">

                                        <td>
                                            <div v-if="publicUser.completion_type === 'full'">
                                                {{ publicUser.submission_date }} at {{ publicUser.submission_time }}
                                            </div>
                                            <div v-else>
                                                N/A
                                            </div>
                                            
                                        </td>

                                        <td>

                                            <div v-if="publicUser.completion_type === 'full'">
                                                Full
                                            </div>
                                            <div v-else>
                                                Partial
                                            </div>

                                        </td>

                                        <td>

                                            <div v-if="publicUser.completion_type === 'full'">
                                                {{ publicUser.completion_duration_time }}
                                            </div>
                                            <div v-else>
                                                N/A
                                            </div>
                                            
                                        </td>

                                        <td>
                                            <div v-if="publicUser.geo_data">
                                                {{ publicUser.geo_data.city ?? 'N/A' }}, {{ publicUser.geo_data.country_name
                                                    ?? 'N/A' }}
                                            </div>
                                            <div v-else>
                                                N/A
                                            </div>

                                        </td>

                                        <td>
                                            {{ publicUser.device_type }}
                                        </td>



                                    </tr>


                                </tbody>


                            </table>

                        </div>

                        <div v-else style="margin-top: 200px;">

                            <img width="22" :src="require(`@/assets/images/box.png`)" />

                            <div class="row" style="margin-top: 15px;">
                                <b style="font-size: 13px;">No responses yet</b>
                            </div>


                        </div>

                    </div>


                    <div v-if="quizStore.currentProjectTabType === 'share'">


                        <div class="shareLinkWrapper">

                            <div class="shareHeader">
                                Share Link
                            </div>

                            <div class="shareBody">
                                Copy this link to share your form on social media, messaging apps or via email.
                            </div>

                            <div class="inputBoxWrapper">
                                <input readonly type="text" class="form-control myInput"
                                    :value="quizStore.buildShareLink()">
                            </div>

                            <div class="row bottomOptions">

                                <div class="col-2">
                                    <button class="button-13" role="button" @click="copyToClipboard">Copy</button>
                                </div>

                                <div class="col-10">
                                    <p style="float: right; font-size: 12px; text-decoration: underline;">
                                        Use your custom domain
                                    </p>
                                </div>


                            </div>


                        </div>

                        <div class="embeddingWrapper">

                            <div class="embedHeader">
                                Embed
                            </div>

                            <div class="horizontalEmbedOptions">

                                <div class="embedOption" @click="quizStore.openEmbedModal()">

                                    <div class="imageAndTextWrapper">

                                        <img width="50"
                                            :src="require(`@/assets/images/${quizStore.embedOptions[0].icon}.png`)" />

                                        <div style="font-size: 14px; font-weight: bold; margin-top: 5px;">
                                            {{ quizStore.embedOptions[0].name }}
                                        </div>

                                    </div>

                                </div>

                                <div class="embedOption" @click="quizStore.openPopupModalOptions()">

                                    <div class="imageAndTextWrapper">

                                        <img width="50"
                                            :src="require(`@/assets/images/${quizStore.embedOptions[1].icon}.png`)" />

                                        <div style="font-size: 14px; font-weight: bold; margin-top: 5px;">
                                            {{ quizStore.embedOptions[1].name }}
                                        </div>

                                    </div>

                                </div>





                            </div>


                        </div>


                    </div>

                    <div v-if="quizStore.currentProjectTabType === 'integrations'" style="margin-bottom: 100px;">



                        <div class="integrationHeaderWrapper">

                            <div class="topHeaderText">
                                Explore integrations
                            </div>

                            <div class="descText">
                                Make Convertlyio even more powerful by using these tools with Zapier.
                            </div>

                        </div>

                        <div class="integrationsItemsWrapper">

                            <div v-for="(integration, index) in quizStore.integrations" :key="index" class="itemWrapper">


                                <div class="theItem">

                                    <div class="intLogo">

                                        <img width="20" style="float: left;"
                                            :src="require(`@/assets/integrations/${integration.icon_name}.png`)" />

                                    </div>

                                    <div class="intName">
                                        {{ integration.name }}
                                    </div>

                                    <div class="intDesc">
                                        {{ integration.desc }}
                                    </div>

                                    <div class="intLinks">


                                        <div class="linksWrapper">

                                            <a class="zapierLink" :href="integration.url_to_zapier" target="_blank">
                                                Zapier
                                            </a>

                                        </div>

                                    </div>



                                </div>


                            </div>


                        </div>


                        <div class="zapierWrapper">

                            <div class="zapLeft">

                                <div class="zapHeader">
                                    Not seeing your favourite tool?
                                </div>

                                <div class="zapDesc">
                                    Connect Convertlyio to Zapier to send submission data to 6,000+ apps.
                                </div>

                            </div>

                            <div class="zapRight">

                                <a class="button-5" href="https://zapier.com/how-it-works" style="float: right;"
                                    target="_blank">Learn more</a>

                            </div>


                        </div>


                    </div>

                    <div v-if="quizStore.currentProjectTabType === 'settings'">


                        <div class="settingsWrapper">


                            <div class="generalHeader">

                                General

                            </div>

                            <div>

                                <hr class="lineDivider">

                                <div class="lineItem">


                                    <div class="lineItemLeft">

                                        <div class="headerLeft">
                                            New submission email notification
                                        </div>

                                        <div class="headerLeftBottom">
                                            Get an email for each successful submission.
                                        </div>

                                    </div>

                                    <div class="lineItemRight">




                                        <div class="switchOn" @click="quizStore.toggleEmailNotify"
                                            v-if="quizStore.currentQuiz.should_email_notify === 'yes'">

                                            YES

                                        </div>

                                        <div v-else class="switchOff" @click="quizStore.toggleEmailNotify">
                                            NO
                                        </div>


                                    </div>

                                </div>


                            </div>




                        </div>





                    </div>

                </div>





            </div>


        </div>



        <!-- Modal Responses -->
        <div v-if="quizStore.showModalResponseDetailModal" class="modal-overlay">

            <div class="modal-box">

                <img class="myCloseX" @click="quizStore.closeResponseDetailModal"
                    :src="require(`@/assets/images/x_close_black.png`)" />


                <div class="modal-content">

                    <!-- <button @click="quizStore.closeResponseDetailModal">close</button>  -->


                    <div class="tabsWrapper">


                        <div class="tabBtn" v-for="(responseTabOption, index) in quizStore.responseTabOptions" :key="index"
                            :class="{ tabBtnClicked: responseTabOption.type === quizStore.currentResponseTabType }"
                            @click="quizStore.currentResponseTabType = responseTabOption.type">
                            {{ responseTabOption.name }}
                        </div>


                    </div>

                    <div class="modalBodyWrapper">


                        <div v-if="quizStore.currentResponseTabType === 'form_data'">




                            <ul class="list-group list-group-flush">

                                <li v-for="(formInputValue, index) in quizStore.publicUserResponseData.formData"
                                    :key="index" class="list-group-item">


                                    <div style="float: left; margin-top: 10px; margin-bottom: 10px;"
                                        v-if="formInputValue.input_field_details">


                                        <div v-if="formInputValue.type === 'text_input'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b>
                                            {{ formInputValue.string_value }}

                                        </div>

                                        <div v-if="formInputValue.type === 'date_input'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b> {{
                                                quizStore.formatDate(formInputValue.string_value) }}

                                        </div>

                                        <div v-if="formInputValue.type === 'time_input'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b> {{
                                                quizStore.formatTime(formInputValue.string_value) }}

                                        </div>

                                        <div v-if="formInputValue.type === 'email_input'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b>
                                            {{ formInputValue.string_value }}

                                        </div>

                                        <div v-if="formInputValue.type === 'textbox'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b>
                                            {{ formInputValue.string_value }}

                                        </div>



                                    </div>


                                    <div v-if="formInputValue.type === 'checkbox'"
                                        style="float: left; margin-top: 10px; margin-bottom: 10px;">

                                        <div v-if="formInputValue.checkbox_details">

                                            <div v-if="formInputValue.is_checkbox_selected === 1">
                                                <b>{{ formInputValue.checkbox_details.text_body }}:</b>
                                                <img width="20" :src="require(`@/assets/images/yes_checked.png`)" /> Checked
                                            </div>

                                            <div v-else>
                                                <b>{{ formInputValue.checkbox_details.text_body }}: </b>
                                                <img width="15" :src="require(`@/assets/images/not_checked1.png`)" /> Not
                                                checked
                                            </div>





                                        </div>

                                    </div>



                                </li>

                            </ul>






                        </div>

                        <div v-if="quizStore.currentResponseTabType === 'questions'">







                            <ul class="list-group list-group-flush">


                                <div v-if="quizStore.currentQuiz.type === 'answer_based_outcomes'" class="outcomeContainer">


                                    <div v-if="quizStore.publicUserResponseData.winning_outcome">
                                        Outcome: {{ quizStore.publicUserResponseData.winning_outcome.page_details.page_title
                                        }}
                                    </div>


                                </div>

                                <div v-if="quizStore.currentQuiz.type === 'score_based_outcomes'" class="outcomeContainer">


                                    <div v-if="quizStore.publicUserResponseData.scoreOutcome">
                                        Score: {{ quizStore.publicUserResponseData.scoreOutcome.score }} / {{
                                            quizStore.publicUserResponseData.totalQuestionsCount }}
                                    </div>


                                </div>



                                <li v-for="(question, index) in quizStore.publicUserResponseData.quiz_results" :key="index"
                                    class="list-group-item">

                                    <div style="float: left;">




                                        <div class="questionContainer">

                                            <div class="questionArea">

                                                {{ index + 1 }} - {{ question.question_text }}

                                            </div>

                                            <div class="answerArea">


                                                <div v-for="(answer, index) in question.answers" :key="index"
                                                    class="answerBox">
                                                    {{ answer.answer_text }}
                                                </div>


                                            </div>


                                            <br>
                                        </div>




                                    </div>




                                </li>




                            </ul>



                        </div>

                        <div v-if="quizStore.currentResponseTabType === 'metadata'">




                            <ul class="list-group list-group-flush">



                                <li class="list-group-item">

                                    <div style="float: left;">

                                        <div v-if="quizStore.publicUserResponseData.meta_data.geoData">
                                            <b>Country:</b> {{
                                                quizStore.publicUserResponseData.meta_data.geoData.country_name }}
                                        </div>

                                        <div v-else>
                                            <b>Country:</b> N/A
                                        </div>



                                    </div>


                                </li>

                                <li class="list-group-item">

                                    <div style="float: left;">
                                        <b>Submission Time:</b> {{
                                            quizStore.publicUserResponseData.meta_data.submission_time }}
                                    </div>



                                </li>

                                <li class="list-group-item">

                                    <div style="float: left;">
                                        <b>Submission Date:</b> {{
                                            quizStore.publicUserResponseData.meta_data.submission_date }}
                                    </div>



                                </li>

                                <li class="list-group-item">

                                    <div style="float: left;">
                                        <b>Device Type:</b> {{ quizStore.publicUserResponseData.meta_data.device_type }}
                                    </div>



                                </li>

                                <li class="list-group-item">

                                    <div style="float: left;">
                                        <b>Duration:</b> {{ quizStore.publicUserResponseData.meta_data.duration }}
                                    </div>


                                </li>


                            </ul>






                        </div>


                    </div>






                </div>
            </div>

        </div>

        <!-- Embed Modal -->
        <div v-if="quizStore.showEmbedModal" class="modal-overlay1">

            <div class="modal-box1">

                <img class="closeX" @click="quizStore.closeEmbedModal"
                    :src="require(`@/assets/images/x_close_black.png`)" />


                <div class="modal-content1">


                    <div class="modalHeader">
                        Adding your embed code
                    </div>

                    <div class="modalHeader2">
                        Step 1
                    </div>

                    <div class="embedModalBody">
                        Copy and paste this script in the &lt;head&gt; section of your website 
                    </div>

                    <div class="codeBox2">
                        {{ quizStore.getScriptCodeForHeader() }}
                    </div>

                    <div class="modalHeader2">
                        Step 2
                    </div>

                    <div class="embedModalBody"> 
                        Paste this HTML code snippet on the page where you want the embed to appear.
                    </div>

                    <div class="codeBox">

                        {{ quizStore.getIframeString() }}

                    </div>

                    <div class="bottomButtons">


                        <button class="button-13" role="button" @click="copyIframeCode">Copy</button>

                    </div>




                </div>
            </div>

        </div>


        <!-- Popup Options Modal -->
        <div v-if="quizStore.showPopupOptionsModal" class="modal-overlay2">

            <div class="modal-box2">

                <img class="closeX" @click="quizStore.closePopupModalOptions"
                    :src="require(`@/assets/images/x_close_black.png`)" />




                <div class="modal-content2">


                    <div class="modalLeftSide">

                        <div class="sideBarHeader">
                            Popup Options
                        </div>


                        <div class="dropdownWrapper">

                            <select class="form-control" v-model="quizStore.currentPopupOption">
                                <option v-for="option in quizStore.popupOptions" :value="option.type" :key="option">
                                    {{ option.name }}
                                </option>
                            </select>

                        </div>

                        <div class="timeBox" v-if="quizStore.currentPopupOption === 'timer'">

                            <div>
                                <label class="form-label" style="float: left; font-size: 14px;">Time delay (seconds)</label>
                                <input v-model="quizStore.defaultTime" type="number" min="0" class="form-control">
                            </div>


                        </div>


                    </div>

                    <div class="modalRightSide">

                        <div class="rightSideWrapper">

                            <div v-if="quizStore.currentPopupOption === 'load'">



                                <div class="textColumn">

                                    <p class="h5" style="margin-bottom: 20px; font-weight: bold;">Add instant popup to your
                                        website</p>


                                    <p class="h6">Step 1</p>

                                    <p style="text-align: left;">
                                        Copy and paste this script in the &lt;head&gt; section of your website 

                                    </p>


                                    <div class="codeBox2">
                                        {{ quizStore.getScriptCodeForHeader() }}
                                    </div>

                                    <button class="button-13" role="button"
                                        @click="quizStore.copyCode('headerScript')">Copy</button>

                                    <p></p>
                                    <p></p>

                                    <p class="h6">Step 2</p>

                                    <p style="text-align: left;">
                                        Copy and paste this code in the HTML of the specific page you want it to load
                                    </p>

                                    <div class="codeBox2">
                                        {{ quizStore.getLoadPopupInstantlyCode() }}
                                    </div>

                                    <button class="button-13" role="button"
                                        @click="quizStore.copyCode('immmediatePopup')">Copy</button>

                                </div>

                            </div>

                            <div v-if="quizStore.currentPopupOption === 'timer'">

                                <div class="textColumn">

                                    <p class="h5" style="margin-bottom: 20px; font-weight: bold;">Add a delayed popup to
                                        your website</p>


                                    <p class="h6">Step 1</p>

                                    <p style="text-align: left;">
                                        Copy and paste this script in the &lt;head&gt; section of your website

                                    </p>


                                    <div class="codeBox2">
                                        {{ quizStore.getScriptCodeForHeader() }}
                                    </div>

                                    <button class="button-13" role="button"
                                        @click="quizStore.copyCode('headerScript')">Copy</button>

                                    <p></p>
                                    <p></p>

                                    <p class="h6">Step 2</p>

                                    <p style="text-align: left;">
                                        Copy and paste this code in the HTML of the specific page you want it to load
                                    </p>

                                    <div class="codeBox2">
                                        {{ quizStore.getTimerPopupCode() }}
                                    </div>

                                    <button class="button-13" role="button"
                                        @click="quizStore.copyCode('timerPopup')">Copy</button>

                                </div>

                            </div>


                            <div v-if="quizStore.currentPopupOption === 'exit'">
                                <div class="textColumn">

                                    <p class="h5" style="margin-bottom: 20px; font-weight: bold;">Add a exit popup to
                                        your website</p>


                                    <p class="h6">Step 1</p>

                                    <p style="text-align: left;">
                                        Copy and paste this script in the &lt;head&gt; section of your website

                                    </p>


                                    <div class="codeBox2">
                                        {{ quizStore.getScriptCodeForHeader() }}
                                    </div>

                                    <button class="button-13" role="button"
                                        @click="quizStore.copyCode('headerScript')">Copy</button>

                                    <p></p>
                                    <p></p>

                                    <p class="h6">Step 2</p>

                                    <p style="text-align: left;">
                                        Copy and paste this code in the HTML of the specific page you want it to load
                                    </p>

                                    <div class="codeBox2">
                                        {{ quizStore.getExitPopupCode() }}
                                    </div>

                                    <button class="button-13" role="button"
                                        @click="quizStore.copyCode('exitPopup')">Copy</button>

                                </div>
                            </div>


                            <div v-if="quizStore.currentPopupOption === 'button'">
                                <div class="textColumn">

                                    <p class="h5" style="margin-bottom: 20px; font-weight: bold;">Add a popup triggered by a
                                        button to
                                        your website</p>


                                    <p class="h6">Step 1</p>

                                    <p style="text-align: left;">
                                        Copy and paste this script in the &lt;head&gt; section of your website

                                    </p>


                                    <div class="codeBox2">
                                        {{ quizStore.getScriptCodeForHeader() }}
                                    </div>

                                    <button class="button-13" role="button"
                                        @click="quizStore.copyCode('headerScript')">Copy</button>

                                    <p></p>
                                    <p></p>

                                    <p class="h6">Step 2</p>

                                    <p style="text-align: left;">
                                        Copy and paste this code in the HTML of the specific page you want it to load
                                    </p>

                                    <div class="codeBox2">
                                        {{ quizStore.getButtonCodeForPopup() }}
                                    </div>

                                    <button class="button-13" role="button"
                                        @click="quizStore.copyCode('buttonPopup')">Copy</button>

                                </div>
                            </div>


                        </div>



                    </div>



                </div>


            </div>

        </div>

        <div class="modal fade" id="updateQuizModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Rename
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form @submit.prevent="updateThisQuiz">

                        <div class="modal-body">


                            <div class="mb-3">

                                <input v-model="quizStore.currentQuiz.name" type="text" class="form-control"
                                    placeholder="Quiz name" required>
                            </div>


                        </div>

                        <div class="modal-footer">

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Cancel
                            </button>

                            <button type="submit" class="btn btn-success">
                                Save
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteQuizModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="text-align: left;">
                            Are you sure you want to delete {{ quizStore.currentQuiz.name }}?
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form @submit.prevent="deleteAQuiz">

                        <div class="modal-body" style="text-align: left;">


                            <b style="color: red;">IMPORTANT:</b> Deleting <b>{{ quizStore.currentQuiz.name }}</b>
                            will delete all data related to it, including leads. This data cannot be recovered.


                        </div>

                        <div class="modal-footer">

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Cancel
                            </button>

                            <button type="submit" class="btn btn-danger">
                                Delete Quiz
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>





    </div>
</template>

<script>
import { useQuizStore } from '@/stores/QuizStore'
import MainSideBarVue from '@/components/MainSideBar.vue';
import { onMounted, } from 'vue';
import { useRouter } from 'vue-router';
import { Modal } from "bootstrap";


export default {
    name: 'MainPortal',

    components: {
        MainSideBarVue
    },

    props: ['random_string', 'workspaceUUID'],


    setup(props) {


        const quizStore = useQuizStore()
        const router = useRouter();

        onMounted(async () => {

            await quizStore.getAllQuizResponses(props.random_string);

        });




        const copyToClipboard = async () => {
            try {
                await navigator.clipboard.writeText(quizStore.buildShareLink());
                alert('Copied');
            } catch (e) {
                alert('Cannot copy');
            }
        };


        const copyIframeCode = async () => {
            try {
                await navigator.clipboard.writeText(quizStore.getIframeString());
                alert('Copied');
            } catch (e) {
                alert('Cannot copy');
            }
        };



        const navigateToEditor = () => {
            router.push({ name: 'MyEditorV2', params: { quiz_random_string: quizStore.currentQuiz.random_string } });
        };



        const hideModal = (modalName) => {

            //hide modal
            var modal = document.getElementById(modalName);
            var thisModal = Modal.getOrCreateInstance(modal);

            thisModal.hide();
        };

        const showModal = (modalName) => {

            var modal = new Modal(
                document.getElementById(modalName),
                {
                    keyboard: false,
                }
            );

            modal.show();
        }

        const showUpdateQuizModal = () => {


            showModal('updateQuizModal')

        }

        const showDeleteQuizModal = () => {

            showModal('deleteQuizModal')



        }

        const deleteAQuiz = () => {

            quizStore.deleteQuiz()

            hideModal('deleteQuizModal')

            navigateToWorkspace()


        }

        const updateThisQuiz = () => {

            quizStore.updateQuiz()

            hideModal('updateQuizModal')


        }

        const navigateToWorkspace = () => {
            router.push({ name: 'ViewAWorkspace', params: { workspaceUUID: props.workspaceUUID } });
        };






        return {

            quizStore, copyToClipboard, copyIframeCode, navigateToEditor, showUpdateQuizModal, updateThisQuiz,
            showDeleteQuizModal, deleteAQuiz

        }
    }



};
</script>

<style scoped>
.dashboardBodyWrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.MainContentArea {
    width: 83%;
    overflow-y: auto;

}



.contentHeaderWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    margin-bottom: 20px;
    /* background-color: antiquewhite; */
    display: flex;
}

.contentBodyWrapper {
    /* background-color: aquamarine; */
    padding-left: 25px;
    padding-right: 25px;

}

.leftDivWrapper {
    /* background-color: rgb(167, 167, 138); */
    width: 50%;
}

.rightDivWrapper {
    /* background-color: rgb(206, 197, 206); */
    width: 50%;
}

.headerText {
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    /* float: left;
    display: flex; */
}

.headerTextWrapper {
    float: left;
    display: flex;
}

.dividerLine {
    width: 95%;
    color: #afb2c6;
}


.editBtn {
    /* background-color: #3EB489; */
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    /* width: 70px; */
    float: right;
}


.editBtn:hover {
    background-color: #f7fafa;
}

.imgIcon {
    margin-right: 2px;
}

.imgIcon2 {
    margin-right: 4px;
    cursor: pointer;
}

.tabsWrapper {
    display: flex;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    margin-bottom: 30px;
}

.tabBtn {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #f1efef;
    font-size: 13px;
    color: rgb(8, 5, 5);
    cursor: pointer;
}

.tabBtnClicked {
    background: #f9f9f9;
    color: rgb(33, 31, 31);
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
}

.tabBtn:hover {
    background: #f9f9f9;
    color: rgb(33, 31, 31);
    /* border-color: #18191d; */
}

.tabContentWrapper {
    /* background-color: #afb2c6; */
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 15px;
}

.rowBG {
    cursor: pointer;
}

.tableHeader {
    margin-top: 18px;
    margin-bottom: 10px;


}

.exportBtn {

    background: white;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;

    font-family: "Amazon Ember", sans-serif;
    font-size: 11px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    /* width: 70px; */


}



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.modal-content {
    text-align: center;
}


.modalBodyWrapper {
    width: 100%;
    /* background-color: #afb2c6; */
    max-height: 600px;
    /* Set a maximum height */
    overflow-y: auto;
    /* Enable vertical scrollingll */
}

.closeX {

    width: 15px;
    float: right;
    margin-bottom: 18px;
    cursor: pointer;

}

.myCloseX {

    width: 15px;
    float: right;
    margin-bottom: 18px;
    cursor: pointer;

}

.questionContainer {
    display: flex;
    flex-direction: column;
    /* background-color: gray; */
    align-items: flex-start;
    margin-top: 20px;
}

.questionArea {
    font-weight: bold;
    font-size: 17px;
}

.answerArea {
    font-size: 15px;
}


.answerBox {
    max-width: 500px;
    /* background-color: #afb2c6; */
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.outcomeContainer {
    max-width: 300px;
    /* background-color: #8c98e4; */
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.embeddingWrapper {
    flex-direction: column;
    display: flex;
    /* background-color: gray; */
    margin-top: 40px;

}



.embedOption {
    width: 205px;
    height: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-right: 20px;
    padding: 20px;
    border-radius: 6px;
    display: flex;
    /* Use flexbox */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}


.embedOption:hover {
    background-color: rgb(244, 244, 244);
    cursor: pointer;
}

.horizontalEmbedOptions {
    display: flex;
    flex-wrap: wrap;

}

.shareLinkWrapper {
    width: 500px;
    flex-direction: column;
    display: flex;
}

.shareHeader {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.shareBody {
    text-align: left;
    margin-bottom: 10px;
}

.inputBoxWrapper {
    width: 300px;

}


.bottomOptions {
    margin-top: 10px;
    /* background-color: #afb2c6; */
    width: 320px;

}


.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 13px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 65px;
    float: left;
}

.button-13:hover {
    background-color: #f7fafa;
}

.button-13:focus {
    border-color: #feffff;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
}

.myInput {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


.embedHeader {
    text-align: left;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
}


.imageAndTextWrapper {
    flex-direction: column;
    display: flex;
    align-items: center;

}


.modal-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-box1 {
    background-color: white;
    width: 600px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal-content1 {
    display: flex;
    flex-direction: column;

}



.modalHeader {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 15px;
}

.modalHeader2 {
    font-weight: bold;
    font-size: 15px;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 15px;
}


.embedModalBody {
    text-align: left;
    margin-bottom: 15px;
}


.codeBox {
    background-color: #1c2828;
    color: white;
    padding: 15px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: left;
}

.bottomButtons {
    /* background-color: #afb2c6; */
}




.modal-overlay2 {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

}

.modal-box2 {

    background-color: white;
    width: 1100px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;

}

.modal-content2 {
    display: flex;
    height: 600px;
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}

.modalLeftSide {
    width: 30%;
    /* background-color: lightgray;  */
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

}

.modalRightSide {
    width: 70%;
    /* background-color: lightblue;  */
    overflow-y: auto;
    box-shadow: rgba(232, 201, 201, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.sideBarHeader {
    background-color: rgb(215, 226, 230);
    width: 100%;
    padding: 10px;
    text-align: left;
    font-weight: bold;
}

.dropdownWrapper {

    /* background-color: rgb(1, 18, 24);  */
    width: 100%;
    padding: 10px;
}

.rightSideWrapper {
    /* background-color: #afb2c6; */
    padding: 20px;

}

.textColumn {
    /* background-color: #afb2c6;  */
    flex-direction: column;
    display: flex;
    align-items: flex-start;
}


.codeBox2 {
    background-color: #1c2828;
    color: white;
    padding: 15px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-align: left;
    width: 100%;
}


.timeBox {
    padding: 10px;
}

.myBackIcon {
    /* color: red; */
    font-size: 1rem;
    margin-right: 10px;
}

.myBackIcon:hover {
    color: green;
    cursor: pointer;
}


.linkStyle {
    text-decoration: none;
    color: inherit;
}


.integrationHeaderWrapper {
    width: 100%;
    /* background-color: #afb2c6; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.topHeaderText {
    /* background-color: #737fd2; */
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}

.descText {
    /* background-color: #213be9; */
    text-align: left;
}


.integrationsItemsWrapper {
    width: 100%;
    /* background-color: #afb2c6; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;

}


.itemWrapper {
    /* background-color: #c8d595; */
    flex-basis: calc(100% / 3);
    box-sizing: border-box;
    padding: 5px;

}

.theItem {
    /* background-color: plum; */
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: column;
    height: 150px;

}

.intLogo {
    /* background-color: rgb(219, 210, 219); */

}

.intName {
    /* background-color: plum; */
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 7px;
}

.intDesc {
    /* background-color: yellow; */
    text-align: left;
    font-size: 12px;
    margin-bottom: 10px;
}

.intLinks {
    /* background-color: rgb(0, 255, 26); */
    text-align: left;
    font-size: 12px;
}

.zapierLink {
    color: rgb(255, 85, 0);
    font-weight: bold;
    font-size: 13px;
    text-decoration: none;
}


.zapierWrapper {
    /* background-color: rgb(128, 128, 128); */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    padding: 20px;
}

.zapLogo {
    background-color: #e3ffe7;
    margin-bottom: 10px;
}

.zapHeader {
    /* background-color: #93d99d; */
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
}

.zapDesc {
    /* background-color: #93d99d; */
    margin-bottom: 10px;
    text-align: left;
}

.zapLeft {
    /* background-color: #afb2c6; */
    width: 50%;
}

.zapRight {
    /* background-color: #304bff; */
    width: 50%;
}


.button-5 {
    align-items: center;
    background-clip: padding-box;
    background-color: #fa6400;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
    margin-top: 12px;
}

.button-5:hover,
.button-5:focus {
    background-color: #fb8332;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
    transform: translateY(-1px);
}

.button-5:active {
    background-color: #c85000;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}

.settingsWrapper {
    /* background-color: #afb2c6; */
    width: 100%;
}

.generalHeader {
    width: 100%;
    /* background-color: #334df7; */
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.lineItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: #8ad0a3; */
    margin-top: 0px;
    padding-top: 15px;
    padding-bottom: 15px;

}

.lineItemLeft {
    width: 50%;
    /* background-color: #7782c9; */
    text-align: left;
}

.lineItemRight {
    width: 50%;
    /* background-color: #8d96ce; */
}

.headerLeft {
    font-size: 16px;
    font-weight: bold;
}

.headerLeftBottom {
    font-size: 14px;
}

.toggleBtn {
    float: right;
    margin-top: 9px;
}

.lineDivider {
    color: rgb(203, 203, 203);
    margin-bottom: 0px;
    margin-top: 0px;
}


.switchOn {
    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: #289d72;
    font-weight: bold;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    float: right;
    margin-top: 9px;
    color: white;
}

.switchOff {

    float: right;
    margin-top: 9px;
    width: 50px;
    height: 20px;
    border-radius: 10px;
    background: #e6edea;
    font-weight: bold;
    font-size: 10px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}



.aDropdownItem {
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
}

.aDropdownItem:hover {
    background-color: #e6e6e9;
    /* padding: 5px; */
    border-radius: 5px;
}

.dropdown-menu {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border: none;
    padding-left: 5px;
    padding-right: 5px;
}


.mydots {
    width: 20px;
    cursor: pointer;
}

.mydots:hover {
    background-color: #e6e6e9;
    padding: 2px;
    border-radius: 5px;
}



.exportBtnWrapper {
    /* background-color: rgb(214, 213, 213); */
    width: 100%;
    display: flex;
    flex-direction: row;
}


.export1 {
    margin-right: 14px;
    font-size: 10px;
    padding: 5px;

}

.export2 {
    font-size: 10px;
    padding: 5px;
}


.export1:hover {
    background-color: #f9f9f9;
    margin-right: 14px;
    font-size: 10px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.export2:hover {
    background-color: #f9f9f9;
    font-size: 10px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.summaryWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.summaryGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.summaryItem {
    width: calc(33.33% - 10px);
    /* Subtracting margin if present */
    margin: 5px;
    /* Adjust margin as needed */
    /* background-color: #c6d5d0; */
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    background: #FFEEEE;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #DDEFBB, #FFEEEE);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #DDEFBB, #FFEEEE);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */



}


.summHeader {
    font-size: 25px;
    font-weight: bold;
}

.summBody {}

.lastDataTimeText{
    text-align: left;
    font-size: 12px; 
    padding-left: 12px;
    margin-bottom: 4px;
 
}


</style>