<template>
    <div>

        <div class="dashboardBodyWrapper">


            <MainSideBarVue />



            <div class="MainContentArea">


                <div class="contentHeaderWrapper">

                    <div class="leftDivWrapper">

                        <div class="headerText">

                            <router-link class="linkStyle" :to="{ name: 'Home' }">

                                {{ workspaceStore.currentWorkspaceObj.name }} 

                            </router-link>


                        </div>



                    </div>

                    <div class="rightDivWrapper">

                        <router-link class="btn btn-success button-3"
                        :to="{ name: 'CreateQuiz', params: { workspaceUUID: workspaceStore.currentWorkspaceObj.uuid }}" > 
                        New Quiz
                        </router-link>


                   

                        <!-- <button @click="showCreateQuizModal" class="button-3" role="button">New Quiz +</button> -->

                        <!-- <div class="dropdown" style="float: right;">

                            <a class="btn btn-success dropdown-toggle button-3" href="#" role="button" id="dropdownMenuLink"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                New Quiz
                            </a>

                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">


                                <li @click="showCreateQuizModal(workspaceStore.quizzes)">
                                    <div class=" aDropdownItem" href="#">Start from scratch</div>
                                </li>

                                <li>
                                    <div class=" aDropdownItem" href="#">Templates (Coming soon)</div>
                                </li>

                            </ul>
                        </div> -->

                    </div>



                </div>


                <center>
                    <hr class="dividerLine">
                </center>



                <div class="contentBodyWrapper">







                    <div v-for="(quiz, index) in workspaceStore.quizzes" :key="index">


    

                        <!-- <router-link class="linkStyle"
                            :to="{ name: 'MainPortal', params: { random_string: quiz.random_string, workspaceUUID: workspaceStore.currentWorkspaceObj.uuid }}" > -->

                        <div class="ListItem" @click="navigateToProject(quiz)">

                            <div class="leftSide">

                                <div class="leftWrapper">

                                    <div class="topArea"> 

                                        <div style="float: left; font-weight: bold; text-align: left;">
                                            {{ quiz.name }} - 
                                            <span v-if="quiz.is_live === 'yes'" class="badge bg-success">Live</span>
                                            <span v-if="quiz.is_live === 'no'" class="badge bg-dark">Draft</span>
                                        </div>

                                    </div>

                                    <div class="bottomArea">

                                        <div v-if="quiz.public_users_fully_completed" style="float: left; font-size: 13px;">
                                            {{ quiz.public_users_fully_completed.length }} Submissions
                                        </div>
 

                                    </div>

                                </div>



                            </div>

                            <div class="rightSide">

                                <div style="float: right; margin-right: 20px;" @click.stop="showDeleteQuizModal(quiz)"> <img width="13"
                                        style="margin-top: 15px;" :src="require(`@/assets/images/t_trash.png`)" />
                                </div>


                              
                                <!--                                     
                                    <div class="optionsWrapper">

                                        <div class="boxy">
                                           
                              
                                                <div @click.stop="showDeleteQuizModal(quiz)"> <img width="13"
                                                        style="margin-top: -4px;"
                                                        :src="require(`@/assets/images/t_trash.png`)" />Delete</div>
                                            
                                        </div>

                                    </div> -->

                                <!-- <div class="dropdown"> -->

                                <!-- <svg id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                            xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                            class="bi bi-three-dots mydots" viewBox="0 0 16 16">
                                            <path
                                                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                        </svg>

                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                            <li>
                                                <router-link class="linkStyle"
                                                    :to="{ name: 'MyEditor', params: { quiz_random_string: quiz.random_string } }">

                                                    <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="15"
                                                            :src="require(`@/assets/images/p_edit.png`)" />Edit</div>


                                                </router-link>

                                            </li>

                                            <li>
                                                <router-link class="linkStyle"
                                                    :to="{ name: 'ViewQuizResponse', params: { quiz_random_string: quiz.random_string } }">

                                                    <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="17"
                                                            :src="require(`@/assets/images/leads.png`)" />Leads</div>


                                                </router-link>

                                            </li>

                                            <li>
                                                <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="16"
                                                        :src="require(`@/assets/images/m_view.png`)" />Preview</div>
                                            </li>

                                            <li @click="showUpdateQuizModal(quiz)">
                                                <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="16"
                                                        :src="require(`@/assets/images/rename.png`)" />Rename</div>
                                            </li>

                                            <li>
                                                <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="16"
                                                        :src="require(`@/assets/images/m_share.png`)" />Share</div>
                                            </li>



                                            <li @click="showDeleteQuizModal(quiz)">
                                                <div class=" aDropdownItem" href="#"> <img class="imgIcon" width="13"
                                                        style="margin-top: -4px;"
                                                        :src="require(`@/assets/images/t_trash.png`)" />Delete</div>
                                            </li>

                                        </ul> -->

                                <!-- </div> -->




                            </div>

                        </div>

                        <!-- </router-link> -->



                    </div>





                </div>



            </div>


        </div>


        <div class="modal fade" id="createQuizModal" tabindex="-1">

            <div class="modal-dialog" style="max-width: 800px;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            New Project
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form @submit.prevent="storeNewQuiz">

                        <div class="modal-body">


                            <div class="mb-3">

                                <input v-model="quizStore.quizFormData.name" type="text" class="form-control"
                                    placeholder="Project name" required>



                                <div class="projectTypesContainer">


                                    <div v-for="(quizType, index) in quizStore.quizTypes" :key="index"
                                        @click="quizStore.setCurrentQuizType(quizType)" class="projectItem"
                                        :class="{ projectItemSelected: quizStore.currentlySelectedQuizType.id === quizType.id }">

                                        <div class="projectImage">


                                            <img :src="require(`@/assets/images/${quizType.img_icon}.png`)"
                                                :width="quizType.width" />

                                        </div>

                                        <div class="projectName">

                                            {{ quizType.name }}

                                        </div>

                                        <div class="projectDesc">

                                            {{ quizType.desc }}

                                        </div>

                                    </div>


                                </div>




                            </div>


                        </div>

                        <div class="modal-footer">

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Cancel
                            </button>

                            <button type="submit" class="btn btn-success">
                                Create
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>

        <div class="modal fade" id="updateQuizModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Rename
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form @submit.prevent="updateThisQuiz">

                        <div class="modal-body">


                            <div class="mb-3">

                                <input v-model="quizStore.currentQuiz.name" type="text" class="form-control"
                                    placeholder="Quiz name" required>
                            </div>


                        </div>

                        <div class="modal-footer">

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Cancel
                            </button>

                            <button type="submit" class="btn btn-success">
                                Save
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>

        <div class="modal fade" id="deleteQuizModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="text-align: left;">
                            Are you sure you want to delete {{ quizStore.currentQuiz.name }}?
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form @submit.prevent="deleteAQuiz">

                        <div class="modal-body" style="text-align: left;">


                            <b style="color: red;">IMPORTANT:</b> Deleting <b>{{ quizStore.currentQuiz.name }}</b>
                            will delete all data related to it, including leads. This data cannot be recovered.


                        </div>

                        <div class="modal-footer">

                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Cancel
                            </button>

                            <button type="submit" class="btn btn-danger">
                                Delete Quiz
                            </button>
                        </div>

                    </form>


                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { useWorkspaceStore } from '@/stores/WorkspaceStore'
import { useQuizStore } from '@/stores/QuizStore'
import { useAuthStore } from '@/stores/AuthStore'
import MainSideBarVue from '@/components/MainSideBar.vue';
import { Modal } from "bootstrap";
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';




export default {
    name: 'ViewAWorkspace',

    components: {
        MainSideBarVue
    },

    props: ['workspaceUUID'],

    setup(props) {

        const authStore = useAuthStore()
        const workspaceStore = useWorkspaceStore()
        const quizStore = useQuizStore()
        const router = useRouter();


        //fetch data
        workspaceStore.fetchThisWorkspace(props.workspaceUUID)



        onMounted(() => {
 
            //fetch user data to check live project limiy
            authStore.getUserData() 


        });



        const showCreateQuizModal = (quizzes) => {

            // console.log('quiizzes count: ', quizzes.length)
            // console.log('live_projects_limit: ', authStore.currentPlan.live_projects_limit)

            //check if project limit reached
            if(quizzes.length === authStore.currentPlan.live_projects_limit){

                alert("Oops looks like you have reached your project limit. Upgrade to create more projects.")

                router.push({ name: 'PaidPlans'}); 

                return;

            }

            //reset field
            quizStore.quizFormData.name = ''

            showModal('createQuizModal')
        }

        const showUpdateQuizModal = (quiz) => {

            setCurrentQuiz(quiz)

            showModal('updateQuizModal')

        }

        const showDeleteQuizModal = (quiz) => {

            setCurrentQuiz(quiz)

            showModal('deleteQuizModal')

        }

        const setCurrentQuiz = (quiz) => {

            quizStore.currentQuiz = quiz
            console.log(quizStore.currentQuiz)

        }


        const storeNewQuiz = () => {

            quizStore.storeQuiz(workspaceStore.currentWorkspaceObj.uuid)

            //refresh list
            workspaceStore.fetchThisWorkspace(workspaceStore.currentWorkspaceObj.uuid)

            hideModal('createQuizModal')

        }

        const updateThisQuiz = () => {

            quizStore.updateQuiz()

            //refresh list
            workspaceStore.fetchThisWorkspace(workspaceStore.currentWorkspaceObj.uuid)

            hideModal('updateQuizModal')


        }

        const deleteAQuiz = () => {

            quizStore.deleteQuiz() 

            workspaceStore.fetchThisWorkspace(props.workspaceUUID)

            hideModal('deleteQuizModal')

            


        }

        const showModal = (modalName) => {

            var modal = new Modal(
                document.getElementById(modalName),
                {
                    keyboard: false,
                }
            );

            modal.show();
        }

        const hideModal = (modalName) => {

            //hide modal
            var modal = document.getElementById(modalName);
            var thisModal = Modal.getOrCreateInstance(modal);

            thisModal.hide();
        };


        const navigateToProject = (quiz) => {
            router.push({ name: 'MainPortal', params: { random_string: quiz.random_string, workspaceUUID: workspaceStore.currentWorkspaceObj.uuid } });
        };

        return {
            authStore, workspaceStore, showCreateQuizModal, storeNewQuiz,
            quizStore, showUpdateQuizModal, updateThisQuiz, showDeleteQuizModal,
            deleteAQuiz, navigateToProject 
        }

    }


};
</script>

<style scoped>
.dashboardBodyWrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.MainContentArea {
    width: 83%;
    overflow-y: auto;

}



.contentHeaderWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    /* background-color: antiquewhite; */
    display: flex;
}

.contentBodyWrapper {
    /* background-color: aquamarine; */
    padding-left: 25px;
    padding-right: 25px;

}

.leftDivWrapper {
    /* background-color: rgb(167, 167, 138); */
    width: 50%;
}

.rightDivWrapper {
    /* background-color: rgb(206, 197, 206); */
    width: 50%;
}

.headerText {
    font-weight: bold;
    font-size: 25px;
    float: left;
}

.headerText:hover {
    color: #298f3f;
    cursor: pointer;
}

.dividerLine {
    width: 95%;
    color: #afb2c6;
}




/* CSS */
.button-3 {
    float: right;
    appearance: none;
    background-color: #3aa358;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
}

.button-3:hover {
    background-color: #2c974b;
}

.button-3:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
}

.button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
}

.button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


.ListItem {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    /* background-color: #afb2c6; */
    /* height: 50px; */
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
    /* cursor: pointer; */
}

.ListItem:hover {
    background-color: #f9f9f9;
    cursor: pointer;
}

.leftSide {
    width: 50%;
    /* background-color: yellow; */
}

.rightSide {
    width: 50%;
    /* background-color: blue; */
}

.topArea {
    /* background-color: #298e46; */
}

.bottomArea {
    /* background-color: purple; */
}

.leftWrapper {
    display: flex;
    flex-direction: column;
}


.moreOptions {
    width: 15px;
    float: right;
    margin-top: 12px;
    margin-right: 10px;

}

.mydots {
    width: 20px;
    height: 20px;
    float: right;
    margin: 10px;

}

.mydots:hover {
    width: 20px;
    height: 20px;
    float: right;
    margin: 10px;
    background-color: #d5ebdb;
    border-radius: 3px;
}

.aDropdownItem {
    font-size: 15px;
    padding: 10px;
    cursor: pointer;
}

.aDropdownItem:hover {
    background-color: #f9f9f9;
}

.dropdown-menu {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border: none;
}

#dropdownMenuButton1 {
    cursor: pointer;
}

.imgIcon {
    margin-right: 6px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}


.projectItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: gray; */
    background: linear-gradient(174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin: 10px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    /* height: 300px; */
}


.projectItem:hover {

    background: linear-gradient(45deg, #a8d6c5 0%, #fdfbb4 100%);
    cursor: pointer;
    transform: scale(1.1);
}

.projectItemSelected {

    background: linear-gradient(45deg, #c6eadd 0%, #fdfbb4 100%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin: 10px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;

}

.projectImage {
    /* background: yellow; */
    /* background: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% ); */
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    width: 100%;
    height: 100px;
    padding: 20px;
}

.projectName {
    /* padding: 20px; */
    /* background: rgb(217, 217, 192); */
    width: 100%;
    font-weight: bold;
    /* height: 100%; */
}

.projectDesc {

    /* background: rgb(173, 206, 146); */
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    font-size: 15px;

}

.projectTypesContainer {
    display: flex;
    margin-top: 20px;

}

.optionsWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.boxy {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    background-color: #94d3a2;

    /* margin-right: 18px; */

}
</style>