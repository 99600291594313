import apiClient from "./MyClient";
import { useAuthStore } from '@/stores/AuthStore'


export default{ 

    

    async getWorkspaces(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/workspaces');
    },


    async storeWorkspace(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/workspace/store/', formData);

    },


    async updateWorkspace(workspaceUUID, formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/workspace/update/' + workspaceUUID, formData);

    },

    async deleteWorkspace(workspaceUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/workspace/delete/' + workspaceUUID);

    },


    async getAWorkspace(workspaceUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/workspace/' + workspaceUUID);

    },
    

    
  
    
  
}
