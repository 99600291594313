import { defineStore } from 'pinia'
import QuizService from '@/services/QuizService.js'
import { debounce } from 'lodash'
import moment from 'moment'

export const useLiveQuizDataStore = defineStore('liveQuizDataStore', {
  state: () => ({
    defaultPagesArray: [],
    outcomePagesArray: [],

    quiz: {},
    motherArray: [],

    publicUser: {},
    message: '',
    currentPlan: {},

    currentPageIndex: 0,
    currentAnswerIndex: null,
    progressPercentage: 0,

    selectedOuterBGtype: '',
    selectedOuterBGgradient: '',
    selectedNormalBGcolor: '',
    selectedBGimageUrl: '',
    is_progress_bar_enabled: '',
    selectedProgressBarColor: '',

    selectedCardBGtype: '',
    selectedCardBGgradient: '',
    selectedCardNormalBGcolor: '',
    selectedCardBGimageUrl: '',

    outcomeTracker: [],
    defaultSubTypePagesOnly: [],
    theFinalOutcomeIndex: null,
    theLastDefaultPageIndex: null,

    pagesWithQuestionOnlyForScoreBased: [],
    correctAnswersCount: null,
    questionPagesCount: null,
    userAnswers: [],

    isRunning: false,
    seconds: 0,
    intervalId: null,

    currentDate: moment().format('YYYY-MM-DD'),

    questionsAnsweredArray: [],
    inputFieldsAnsweredArray: [],

    inputFieldsDataCollected: [],


  }),

  actions: {
    async fetchQuiz (random_string) {
      console.log('BEFORE fetchQuiz ran')

      try {
        const response = await QuizService.fetchLiveQuizData(random_string)

        this.message = response.data.message

        if (this.message === 'account_is_within_limit') {
          this.publicUser = response.data.publicUser //current public user session
          this.quiz = response.data.quiz
          this.currentPlan = response.data.currentPlan
          var defaultPages = response.data.defaultPagesArray
          var outcomePages = response.data.outcomePagesArray

          this.defaultPagesArray = []
          this.outcomePagesArray = []

          defaultPages.forEach(page => {
            this.defaultPagesArray.push(page)
          })

          outcomePages.forEach(page => {
            this.outcomePagesArray.push(page)
          })

          //prepare single outcome array structure
          if (this.quiz.type === 'single_outcome') {
            //add the outcome page last in the defaultPagesArray
            this.defaultPagesArray.push(this.outcomePagesArray[0])

            console.log(
              'single_outcome array structure: ',
              this.defaultPagesArray
            )
          }

          //prepare score outcome array structure
          if (this.quiz.type === 'score_based_outcomes') {
            //add the outcome page last in the defaultPagesArray
            this.defaultPagesArray.push(this.outcomePagesArray[0])

            console.log(
              'score_based_outcomes array structure: ',
              this.defaultPagesArray
            )
          }

          // //prepare score outcome array structure
          // if(this.quiz.type === 'answer_based_outcomes'){

          // }

          console.log('this.defaultPagesArray', this.defaultPagesArray)
          console.log('this.outcomePagesArray', this.outcomePagesArray)

          //populate quiz BG styling
          this.selectedOuterBGtype = this.quiz.outer_bg_type
          this.selectedCardBGtype = this.quiz.card_bg_type
          this.is_progress_bar_enabled = this.quiz.is_progress_bar_enabled
          this.selectedProgressBarColor = this.quiz.progress_bar_bg_color

          if (this.quiz.outer_bg_type == 'gradient_bg') {
            this.selectedOuterBGgradient = this.quiz.outer_bg_gradient
          }
          if (this.quiz.outer_bg_type == 'normal_bg') {
            this.selectedNormalBGcolor = this.quiz.outer_bg_color
          }
          if (this.quiz.outer_bg_type == 'img_bg') {
            this.selectedBGimageUrl = this.quiz.outer_bg_img_url
          }

          //populate quiz card BG styling
          if (this.quiz.card_bg_type == 'gradient_bg') {
            this.selectedCardBGgradient = this.quiz.card_bg_gradient
          }
          if (this.quiz.card_bg_type == 'normal_bg') {
            this.selectedCardNormalBGcolor = this.quiz.card_bg_color
          }
          if (this.quiz.card_bg_type == 'img_bg') {
            this.selectedCardBGimageUrl = this.quiz.card_bg_img_url
          }

          console.log(response.data)
        }

        if (this.message === 'limit_reached') {
          //do nothing
        }
      } catch (err) {
        console.log(err)
      }

      console.log('AFTER fetchQuiz ran')
    },

    // fetchQuiz(random_string) {

    //     QuizService.fetchLiveQuizData(random_string)
    //         .then((response) => {

    //             this.publicUser = response.data.publicUser //current public user session
    //             this.quiz = response.data.quiz

    //             var pages = response.data.pages

    //             pages.forEach((page) => {

    //                 this.motherArray.push(page)

    //             });

    //             //populate quiz BG styling
    //             this.selectedOuterBGtype = this.quiz.outer_bg_type
    //             this.selectedCardBGtype = this.quiz.card_bg_type
    //             this.is_progress_bar_enabled = this.quiz.is_progress_bar_enabled
    //             this.selectedProgressBarColor = this.quiz.progress_bar_bg_color

    //             if (this.quiz.outer_bg_type == 'gradient_bg') {

    //                 this.selectedOuterBGgradient = this.quiz.outer_bg_gradient
    //             }
    //             if (this.quiz.outer_bg_type == 'normal_bg') {

    //                 this.selectedNormalBGcolor = this.quiz.outer_bg_color

    //             }
    //             if (this.quiz.outer_bg_type == 'img_bg') {

    //                 this.selectedBGimageUrl = this.quiz.outer_bg_img_url

    //             }

    //             //populate quiz card BG styling
    //             if (this.quiz.card_bg_type == 'gradient_bg') {

    //                 this.selectedCardBGgradient = this.quiz.card_bg_gradient

    //             }

    //             if (this.quiz.card_bg_type == 'normal_bg') {

    //                 this.selectedCardNormalBGcolor = this.quiz.card_bg_color

    //             }

    //             if (this.quiz.card_bg_type == 'img_bg') {

    //                 this.selectedCardBGimageUrl = this.quiz.card_bg_img_url

    //             }

    //             console.log(response.data)

    //         }).catch((err) => {
    //             console.log(err)
    //         });

    // },

    // nextQuestion() {

    //     if (this.currentPageIndex == (this.motherArray.length - 1)) {
    //         console.log('END OF QUIZ')
    //     } else {

    //         this.currentPageIndex++;
    //         this.calcProgressPercentage()

    //     }

    // },

    nextQuestion (answer) {
      //check if this answer is skip_logic or just next_page
      if (answer.answer_navigation_type === 'skip_logic') {
        const nexPageIndexToSkipTo = this.defaultPagesArray.findIndex(
          page => page.id === answer.next_page_id
        )

        //jump to that index
        this.currentPageIndex = nexPageIndexToSkipTo

        this.calcProgressPercentage()
      }

      if (answer.answer_navigation_type === 'next_page') {
        this.currentPageIndex++

        this.calcProgressPercentage()
      }

      // this.previewPageIndex++;

      // this.calcProgressPercentage()
    },

    calcProgressPercentage () {
      if (this.currentPageIndex == this.defaultPagesArray.length - 1) {
        //rember if this quiz is a answer based the outcome page will be added last
        //so set to 90%
        if (this.quiz.type === 'answer_based_outcomes') {
          this.progressPercentage = 90
        } else {
          //for any other quiz type the outcome pages was already added to the array so
          //set to 100%
          this.progressPercentage = 100
        }
      } else {
        this.progressPercentage =
          (this.currentPageIndex / this.defaultPagesArray.length) * 100
      }
    },

    storeAQuestionAnswer (element, answer) {
      // console.log('storeAQuestionAnswer function fired!', element, answer )

      var formData = {
        public_user_id: this.publicUser.id,
        quiz_id: this.quiz.id,
        question: element,
        answer: answer
      }

      // console.log('FORMDATA', formData)

      QuizService.storeQuizQuestionAnswer(formData)
        .then(response => {
          console.log('ANSWER STORED: ', response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async validateAndNext (page) {
      const elements = page.design_layout

      //loop over each element and check for empty fields for type input_field, textbox and checkbox

      var errorCount = 0

      let isRestartQuizButton = false

      elements.forEach(element => {
        element.show_error = 0 //reset

        if (
          element.type === 'text_input' &&
          element.is_required === 1 &&
          (element.value === '' || element.value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element.label)
        }

        if (
          element.type === 'number_input' &&
          element.is_required === 1 &&
          (element.number_value === '' || element.number_value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element)
        }

        if (
          element.type === 'time_input' &&
          element.is_required === 1 &&
          (element.value === '' || element.value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element)
        }

        if (
          element.type === 'date_input' &&
          element.is_required === 1 &&
          (element.value === '' || element.value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element)
        }

        if (
          element.type === 'email_input' &&
          element.is_required === 1 &&
          (element.value === '' ||
            element.value === null ||
            !element.value.includes('@'))
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element)
        }

        if (
          element.type === 'textbox' &&
          element.is_required === 1 &&
          (element.value === '' || element.value === null)
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element.label)
        }

        if (
          element.type === 'checkbox' &&
          element.is_required === 1 &&
          element.value === 0
        ) {
          element.show_error = 1
          errorCount++

          console.log('FIELD REQUIRED: ', element.name_in_report)
        }

        if (element.type === 'question') {
          var answersSelectedCount = 0

          //check if min_amt of answers was selected
          element.answers.forEach(answer => {
            if (answer.is_selected === 1) {
              answersSelectedCount++
            }
          })

          //then compare
          if (element.min_answers_amt > answersSelectedCount) {
            errorCount++

            alert(
              'Please select a minium of ' + element.min_answers_amt + ' anwers'
            )
          }

          // element.show_error = 1
          // errorCount++

          // console.log('question check: ', element.name_in_report)
        }

        //If the user didnt check a checkbox at the time when the next button validation is fired, then just save thhe value as 0,
        if (element.type === 'checkbox' && element.value != true) {
          const formData = {
            public_user_id: this.publicUser.id,
            input_field: element,
            input_field_string_value: null,
            input_field_number_value: null,
            is_checkbox_selected: 0
          }

          QuizService.saveFormInputFieldValue(formData)
            .then(response => {
              console.log('backend response: ', response.data)
            })
            .catch(err => {
              console.log(err)
            })

          console.log('checkbox not checked so 0', element)
        }

        if (
          element.type === 'button' &&
          element.action_type === 'restart_quiz'
        ) {
          isRestartQuizButton = true

          console.log('yes restart')
        }
      })

      //just go next page as usual
      if (errorCount === 0) {
        

        if(this.quiz.type === 'single_outcome' || this.quiz.type === 'score_based_outcomes'){


          //add collected data from inputfields
          this.addDataCollectedFromInputFieldsToArray()

          //pipable data from input fields
          this.addInputFieldsDataToArray()

          //pipable data from question
          this.checkNextPageForPipableIds()

        }

        //this code block runs up until the last page index in defaultpages array then no more as the outcome page will be added last
        if(this.quiz.type === 'answer_based_outcomes' && this.currentPageIndex !== this.defaultPagesArray.length - 1){


          this.addDataCollectedFromInputFieldsToArray()
          

          //pipable data from input fields
          this.addInputFieldsDataToArray()

          //pipable data from question
          this.checkNextPageForPipableIds()

          

        }

        

        


        //only if isRestartQuizButton is false go next
        if (isRestartQuizButton === false) {
          
          this.currentPageIndex++
          this.calcProgressPercentage()

          if (this.quiz.type === 'single_outcome') {

            if (this.currentPageIndex == this.defaultPagesArray.length - 1) {

              let page = this.defaultPagesArray[this.currentPageIndex]



              if (page.redirect_url != null) {

                this.saveAllInputDataCollected()

                this.saveDuration()

                this.markQuizCompletion('full')

                //NOTE: this is used for redirecting standalone landing page NOT iframes
                setTimeout(function () {
                  window.location.href = page.redirect_url
                }, 4500)
              } else {

                this.saveAllInputDataCollected()


                this.saveDuration()

                //mark as complete
                this.markQuizCompletion('full')
              }
            }
          }

          if (this.quiz.type === 'score_based_outcomes') {

            if (this.currentPageIndex == this.defaultPagesArray.length - 1) {

              let page = this.defaultPagesArray[this.currentPageIndex]

              if (page.redirect_url != null) {


                this.saveAllInputDataCollected()

                this.saveDuration()

                this.saveScore()

                this.markQuizCompletion('full')

                setTimeout(function () {
                  window.location.href = page.redirect_url
                }, 5500)

              } else {

                this.saveAllInputDataCollected()

                this.saveDuration()

                this.saveScore()

                //mark as complete
                this.markQuizCompletion('full')
              }

              // this.saveDuration()

              // //save score
              // this.saveScore()

              // //mark as complete
              // this.markQuizCompletion('full')
            }
          }

          if (
            this.quiz.type === 'answer_based_outcomes' &&
            this.currentPageIndex > this.defaultPagesArray.length - 1
          ) {
            let winnngOutcomePage = this.outcomePagesArray[this.theFinalOutcomeIndex]

            this.defaultPagesArray.push(winnngOutcomePage) //add winner page into array


            //check for input field data from previous page
            //check that the array has at least two elements before trying to access the second-to-last element, to avoid runtime errors
            if (this.defaultPagesArray.length >= 2) {

                let secondToLastPage = this.defaultPagesArray[this.defaultPagesArray.length - 2];

                this.addInputFieldsDataFromSecondToLastPage(secondToLastPage)
                this.addDataCollectedFromInputFieldsFromSecondToLastPageToArray(secondToLastPage)

                // console.log('secondToLastPage', secondToLastPage);

            }
          

            //check for pipable data 
            this.checkWinningOutcomepPageForPipeableIds(winnngOutcomePage)
            

            //check if the winnngOutcomePage has a redirect url
            if (winnngOutcomePage.redirect_url != null) {


              this.saveAllInputDataCollected()

              //save the winning outcome to db
              this.saveWinningoutcomePage()


              //stop the timer and update in in db
              this.saveDuration()

              //mark as complete
              this.markQuizCompletion('full')

              setTimeout(function () {
                window.location.href = winnngOutcomePage.redirect_url
              }, 5000)
            } else {


              this.saveAllInputDataCollected()

              //save the winning outcome to db
              this.saveWinningoutcomePage()

              //stop the timer and update in in db
              this.saveDuration()

              //mark as complete
              this.markQuizCompletion('full')
            }

            // this.previewPageIndex = this.theFinalOutcomeIndex
            // this.progressPercentage = 100

            console.log('REDIRECT TO FINAL OUTCOMEEEEEE')
          }
        } else {
          //refresh page to restart
          window.location.reload()
        }
      }

      // //since we know the theLastDefaultPageIndex, anything shown after that should be the final outcome page with highest weight
      // //so if previewPageIndex is greater than theLastDefaultPageIndex, show the final outcome
      // if (
      //   this.quiz.type === 'answer_based_outcomes' &&
      //   this.currentPageIndex > this.theLastDefaultPageIndex
      // ) {
      //   console.log('REDIRECT TO FINAL OUTCOME')

      //   this.currentPageIndex = this.theFinalOutcomeIndex
      //   this.progressPercentage = 100

      //   //save the winning outcome to db
      //   this.saveWinningoutcomePage()

      //   //stop the timer and update in in db
      //   this.saveDuration()

      //   //mark as complete
      //   this.markQuizCompletion('full')
      // }

      console.log('ERROR COUNT: ', errorCount)
    },

    toggleSelectedAnswer (question, answer) {
      this.addQuestionAnsweredToArray(answer, question)

      if (answer.is_selected === 1) {
        // Unselect the answer
        answer.is_selected = 0
        question.answers_selected_count--

        if (this.quiz.type === 'single_outcome') {
          //update in db
          this.storeAQuestionAnswer(question, answer)
        }

        if (this.quiz.type === 'answer_based_outcomes') {
          //update outcome mapping to minus from weight
          let changeWeightValue = -1
          this.multipleAnswersSelect(answer, changeWeightValue)

          //update in db
          this.storeAQuestionAnswer(question, answer)
        }

        if (this.quiz.type === 'score_based_outcomes') {
          var userAnswer = this.userAnswers.find(
            obj => obj.question_id === question.id
          )
          var newAnswersArray = userAnswer.answers.filter(
            ansId => ansId !== answer.id
          )
          userAnswer.answers = newAnswersArray

          //update in db
          this.storeAQuestionAnswer(question, answer)
        }
      } else if (question.answers_selected_count < question.max_answers_amt) {
        // Select the answer if the maximum limit is not reached
        answer.is_selected = 1
        question.answers_selected_count++

        if (this.quiz.type === 'single_outcome') {
          //update in db
          this.storeAQuestionAnswer(question, answer)
        }

        if (this.quiz.type === 'answer_based_outcomes') {
          //update outcome mapping
          let changeWeightValue = 1
          this.multipleAnswersSelect(answer, changeWeightValue)

          //update in db
          this.storeAQuestionAnswer(question, answer)
        }

        if (this.quiz.type === 'score_based_outcomes') {
          //update in db
          this.storeAQuestionAnswer(question, answer)

          //put the correct answers in a seperate arryay to compare later
          var correctAnswersOnly = []

          question.answers.forEach(ans => {
            if (ans.is_correct === 1) {
              correctAnswersOnly.push(ans.id)
            }
          })

          var optionSelected = {
            question_id: question.id,
            answers: [],
            correct_answers_only: correctAnswersOnly
          }

          if (this.userAnswers.length === 0) {
            optionSelected.answers.push(answer.id)
            this.userAnswers.push(optionSelected)

            console.log('userAnswers', this.userAnswers)
          } else {
            // Check if the question already exists in userAnswers
            var questionExists = false

            this.userAnswers.forEach(userAnswer => {
              if (userAnswer.question_id === optionSelected.question_id) {
                // Update the existing entry

                questionExists = true

                //add answer

                userAnswer.answers.push(answer.id)
                // console.log('MATCHED - Updated', userAnswer);
              }
            })

            // If the question doesn't exist, add it to the array
            if (!questionExists) {
              optionSelected.answers.push(answer.id)
              this.userAnswers.push(optionSelected)

              console.log('Added to array')
            }
          }

          this.calcMultipleAnswersScore()
        }
      } else {
        // Show an alert if the maximum limit is reached
        alert(
          `You can only select a maximum of ${question.max_answers_amt} answers.`
        )
      }
    },

    multipleAnswersSelect (answer, changeWeightValue) {
      if (changeWeightValue === 1) {
        //i need to know which outcome page this answer maps to
        let answerMapping = answer.outcome_pages

        ////foreach the outcomeTracker, and inside of that aslo foreach the answerMapping
        //// and check if the page_id from outcomeTracker matches the one in answerMapping
        ////then add the weight value to the weight property in outcomeTracker
        this.outcomeTracker.forEach(outcomePage => {
          answerMapping.forEach(answerMapping => {
            if (outcomePage.page_id === answerMapping.page_id) {
              outcomePage.total_weight =
                outcomePage.total_weight + answerMapping.weight
            }
          })
        })

        ////run the outcome caluction to see which outcome has the highest total weight
        let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
          return prev.total_weight > current.total_weight ? prev : current
        })

        const outcomePageIndexToShow = this.outcomePagesArray.findIndex(
          page => page.id === maxWeightPage.page_id
        )

        this.theFinalOutcomeIndex = outcomePageIndexToShow

        console.log('theFinalOutcomeIndex', this.theFinalOutcomeIndex)
        console.log(
          'the outcome page to show',
          this.outcomePagesArray[this.theFinalOutcomeIndex]
        )
      }

      if (changeWeightValue === -1) {
        //i need to know which outcome page this answer maps to
        let answerMapping = answer.outcome_pages

        ////foreach the outcomeTracker, and inside of that aslo foreach the answerMapping
        //// and check if the page_id from outcomeTracker matches the one in answerMapping
        ////then add the weight value to the weight property in outcomeTracker
        this.outcomeTracker.forEach(outcomePage => {
          answerMapping.forEach(answerMapping => {
            if (outcomePage.page_id === answerMapping.page_id) {
              outcomePage.total_weight =
                outcomePage.total_weight - answerMapping.weight
            }
          })
        })

        ////run the outcome caluction to see which outcome has the highest total weight
        let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
          return prev.total_weight > current.total_weight ? prev : current
        })

        const outcomePageIndexToShow = this.outcomePagesArray.findIndex(
          page => page.id === maxWeightPage.page_id
        )

        this.theFinalOutcomeIndex = outcomePageIndexToShow

        console.log('theFinalOutcomeIndex', this.theFinalOutcomeIndex)
        console.log(
          'the outcome page to show',
          this.outcomePagesArray[this.theFinalOutcomeIndex]
        )
      }

      // if (changeWeightValue === 1) {

      //   if (this.quiz.type === 'answer_based_outcomes') {
      //     let answerMapping = answer.outcome_pages

      //     this.outcomeTracker.forEach(outcomePage => {
      //       answerMapping.forEach(answerMapping => {
      //         if (outcomePage.page_id === answerMapping.page_id) {
      //           outcomePage.total_weight =
      //             outcomePage.total_weight + answerMapping.weight
      //         }
      //       })
      //     })

      //     //run the outcome caluction to see which outcome has the total weight
      //     let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
      //       return prev.total_weight > current.total_weight ? prev : current
      //     })

      //     //Check if this default page is the last one in the mother array
      //     let lastDefaultPageIndex = -1

      //     this.motherArray.forEach((page, index) => {
      //       if (page.sub_type === 'default') {
      //         lastDefaultPageIndex = index
      //       }
      //     })

      //     if (lastDefaultPageIndex !== -1) {
      //       const outcomePageIndexToShow = this.motherArray.findIndex(
      //         page => page.id === maxWeightPage.page_id
      //       )
      //       this.theFinalOutcomeIndex = outcomePageIndexToShow

      //       this.theLastDefaultPageIndex = lastDefaultPageIndex

      //       console.log(
      //         `The last 'default' type object is at index ${lastDefaultPageIndex}`
      //       )
      //     }
      //   }

      //   if (this.quiz.type === 'score_based_outcomes') {
      //     console.log(
      //       'its score_based_outcomes so do score_based_outcomes shit!'
      //     )
      //   }
      // }

      // if (changeWeightValue === -1) {
      //   if (this.quiz.type === 'answer_based_outcomes') {
      //     let answerMapping = answer.outcome_pages

      //     this.outcomeTracker.forEach(outcomePage => {
      //       answerMapping.forEach(answerMapping => {
      //         if (outcomePage.page_id === answerMapping.page_id) {
      //           outcomePage.total_weight =
      //             outcomePage.total_weight - answerMapping.weight
      //         }
      //       })
      //     })

      //     //run the outcome caluction to see which outcome has the total weight
      //     let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
      //       return prev.total_weight > current.total_weight ? prev : current
      //     })

      //     //Check if this default page is the last one in the mother array
      //     let lastDefaultPageIndex = -1

      //     this.motherArray.forEach((page, index) => {
      //       if (page.sub_type === 'default') {
      //         lastDefaultPageIndex = index
      //       }
      //     })

      //     if (lastDefaultPageIndex !== -1) {
      //       const outcomePageIndexToShow = this.motherArray.findIndex(
      //         page => page.id === maxWeightPage.page_id
      //       )
      //       this.theFinalOutcomeIndex = outcomePageIndexToShow

      //       this.theLastDefaultPageIndex = lastDefaultPageIndex

      //       console.log(
      //         `The last 'default' type object is at index ${lastDefaultPageIndex}`
      //       )
      //     }
      //   }

      //   if (this.quiz.type === 'score_based_outcomes') {
      //     console.log(
      //       'its score_based_outcomes so do score_based_outcomes shit!'
      //     )
      //   }
      // }
    },

    oneAnswerSelectOnly (question, answer) {

      this.addQuestionAnsweredToArray(answer, question)
      this.checkNextPageForPipableIds()

      //single answer questions must only have one answer is_selected highlighted
      if (question.click_action_type === 'single_answer') {
        question.answers.forEach(ans => {
          ans.is_selected = 0
        })
      }

      if (answer.is_selected === 1) {
        answer.is_selected = 0

        if (this.quiz.type === 'single_outcome') {
          //update in db
          this.storeAQuestionAnswer(question, answer)

          this.nextQuestion(answer)
        }

        if (this.quiz.type === 'answer_based_outcomes') {
          //update in db
          this.storeAQuestionAnswer(question, answer)

          this.calculatePageOutcomeToShow(answer)
        }

        if (this.quiz.type === 'score_based_outcomes') {
          //update in db
          this.storeAQuestionAnswer(question, answer)

          this.calculateScore(answer, question)
        }
      } else {
        answer.is_selected = 1

        if (this.quiz.type === 'single_outcome') {
          //update in db
          this.storeAQuestionAnswer(question, answer)

          this.nextQuestion(answer)
        }

        if (this.quiz.type === 'answer_based_outcomes') {
          //update in db
          this.storeAQuestionAnswer(question, answer)

          this.calculatePageOutcomeToShow(answer)
        }

        if (this.quiz.type === 'score_based_outcomes') {
          //update in db
          this.storeAQuestionAnswer(question, answer)

          this.calculateScore(answer, question)
        }
      }
    },

    calculatePageOutcomeToShow (answer) {
      //i need to know which outcome page this answer maps to
      let answerMapping = answer.outcome_pages

      ////foreach the outcomeTracker, and inside of that aslo foreach the answerMapping
      //// and check if the page_id from outcomeTracker matches the one in answerMapping
      ////then add the weight value to the weight property in outcomeTracker
      this.outcomeTracker.forEach(outcomePage => {
        answerMapping.forEach(answerMapping => {
          if (outcomePage.page_id === answerMapping.page_id) {
            outcomePage.total_weight =
              outcomePage.total_weight + answerMapping.weight
          }
        })
      })

      ////run the outcome caluction to see which outcome has the highest total weight
      let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
        return prev.total_weight > current.total_weight ? prev : current
      })

      const outcomePageIndexToShow = this.outcomePagesArray.findIndex(
        page => page.id === maxWeightPage.page_id
      )

      this.theFinalOutcomeIndex = outcomePageIndexToShow

      console.log('theFinalOutcomeIndex', this.theFinalOutcomeIndex)
      console.log(
        'the outcome page to show',
        this.outcomePagesArray[this.theFinalOutcomeIndex]
      )

      //Handle skip logic if its used for this answer
      if (answer.answer_navigation_type === 'skip_logic') {
        const nexPageIndexToSkipTo = this.defaultPagesArray.findIndex(
          page => page.id === answer.next_page_id
        )

        ////jump to that index
        this.currentPageIndex = nexPageIndexToSkipTo

        this.calcProgressPercentage()

        return //stop here
      }

      this.validateAndNext(this.defaultPagesArray[this.currentPageIndex])

      // let answerMapping = answer.outcome_pages

      // this.outcomeTracker.forEach(outcomePage => {
      //   answerMapping.forEach(answerMapping => {
      //     if (outcomePage.page_id === answerMapping.page_id) {
      //       outcomePage.total_weight =
      //         outcomePage.total_weight + answerMapping.weight
      //     }
      //   })
      // })

      // this.currentPageIndex++
      // this.calcProgressPercentage()

      // //run the outcome caluction to see which outcome has the total weight
      // let maxWeightPage = this.outcomeTracker.reduce((prev, current) => {
      //   return prev.total_weight > current.total_weight ? prev : current
      // })

      // //Check if this default sub type is the last one in the mother array
      // let lastDefaultPageIndex = -1

      // this.motherArray.forEach((page, index) => {
      //   if (page.sub_type === 'default') {
      //     lastDefaultPageIndex = index
      //   }
      // })

      // if (lastDefaultPageIndex !== -1) {
      //   const outcomePageIndexToShow = this.motherArray.findIndex(
      //     page => page.id === maxWeightPage.page_id
      //   )
      //   this.theFinalOutcomeIndex = outcomePageIndexToShow

      //   this.theLastDefaultPageIndex = lastDefaultPageIndex

      //   console.log(
      //     `The last 'default' type object is at index ${lastDefaultPageIndex}`
      //   )
      // }

      // if (this.currentPageIndex > this.theLastDefaultPageIndex) {
      //   console.log('REDIRECT TO FINAL OUTCOME')

      //   this.currentPageIndex = this.theFinalOutcomeIndex
      //   this.progressPercentage = 100
      // }

      // if (answer.answer_navigation_type === 'skip_logic') {
      //   const nexPageIndexToSkipTo = this.motherArray.findIndex(
      //     page => page.id === answer.next_page_id
      //   )

      //   //jump to that index
      //   this.currentPageIndex = nexPageIndexToSkipTo

      //   this.calcProgressPercentage()
      // }
    },

    //for score based single answer
    calculateScore (answer, question) {
      if (question.click_action_type === 'single_answer') {
        var optionSelected = {
          question_id: question.id,
          answers: [],
          correct_answers_only: [],
          got_correct: answer.is_correct === 1 ? 'yes' : 'no'
        }

        optionSelected.answers.push(answer.id)

        if (answer.is_correct === 1) {
          optionSelected.correct_answers_only.push(answer.id)
        }

        //check question id if it already exists in array
        var questionExists = false

        this.userAnswers.forEach(userAnswer => {
          if (userAnswer.question_id === optionSelected.question_id) {
            questionExists = true
            userAnswer.answers = optionSelected.answers
          }
        })

        // If the question doesn't exist, add it to the array
        if (!questionExists) {
          this.userAnswers.push(optionSelected)
        }

        this.calcMultipleAnswersScore()

        this.nextQuestion(answer)

        // var optionSelected = {
        //     question_id: question.id,
        //     answer_id: answer.id,
        //     is_correct: answer.is_correct
        // }

        // this.userAnswers.push(optionSelected)

        // this.nextQuestion(answer)

        // if (this.currentPageIndex === (this.motherArray.length - 1)) {

        //     //count how many is_correct = 1 in userAnswers
        //     this.correctAnswersCount = this.userAnswers.filter(answer => answer.is_correct === 1).length;
        //     this.questionPagesCount = this.pagesWithQuestionOnlyForScoreBased.length;

        //     //put the correctAnswersCount and questionPagesCount values in the score elemeent
        //     this.motherArray.forEach((page) => {

        //         page.design_layout.forEach((element) => {

        //             if (element.type === 'score') {

        //                 element.correct_amt = this.correctAnswersCount
        //                 element.total_questions_amt = this.questionPagesCount

        //             }

        //         })

        //     })

        //     console.log('userAnswers', this.userAnswers)
        //     console.log('correctAnswers', this.correctAnswersCount)
        //     console.log('questionPagesCount', this.questionPagesCount)

        // }
      }
    },

    //for score based multple answers
    calcMultipleAnswersScore () {
      this.userAnswers.forEach(userAnswer => {
        const sortedAnswers = [...userAnswer.answers].sort()
        const sortedCorrectAnswers = [...userAnswer.correct_answers_only].sort()

        if (
          JSON.stringify(sortedAnswers) === JSON.stringify(sortedCorrectAnswers)
        ) {
          userAnswer.got_correct = 'yes'
        } else {
          userAnswer.got_correct = 'no'
        }
      })

      this.correctAnswersCount = this.userAnswers.filter(
        userAnswer => userAnswer.got_correct === 'yes'
      ).length

      //i need to know how many default pages have question element
      let countPagesWithAQuestion = 0

      this.defaultPagesArray.forEach(page => {
        page.design_layout.forEach(element => {
          if (element.type === 'question') {
            countPagesWithAQuestion++
          }
        })
      })

      this.questionPagesCount = countPagesWithAQuestion

      //put the correctAnswersCount and questionPagesCount values in the score elemeent
      this.defaultPagesArray.forEach(page => {
        page.design_layout.forEach(element => {
          if (element.type === 'score') {
            element.correct_amt = this.correctAnswersCount
            element.total_questions_amt = this.questionPagesCount
          }
        })
      })

      //for outcome pages array too
      this.outcomePagesArray.forEach(page => {
        page.design_layout.forEach(element => {
          if (element.type === 'score') {
            element.correct_amt = this.correctAnswersCount
            element.total_questions_amt = this.questionPagesCount
          }
        })
      })

      console.log('calcMultipleAnswersScore', this.userAnswers)
    },

    setResultsTracker () {
      //get the pages of sub_type default into its own array for better navigation
      this.defaultPagesArray.forEach(page => {
        this.defaultSubTypePagesOnly.push({
          page_id: page.id
        })
      })

      //get the outcome pages out into an array to tracker weight increment results
      this.outcomePagesArray.forEach(page => {
        this.outcomeTracker.push({
          page_id: page.id,
          title: page.page_title,
          total_weight: 0
        })
      })

      console.log('outcomeTracker: ', this.outcomeTracker)
      console.log('defaultSubTypePagesOnly: ', this.defaultSubTypePagesOnly)
    },

    setScoreBasedQuestionPagesOnly () {
      this.motherArray.forEach(page => {
        if (page.sub_type === 'default') {
          page.design_layout.forEach(element => {
            if (element.type === 'question') {
              //add page to array only if it has a question
              this.pagesWithQuestionOnlyForScoreBased.push(page)
            }
          })
        }
      })

      console.log(
        'pagesWithQuestionOnlyForScoreBased',
        this.pagesWithQuestionOnlyForScoreBased
      )
    },

    formatDate (date, element) {
      var formatedDate = moment(date).format('YYYY-MM-DD')

      this.saveInputFieldValue(formatedDate, element)
    },

    formatTime (date, element) {
      var formatedTime = moment(date).format('HH:mm')

      this.saveInputFieldValue(formatedTime, element)
    },

    startTimer () {
      if (!this.isRunning) {
        this.isRunning = true
        this.intervalId = setInterval(() => {
          this.seconds += 1
        }, 1000)
      }
    },

    stopTimer () {
      if (this.isRunning) {
        this.isRunning = false
        clearInterval(this.intervalId)
      }
    },

    saveInputFieldValue: debounce(function (value, element) {


      if (typeof value === 'string') {
        // console.log('STRING VALUE: ', value, element)

        const formData = {
          public_user_id: this.publicUser.id,
          input_field: element,
          input_field_string_value: value,
          input_field_number_value: null,
          is_checkbox_selected: null,
          checkbox_id: null
        }

        QuizService.saveFormInputFieldValue(formData)
          .then(response => {
            console.log('backend response: ', response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }

      if (typeof value === 'number') {
        // console.log('NUMBER VALUE: ', value, element)

        const formData = {
          public_user_id: this.publicUser.id,
          input_field: element,
          input_field_string_value: null,
          is_checkbox_selected: null,
          checkbox_id: null,
          input_field_number_value: value
        }

        QuizService.saveFormInputFieldValue(formData)
          .then(response => {
            console.log('backend response: ', response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }

      //for checkbox
      if (element.type === 'checkbox') {
        //if true set to 1
        if (value === true) {
          const formData = {
            public_user_id: this.publicUser.id,
            input_field: element,
            input_field_string_value: null,
            input_field_number_value: null,
            is_checkbox_selected: 1,
            checkbox_id: element
          }

          // console.log('formdata sent', formData)

          QuizService.saveFormInputFieldValue(formData)
            .then(response => {
              console.log('backend response: ', response.data)
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          const formData = {
            public_user_id: this.publicUser.id,
            input_field: element,
            input_field_string_value: null,
            input_field_number_value: null,
            is_checkbox_selected: 0,
            checkbox_id: element
          }

          // console.log('formdata sent', formData)

          QuizService.saveFormInputFieldValue(formData)
            .then(response => {
              console.log('backend response: ', response.data)
            })
            .catch(err => {
              console.log(err)
            })
        }
      }

    }, 500),

    saveCheckboxValue: debounce(function (value, element) {
      //if true set to 1
      if (value === true) {
        const formData = {
          public_user_id: this.publicUser.id,
          input_field: element,
          input_field_string_value: null,
          input_field_number_value: null,
          is_checkbox_selected: 1,
          checkbox_id: element.id
        }

        // console.log('formdata sent', value, element, formData)

        QuizService.saveFormInputFieldValue(formData)
          .then(response => {
            console.log('backend response: ', response.data)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        const formData = {
          public_user_id: this.publicUser.id,
          input_field: element,
          input_field_string_value: null,
          input_field_number_value: null,
          is_checkbox_selected: 0,
          checkbox_id: element.id
        }

        // console.log('formdata sent', value, element, formData)

        QuizService.saveFormInputFieldValue(formData)
          .then(response => {
            console.log('backend response: ', response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    }, 500),

    saveWinningoutcomePage () {
      const formData = {
        quiz: this.quiz,
        public_user_id: this.publicUser.id,
        winning_outcome_page_id:
          this.outcomePagesArray[this.theFinalOutcomeIndex].id
      }

      console.log('saveWinningoutcomePage', formData)

      QuizService.saveThisQuizOutcomeInDB(formData)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    saveScore () {
      const formData = {
        quiz: this.quiz,
        public_user_id: this.publicUser.id,
        score: this.correctAnswersCount
      }

      QuizService.saveThisQuizOutcomeInDB(formData)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    saveDuration () {
      this.stopTimer()

      const formData = {
        public_user_id: this.publicUser.id,
        duration: this.seconds,
        submission_time: this.getCurrentTime(),
        submission_date: this.currentDate
      }

      QuizService.saveThisDuration(formData)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })

      // console.log(formData)
    },

    getCurrentTime () {
      return moment().format('HH:mm')
    },

    // saveDeviceType(device_type){

    //     const formData = {
    //         public_user_id: this.publicUser.id,
    //         device_type: device_type
    //     }

    //     QuizService.saveThisDeviceType(formData)
    //         .then((response) => {

    //             console.log(response.data)

    //         }).catch((err) => {

    //             console.log(err)

    //         });

    //     console.log('formData', formData)

    // },

    saveDeviceType: debounce(function (device_type) {
      const formData = {
        public_user_id: this.publicUser.id,
        device_type: device_type
      }

      QuizService.saveThisDeviceType(formData)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })

      console.log('formData', formData)
    }, 3000),

    markQuizCompletion (completion_type) {
      const formData = {
        public_user_id: this.publicUser.id,
        completion_type: completion_type
      }

      QuizService.markThisQuizCompletion(formData)
        .then(response => {
          console.log('mark full', response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    incrementQuizVisitCount () {
      const formData = {
        quiz_id: this.quiz.id
      }

      QuizService.updateQuizVisitCounter(formData)
        .then(response => {
          console.log(response.data)

          // console.log('COUNT INCREMENTED ')
        })
        .catch(err => {
          console.log(err)
        })
    },

    openURLNewTab (url) {
      window.open(url, '_blank')
    },

    openURLInSameTab (url) {
      window.location.href = url
    },

    addQuestionAnsweredToArray (answer, question) {
      let questionData = {
        question_id: null,
        question_pipeable_id: null,
        answers: []
      }

      //check if question already exists
      if (
        this.questionsAnsweredArray.some(obj => obj.question_id === question.id)
      ) {
        let theIndexOfExistingQuestionId =
          this.questionsAnsweredArray.findIndex(
            obj => obj.question_id === question.id
          )

        //update the answers for this question
        //check if answer already exists, if yes, remove it. user will select another one
        let questiontToUpdate =
          this.questionsAnsweredArray[theIndexOfExistingQuestionId]

        if (
          questiontToUpdate.answers.some(obj => obj.answer_id === answer.id)
        ) {
          //alredy does so delete
          let theIndexOfExistingAnswerId = questiontToUpdate.answers.findIndex(
            obj => obj.answer_id === answer.id
          )

          if (theIndexOfExistingAnswerId !== -1) {
            questiontToUpdate.answers.splice(theIndexOfExistingAnswerId, 1)
            // console.log('answer ID removed from the array');
          }
        } else {
          //no then just add it
          let answerToAdd = {
            answer_id: answer.id,
            answer_text: answer.answer_text
          }

          questiontToUpdate.answers.push(answerToAdd)
        }

        // console.log('Question ID exists in the array');
      } else {
        questionData.question_id = question.id
        questionData.question_pipeable_id = question.pipeable_id

        //add answer
        let answerToAdd = {
          answer_id: answer.id,
          answer_text: answer.answer_text
        }

        questionData.answers.push(answerToAdd)

        //add to array
        this.questionsAnsweredArray.push(questionData)

        // console.log('NEW Question ID ADDED TO array');
      }
    },

    addInputFieldsDataToArray () {

      let currentPage = this.defaultPagesArray[this.currentPageIndex]

      //first check current page for input fields and put them in array
      currentPage.design_layout.forEach(element => {

        let inputFieldData = {
          input_field_id: null,
          type: null,
          input_field_pipeable_id: null,
          input_field_value: null
        }

        if (
          element.type === 'text_input' ||
          element.type === 'number_input' ||
          element.type === 'date_input' ||
          element.type === 'email_input' ||
          element.type === 'textbox' ||
          element.type === 'time_input'
        ) {
          inputFieldData.input_field_id = element.id
          inputFieldData.type = element.type
          inputFieldData.input_field_pipeable_id = element.pipeable_id
          inputFieldData.input_field_value = element.value //in future add a ternerary check here for number value else string


          // //dont forget to format date and time
          if(element.type === 'date_input'){

            inputFieldData.input_field_value = this.formatDateForPiping(element.value)

          }
          if(element.type === 'time_input'){

            inputFieldData.input_field_value = this.formatTimeForPiping(element.value)

          }

          console.log('BEFORE inputFieldData ADDED TO ARRAY', inputFieldData)

          this.inputFieldsAnsweredArray.push(inputFieldData)


        }
      })
    },

    checkNextPageForPipableIds() { 
      let nextPage = this.defaultPagesArray[this.currentPageIndex + 1]

      //find matching string in question text and replace
      nextPage.design_layout.forEach(element => {

        if (element.type === 'question') {

          let thePipeableIds = this.extractPipeableIds(element.question_text)

          console.log('thePipeableIds to put in question text', thePipeableIds)

          thePipeableIds.forEach(pipeableId => {

            
            //see if this matches in the questionsAnsweredArray
            this.questionsAnsweredArray.forEach(questionData => {
              
              if (questionData.question_pipeable_id === pipeableId) {

                let answerString = ''
                let answers = questionData.answers.map(ans => ans.answer_text)
 
                answerString = answers.join(', ')

                if (answers.length > 1) {
                  let lastCommaIndex = answerString.lastIndexOf(', ')

                  answerString =
                    answerString.substring(0, lastCommaIndex) +
                    ' & ' +
                    answerString.substring(lastCommaIndex + 2)
                }

                var updateQuestionText = this.replaceSpecificIdWithText(element.question_text, pipeableId, answerString)

                element.question_text = updateQuestionText

                // console.log('updateQuestionText with question PIPE', updateQuestionText)

              }

            })


            //see if this matches in the inputFieldsAnsweredArray
            this.inputFieldsAnsweredArray.forEach((inputData) => {


              if (inputData.input_field_pipeable_id === pipeableId) {

                let theInputValue = inputData.input_field_value

                var updateQuestionText = this.replaceSpecificIdWithText(element.question_text, pipeableId, theInputValue)

                element.question_text = updateQuestionText

                // console.log('updateQuestionText with INPUT PIPE', updateQuestionText)


              }


            })
           

          
          })

       
        }

        if (element.type === 'header_text') {

          let thePipeableIds = this.extractPipeableIds(element.code_block)

          console.log('thePipeableIds to put in header', thePipeableIds)
          // console.log('header text', element.code_block)

          thePipeableIds.forEach(pipeableId => {

            
            //see if this matches in the questionsAnsweredArray
            this.questionsAnsweredArray.forEach(questionData => {

              if (questionData.question_pipeable_id === pipeableId) {

                let answerString = ''
                let answers = questionData.answers.map(ans => ans.answer_text)
 
                answerString = answers.join(', ')

                if (answers.length > 1) {
                  let lastCommaIndex = answerString.lastIndexOf(', ')

                  answerString =
                    answerString.substring(0, lastCommaIndex) +
                    ' & ' +
                    answerString.substring(lastCommaIndex + 2)
                }

                var updateHeaderText = this.replaceSpecificIdWithText(element.code_block, pipeableId, answerString)

                element.code_block = updateHeaderText

                // console.log("updateHeaderText", updateHeaderText)
                

              }
              
           
              
            })


            //see if this matches in the inputFieldsAnsweredArray
            this.inputFieldsAnsweredArray.forEach((inputData) => {

              if (inputData.input_field_pipeable_id === pipeableId) {

                let theInputValue = inputData.input_field_value

                var updateHeaderText = this.replaceSpecificIdWithText(element.code_block, pipeableId, theInputValue)

                element.code_block = updateHeaderText

                // console.log('updateQuestionText with INPUT PIPE', updateQuestionText)


              }
            


            })
           

          
          })

        }

        if (element.type === 'text') {

          let thePipeableIds = this.extractPipeableIds(element.code_block)

          console.log('thePipeableIds to put in text', thePipeableIds)

          thePipeableIds.forEach(pipeableId => {

            
            //see if this matches in the questionsAnsweredArray
            this.questionsAnsweredArray.forEach(questionData => {

              if (questionData.question_pipeable_id === pipeableId) {

                let answerString = ''
                let answers = questionData.answers.map(ans => ans.answer_text)
 
                answerString = answers.join(', ')

                if (answers.length > 1) {
                  let lastCommaIndex = answerString.lastIndexOf(', ')

                  answerString =
                    answerString.substring(0, lastCommaIndex) +
                    ' & ' +
                    answerString.substring(lastCommaIndex + 2)
                }

                var updateText = this.replaceSpecificIdWithText(element.code_block, pipeableId, answerString)

                element.code_block = updateText

                // console.log("updatedHeaderText BLOCK", updateText)
                

              }
              
           
              
            })


            //see if this matches in the inputFieldsAnsweredArray
            this.inputFieldsAnsweredArray.forEach((inputData) => {

              if (inputData.input_field_pipeable_id === pipeableId) {

                let theInputValue = inputData.input_field_value

                var updateText = this.replaceSpecificIdWithText(element.code_block, pipeableId, theInputValue)

                element.code_block = updateText

                // console.log('updateQuestionText with INPUT PIPE', updateQuestionText)


              }
            


            })
           

          
          })

          
          

        }


      })
    },

    checkWinningOutcomepPageForPipeableIds(winningOutcomePage){

      // let lasPage = this.defaultPagesArray[this.currentPageIndex + 1]

      //find matching string in question text and replace
      winningOutcomePage.design_layout.forEach(element => {

        if (element.type === 'question') {

          let thePipeableIds = this.extractPipeableIds(element.question_text)

          console.log('thePipeableIds to put in question text', thePipeableIds)

          thePipeableIds.forEach(pipeableId => {

            
            //see if this matches in the questionsAnsweredArray
            this.questionsAnsweredArray.forEach(questionData => {
              
              if (questionData.question_pipeable_id === pipeableId) {

                let answerString = ''
                let answers = questionData.answers.map(ans => ans.answer_text)
 
                answerString = answers.join(', ')

                if (answers.length > 1) {
                  let lastCommaIndex = answerString.lastIndexOf(', ')

                  answerString =
                    answerString.substring(0, lastCommaIndex) +
                    ' & ' +
                    answerString.substring(lastCommaIndex + 2)
                }

                var updateQuestionText = this.replaceSpecificIdWithText(element.question_text, pipeableId, answerString)

                element.question_text = updateQuestionText

                // console.log('updateQuestionText with question PIPE', updateQuestionText)

              }

            })


            //see if this matches in the inputFieldsAnsweredArray
            this.inputFieldsAnsweredArray.forEach((inputData) => {

              if (inputData.input_field_pipeable_id === pipeableId) {

                let theInputValue = inputData.input_field_value

                var updateQuestionText = this.replaceSpecificIdWithText(element.question_text, pipeableId, theInputValue)

                element.question_text = updateQuestionText

                // console.log('updateQuestionText with INPUT PIPE', updateQuestionText)


              }


            })
           

          
          })

       
        }

        if (element.type === 'header_text') {

          let thePipeableIds = this.extractPipeableIds(element.code_block)

          console.log('thePipeableIds to put in header', thePipeableIds)
          // console.log('header text', element.code_block)

          thePipeableIds.forEach(pipeableId => {

            
            //see if this matches in the questionsAnsweredArray
            this.questionsAnsweredArray.forEach(questionData => {

              if (questionData.question_pipeable_id === pipeableId) {

                let answerString = ''
                let answers = questionData.answers.map(ans => ans.answer_text)
 
                answerString = answers.join(', ')

                if (answers.length > 1) {
                  let lastCommaIndex = answerString.lastIndexOf(', ')

                  answerString =
                    answerString.substring(0, lastCommaIndex) +
                    ' & ' +
                    answerString.substring(lastCommaIndex + 2)
                }

                var updateHeaderText = this.replaceSpecificIdWithText(element.code_block, pipeableId, answerString)

                element.code_block = updateHeaderText

                // console.log("updateHeaderText", updateHeaderText)
                

              }
              
           
              
            })


            //see if this matches in the inputFieldsAnsweredArray
            this.inputFieldsAnsweredArray.forEach((inputData) => {

              if (inputData.input_field_pipeable_id === pipeableId) {

                let theInputValue = inputData.input_field_value

                var updateHeaderText = this.replaceSpecificIdWithText(element.code_block, pipeableId, theInputValue)

                element.code_block = updateHeaderText

                // console.log('updateQuestionText with INPUT PIPE', updateQuestionText)


              }
            


            })
           

          
          })

        }

        if (element.type === 'text') {

          let thePipeableIds = this.extractPipeableIds(element.code_block)

          console.log('thePipeableIds to put in text', thePipeableIds)

          thePipeableIds.forEach(pipeableId => {

            
            //see if this matches in the questionsAnsweredArray
            this.questionsAnsweredArray.forEach(questionData => {

              if (questionData.question_pipeable_id === pipeableId) {

                let answerString = ''
                let answers = questionData.answers.map(ans => ans.answer_text)
 
                answerString = answers.join(', ')

                if (answers.length > 1) {
                  let lastCommaIndex = answerString.lastIndexOf(', ')

                  answerString =
                    answerString.substring(0, lastCommaIndex) +
                    ' & ' +
                    answerString.substring(lastCommaIndex + 2)
                }

                var updateText = this.replaceSpecificIdWithText(element.code_block, pipeableId, answerString)

                element.code_block = updateText

                // console.log("updatedHeaderText BLOCK", updateText)
                

              }
              
           
              
            })


            //see if this matches in the inputFieldsAnsweredArray
            this.inputFieldsAnsweredArray.forEach((inputData) => {

              if (inputData.input_field_pipeable_id === pipeableId) {

                let theInputValue = inputData.input_field_value

                var updateText = this.replaceSpecificIdWithText(element.code_block, pipeableId, theInputValue)

                element.code_block = updateText

                // console.log('updateQuestionText with INPUT PIPE', updateQuestionText)


              }
            


            })
           

          
          })

          
          

        }


      })

    },

    addInputFieldsDataFromSecondToLastPage (secondToLastPage) {

      
      //first check current page for input fields and put them in array
      secondToLastPage.design_layout.forEach(element => {

        let inputFieldData = {
          input_field_id: null,
          type: null,
          input_field_pipeable_id: null,
          input_field_value: null
        }

        if (
          element.type === 'text_input' ||
          element.type === 'number_input' ||
          element.type === 'date_input' ||
          element.type === 'email_input' ||
          element.type === 'textbox' ||
          element.type === 'time_input'
        ) {
          inputFieldData.input_field_id = element.id
          inputFieldData.type = element.type
          inputFieldData.input_field_pipeable_id = element.pipeable_id
          inputFieldData.input_field_value = element.value //in future add a ternerary check here for number value else string


          // //dont forget to format date and time
          if(element.type === 'date_input'){

            inputFieldData.input_field_value = this.formatDateForPiping(element.value)

          }
          if(element.type === 'time_input'){

            inputFieldData.input_field_value = this.formatTimeForPiping(element.value)

          }

          console.log('BEFORE inputFieldData ADDED TO ARRAY', inputFieldData)

          this.inputFieldsAnsweredArray.push(inputFieldData)


        }
      })
    },



    // checkNextPageForPipableIdsFromInputFields () {
    //   let nextPage = this.defaultPagesArray[this.currentPageIndex + 1]

    //   //check for matching pipeable ids in next page
    //   nextPage.design_layout.forEach(element => {
    //     if (element.type === 'question') {
    //       let fullBracketContent = this.extractBracketContent(
    //         element.question_text
    //       )

    //       if (fullBracketContent) {
    //         let thePipeableId = this.extractPipeableId(fullBracketContent)

    //         if (thePipeableId) {
    //           //find match
    //           this.inputFieldsAnsweredArray.forEach(inputData => {

    //             if (inputData.input_field_pipeable_id === thePipeableId) {
    //               let theInputValue = inputData.input_field_value

    //               let updatedString = element.question_text.replace(
    //                 /\[.*?\]/g,
    //                 theInputValue
    //               )

    //               element.question_text = updatedString
    //             }
    //           })
    //         }
    //       }
    //     }

    //     // if (element.type === 'header_text') {

    //     //   let fullBracketContent = this.extractBracketContent(element.code_block)

    //     //   if (fullBracketContent) {

    //     //     console.log('fullBracketContent', fullBracketContent)

    //     //   }
    //     // }
    //   })
    // },

    extractBracketContent (str) {
      var matches = str.match(/\[(.*?)\]/)

      if (matches) {
        return matches[1]
      } else {
        return null
      }
    }, 

    extractPipeableIds (str) {
      var matches = str.match(/\{(.*?)\}/g)

      if (matches) {
        return matches.map(match => match.slice(1, -1))
      } else {
        return []
      }
    },

    replaceSpecificIdWithText(str, pipeableId, replacementText) {

      const regex = new RegExp(`\\{${pipeableId}\\}`, 'g');
    
      return str.replace(regex, replacementText);
    },

    formatDateForPiping (date) {
      var formatedDate = moment(date).format('MMMM D, YYYY')

      return formatedDate;
    },

    formatTimeForPiping (date) {
      var formatedTime = moment(date).format('h:mm a')

      return formatedTime;
    },
    

    
    addDataCollectedFromInputFieldsToArray(){

       
      let currentPage = this.defaultPagesArray[this.currentPageIndex]

      //first check current page for input fields and put them in array
      currentPage.design_layout.forEach(element => {

        let inputFieldData = {
          input_field: null,
          input_field_string_value: null,
          input_field_number_value: null,
          is_checkbox_selected: null,
          checkbox_id: null
        }

        if (
          element.type === 'text_input' ||
          element.type === 'number_input' ||
          element.type === 'date_input' ||
          element.type === 'email_input' ||
          element.type === 'textbox' ||
          element.type === 'time_input' ||
          element.type === 'checkbox'

        ) {


          let originalElement = element;
          let copiedElement = JSON.parse(JSON.stringify(originalElement));

          // //dont forget to format date and time
          if(element.type === 'date_input'){

            copiedElement.value = this.formatDateForInputDataCollection(copiedElement.value)

          }

          if(element.type === 'time_input'){

            copiedElement.value = this.formatTimeForInputDataCollection(copiedElement.value)

          }


          
          if(element.type === 'checkbox'){

            inputFieldData.input_field = copiedElement
            inputFieldData.input_field_string_value = null
            inputFieldData.input_field_number_value = null
            inputFieldData.is_checkbox_selected = copiedElement.value ? 1 : 0  //if checkbox was checked ot not
            inputFieldData.checkbox_id = copiedElement.id

          }else{

            inputFieldData.input_field = copiedElement
            inputFieldData.input_field_string_value = copiedElement.value
            inputFieldData.input_field_number_value = copiedElement.number_value
            inputFieldData.is_checkbox_selected = null  
            inputFieldData.checkbox_id = null
            

          }



          this.inputFieldsDataCollected.push(inputFieldData) 
         


        }


      })


    },

    formatDateForInputDataCollection (date) {
      var formatedDate = moment(date).format('YYYY-MM-DD')

      return formatedDate;
    },

    formatTimeForInputDataCollection (date) {
      var formatedTime = moment(date).format('HH:mm')

      return formatedTime;
    },

    saveAllInputDataCollected(){

      const formData = {
        public_user_id: this.publicUser.id,
        input_fields: this.inputFieldsDataCollected

      }

      QuizService.saveAllFormFieldsData(formData)
        .then((response) => {

          console.log('THIS ONE HERE: ', response.data)
          
        }).catch((err) => {

          console.log(err)
          
        });


      // console.log("FINAL SAVE ALL INPUT DATA")

    },

    addDataCollectedFromInputFieldsFromSecondToLastPageToArray(page){

      //first check current page for input fields and put them in array
      page.design_layout.forEach(element => {

        let inputFieldData = {
          input_field: null,
          input_field_string_value: null,
          input_field_number_value: null,
          is_checkbox_selected: null,
          checkbox_id: null
        }

        if (
          element.type === 'text_input' ||
          element.type === 'number_input' ||
          element.type === 'date_input' ||
          element.type === 'email_input' ||
          element.type === 'textbox' ||
          element.type === 'time_input' ||
          element.type === 'checkbox'

        ) {


          let originalElement = element;
          let copiedElement = JSON.parse(JSON.stringify(originalElement));

          // //dont forget to format date and time
          if(element.type === 'date_input'){

            copiedElement.value = this.formatDateForInputDataCollection(copiedElement.value)

          }

          if(element.type === 'time_input'){

            copiedElement.value = this.formatTimeForInputDataCollection(copiedElement.value)

          }


          
          if(element.type === 'checkbox'){

            inputFieldData.input_field = copiedElement
            inputFieldData.input_field_string_value = null
            inputFieldData.input_field_number_value = null
            inputFieldData.is_checkbox_selected = copiedElement.value ? 1 : 0  //if checkbox was checked ot not
            inputFieldData.checkbox_id = copiedElement.id

          }else{

            inputFieldData.input_field = copiedElement
            inputFieldData.input_field_string_value = copiedElement.value
            inputFieldData.input_field_number_value = copiedElement.number_value
            inputFieldData.is_checkbox_selected = null  
            inputFieldData.checkbox_id = null
            

          }



          this.inputFieldsDataCollected.push(inputFieldData) 
         


        }


      })


    }
    
    
  }
})
