<template>
    <div>

        <div class="progressBarWrapper">

            <div class="wrapper">


                <div class="progressBar">

                    <div class="progressFill" :style="{ 
                        width: quizEditorStore.progressPercentage + '%',
                        background: quizEditorStore.selectedProgressBarColor,
                    }">

                    </div>

                </div>



            </div>


        </div>



    </div>
</template>

<script>
import { useQuizEditorStoreV2 } from "@/stores/QuizEditorStoreV2";

export default {

    name: 'MobileQuizProgressBar',


    setup() {

        const quizEditorStore = useQuizEditorStoreV2()




        return {
            quizEditorStore,
        }
    }


};
</script>

<style scoped>
.progressBarWrapper {
    /* position: sticky;
    top: 0;
    z-index: 13; */
   
}

.progressBar {
    width: 100%;
    height: 10px;
    background-color: rgba(177, 192, 177, 0.5);
    overflow: hidden;
   
}

.wrapper {
    width: 100%;

}

.progressFill {

    height: 100%;
    background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
    transition: width 0.8s ease;
}
</style>