import { defineStore } from 'pinia'
import WorkspaceService from '@/services/WorkspaceService.js'



export const useWorkspaceStore = defineStore('workspaceStore', {

    state: () => ({


        workspaces: [],

        workspaceFormData: {
            name: ''
        },

        currentWorkspace: {},
        currentWorkspaceObj: {},
        quizzes: [],
        

    }),



    actions: {


        async fetchWorkspaces(){

            WorkspaceService.getWorkspaces()
                .then((response) => {

                    this.workspaces = response.data.workspaces

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        async storeNewWorkspace(){

            WorkspaceService.storeWorkspace(this.workspaceFormData)
                .then((response) => {
                
                    //refresh list
                    this.fetchWorkspaces()

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });


        },

        async updateThisWorkspace(){

            this.workspaceFormData.name = this.currentWorkspace.name

            console.log(this.currentWorkspace.uuid, this.workspaceFormData)

            WorkspaceService.updateWorkspace(this.currentWorkspace.uuid, this.workspaceFormData)
                .then((response) => {

                    //refresh list
                    this.fetchWorkspaces()

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });

        },

        async deleteThisWorkspace(){

            WorkspaceService.deleteWorkspace(this.currentWorkspace.uuid)
                .then((response) => {

                    //refresh list
                    this.fetchWorkspaces()
                    
                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });


        },


        limitText(body) {

            if (body.length > 25) {
                return body.substring(0, 25) + '...';
            }

            return body;

        },

        async fetchThisWorkspace(uuid){

            //reset variables
            this.currentWorkspaceObj = {}
            this.quizzes = []

            WorkspaceService.getAWorkspace(uuid)
                .then((response) => {

                    this.currentWorkspaceObj = response.data.workspace
                    this.quizzes = response.data.quizzes

                    console.log(response.data)
                    
                }).catch((err) => {

                    console.log(err)
                    
                });
            

        },


        
        
       
        

    }

})

