<template>
    <div class="sideBar">



        <div class="logoWrapper">

            <img class="imgLogo" :src="require(`@/assets/images/logo_trans2.png`)" />

        </div>


        <div class="MainMenuWrapper">

            <div v-for="(menuItem, index) in generalStore.topMenuArray" :key="index">

                <div class="menuItemRow" v-if="menuItem.is_logout" @click="authStore.logoutUser">

                    <div class="iconArea">

                        <img class="menuIcon" :src="require(`@/assets/images/${menuItem.icon_name}.png`)"
                            :width="menuItem.width" />

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 7px;">{{ menuItem.label }}</span>

                    </div>

                </div>


                <div v-else-if="menuItem.route_name !== ''">

                    <router-link class="linkStyle" :to="{ name: menuItem.route_name }">

                        <div class="menuItemRow">


                            <div class="iconArea">

                                <img class="menuIcon" :src="require(`@/assets/images/${menuItem.icon_name}.png`)"
                                    :width="menuItem.width" />

                            </div>

                            <div class="labelArea">

                                <span style="float: left; margin-left: 7px;">{{ menuItem.label }}</span>

                            </div>

                        </div>

                    </router-link>

                </div>


                <div class="menuItemRow" v-else>

                    <div class="iconArea">

                        <img class="menuIcon" :src="require(`@/assets/images/${menuItem.icon_name}.png`)"
                            :width="menuItem.width" />

                    </div>

                    <div class="labelArea">

                        <span style="float: left; margin-left: 7px;">{{ menuItem.label }}</span>

                    </div>

                </div>

            </div>

        </div>


        <div class="resourcesWrapper">

            <div class="resourceHeader">
                Support
            </div>

            <div v-for="(menuItem, index) in generalStore.resourcesArray" :key="index">

                <div v-if="menuItem.link_url !== null">

                    <a :href="menuItem.link_url" target="_blank" class="linkStyle" :to="{ name: menuItem.route_name }">

                    <div class="menuItemRow">

                        <div class="iconArea">

                            <img class="menuIcon" :src="require(`@/assets/images/${menuItem.icon_name}.png`)"
                                :width="menuItem.width" />

                        </div>

                        <div class="labelArea">

                            <span style="float: left; margin-left: 7px;">{{ menuItem.label }}</span>

                        </div>

                    </div>


                </a> 

                </div>

                <router-link v-else class="linkStyle" :to="{ name: menuItem.route_name }">

                    <div class="menuItemRow">

                        <div class="iconArea">

                            <img class="menuIcon" :src="require(`@/assets/images/${menuItem.icon_name}.png`)"
                                :width="menuItem.width" />

                        </div>

                        <div class="labelArea">

                            <span style="float: left; margin-left: 7px;">{{ menuItem.label }}</span>

                        </div>

                    </div>


                </router-link> 







            </div>


        </div>




    </div>
</template>

<script>
import { useGeneralStore } from "@/stores/GeneralStore";
import { useAuthStore } from '../stores/AuthStore'

export default {
    name: 'MainSideBar',

    setup() {

        const generalStore = useGeneralStore()
        const authStore = useAuthStore()


        return {
            generalStore, authStore
        }

    }


};
</script>

<style scoped>
.logoWrapper {
    background-color: #a6baba;
    margin-bottom: 65px;
    /* padding: 15px; */
}

.imgLogo {
    float: left;
    width: 130px;
}

.MainMenuWrapper {
    margin-bottom: 30px;
}


.menuItemRow {
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 15px;
    cursor: pointer;
}

.menuItemRow:hover {
    background-color: rgb(232, 243, 234);
    border-radius: 10px;
}

.iconArea {
    /* background-color: #b8ffd3; */
    width: 10%;
    display: flex;
    align-items: center;
}

.labelArea {

    /* background-color: #36e1e1; */
    width: 80%;
    font-weight: 400;
    font-size: 15px;
    display: flex;
    align-items: center;

}

.resourceHeader {
    font-size: 14px;
    font-weight: bold;
    float: left;
    padding-left: 13px;
    margin-bottom: 10px;
    color: #3b4848;
}


.sideBar {
    background-color: #f9f9f9;
    width: 17%;
    overflow-y: auto;
    padding: 10px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}
</style>