<template>
    <div>

        <div class="container">

            <p class="h1">h1. Bootstrap heading</p>


            <p v-for="index in 3" :key="index" style="text-align: left;">Lorem ipsum, dolor sit amet consectetur adipisicing
                elit. Quia impedit, reiciendis in neque eaque voluptate quasi consequatur necessitatibus maxime earum nihil
                voluptatum. Quasi blanditiis, ab architecto vitae accusantium dignissimos recusandae?
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia Lorem ipsum, dolor sit amet consectetur
                adipisicing elit. Quia Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia Lorem ipsum, dolor sit
                amet consectetur adipisicing elit. Quia
            </p>

            <div>

                <iframe ref="theIframe" frameborder="0" src="http://localhost:3000/embed/a31n3a"
                    style="width:100%; overflow: hidden; transition-duration: 600ms;" scrolling="no"></iframe>


            </div>

            <p v-for="index in 4" :key="index" style="text-align: left;">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia impedit, reiciendis in neque eaque voluptate
                quasi consequatur necessitatibus maxime earum nihil voluptatum. Quasi blanditiis, ab architecto vitae
                accusantium dignissimos recusandae?
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia Lorem ipsum, dolor sit amet consectetur
                adipisicing elit. Quia Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia Lorem ipsum, dolor sit
                amet consectetur adipisicing elit. Quia Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia
            </p>




        </div>



    </div>
</template>

<script>
import { onMounted, ref } from 'vue';



export default {

    name: 'TestPage',


    setup() {
        const theIframe = ref(null);

        onMounted(() => {
            window.addEventListener('message', (event) => {
                if (typeof event.data === 'object' && event.data.height) {
                    theIframe.value.style.height = `${event.data.height}px`;
                }
            });

            theIframe.value.onload = () => {
                theIframe.value.contentWindow.postMessage({
                    height: theIframe.value.contentWindow.document.body.scrollHeight
                }, '*');
            };
        });

        return { theIframe };
    }


};
</script>

<style scoped></style>