<template>
    <div>

        <div class="dashboardBodyWrapper">


            <MainSideBarVue />



            <div class="MainContentArea">


                <div class="contentHeaderWrapper">

                    <div class="leftDivWrapper">

                        <div class="headerText">

                            {{ quizStore.currentQuiz.name }}

                        </div>

                        <div class="subHeaderText">

                            <div v-if="quizStore.currentQuiz.type === 'single_outcome'">
                                Type: Single Outcome
                            </div>

                            <div v-if="quizStore.currentQuiz.type === 'answer_based_outcomes'">
                                Type: Answer Based Outcomes
                            </div>

                            <div v-if="quizStore.currentQuiz.type === 'score_based_outcomes'">
                                Type: Score Based Outcomes
                            </div>


                        </div>


                    </div>

                    <div class="rightDivWrapper">

                        <button class="button-2" role="button">

                            Export Data
                        </button>


                    </div>



                </div>


                <center>
                    <hr class="dividerLine">
                </center>

                <div class="tableHeaderWrapper">

                    <div class="leftDivWrapper">

                        <div class="tableHeaderText">

                            Leads

                        </div>



                    </div>

                    <div class="rightDivWrapper">



                    </div>



                </div>



                <div class="contentBodyWrapper">




                    <table class="table table-bordered table-hover">

                        <thead>
                            <tr>
                                <th scope="col">Submitted at</th>
                                <th scope="col">Form Completion</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Country</th>
                                <th scope="col">Device</th>

                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="(publicUser, index) in quizStore.publicUsers" :key="publicUser.id" class="rowBG"
                                @mouseover="quizStore.currentResponseIndex = index"
                                @mouseleave="quizStore.currentResponseIndex = null"
                                @click="quizStore.fetchDataAndOpenResponseModal(publicUser.uuid)">

                                <td>
                                    {{ publicUser.submission_date }} at {{ publicUser.submission_time }}
                                </td>

                                <td>

                                    <div v-if="publicUser.completion_type === 'full'">
                                        Full
                                    </div>
                                    <div v-else>
                                        Partial
                                    </div>

                                </td>

                                <td>
                                    {{ publicUser.completion_duration_time }}
                                </td>

                                <td>
                                    <div v-if="publicUser.geo_data">
                                        {{ publicUser.geo_data.city }}, {{ publicUser.geo_data.country_name }}
                                    </div>
                                    <div v-else>
                                        N/A
                                    </div>

                                </td>

                                <td>
                                    {{ publicUser.device_type }}
                                </td>



                            </tr>


                        </tbody>


                    </table>




                </div>



            </div>


        </div>


        <!-- Modal -->
        <div v-if="quizStore.showModalResponseDetailModal" class="modal-overlay">

            <div class="modal-box">


                <img class="closeX" @click="quizStore.closeResponseDetailModal"
                    :src="require(`@/assets/images/x_close_black.png`)" />

                <div class="modal-content">

                    <div class="tabsWrapper">


                        <div class="tabBtn" v-for="(responseTabOption, index) in quizStore.responseTabOptions" :key="index"
                            :class="{ tabBtnClicked: responseTabOption.type === quizStore.currentResponseTabType }"
                            @click="quizStore.currentResponseTabType = responseTabOption.type">
                            {{ responseTabOption.name }}
                        </div>


                    </div>

                    <div class="modalBodyWrapper">


                        <div v-if="quizStore.currentResponseTabType === 'form_data'">




                            <ul class="list-group list-group-flush">

                                <li v-for="(formInputValue, index) in quizStore.publicUserResponseData.formData"
                                    :key="index" class="list-group-item">


                                    <div style="float: left; margin-top: 10px; margin-bottom: 10px;"
                                        v-if="formInputValue.input_field_details">


                                        <div v-if="formInputValue.type === 'text_input'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b>
                                            {{ formInputValue.string_value }}

                                        </div>

                                        <div v-if="formInputValue.type === 'date_input'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b> {{
                                                quizStore.formatDate(formInputValue.string_value) }}

                                        </div>

                                        <div v-if="formInputValue.type === 'time_input'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b> {{
                                                quizStore.formatTime(formInputValue.string_value) }}

                                        </div>

                                        <div v-if="formInputValue.type === 'email_input'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b>
                                            {{ formInputValue.string_value }}

                                        </div>

                                        <div v-if="formInputValue.type === 'textbox'">

                                            <b>{{ formInputValue.input_field_details.label }}:</b>
                                            {{ formInputValue.string_value }}

                                        </div>



                                    </div>


                                    <div v-if="formInputValue.type === 'checkbox'"
                                        style="float: left; margin-top: 10px; margin-bottom: 10px;">

                                        <div v-if="formInputValue.checkbox_details">

                                            <div v-if="formInputValue.is_checkbox_selected === 1">
                                                <b>{{ formInputValue.checkbox_details.text_body }}:</b>
                                                <img width="20" :src="require(`@/assets/images/yes_checked.png`)" /> Checked
                                            </div>

                                            <div v-else>
                                                <b>{{ formInputValue.checkbox_details.text_body }}: </b>
                                                <img width="15" :src="require(`@/assets/images/not_checked1.png`)" /> Not
                                                checked
                                            </div>





                                        </div>

                                    </div>



                                </li>

                            </ul>






                        </div>

                        <div v-if="quizStore.currentResponseTabType === 'questions'">







                            <ul class="list-group list-group-flush">


                                <div v-if="quizStore.currentQuiz.type === 'answer_based_outcomes'" class="outcomeContainer">

                                    
                                    <div v-if="quizStore.publicUserResponseData.winning_outcome">
                                        Outcome: {{ quizStore.publicUserResponseData.winning_outcome.page_details.page_title }}
                                    </div> 
 

                                </div>

                                <div v-if="quizStore.currentQuiz.type === 'score_based_outcomes'" class="outcomeContainer">

                                    
                                    <div v-if="quizStore.publicUserResponseData.scoreOutcome">
                                        Score: {{ quizStore.publicUserResponseData.scoreOutcome.score}} / {{ quizStore.publicUserResponseData.totalQuestionsCount}}
                                    </div> 
 

                                </div>



                                <li v-for="(question, index) in quizStore.publicUserResponseData.quiz_results" :key="index"
                                    class="list-group-item">

                                    <div style="float: left;">




                                        <div class="questionContainer">

                                            <div class="questionArea">

                                                {{ index + 1 }} - {{ question.question_text }}

                                            </div>

                                            <div class="answerArea">


                                                <div v-for="(answer, index) in question.answers" :key="index"
                                                    class="answerBox">
                                                    {{ answer.answer_text }}
                                                </div>


                                            </div>


                                            <br>
                                        </div>




                                    </div>




                                </li>




                            </ul>



                        </div>

                        <div v-if="quizStore.currentResponseTabType === 'metadata'">


                            <!-- <div style="float: left; margin-top: 10px; margin-bottom: 10px;">
                            </div> -->


                            <ul class="list-group list-group-flush">



                                <li class="list-group-item">

                                    <div style="float: left;">

                                        <b>Country:</b> {{ quizStore.publicUserResponseData.meta_data.geoData.country_name
                                        }}

                                    </div>


                                </li>

                                <li class="list-group-item">

                                    <div style="float: left;">
                                        <b>Submission Time:</b> {{
                                            quizStore.publicUserResponseData.meta_data.submission_time }}
                                    </div>



                                </li>

                                <li class="list-group-item">

                                    <div style="float: left;">
                                        <b>Submission Date:</b> {{
                                            quizStore.publicUserResponseData.meta_data.submission_date }}
                                    </div>



                                </li>

                                <li class="list-group-item">

                                    <div style="float: left;">
                                        <b>Device Type:</b> {{ quizStore.publicUserResponseData.meta_data.device_type }}
                                    </div>



                                </li>

                                <li class="list-group-item">

                                    <div style="float: left;">
                                        <b>Duration:</b> {{ quizStore.publicUserResponseData.meta_data.duration }}
                                    </div>


                                </li>


                            </ul>






                        </div>


                    </div>






                </div>
            </div>

        </div>

    </div>
</template>

<script>

import MainSideBarVue from '@/components/MainSideBar.vue';
import { useQuizStore } from "@/stores/QuizStore";
import { onMounted } from 'vue';


export default {
    name: 'ViewQuizResponse',

    components: {
        MainSideBarVue
    },

    props: ['quiz_random_string'],

    setup(props) {

        const quizStore = useQuizStore();



        onMounted(async () => {

            await quizStore.getAllQuizResponses(props.quiz_random_string);

        });



        return {

            quizStore

        }
    }


};
</script>

<style scoped>
.dashboardBodyWrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.MainContentArea {
    width: 83%;
    overflow-y: auto;

}



.contentHeaderWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    /* background-color: antiquewhite; */
    display: flex;
}

.tableHeaderWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 14px;
    display: flex;
}

.contentBodyWrapper {
    /* background-color: aquamarine; */
    padding-left: 25px;
    padding-right: 25px;

}

.leftDivWrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* background-color: gray; */
    align-items: flex-start;
}

.rightDivWrapper {
    /* background-color: rgb(206, 197, 206); */
    width: 50%;
}


.dividerLine {
    width: 95%;
    color: #afb2c6;
}

.headerText {
    font-weight: bold;
    font-size: 25px;

}

.tableHeaderText {
    font-weight: bold;
    font-size: 19px;

}

.subHeaderText {
    font-size: 13px;
    margin-top: 4px;
}


.button-2 {
    background-color: rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    border-width: 0;
    color: #333333;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 10px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    float: right;
}


.button-2:hover {
    background-color: #0bc01a21;
}

.imgIcon {
    margin-right: 6px;
    width: 15px;
}

.rowBG {
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box {
    background-color: white;
    width: 1100px;
    padding: 20px;
    /* border-radius: 5px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}


.modal-content {
    text-align: center;
}


.modalBodyWrapper {
    width: 100%;
    /* background-color: #afb2c6; */
    max-height: 600px;
    /* Set a maximum height */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.tabBtn {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #c7e6dd;
    font-size: 13px;
    color: rgb(8, 5, 5);
    cursor: pointer;
}

.tabBtnClicked {
    background: #18191d;
    color: white;
    border-color: #18191d;
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
}

.tabBtn:hover {
    background: #18191d;
    color: white;
    border-color: #18191d;
}

.tabsWrapper {
    display: flex;
    background: yellow;
}


.closeX {

    width: 15px;
    float: right;
    margin-bottom: 18px;
    cursor: pointer;


}


.questionContainer {
    display: flex;
    flex-direction: column;
    /* background-color: gray; */
    align-items: flex-start;
    margin-top: 20px;
}

.questionArea {
    font-weight: bold;
    font-size: 17px;
}

.answerArea {
    font-size: 15px;
}


.answerBox {
    max-width: 500px;
    /* background-color: #afb2c6; */
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 10px;
    margin-bottom: 10px;
}


.outcomeContainer {
    max-width: 300px;
    /* background-color: #8c98e4; */
    padding: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}</style>