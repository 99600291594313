import apiClient from "./MyClient";
import { useAuthStore } from '@/stores/AuthStore'


export default{ 

    

    async storeThisQuiz(formData, workspaceUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/workspace/quiz/store/' + workspaceUUID, formData);

    },

    async updateThisQuiz(formData, quizUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/update/' + quizUUID, formData);

    },

    async deleteThisQuiz(quizUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/delete/' + quizUUID);

    },

    //v1
    async fetchQuizData(quizUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/quiz/' + quizUUID);

    },

    //v2
    async fetchQuizDataForEditorV2(quiz_random_string){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/quiz/v2/' + quiz_random_string);

    },


    async saveANewPage(quizId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/newpage/save/' + quizId); 


    },

    async saveNewOutcomePage(quizId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/newoutcomepage/save/' + quizId); 

    },


    async deleteAPage(pageId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/page/delete/' + pageId); 


    },



    async saveQuiz(formData, quizId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/save/' + quizId, formData); 

    },

    async saveQuizAnswerMapping(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/save/answer/mapping/', formData); 

    },


    async addNewAnswer(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/question/add/newanswer', formData); 

    },


    async updateAnswerNavigationType(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/answer/navigation/update/', formData); 

    },

    async deleteThisAnswer(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/delete/answer/', formData); 

    },

    async deleteAnswerToOutcomeMapping(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/delete/answer/mapping/', formData); 

    },


    async storePageElement(formData, pageId){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/pageelement/store/' + pageId, formData); 

    },

    async deletePageElement(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/pageelement/delete/', formData); 


    },

    async saveTheWholeQuizProject(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/save/project/', formData); 

    },

    async fetchLiveQuizData(random_string){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/live/quiz/' + random_string);

    },


    async storeQuizQuestionAnswer(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/store/answer/', formData); 

    },



    async saveFormInputFieldValue(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/forminput/save/', formData); 

    },


    async saveThisDuration(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/duration/', formData); 

    },

    async markThisQuizCompletion(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/completion/type/', formData); 

    },

    async updateQuizVisitCounter(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/visit/count', formData); 

    },

    async saveThisDeviceType(formData){  

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/device/type/', formData); 

    },

    async saveThisQuizOutcomeInDB(formData){  

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/save/this/outcome/', formData); 

    },


    async fetchQuizResponses(random_string){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/quiz/responses/' + random_string); 

    },

    async toggleEmailNotification(formData){  

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/submission/notification/toggle/email/', formData); 

    },


    async fetchAPublicUserDataForAQuiz(public_user_UUID){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/quiz/publicuser/' + public_user_UUID); 

    },

    async exportDataAsExcel(quiz_random_string){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/export/all/quiz/submissions/' + quiz_random_string, { responseType: 'blob' }); 

    },

    async exportSubmissionsAsCSV(quiz_random_string){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/export/csv/all/quiz/submissions/' + quiz_random_string, { responseType: 'blob' }); 

    },


    async deleteThisSocialPlaform(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/delete/socialplaform/', formData); 

    },

    async addNewSocialPlaform(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/add/socialplaform/', formData); 

    },

    async uploadImageForImageElement(formData){  

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token;
        
        //for file uploads IMPORTANT
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return apiClient.post('api/upload/image/for/image-element/', formData, config); 

    },

    async uploadAnswerImage(formData){  

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token;
        
        //for file uploads IMPORTANT
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return apiClient.post('api/upload/answer/image/', formData, config); 

    },

    async uploadQuizBGImage(formData){  

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token;
        
        //for file uploads IMPORTANT
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return apiClient.post('api/upload/quiz-outer-bg/image/', formData, config); 

    },
    

    async getSummaryData(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/quiz/analytics/data/', formData); 

    },

    async publishProjectLive(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/publish/live/', formData);  

    },


    async aIGenerateQuizContent(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/ai/generate/quiz/', formData);   

    },

    async createQuizFromAIContent(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/create/ai/quiz/', formData);   

    },

    async saveAllFormFieldsData(formData){ 

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/quiz/save/all/forminputs/', formData); 

    },

    
  
    
  
}
