<template>
    <div>

        <div class="dashboardBodyWrapper">


            <MainSideBarVue />



            <div class="MainContentArea">


                <div class="contentHeaderWrapper">

                    <div class="leftDivWrapper">

                        <div class="headerText">

                            Create Quiz

                        </div>



                    </div>

                    <div class="rightDivWrapper">


                        <!-- USAGE STATES HERE -->


                    </div>



                </div>


                <center>
                    <hr class="dividerLine">
                </center>



                <div class="contentBodyWrapper">


                   <!-- quiz type: {{ quizStore.currentlySelectedQuizType.type }} | quizContent: {{ quizStore.quizContent }} -->

                    


                    <div v-if="quizStore.currentStep === 'select_creation_option'">

                        <div class="section1">

                            <div class="boxOption"
                                :class="{ boxOptionSelected: quizStore.createQuizFormData.creationOption === 'create_with_ai' }"
                                @click="quizStore.setQuizCreationOption('create_with_ai')">

                                <div style="font-size: 16px;">
                                    <b>Create Quiz With AI </b>
                                </div>

                                <div style="font-size: 12px; margin-top: 10px;">
                                    Let AI generate the quiz questions and marketing copy
                                </div>

                            </div>

                            <div class="boxOption"
                                :class="{ boxOptionSelected: quizStore.createQuizFormData.creationOption === 'create_manually' }"
                                @click="quizStore.setQuizCreationOption('create_manually')">

                                <div style="font-size: 16px;">
                                    <b>Create Manually </b>
                                </div>

                                <div style="font-size: 12px; margin-top: 10px;">
                                    You'll have to add the questions one by one and the marketing copy
                                </div>

                            </div>




                        </div>

                        <div class="bottomNavWrapper">

                            <div class="bottomNav">

                                <div class="leftSide">

                                    <button v-if="quizStore.currentStep !== 'select_creation_option'"
                                        class="prevBtn">Previous</button>


                                </div>

                                <div class="rightSide">

                                    <button @click="quizStore.nextStep1('select_quiz_type')" class="nextBtn">Next</button>

                                </div>


                            </div>

                        </div>



                    </div>

                    <div v-if="quizStore.currentStep === 'select_quiz_type'">

                        <div class="section2">

                            <div class="projectTypesContainer">


                                <div v-for="(quizType, index) in quizStore.quizTypes" :key="index"
                                    @click="quizStore.setCurrentQuizType(quizType)" class="projectItem"
                                    :class="{ projectItemSelected: quizStore.currentlySelectedQuizType.id === quizType.id }">

                                    <div class="projectImage">


                                        <img :src="require(`@/assets/images/${quizType.img_icon}.png`)"
                                            :width="quizType.width" />

                                    </div>

                                    <div class="projectName">

                                        {{ quizType.name }}

                                    </div>

                                    <div class="projectDesc">

                                        {{ quizType.desc }}

                                    </div>

                                </div>


                            </div>





                        </div>

                        <div class="bottomNavWrapper">

                            <div class="bottomNav">

                                <div class="leftSide">

                                    <button class="prevBtn" @click="quizStore.goBack1()">Previous</button>


                                </div>

                                <div class="rightSide">

                                    <button @click="quizStore.nextStep2()" class="nextBtn">Next</button>

                                </div>


                            </div>


                        </div>







                    </div>


                    <div v-if="quizStore.currentStep === 'create_quiz_manually'">

                        <div class="section1">


                            <div class="formWrapper">

                             


                                <div class="mb-3">
                                    <label class="form-label" style="font-size: 16px; margin-bottom: 10px; float: left;">
                                        <b>Quiz Name</b> 
                                    </label> 

                                    <input v-model="quizStore.quizFormData.name" type="text" 
                                   class="form-control inputBox" />


                                </div>

                                <button 
                                @click="quizStore.storeQuiz"
                                class="btn btn-dark"
                                        style="width: 100%; font-weight: bold;">
                                        Create
                                </button>


                             



                            </div>

                        </div>


                        <div class="bottomNavWrapper">

                            <div class="bottomNav">

                                <div class="leftSide">

                                    <button class="prevBtn" @click="quizStore.goBack2()">Previous</button>


                                </div>

                                <div class="rightSide">

                                    <!-- <button @click="quizStore.nextStep2()" class="nextBtn">Next</button> -->

                                </div>


                            </div>


                        </div>




                    </div>


                    <div v-if="quizStore.currentStep === 'get_user_prompt'">

                        <div class="section3">

                            <div class="formWrapper">

                                <div class="mb-3">

                                    <label class="form-label" style="font-size: 16px; margin-bottom: 10px; float: left;">
                                        <b>What kind of quiz funnel should be created?</b>
                                    </label>

                                    <textarea class="form-control prompt_box"
                                        v-model="quizStore.createQuizFormData.user_prompt"
                                        placeholder="Create a quiz to help real estate prospects identify their ideal living environment."
                                        maxlength="300"
                                        ></textarea>

                                </div>


                                <div class="mb-3">
                                    <label class="form-label" style="font-size: 16px; margin-bottom: 10px; float: left;">
                                        <b>How many questions?</b>
                                    </label>

                                    <input 
                                    @input="quizStore.checkQuestionLimit"
                                    v-model="quizStore.createQuizFormData.amt_of_questions" type="number"
                                    class="form-control inputBox" max="15" min="3" />


                                </div>

                                <div class="mb-3">
                                    <label class="form-label" style="font-size: 16px; margin-bottom: 10px; float: left;">
                                        <b>How many answers per question?</b>
                                    </label>

                                    <input 
                                    v-model="quizStore.createQuizFormData.amt_of_answers_per_question" 
                                    @input="quizStore.checkAnswersLimit"
                                    type="number"
                                    class="form-control inputBox" min="2" max="4" />


                                </div>

                                <div class="mb-3"
                                    v-if="quizStore.currentlySelectedQuizType.type === 'answer_based_outcomes'">
                                    <label class="form-label" style="font-size: 16px; margin-bottom: 10px; float: left;">
                                        <b>How many outcomes should be generated?</b>
                                    </label>

                                    <input 
                                    v-model="quizStore.createQuizFormData.amt_of_outcomes" 
                                    @input="quizStore.checkOutcomeLimit"
                                    type="number"
                                    class="form-control inputBox" min="2" max="4" />


                                </div>

                                <div class="mb-3">



                                    <div v-if="quizStore.isLoading" class="loadingDiv">

                                        <div>
                                            Generating quiz please wait...
                                        </div>

                                        <div class="spinner-grow text-success" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>

                                    </div>


                                    <button v-else @click="quizStore.generateAIQuizContent" class="btn btn-dark"
                                        style="width: 100%; font-weight: bold;">
                                        Generate
                                    </button>


                                </div>



                            </div>





                        </div>


                        <div class="bottomNavWrapper">

                            <div class="bottomNav">

                                <div class="leftSide">

                                    <button class="prevBtn" @click="quizStore.goBack2()">Previous</button>


                                </div>

                                <div class="rightSide">

                                    <!-- <button @click="quizStore.nextStep2()" class="nextBtn">Next</button> -->

                                </div>


                            </div>


                        </div>



                    </div>





                    <!-- <button @click="quizStore.openResultsModal" >OPEN</button> -->





                </div>



            </div>


            <div v-if="quizStore.showResultsModal" class="modal-overlay1">

                <div class="modal-box1">

                    <div class="modalNav">

                        <div class="space1">

                            <!-- <button class="button-13"
                            style="margin-right: 9px;"
                            role="button">Copy Results</button> -->

                            <button @click="quizStore.closeResultsModal" :disabled="quizStore.isLoading" class="button-13"
                                role="button">Regenerate</button>


                        </div>

                        <div class="space2">


                        </div>

                        <div class="space3">


                            <button v-if="quizStore.isLoading" class="button-24" role="button" disabled>Creating your quiz
                                funnel please wait...</button>

                            <button v-else @click="quizStore.createTheQuizFromAIResults" class="button-24"
                                role="button">Create Quiz With These Results</button>

                        </div>

                    </div>


                    <div class="modal-content1">


                        <div v-for="(question, index) in quizStore.quizContent.questions" :key="index" class="questionItem">

                            <div class="questionText">
                                {{ question.question_text }}
                            </div>

                            <ul class="list-group" style="width: 100%;">

                                <li v-for="(answer, index) in question.answers" :key="index"
                                    class="list-group-item d-flex justify-content-between align-items-center"
                                    style="text-align: left;">
                                    {{ answer.answer_text }}

                                    <div v-if="quizStore.currentlySelectedQuizType.type === 'score_based_outcomes'">

                                        <span v-if="answer.is_correct" class="badge bg-dark rounded-pill">Correct</span>

                                    </div>

                                    <div v-if="quizStore.currentlySelectedQuizType.type === 'answer_based_outcomes'">

                                        <span style="font-size: 11px;">Mapped to </span>
                                        <span style="font-size: 11px;" class="badge bg-dark rounded-pill">
                                            {{ answer.outcome_name }}
                                        </span>

                                    </div>


                                </li>





                            </ul>


                        </div>





                    </div>

                </div>

            </div>


        </div>



    </div>
</template>

<script>
import MainSideBarVue from '@/components/MainSideBar.vue';
import { useAuthStore } from "@/stores/AuthStore";
import { useQuizStore } from '@/stores/QuizStore'
import { onMounted, onBeforeUnmount } from 'vue';



export default {
    name: 'CreateQuiz',

    props: ['workspaceUUID'],

    components: {
        MainSideBarVue,
    },



    setup(props) {

        const authStore = useAuthStore();
        const quizStore = useQuizStore()



        onMounted(() => {

            authStore.getUserData()

            //workspacee uuid
            quizStore.createQuizFormData.workspaceUUID = props.workspaceUUID


        });


        onBeforeUnmount(() => {
            
            //reset state
            quizStore.resetForm()


        });


        return {
            authStore, quizStore
        }

    }


};
</script>

<style scoped>
.dashboardBodyWrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}


.MainContentArea {
    width: 83%;
    overflow-y: auto;
    padding-bottom: 30px;
}



.contentHeaderWrapper {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    /* background-color: antiquewhite; */
    display: flex;
}

.contentBodyWrapper {
    /* background-color: aquamarine; */
    padding-left: 25px;
    padding-right: 25px;

}

.leftDivWrapper {
    /* background-color: rgb(167, 167, 138); */
    width: 50%;
}

.rightDivWrapper {
    /* background-color: rgb(206, 197, 206); */
    width: 50%;
}

.headerText {
    font-weight: bold;
    font-size: 25px;
    float: left;
}

.dividerLine {
    width: 95%;
    color: #afb2c6;
}



.billingWrapper {
    width: 100%;
    /* background-color: #afb2c6; */
    display: flex;

}

.currentPlanWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
}

.headerPlan {
    text-align: left;
    font-weight: bold;
}

.subHeaderPlan {
    text-align: left;
}

.viewPlansWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;

}


.featuresAndInvoicesBox {
    display: flex;
}

.currentPlanFeatures {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.featureHeader {
    /* background-color: #afb2c6; */
    text-align: left;
    font-weight: bold;
}


.invoiceHeaderText {
    /* background-color: #afb2c6; */
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.invoicesContainer {
    /* background-color: #94d3a2; */
    height: 640px;
    overflow-y: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 5px;
    padding: 15px;
}

.featureItem {
    /* background-color: #94d3a2; */
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 5px;
    padding-left: 25px;
    padding-right: 25px;
    align-items: flex-start;
    font-size: 14px;

}




.featureDescWrapper {
    text-align: left;
}

.invoicesWrapper {
    width: 50%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 15px;
    padding: 10px;
    height: 700px;


}

.imgIcon {
    margin-right: 6px;
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}


/* CSS */
.button-3 {
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
}

.button-3:hover {
    background-color: #2c974b;
}

.button-3:focus {
    box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
    outline: none;
}

.button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, .1);
    color: rgba(255, 255, 255, .8);
    cursor: default;
}

.button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


.outerTabWrapper {
    /* background-color: #94d3a2; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.tabsBox {
    background-color: aliceblue;
    width: 400px;
}




.tabBtn {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #f1efef;
    background: rgb(255, 255, 255);
    font-size: 13px;
    color: rgb(8, 5, 5);
    cursor: pointer;
}

.tabBtnClicked {
    background: #efefef;
    color: rgb(33, 31, 31);
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    font-size: 13px;
    cursor: pointer;
    font-weight: bold;
}

.tabBtn:hover {
    background: #f9f9f9;
    color: rgb(33, 31, 31);
}

.tabsWrapper {
    display: flex;
    /* background: yellow; */
}

.plansBoxWrapper {
    display: flex;
    /* background-color: #afb2c6; */
    margin-top: 20px;
}

.planItem {
    /* background-color: aqua; */
    width: 100%;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
}

.planName {
    /* background-color: #f4f4f6; */
    width: 100%;
    padding-top: 15px;
    padding-bottom: 7px;
    font-size: 20px;
    font-weight: bold;
}

.planDesc {
    /* background-color: #d5d5d5; */
    width: 100%;
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
}

.priceArea {

    /* background-color: #988888; */
    width: 100%;
    padding-top: 15px;
    font-size: 21px;
    font-weight: bold;
}

.priceMoreInfoArea {
    width: 100%;
    font-size: 13px;
}

.ctaArea {
    /* background-color: #e7f8c1; */
    width: 100%;
    padding: 15px;

}

.featureItem {
    /* background-color: #94d3a2; */
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 5px;
}

.imgWrapper {
    margin-right: 10px;
}


.summaryWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.summaryGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.summaryItem {
    width: calc(33.33% - 10px);
    margin: 5px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: #ffffff;




}


.formWrapper {
    width: 450px;

}


.section1 {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 150px;

}


.section2 {
    width: 100%;
    margin-top: 150px;
}

.section3 {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 50px;
}



.boxOption {
    width: 300px;
    height: 200px;
    /* background-color: #94d3a2; */
    margin: 0 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 20px;

}

.boxOption:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
}

.boxOptionSelected {
    width: 300px;
    height: 200px;
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    cursor: pointer;
    margin: 0 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 20px;

}

.nextBtn {
    width: 80px;
    height: 30px;
    background-color: white;
    font-weight: bold;
    font-size: 12px;
    /* margin-top: 90px; */
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border: none;
}

.nextBtn:hover {
    background-color: rgb(0, 0, 0);
    color: white;
}

.prevBtn {
    width: 80px;
    height: 30px;
    background-color: white;
    font-weight: bold;
    font-size: 12px;
    /* margin-top: 90px; */
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border: none;
}

.prevBtn:hover {
    background-color: rgb(0, 0, 0);
    color: white;
}

.prompt_box {
    min-height: 100px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.inputBox {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}


.projectTypesContainer {
    display: flex;
    margin-top: 20px;

}

.projectItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background: gray; */
    background: linear-gradient(174.2deg, rgba(255, 244, 228, 1) 7.1%, rgba(240, 246, 238, 1) 67.4%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin: 10px;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    /* height: 300px; */
}


.projectItem:hover {

    background: linear-gradient(45deg, #a8d6c5 0%, #fdfbb4 100%);
    cursor: pointer;
    transform: scale(1);
}

.projectItemSelected {

    background: linear-gradient(45deg, #c6eadd 0%, #fdfbb4 100%);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin: 10px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;

}

.projectImage {
    /* background: yellow; */
    /* background: linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% ); */
    /* border-top-left-radius: 10px;
border-top-right-radius: 10px; */
    width: 100%;
    height: 100px;
    padding: 20px;
}

.projectName {
    /* padding: 20px; */
    /* background: rgb(217, 217, 192); */
    width: 100%;
    font-weight: bold;
    /* height: 100%; */
}

.projectDesc {

    /* background: rgb(173, 206, 146); */
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    font-size: 15px;

}


.bottomNavWrapper {
    display: flex;
    justify-content: center;
    width: 100%;

}

.bottomNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    /* background-color: #afb2c6; */
    margin-top: 150px;
}

.loadingDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.modal-overlay1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    z-index: 999;
    /* Ensure the modal is on top */
}

/* Styles for the modal box */
.modal-box1 {

    background-color: white;
    width: 980px;
    /* padding: 20px; */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 600px;

}

/* Styles for modal content (customize as needed) */
.modal-content1 {

    height: 500px;
    overflow-y: auto;
    padding: 20px;


}


.modalNav {
    display: flex;
    background-color: #f7f7f7;
    width: 100%;
    padding: 10px;
    align-items: center;


}

.space1 {
    width: 25%;


}

.space2 {
    width: 5%;
}

.space3 {
    width: 70%;
}

.useThisBtn {
    float: right;
}

.copyResultsBtn {
    float: left;
}

.regenBtn {
    float: left;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.button-24 {
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    float: right;
}

.button-24:hover,
.button-24:active {
    background-color: initial;
    background-position: 0 0;
    color: #FF4742;
}

.button-24:active {
    opacity: .5;
}

.button-13 {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 11px;
    line-height: 29px;
    padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 100px;
    float: left;
}

.button-13:hover {
    background-color: #f7fafa;
}

.button-13:focus {
    border-color: #292727;
    border-width: 1.5px;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
    outline: 0;
}


.questionItem {
    display: flex;
    flex-direction: column;
    /* background-color: #a8d6c5; */
    align-items: flex-start;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 27px;
}

.questionText {
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
}

.answerText {
    text-align: left;
}
</style>