
<template>
    <div class="loginWrapper"> 

        <div class="topNav">

            <img class="imgLogo" :src="require(`@/assets/images/logo_trans2.png`)" />

        </div>

        <div class="formWrapper"> 


            <div v-if="authStore.successMessage" class="successAlert" style="max-width: 400px;">
                {{ authStore.successMessage }}
            </div>

            <div v-if="authStore.errorMessage"  class="dangerAlert" style="max-width: 400px;">
                {{ authStore.errorMessage }}
            </div>

            


            <form @submit.prevent="authStore.forgotPassword">

                <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label" style="float: left;">Email address</label>
                    <input v-model="authStore.email" type="email" class="form-control" required>
                </div>

         
                

                <div v-if="authStore.isLoading">

                    <button type="button" class="btn btn-primary loginBTN" disabled>Processing...</button>

                </div>

                <div v-else>
                    <button type="submit" class="btn btn-primary loginBTN">Send Password Reset Link</button> 
                </div>
               
                

            </form>

         




        </div>

    </div>
</template>

<script>

import { useAuthStore } from '../../stores/AuthStore'

export default {
    name: 'ForgotPassword',


    setup() {

        const authStore = useAuthStore()


        return {
            authStore
        }

    }


};
</script>

<style scoped>
.imgLogo {
    float: left;
    width: 170px;
}

.loginWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* background-color: aliceblue; */
}

.topNav {
    /* background-color: rgb(255, 255, 255); */
    width: 100%;
    position: fixed;
    top: 0;
}

.formWrapper {
    /* Styling for form wrapper */
}

.loginBTN {
    background-color: black;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
}

.loginBTN:hover {
    background-color: rgb(36, 141, 48);
}

.form-label {
    font-weight: bold;
}

.form-control {
    background-color: rgb(243, 246, 246);
}


.successAlert{
    /* background-color: rgb(182, 227, 182); */
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    font-weight: bold;
}

.dangerAlert{
    /* background-color: rgb(164, 22, 22); */
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    font-weight: bold;
    color: rgb(4, 1, 1);
}




</style>