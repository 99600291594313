// import axios from 'axios';
// import AuthService from '@/services/AuthService.js'

// const apiClient = axios.create({

//     baseURL: process.env.VUE_APP_BACKEND_API_URL,
//     withCredentials: true,
//     headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//     }
// })

// apiClient.interceptors.response.use(

//   response => response, // Simply return the response if no error

//   async error => {

//     const originalRequest = error.config;
    
//     // If the error is 401 (Unauthorized), and the request is not a token refresh, try to refresh the token
//     if (error.response.status === 401 && !originalRequest._retry) {

//       originalRequest._retry = true; // Mark the request as retried

//       // Try to refresh the token
//       const newToken = await AuthService.refreshToken();

//       // If the token refresh is successful, retry the original request with the new token
//       if (newToken) {
//         originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
//         return apiClient(originalRequest);
//       }

//       // If the token refresh fails, throw an error
//       throw error;
//     }

//     // If the error is not 401 or the request was a token refresh, throw the error
//     throw error;
//   }
// );

// export default apiClient;



import axios from 'axios';


const apiClient = axios.create({

    baseURL: process.env.VUE_APP_BACKEND_API_URL,
    withCredentials: true,
    
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        
    }


})



export default apiClient;