import { defineStore } from 'pinia'
import QuizService from '@/services/QuizService.js'
import moment from 'moment'
import { toast } from 'vue3-toastify'

export const useQuizStore = defineStore('quizStore', {
  state: () => ({

    quizFormData: {
      name: '',
      type: ''
    },

    currentWorkspace: {}, 
    currentQuiz: {},

    quizTypes: [
      {
        id: 1,
        name: 'Single Outcome',
        type: 'single_outcome',
        desc: 'Route all users to one final page',
        img_icon: 'single_outcome',
        width: 50
      },
      {
        id: 2,
        name: 'Answer-based Outcomes',
        type: 'answer_based_outcomes',
        desc: 'Route users to different final pages based on answers they have selected and show them custom content',
        img_icon: 'answer_based_outcomes',
        width: 50
      },
      {
        id: 3,
        name: 'Score Assessment',
        type: 'score_based_outcomes',
        desc: 'Provide a score based on how many questions the user answered correctly',
        img_icon: 'score_based_outcomes',
        width: 50
      }
    ],

    currentlySelectedQuizType: {
      id: 1,
      name: 'Single Outcome',
      type: 'single_outcome',
      desc: 'Route all users to one final page',
      img_icon: 'single_outcome',
      width: 50
    },

    responseTabOptions: [
      { id: 1, name: 'Form Data', type: 'form_data' },
      { id: 2, name: 'Questions', type: 'questions' },
      { id: 3, name: 'Metadata', type: 'metadata' }
    ],

    projectTabOptions: [ 
      { id: 1, name: 'Summary', type: 'summary' },
      { id: 2, name: 'Responses', type: 'responses' },
      { id: 3, name: 'Share', type: 'share' },
      { id: 4, name: 'Integrations', type: 'integrations' },
      { id: 5, name: 'Settings', type: 'settings' }
    ],

    embedOptions: [
      { id: 1, name: 'Standard', type: 'standard', icon: 'standard' },
      { id: 2, name: 'Popup', type: 'popup', icon: 'popup' },
      { id: 3, name: 'Full Page', type: 'fullpage', icon: 'fullpage' }
    ],

    popupOptions: [
      { id: 1, name: 'Immediate', type: 'load' },
      { id: 2, name: 'After Time Delay', type: 'timer' },
      { id: 3, name: 'On exit intent', type: 'exit' },
      { id: 4, name: 'On button click', type: 'button' }
    ],

    currentPopupOption: 'load',

    currentResponseTabType: 'form_data',

    currentProjectTabType: 'summary',

    currentResponseIndex: null,
    showModalResponseDetailModal: false,

    publicUsers: [],

    integrations: [],

    summaryData: {},

    publicUserResponseData: {
      formData: null,
      quiz_results: null,
      meta_data: null,
      winning_outcome: null,
      scoreOutcome: null,
      totalQuestionsCount: null
    },

    showEmbedModal: false,

    showPopupOptionsModal: false,

    defaultTime: 10,


    createQuizFormData: {

      creationOption: 'create_with_ai',  //default ,
      user_prompt: '',
      quiz_type: '',
      amt_of_questions: 5,
      amt_of_answers_per_question: 4,
      amt_of_outcomes: 2,
      workspaceUUID: ''
      

    },

    currentStep: 'select_creation_option',
    
    isLoading: false,

    showResultsModal: false, 

    quizContent: {},

    


  }),

  actions: {
    async storeQuiz (workspaceUUID) {
      this.quizFormData.type = this.currentlySelectedQuizType.type 

      if(this.quizFormData.name == ''){ 

        alert("Please give this quiz funnel a name")

      }

      console.log(this.quizFormData, workspaceUUID)

      QuizService.storeThisQuiz(this.quizFormData, this.createQuizFormData.workspaceUUID)
        .then(response => {
          var quiz = response.data.quiz

          this.router.push({
            name: 'MyEditorV2',
            params: { quiz_random_string: quiz.random_string } 
          })

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async updateQuiz () {
      this.quizFormData.name = this.currentQuiz.name

      QuizService.updateThisQuiz(this.quizFormData, this.currentQuiz.uuid)
        .then(response => {
          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async deleteQuiz () {
      QuizService.deleteThisQuiz(this.currentQuiz.uuid)
        .then(response => {

      

          console.log(response.data)

        })
        .catch(err => {
          console.log(err)
        })
    },

    setCurrentQuizType (quizType) {
      this.currentlySelectedQuizType = quizType

      console.log('QUIZ TYPE', this.currentlySelectedQuizType)
    },

    openResponseDetailModal () {
      this.showModalResponseDetailModal = true
    },

    closeResponseDetailModal () {
      this.showModalResponseDetailModal = false
    },

    openEmbedModal () {
      this.showEmbedModal = true
    },

    closeEmbedModal () {
      this.showEmbedModal = false
    },

    openPopupModalOptions () {
      this.showPopupOptionsModal = true
    },

    closePopupModalOptions () {
      this.showPopupOptionsModal = false
    },

    getAllQuizResponses (random_string) {
      QuizService.fetchQuizResponses(random_string)
        .then(response => {

          this.publicUsers = response.data.publicUsers
          this.currentQuiz = response.data.quiz
          this.summaryData = response.data.summaryData
          this.integrations = response.data.integrations

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    getQuizResponseDataForAPublicUser (public_user_UUID) {
      QuizService.fetchAPublicUserDataForAQuiz(public_user_UUID)
        .then(response => {
          this.publicUserResponseData.formData = response.data.formData
          this.publicUserResponseData.quiz_results =
            response.data.modifiedQuizResults
          this.publicUserResponseData.meta_data = response.data.metaData

          if (this.currentQuiz.type === 'answer_based_outcomes') {
            this.publicUserResponseData.winning_outcome =
              response.data.winning_outcome
          }

          if (this.currentQuiz.type === 'score_based_outcomes') {
            this.publicUserResponseData.scoreOutcome =
              response.data.scoreOutcome
            this.publicUserResponseData.totalQuestionsCount =
              response.data.totalQuestionsCount
          }

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    fetchDataAndOpenResponseModal (uuid) {
      this.openResponseDetailModal()

      this.getQuizResponseDataForAPublicUser(uuid)
    },

    formatDate (date) { 
      var formatedDate = moment(date).format('MMM D, YYYY')

      return formatedDate
    },

    formatTime (time) {
      var duration = moment.duration(time, 'seconds');
      // var hours = Math.floor(duration.asHours());
      var minutes = Math.floor(duration.minutes());
      var seconds = Math.floor(duration.seconds());

      return `${minutes}m ${seconds}s`;
      
      // var formatedTime = moment(time, 'HH:mm').format('h:mma')

      // return formatedTime 
    },

    buildShareLink () {
      const link = 'https://convertlyio.app/q/' + this.currentQuiz.random_string

      // const link = 'http://localhost:3000/q/' + this.currentQuiz.random_string

      return link
    },

    buildEmbedLink () {
      const link =
        'https://convertlyio.app/embed/' + this.currentQuiz.random_string

      // const link = 'http://localhost:3000/embed/' + this.currentQuiz.random_string

      return link
    },

    getIframeString () { 
      const src_url = this.buildEmbedLink()

      const iframeCode = `<iframe frameborder="0" src="${src_url}" id="${this.currentQuiz.random_string}"  style="width:100%; overflow: hidden; transition-duration: 600ms;" scrolling="no"></iframe><script>(() => { const activateConvertlyio = new CustomEvent('embedEvent', { detail: { id: '${this.currentQuiz.random_string}'}, }); document.dispatchEvent(activateConvertlyio); }) (); </script>`

      return iframeCode 
    }, 

    getScriptCodeForHeader () {
      // const code = `<script src="http://localhost:3000/embed.js"></script>`;
      const code = `<script src="https://convertlyio.app/embed.js"></script>`

      return code
    },

    getLoadPopupInstantlyCode () {
      const codeString = `<script>(() => {
              const activateConvertlyio = new CustomEvent('popupEvent', {
                detail: {
                  id: '${this.currentQuiz.random_string}',
                  type: 'popup',
                  triggerEvent: 'load',
                },
              });
              document.dispatchEvent(activateConvertlyio);
            }) (); 
            </script>`

      return codeString
    },

    getTimerPopupCode () {
      const codeString = `<script>(() => {
                const activateConvertlyio = new CustomEvent('popupEvent', {
                  detail: {
                    id: '${this.currentQuiz.random_string}',
                    type: 'popup',
                    triggerEvent: 'timer',
                    triggerTimer: ${this.defaultTime},
                  },
                });
                document.dispatchEvent(activateConvertlyio);
              }) (); 
              </script>`

      return codeString
    },

    getExitPopupCode () {
      const codeString = `<script>
            function activateConvertlyio() {
             const customEvent = new CustomEvent('popupEvent', {
               detail: { id: '${this.currentQuiz.random_string}', type: 'popup', triggerEvent: 'exit' },
             });
       
             document.dispatchEvent(customEvent);
             document.body.removeEventListener('mouseleave', activateConvertlyio);
                }
                document.body.addEventListener('mouseleave', activateConvertlyio);
            </script>`

      return codeString
    },

    getButtonCodeForPopup () {
      const codeString = `<button onclick="(() => { const convertlyioEvent = new CustomEvent('popupEvent', { detail: { id: '${this.currentQuiz.random_string}', type: 'popup', triggerEvent: 'button' } }); document.dispatchEvent(convertlyioEvent); })()">Click me</button>`

      return codeString
    },

    async copyCode (string) {
      if (string === 'headerScript') {
        try {
          await navigator.clipboard.writeText(this.getScriptCodeForHeader())
          alert('Copied')
        } catch (e) {
          alert('Cannot copy')
        }
      }

      if (string === 'immmediatePopup') {
        try {
          await navigator.clipboard.writeText(this.getLoadPopupInstantlyCode())
          alert('Copied')
        } catch (e) {
          alert('Cannot copy')
        }
      }

      if (string === 'timerPopup') {
        try {
          await navigator.clipboard.writeText(this.getTimerPopupCode())
          alert('Copied')
        } catch (e) {
          alert('Cannot copy')
        }
      }

      if (string === 'exitPopup') {
        try {
          await navigator.clipboard.writeText(this.getExitPopupCode())
          alert('Copied')
        } catch (e) {
          alert('Cannot copy')
        }
      }

      if (string === 'buttonPopup') {
        try {
          await navigator.clipboard.writeText(this.getButtonCodeForPopup())
          alert('Copied')
        } catch (e) {
          alert('Cannot copy')
        }
      }
    },

    setCurrentWorkspace (workspace) {
      this.currentWorkspace = workspace
    },

    toggleEmailNotify () {
      if (this.currentQuiz.should_email_notify === 'yes') {
        this.currentQuiz.should_email_notify = 'no'

        const formData = {
          toggle_value: this.currentQuiz.should_email_notify,
          quiz_id: this.currentQuiz.id
        }

        QuizService.toggleEmailNotification(formData)
          .then(response => {
            console.log(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.currentQuiz.should_email_notify = 'yes'

        const formData = {
          toggle_value: this.currentQuiz.should_email_notify,
          quiz_id: this.currentQuiz.id
        }

        QuizService.toggleEmailNotification(formData)
          .then(response => {
            console.log(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    exportDataAsExcel () {
      QuizService.exportDataAsExcel(this.currentQuiz.random_string)
        .then(response => {
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          })

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)

          // Open the URL on new Window
          const link = document.createElement('a')
          link.href = fileURL
          link.setAttribute('download', this.currentQuiz.name + '_file.xlsx')
          document.body.appendChild(link)
          link.click()

          console.log(response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    exportAllSubmissionsAsCSV () {
      QuizService.exportSubmissionsAsCSV(this.currentQuiz.random_string)
        .then(response => {
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], {
            type: 'text/csv;charset=UTF-8'
          })
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)
          // Open the URL on new Window
          const link = document.createElement('a')
          link.href = fileURL
          link.setAttribute('download', this.currentQuiz.name + '_file.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          console.log(err)
        })
    },


    setQuizCreationOption(option){

      this.createQuizFormData.creationOption = option

    },

    nextStep1(step){

      this.currentStep = step

    },

    nextStep2(){

      if(this.createQuizFormData.creationOption === 'create_manually'){

        this.currentStep = 'create_quiz_manually'

      }

      if(this.createQuizFormData.creationOption === 'create_with_ai'){

        this.currentStep = 'get_user_prompt'

      }

    },

    goBack1(){
      this.currentStep = 'select_creation_option'
    },

    goBack2(){
      this.currentStep = 'select_quiz_type'
    },

    createQuizManually(){
      console.log('create quiz manually')
    },


    generateAIQuizContent(){


      //check if user entered prompt
      if(this.createQuizFormData.user_prompt == ''){

        alert("Please describe what kind of quiz funnel should be created")

        return;

      }




      //if answer based quiz selected make sure to add outcome count
      if(this.currentlySelectedQuizType.type === 'answer_based_outcomes'){

        const formData = {
          user_prompt: this.createQuizFormData.user_prompt,
          quiz_type: this.currentlySelectedQuizType.type,
          amt_of_questions: this.createQuizFormData.amt_of_questions,
          amt_of_answers_per_question: this.createQuizFormData.amt_of_answers_per_question,
          amt_of_outcomes:  this.createQuizFormData.amt_of_outcomes
        }


        this.isLoading = true
        this.quizContent = {}

        QuizService.aIGenerateQuizContent(formData)
            .then((response) => {

              this.isLoading = false

              //check if ai credits limit reached else proceed
              let message = response.data.message

              if(message === 'ai_credit_limit_reached'){


                toast('You have reached your AI credit usage for the month. Please upgrade for more AI usage credits.', {
                  position: 'bottom-right', 
                  autoClose: 9000,
                })

              }

              if(message === 'proceed'){  


                this.quizContent = response.data.contentArray
                this.showResultsModal = true

                console.log('PROCESEED')

              }

            
              console.log(response.data)
              
            }).catch((err) => {

              console.log(err)
              
            });

        // console.log(formData)

      }else{

        const formData = {
          user_prompt: this.createQuizFormData.user_prompt,
          quiz_type: this.currentlySelectedQuizType.type,
          amt_of_questions: this.createQuizFormData.amt_of_questions,
          amt_of_answers_per_question: this.createQuizFormData.amt_of_answers_per_question,
          amt_of_outcomes: ''
        }

          this.isLoading = true
          this.quizContent = {}

          QuizService.aIGenerateQuizContent(formData)
          .then((response) => {

            this.isLoading = false

            //check if ai credits limit reached else proceed
            let message = response.data.message

            if(message === 'ai_credit_limit_reached'){


              toast('You have reached your AI credit usage for the month. Please upgrade for more AI usage credits.', {
                position: 'bottom-right', 
                autoClose: 9000,
              })

            }

            if(message === 'proceed'){  


              this.quizContent = response.data.contentArray
              this.showResultsModal = true

              console.log('PROCESEED')

            }

          
            console.log(response.data)
            
          }).catch((err) => {

            console.log(err)
            
          });
          

      }

      

      

    },


   

    createTheQuizFromAIResults(){


      const formData = {
        quizContent: this.quizContent, 
        workspaceUUID: this.createQuizFormData.workspaceUUID,
        quiz_type: this.currentlySelectedQuizType.type,
        amt_of_questions: this.createQuizFormData.amt_of_questions,
        amt_of_answers_per_question: this.createQuizFormData.amt_of_answers_per_question,
      }
 

      console.log(formData)

      QuizService.createQuizFromAIContent(formData)
          .then((response) => {


            //once success redirect to editor
            let quiz = response.data.newQuiz

            this.router.push({
              name: 'MyEditorV2',
              params: { quiz_random_string: quiz.random_string } 
            })

            this.showResultsModal = false
            this.quizContent = {}
            this.currentStep = 'select_quiz_type'
            this.resetForm()


            console.log(response.data)
            
          }).catch((err) => {

            console.log(err)
            
          });

    },


    openResultsModal(){ 
      this.showResultsModal = true 
    },

    closeResultsModal(){
      this.showResultsModal = false 
      this.quizContent = null
    },

    resetForm(){ 

      this.currentStep = 'select_creation_option'
      this.currentlySelectedQuizType = {
        id: 1,
        name: 'Single Outcome',
        type: 'single_outcome',
        desc: 'Route all users to one final page',
        img_icon: 'single_outcome',
        width: 50
      }

      //AI FORM
      this.createQuizFormData.creationOption = 'create_with_ai'
      this.createQuizFormData.user_prompt = '';
      this.createQuizFormData.quiz_type = 'single_outcome';
      this.createQuizFormData.amt_of_questions = 5;
      this.createQuizFormData.amt_of_answers_per_question = 4;
      this.createQuizFormData.amt_of_outcomes = 2;

      //MANUAL FORM
      this.quizFormData.name = ''
      this.quizFormData.type = ''
   
     
    },

    checkQuestionLimit(){

      if(this.createQuizFormData.amt_of_questions >= 15){

        alert('For now you can only generate a maximum of 15 questions. You can always add more in the editor.')

        this.createQuizFormData.amt_of_questions = 15


      }

      

    },

    checkAnswersLimit(){

      if(this.createQuizFormData.amt_of_answers_per_question >= 4){

        alert('For now you can only generate a maximum of 4 answers per question. You can always add more in the editor.')

        this.createQuizFormData.amt_of_answers_per_question = 4


      }

      

    },

    checkOutcomeLimit(){ 

      if(this.createQuizFormData.amt_of_outcomes >= 4){

        alert('For now you can only generate a maximum of 4 outcomes. You can always add more in the editor.')

        this.createQuizFormData.amt_of_outcomes = 4


      }

      

    }
   

    


  }
})
