import apiClient from "./MyClient";
import { useAuthStore } from '@/stores/AuthStore'


export default{ 

    
    registerUser(formData){
        return apiClient.post('api/register/admin', formData);
    },

    registerThisManager(formData){
        return apiClient.post('api/register/manager', formData);
    },

    loginUser(formData){
        return apiClient.post('api/auth/login', formData);
    },

   
    refreshToken() {
        const authStore = useAuthStore()
      
        return apiClient.post('api/refresh/token', { 
          refresh_token: authStore.state.token // the expired token
        })
        .then(response => {
          // Update the token in the store
          authStore.state.token = response.data.token
      
          return response.data.token
        })
        .catch(err => {
          console.error(err)
        });
      },
      


    userForgotPassword(formData){
        return apiClient.post('api/forgot-password', formData);
    },

    userResetPassword(formData){
        return apiClient.post('api/reset-password', formData);
    },

    fetchUserData(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/auth/user'); 
    },

    fetchBillingPlans(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/billing/plans');  

    },

    cancelThisPlan(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/billing/cancel/plan');   

    },

    swapPlan(planUUID){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/billing/swap/plan/' + planUUID);   

    },

    activatePaidPlanNow(paddle_plan_id){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/billing/activate/now/' + paddle_plan_id);   

    },


    sendSupportMessage(formData){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.post('api/support/message/send/', formData);   

    },


    verfiyAuthCode(formData){
        return apiClient.post('api/email/auth/code/verify', formData);
    },

    logout(){

        //this is the only endping that need to token
        const authStore = localStorage.getItem('authStore')
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(authStore).token

        return apiClient.post('api/auth/logout');
    },

    getAccountUsageData(){

        const authStore = useAuthStore()
        apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.token

        return apiClient.get('api/account/usage/data/');   

    },

  
}
